import React from 'react';
import { Field } from 'react-final-form';
import DiscountList from './Discounts';

const DiscountSection = (props) => {
  const { mode } = props;
  return <Field component={DiscountList} name="discounted_skus" mode={mode} />;
};

export default DiscountSection;
