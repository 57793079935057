import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import { getProductManagement } from 'api/products';
import { API } from 'axiosClient/config';
import normalizeSkuSubstitutesResponse from 'utils/normalizers/api/normalizeSkuSubstitutesResponse';
import normalizeWarehousesResponse from 'utils/normalizers/api/normalizeWarehousesResponse';
import { FETCH_SKU_DETAILS } from 'store/general/actions';
import PageContentLayout from 'components/layout/PageContentLayout';
import SetPageTitle from 'components/common/SetPageTitle';
import SearchInput from 'components/common/SearchInput';

import SkuSubstitutesTable from './SkuSubstitutesTable';
import userSelectors from 'store/user/selectors';
import { PLAYOSMO_PRODUCTS_WRITE } from 'config/roles';
import SkuSubstituteCreationForm from './SkuSubstituteCreationForm';
import ModalComponent from 'components/common/ModalComponent';
import SelectComponent from 'components/common/SelectDropdown/SelectComponent';

const SkuSubstitutesListing = ({
  // From Redux
  userRoles,
}) => {
  const [loading, setLoading] = useState(false);
  const [substitutes, setSubstitutes] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [filters, setFilters] = useState({});
  const [filteredRows, setFilteredRows] = useState(null);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const dispatch = useDispatch();
  const canEdit = userRoles && userRoles.includes(PLAYOSMO_PRODUCTS_WRITE);

  const warehouseDataItems =
    warehouses?.map((warehouse) => ({
      id: warehouse.code,
      value: warehouse.name,
    })) || [];

  const getTitle = () => 'SKU Substitutes';

  const fetchSkuSubstitutes = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getProductManagement(
        API.products.substitutes.list,
      );
      const normalizedSkuSubstitutes = normalizeSkuSubstitutesResponse(
        response.data.data,
      );
      setSubstitutes(normalizedSkuSubstitutes);
      setFilteredRows(normalizedSkuSubstitutes);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }, []);

  const fetchWarehouses = useCallback(async () => {
    try {
      const response = await getProductManagement(API.products.warehouses);
      const normalizedWarehouses = normalizeWarehousesResponse(
        response.data.data,
      );
      setWarehouses(normalizedWarehouses);
    } catch (e) {
      throw e;
    }
  }, []);

  useEffect(() => {
    fetchWarehouses();
  }, [fetchWarehouses]);

  useEffect(() => {
    fetchSkuSubstitutes();
  }, [fetchSkuSubstitutes]);

  useEffect(() => {
    dispatch({
      type: FETCH_SKU_DETAILS,
      payload: {
        callback: () => {},
        errorCallback: (error) => {},
      },
    });
  }, [dispatch]);

  const handleNewRow = () => {
    setShowCreationModal(true);
  };

  const filterSkuSubstitutesByIdOrName = (value, type) => {
    const newFilters = {
      ...filters,
      [type]: value,
    };

    const keyValue = newFilters.key?.toLowerCase() || '';
    const warehouseValue =
      newFilters.warehouse === 'ALL' ? '' : newFilters.warehouse?.toLowerCase();

    const filteredShippingMethods = substitutes.filter(
      (substitute) =>
        substitute.id?.toLowerCase().includes(keyValue) &&
        substitute.warehouseCode?.toLowerCase().includes(warehouseValue),
    );

    setFilteredRows(filteredShippingMethods);
    setFilters(newFilters);
  };

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <PageContentLayout
        title={getTitle()}
        isLoading={loading}
        buttons={[
          {
            label: 'Add a new substitute',
            onClick: handleNewRow,
          },
        ]}
      >
        <Grid container>
          <Grid item xs={9}>
            <SearchInput
              onSubmit={(val) => filterSkuSubstitutesByIdOrName(val, 'key')}
              placeholder="Search by SKU key"
            />
          </Grid>
          <Grid item xs={3}>
            <SelectComponent
              name="warehouse"
              variant="outlined"
              onChange={(e) =>
                filterSkuSubstitutesByIdOrName(e.target.value, 'warehouse')
              }
              label="Filter by warehouse"
              dataItems={warehouseDataItems}
              value={filters.warehouse}
            />
          </Grid>
          <Grid item xs={12}>
            <SkuSubstitutesTable
              canEdit={canEdit}
              rows={filteredRows || substitutes || []}
              isLoading={loading}
              disablePagination={true}
              rowCount={substitutes && substitutes.length}
              reloadData={fetchSkuSubstitutes}
            />
          </Grid>
        </Grid>
      </PageContentLayout>
      <ModalComponent
        title="Add a substitute"
        open={!!showCreationModal}
        handleClose={() => setShowCreationModal(false)}
      >
        <SkuSubstituteCreationForm
          reloadData={fetchSkuSubstitutes}
          closeModal={() => setShowCreationModal(false)}
          warehouseDataItems={warehouseDataItems}
        />
      </ModalComponent>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(SkuSubstitutesListing);
