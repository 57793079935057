const normalizeSkuSubstitutesResponse = (data) => {
  return data.map((substitute) => ({
    id:
      substitute.src_sku_key +
      substitute.dst_sku_key +
      substitute.warehouse_code +
      substitute.condition_type,
    srcSkuKey: substitute.src_sku_key,
    dstSkuKey: substitute.dst_sku_key,
    warehouseCode: substitute.warehouse_code,
    conditionType: substitute.condition_type,
    isActive: substitute.is_active,
  }));
};

export default normalizeSkuSubstitutesResponse;
