import { takeLatest, put, call } from 'redux-saga/effects';
import { GET_USER_INFO, SET_USER_INFO } from './actions';
import { LOGIN_SUCCESS } from 'store/auth/actions';
import { SET_LOADER } from 'store/general/actions';
import * as Sentry from '@sentry/react';
import { IS_RELC_SERVER, IS_PROD_SERVER } from 'config/environments';
import { getUser } from 'api/auth';

function* getUserInfo(action) {
  const { callback, errorCallback } = action.payload;

  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const userInfo = yield call(getUser);
    if (!userInfo || userInfo.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback();
      }
      return;
    }
    //Setting user value in sentry
    if (IS_RELC_SERVER || IS_PROD_SERVER) {
      Sentry.setUser({ id: userInfo.id, email: userInfo.email });
    }
    yield put({ type: SET_USER_INFO, payload: { userInfo } });
    yield put({ type: LOGIN_SUCCESS, payload: { isLoggedIn: true } });

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    if (errorCallback) {
      yield put({
        type: SET_LOADER,
        payload: {
          isLoading: false,
        },
      });
      errorCallback();
    }
  }
}

export function* userSaga() {
  yield takeLatest(GET_USER_INFO, getUserInfo);
}
