import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Grid,
} from '@mui/material';

import FileInput from 'components/common/FileInput';
import Loader from 'components/common/Loader';
import SelectComponent from 'components/common/SelectDropdown/SelectComponent';

import ModalComponent from 'components/common/ModalComponent';
import { SUPPORTED_LANGUAGES } from 'config';
import { UPLOAD_FILES, DELETE_FILES } from '../store/actions';

const EditBadgeImage = ({ badgeDetail, show, close, updateBadge }) => {
  const { assets } = useSelector((state) => state.assetsReducer);
  const { shippingCountries } = useSelector((state) => state.general);
  const [showImgLoader, setShowImgLoader] = useState(false);
  const [getImage, setImage] = useState(badgeDetail.key);
  const [country, setCountry] = useState(badgeDetail.country);
  const [language, setLanguage] = useState(badgeDetail.language);
  const [radioValue, setRadioValue] = useState(
    badgeDetail.country ? 'country' : 'language',
  );
  const [disableActions, setDisableAction] = useState(false);
  const dispatch = useDispatch();

  const setBadgeImage = (file) => {
    setShowImgLoader(true);
    setDisableAction(true);
    dispatch({
      type: UPLOAD_FILES,
      payload: {
        file: file[0],
        callback: (id) => {
          setShowImgLoader(false);
          setImage(id);
          setDisableAction(false);
        },
        errorCallback: (e) => {
          setShowImgLoader(false);
          setDisableAction(false);
        },
      },
    });
  };
  const removeBadge = (file) => {
    setShowImgLoader(true);
    setDisableAction(true);
    dispatch({
      type: DELETE_FILES,
      payload: {
        fileId: file,
        callback: () => {
          setShowImgLoader(false);
          setImage('');
          setDisableAction(false);
        },
        errorCallback: (e) => {
          setShowImgLoader(false);
          setDisableAction(false);
        },
      },
    });
  };

  const handleAddBadge = () => {
    const badge = {};
    const key = badgeDetail.key;
    badge.key = getImage;
    badge.country = radioValue === 'country' ? country : null;
    badge.language = radioValue === 'language' ? language : null;
    badge.default = badgeDetail.default;

    updateBadge(badge, key);

    close();
  };

  const handleClose = () => {
    close();
  };
  return (
    <ModalComponent title="Edit Badge" open={show} handleClose={handleClose}>
      <Grid container>
        <Grid item xs={12}>
          {showImgLoader ? (
            <Loader />
          ) : (
            <FileInput
              onChange={setBadgeImage}
              value={
                (getImage &&
                  assets[getImage] && {
                    id: getImage,
                    public_url: assets[getImage],
                  }) ||
                null
              }
              remove={removeBadge}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {!badgeDetail.default && (
            <RadioGroup
              name="countryLanguage"
              row
              value={radioValue}
              onChange={(e) => setRadioValue(e.target.value)}
            >
              <FormControlLabel
                value="country"
                control={<Radio />}
                label="Country"
              />
              <FormControlLabel
                value="language"
                control={<Radio />}
                label="Language"
              />
            </RadioGroup>
          )}
        </Grid>
        <Grid item xs={12}>
          {!badgeDetail.default &&
            (radioValue === 'country' ? (
              <SelectComponent
                className="select"
                labelId="selectCountry"
                onChange={(e) => setCountry(e.target.value)}
                label="Select Country"
                dataItems={shippingCountries}
                value={country}
              />
            ) : (
              <SelectComponent
                className="select"
                onChange={(e) => setLanguage(e.target.value)}
                label="Select Language"
                dataItems={SUPPORTED_LANGUAGES}
                value={language}
              />
            ))}
        </Grid>
        <Grid item xs={12} className="button-container">
          <Button
            className="mr-20"
            size="small"
            variant="outlined"
            type="button"
            onClick={handleClose}
            disabled={disableActions}
          >
            Cancel
          </Button>
          <Button
            className="button"
            size="small"
            variant="contained"
            type="button"
            onClick={handleAddBadge}
            disabled={disableActions}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </ModalComponent>
  );
};

export default EditBadgeImage;
