export const tableConfig = [
  {
    title: 'Title',
    id: 'name',
  },
  {
    title: 'SKU',
    id: 'id',
  },
  {
    title: 'Discount (%)',
    id: 'percent_off',
  },
  {
    title: 'Original Price',
    id: 'original_price',
  },
  {
    title: 'Discounted Price',
    id: 'discounted_price',
  },
  {
    title: 'Badge',
    id: 'badges',
  },
  {
    title: 'Actions',
    id: 'actions',
    actions: [
      {
        text: 'Edit',
        permissions: ['EDIT'],
        type: 'Edit',
      },
      {
        text: 'View',
        type: 'View',
      },
      {
        text: 'View Price',
        type: 'ViewPrice',
      },
    ],
  },
];

export const tableToolBarConfig = [
  {
    type: 'SearchInput',
    label: 'Search',
    onSelect: false,
  },
  {
    type: 'Select',
    label: 'Select',
    onSelect: false,
  },
  {
    type: 'Actions',
    label: 'Actions',
    onSelect: true,
  },
];

export const TableCellStates = {
  ACTIONS: 'actions',
  DISCOUNT: 'percent_off',
  View: 'View',

  ORIGINAL_PRICE: 'original_price',
  DISCOUNTED_PRICE: 'discounted_price',
  BADGES: 'badges',
};

export const tableToolBarStates = {
  Actions: 'Actions',
  SearchInput: 'SearchInput',
  Select: 'Select',
};
