import React from 'react';
import { Stream } from '@cloudflare/stream-react';
import ModalComponent from 'components/common/ModalComponent';

const PreviewModal = ({
  open,
  setOpen = () => {},
  cloudflareId,
  youtubeId,
}) => {
  const handleClose = () => setOpen(false);

  return (
    <ModalComponent title={'Preview'} open={open} handleClose={handleClose}>
      {cloudflareId ? (
        <Stream controls src={cloudflareId} height={`${160}px`} loop={true} />
      ) : youtubeId ? (
        <iframe
          title="youtube_preview"
          src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1`}
          style={{ border: 'none', width: '100%', height: 300 }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          tabIndex="-1"
        ></iframe>
      ) : null}
    </ModalComponent>
  );
};

export default PreviewModal;
