import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

const MenuComponent = (props) => {
  const { id, button, options } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    option.onClick();
    handleClose();
  };

  return (
    <div>
      <Button
        key={button.label.replace(' ', '')}
        type="button"
        variant="contained"
        disableElevation
        size="small"
        onClick={handleClick}
        disabled={button.disabled}
      >
        {button.label}
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options?.map((option) => (
          <MenuItem key={option.key} onClick={() => handleOptionClick(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MenuComponent;
