import httpClient from '../axiosClient';
import { API, getLoginUrl } from '../axiosClient/config';

export const login = async (data) => {
  try {
    const resp = await httpClient.post(getLoginUrl(API.auth), { data });
    return resp;
  } catch (e) {
    throw e;
  }
};

export const getUser = async () => {
  try {
    const resp = await httpClient.get(getLoginUrl(API.getUser));
    return resp.data.data;
  } catch (e) {
    throw e;
  }
};

export const logout = async (data) => {
  try {
    await httpClient.post(getLoginUrl(API.logout, { data }));
  } catch (e) {
    throw e;
  }
};

export const getAccount = async (url) => {
  try {
    const resp = await httpClient.get(getLoginUrl(url));
    return resp;
  } catch (e) {
    throw e;
  }
};

export const postAccount = async (url, data) => {
  try {
    const resp = await httpClient.post(getLoginUrl(url), { data });
    return resp;
  } catch (e) {
    throw e;
  }
};
