function hasRoles(roles, adminRole, moduleRoles) {
  if (roles.includes(adminRole) || !moduleRoles) {
    return true;
  }
  for (let i = 0; i < moduleRoles.length; i++) {
    if (roles.includes(moduleRoles[i])) {
      return true;
    }
  }
  return false;
}

export default hasRoles;
