export const envConfirmConfig = {
  title: 'Are you sure you want to delete these campaigns?',
  buttonGroup: [
    {
      text: 'Cancel',
      type: 'CANCEL',
    },
    {
      text: 'Confirm',
      type: 'CONFIRM',
    },
  ],
};

export const envConfirmConfigForDuplicate = {
  title: 'Are you sure you want to duplicate these campaigns?', // this title will be overrided using dynamic values
  buttonGroup: [
    {
      text: 'Cancel',
      type: 'CANCEL',
    },
    {
      text: 'Confirm',
      type: 'CONFIRM',
    },
  ],
};
