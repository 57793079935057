import { Field, Form } from 'react-final-form';
import { DateTime } from 'luxon';
import { Button, Grid, Paper } from '@mui/material';
import TextInput from 'components/common/TextInput';

export const initialValues = {
  ipWhitelist: '',
};

const SearchForm = ({ onFiltersChange = () => {} }) => {
  const handleReset = (form) => {
    form.reset(initialValues);

    onFiltersChange(initialValues, true);
  };

  const handleSubmit = (values) => {
    onFiltersChange(values);
  };

  return (
    <Paper className="p20">
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({ form, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="ipWhitelist"
                  component={TextInput}
                  type="text"
                  label="IP Whitelist"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  className="mr-20"
                  size="small"
                  variant="outlined"
                  type="button"
                  onClick={() => handleReset(form)}
                >
                  Reset
                </Button>
                <Button
                  className="button"
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Paper>
  );
};

export default SearchForm;
