export const tabsConfig = [
  {
    title: 'Current & Upcoming',
    id: 'current',
  },
  {
    title: 'Past',
    id: 'past',
  },
];
