import { useState, useEffect } from 'react';
import { Paper, Grid, Typography, Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { getAPIData } from 'api/playosmo';
import paths from 'routes/paths';
import { API } from 'axiosClient/config';
import { normalizeReturnsResponse } from 'utils';

import SetPageTitle from 'components/common/SetPageTitle';
import PageFormLayout from 'components/layout/PageFormLayout';
import OrderProperty from '../OrderView/components/OrderProperty';
import ReturnProductsTable from './ReturnProductsTable';
import OrderPropertiesWrapper from '../OrderView/components/OrderProperty/OrderPropertiesWrapper';

const ReturnView = ({ match }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rma, setRma] = useState({});
  const history = useHistory();

  useEffect(() => {
    const rmaId = match.params.id;
    // Get the orders based on the parameters
    if (rmaId) {
      setIsLoading(true);

      const fetchRMA = async () => {
        const response = await getAPIData(
          `${API.returns}/${rmaId}?type=rma_id`,
        );

        if (!response || response.status > 208) {
          return;
        }

        setIsLoading(false);
        const data = response.data;

        const formattedData = normalizeReturnsResponse(data).data;

        setRma(formattedData[0]);
      };

      fetchRMA();
    }
  }, [match.params.id]);

  const getTitle = () => (rma?.id ? `RMA #${rma?.id}` : 'Loading...');

  const handleSeeOrder = () => {
    history.push(`${paths.orders.view}/${rma.orderId}`);
  };

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <PageFormLayout
        title={getTitle()}
        isLoading={isLoading}
        buttons={[
          {
            label: 'See order',
            onClick: handleSeeOrder,
          },
        ]}
        breadcrumbs={[
          {
            label: 'RMAs',
            link: paths.orders.returns.list,
          },
          {
            label: `RMA #${rma.id}`,
            isCurrent: true,
          },
        ]}
        showSubmitButtons={false}
      >
        <Paper className="order-section">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Details
              </Typography>
              <OrderPropertiesWrapper>
                <OrderProperty label="ID" value={rma.id} />
                <OrderProperty label="Status" value={rma.status} />
                <OrderProperty label="Received on" value={rma.receivedOn} />
                <OrderProperty label="Created on" value={rma.createdOn} />
                <OrderProperty label="Label" value={rma.returnLabelUrl} />
              </OrderPropertiesWrapper>
            </Grid>
          </Grid>
          <Divider className="order-section-divider mb-20 mt-20" />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Returned products
              </Typography>
              <ReturnProductsTable cart={rma.cart} />
            </Grid>
          </Grid>
        </Paper>
      </PageFormLayout>
    </>
  );
};

export default ReturnView;
