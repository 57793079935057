import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Form, Field } from 'react-final-form';
import { DateTime } from 'luxon';
import { Paper, Button, Typography } from '@mui/material';

import { getLocalDateFromUTC, formatDate } from 'utils';

import { SEVERITY } from 'config';
import { ORDER_ACTION_ERROR, ORDER_ACTION_SUCCESS } from 'config/messages';

import { ADD_NOTE } from 'store/orders/actions';
import userSelectors from 'store/user/selectors';

import TextInput from 'components/common/TextInput';

import './styles.scss';

function NotesSection({
  order,
  canEdit,
  // From Redux
  userInfo,
  dispatch,
}) {
  const [notes, setNotes] = useState(order.notes);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setNotes(order.notes);
  }, [order.notes]);

  const handleFormSubmit = (values, form) => {
    const newNote = {
      user: userInfo.email,
      date: DateTime.now().toISO(),
      content: values.note,
    };

    dispatch({
      type: ADD_NOTE,
      payload: {
        data: {
          orderId: order.orderId,
          ...newNote,
        },
        callback: () => {
          enqueueSnackbar(ORDER_ACTION_SUCCESS, {
            variant: SEVERITY.SUCCESS,
          });
          setNotes(() => [...notes, newNote]);
          form.reset();
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${ORDER_ACTION_ERROR} : ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const sortedNotes = notes?.sort(
    (a, b) => new Date(b.date) - new Date(a.date),
  );

  return (
    <Paper className="order-section order-section-notes">
      <Typography variant="h2" gutterBottom>
        Notes
      </Typography>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{
          note: null,
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate className="mb-20">
            <Field
              required
              id="note"
              name="note"
              component={TextInput}
              placeholder="Add your note here"
              label="New note"
              variant="outlined"
              multiline
              maxRows={Infinity}
              fullWidth
              disabled={!canEdit}
            />
            <div className="mt-10">
              <Button
                size="small"
                variant="contained"
                type="submit"
                disabled={!canEdit}
              >
                Send
              </Button>
            </div>
          </form>
        )}
      />
      <div className="notes">
        {sortedNotes?.map((note) => (
          <div className="note" key={note.date}>
            <div className="note-info">
              <div className="note-author">{note.user}</div>
              <div className="note-date">
                - {formatDate(getLocalDateFromUTC(note.date))}
              </div>
            </div>
            <div className="note-content">{note.content}</div>
          </div>
        ))}
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  userInfo: userSelectors.getUserInfo(state),
});

export default connect(mapStateToProps)(NotesSection);
