import { takeLatest, put, call } from 'redux-saga/effects';
import { GET_PROMO_CODES, GET_PROMO_CODES_SUCCESS } from './actions';
import { SET_LOADER } from 'store/general/actions';
import { handleException } from 'store/general/sagas';

import { getAPIData } from 'api/playosmo';
import { API } from 'axiosClient/config';
import { normalizePromocodesResponse } from '../../../../../utils/normalizers/api/normalizePromocodesResponse';

function* getPromoCodes(action) {
  const { callback, errorCallback, filterPayload } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    let promoResponse;
    if (filterPayload) {
      promoResponse = yield call(
        getAPIData,
        `${API.getPromoCodes}?limit=100&code=${filterPayload.promocode}&website=${filterPayload.website}`,
      );
    } else {
      promoResponse = yield call(getAPIData, `${API.getPromoCodes}?limit=100`);
    }
    const promoCodes = normalizePromocodesResponse(promoResponse);

    if (!promoCodes || promoCodes.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    const data = {
      promoCodes: promoCodes.sort(
        (a, b) => new Date(b.created_on) - new Date(a.created_on),
      ),
    };

    yield put({ type: GET_PROMO_CODES_SUCCESS, payload: { data } });

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });

    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

export function* promoCodeListSaga() {
  yield takeLatest(GET_PROMO_CODES, getPromoCodes);
}
