import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Icon,
  IconButton,
} from '@mui/material';

import { ExpandMore, ExpandLess } from '@mui/icons-material';

import osmoColors from 'utils/ui/colors';

import NavLinkAdapter from 'components/common/NavLinkAdapter';

const useListItemStyles = makeStyles((theme) => ({
  root: (props) => ({
    '&:hover': {
      backgroundColor: '#262525',
      color: osmoColors.white,
      '& svg': {
        fill: osmoColors.grey[60],
      },
    },
    ...(props.canExpand
      ? {
          paddingTop: theme.spacing(0),
          paddingBottom: theme.spacing(0),
        }
      : {}),
    ...(props.nested
      ? {
          color: osmoColors.grey[60],
          paddingLeft: theme.spacing(3),
        }
      : {}),
  }),
  selected: (props) => ({
    color: osmoColors.blue[40],
    backgroundColor: '#262525',
    transitionDuration: 50,
    '& svg': {
      fill: osmoColors.blue[40],
      transitionDuration: 50,
    },
    '&:hover': {
      color: osmoColors.blue[40],
      '& svg': {
        fill: osmoColors.blue[40],
      },
    },
  }),
}));

const useListTextStyles = makeStyles((theme) => ({
  root: (props) =>
    props.nested
      ? {}
      : {
          '& span': {
            fontWeight: 500,
          },
        },
}));

const useListIconStyles = makeStyles((theme) => ({
  root: (props) =>
    props.nested
      ? {
          paddingLeft: theme.spacing(1),
          minWidth: 40,
        }
      : {
          paddingLeft: theme.spacing(0),
          minWidth: 40,
        },
}));

export const useIconStyles = makeStyles((theme) => ({
  root: (props) =>
    props?.nested
      ? {
          color: osmoColors.grey[30],
          width: 20,
          height: 20,
        }
      : {
          color: osmoColors.grey[60],
          width: 24,
          height: 24,
        },
}));

export const useDividerStyles = makeStyles((theme) => ({
  root: {
    borderColor: osmoColors.grey[20],
  },
}));

export default function NavItem(props) {
  const {
    item,
    className,
    nested = false,
    canExpand = false,
    open = false,
    onClick,
  } = props;

  const listItemClasses = useListItemStyles(props);
  const listIconClasses = useListIconStyles(props);
  const listTextClasses = useListTextStyles(props);
  const iconClasses = useIconStyles(props);
  const dividerClasses = useDividerStyles(props);

  const reactRouterItemProps = !canExpand
    ? {
        activeClassName: listItemClasses.selected,
        to: item.url,
        exact: item.exact,
        end: 'true',
      }
    : {};

  return (
    <>
      {item.dividerBefore && <Divider classes={dividerClasses} />}
      <ListItem
        button
        dense={nested}
        component={!canExpand ? NavLinkAdapter : null}
        className={clsx('item', className)}
        classes={listItemClasses}
        onClick={onClick}
        {...reactRouterItemProps}
      >
        {item.icon && (
          <ListItemIcon classes={listIconClasses}>
            {React.cloneElement(item.icon, { classes: iconClasses })}
          </ListItemIcon>
        )}
        <ListItemText primary={item.title} classes={listTextClasses} />
        {canExpand && (
          <IconButton size="large">
            <Icon classes={iconClasses}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </Icon>
          </IconButton>
        )}
      </ListItem>
    </>
  );
}
