import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Paper, Typography, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import {
  ORDER_ACTION_ERROR,
  ORDER_ACTION_SUCCESS,
  EMAIL_CONFIRMATION_ERROR,
  EMAIL_CONFIRMATION_SUCCESS,
} from 'config/messages';

import { FIX_ORDER } from 'store/orders/actions';

import { API } from 'axiosClient/config';
import { postAPIData } from 'api/playosmo';
import ModalComponent from 'components/common/ModalComponent';
import ActionsForm from './ActionsForm';
import RMACreationModal from './RMACreationModal';
import PriceMatchTool from './PriceMatchTool';

import './styles.scss';

function ActionsSection({ order, canEdit, updateRMAData, RMAKeys }) {
  const [isRMAModalOpen, setIsRMAModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [disableAction, setDisableAction] = useState(false);

  const onFixMeClick = () => {
    dispatch({
      type: FIX_ORDER,
      payload: {
        data: {
          orderId: order.orderId,
        },
        callback: () => {
          enqueueSnackbar(ORDER_ACTION_SUCCESS, {
            variant: SEVERITY.SUCCESS,
          });
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${ORDER_ACTION_ERROR} : ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const onCreateRMAClick = () => {
    setIsRMAModalOpen(true);
  };

  const handleRMAModalClose = () => {
    setIsRMAModalOpen(false);
  };

  const sendEmailConfirmation = async (e) => {
    e.preventDefault();
    const data = {
      email_confirm: true,
      order_number: order.orderId,
    };
    setDisableAction(true);
    try {
      const response = await postAPIData(
        API.orders.sendEmailConfirmation,
        data,
      );
      if (!response || response.status > 208) {
        return;
      }
      enqueueSnackbar(EMAIL_CONFIRMATION_SUCCESS, {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      enqueueSnackbar(`${EMAIL_CONFIRMATION_ERROR} : ${e.message}`, {
        variant: SEVERITY.ERROR,
      });
      throw e;
    }
  };

  return (
    <>
      <Paper className="order-section order-section-actions">
        <Typography variant="h2" gutterBottom>
          Actions
        </Typography>

        <ActionsForm order={order} disabled={!canEdit} />

        <Divider className="order-section-divider mb-20 mt-20" />

        <Button
          onClick={onFixMeClick}
          variant="outlined"
          disabled={!canEdit}
          className="mr-10"
        >
          Fix me!
        </Button>
        <Button
          onClick={onCreateRMAClick}
          variant="outlined"
          disabled={!canEdit || !order.shipped}
        >
          Create RMA
        </Button>

        <Divider className="order-section-divider mb-20 mt-20" />

        <PriceMatchTool order={order} disabled={!canEdit} />

        <Divider className="order-section-divider mb-20 mt-20" />

        <Typography variant="h3" gutterBottom>
          Send Email
        </Typography>

        <Button
          variant="contained"
          disabled={disableAction}
          onClick={sendEmailConfirmation}
        >
          Order Confirmation
        </Button>
      </Paper>

      <ModalComponent
        title="Create RMA"
        open={isRMAModalOpen}
        handleClose={handleRMAModalClose}
      >
        <RMACreationModal
          order={order}
          handleClose={handleRMAModalClose}
          updateRMAData={updateRMAData}
          RMAKeys={RMAKeys}
        />
      </ModalComponent>
    </>
  );
}

export default ActionsSection;
