import { Form } from 'react-final-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState, useEffect, useCallback, useMemo } from 'react';
import formArrayMutators from 'final-form-arrays';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import { PLAYOSMO_PRODUCTS_WRITE } from 'config/roles';
import { normalizeWarehousesResponse } from 'utils';
import {
  getProductManagement,
  postProductManagement,
  putProductManagement,
} from 'api/products';
import paths from 'routes/paths';
import { API } from 'axiosClient/config';
import userSelectors from 'store/user/selectors';
import { FETCH_REFERENCES } from 'store/general/actions';

import SetPageTitle from 'components/common/SetPageTitle';
import Confirmation from 'components/common/Confirmation';
import PageFormLayout from 'components/layout/PageFormLayout';
import GeneralSection from './components/GeneralSection';
import StatusSection from './components/StatusSection';
import ModalComponent from 'components/common/ModalComponent';
import DuplicateObjectForm from 'components/common/DuplicateObjectForm';
import { envConfirmConfig } from 'components/containers/CampaignContainer/CreateCampaign/config';

const WarehouseView = ({
  match,
  type = 'view',
  // From Redux
  userRoles,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showConfirmDuplicateModal, setShowConfirmDuplicateModal] =
    useState(false);
  const [warehouse, setWarehouse] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const canEdit = userRoles && userRoles.includes(PLAYOSMO_PRODUCTS_WRITE);
  const isCreationMode = type === 'create';

  const warehouseId = match.params.id;

  const inputBase = {
    disabled: !canEdit,
  };

  const initialValues = {
    name: warehouse?.name || '',
    warehouse_code: warehouse?.code || '',
    destination: warehouse?.destination || '',
    country_code_location: warehouse?.location || '',
    operated_by: warehouse?.operatedBy || '',
    is_enabled: warehouse?.status === 'active' || false,
  };

  const fetchWarehouse = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const response = await getProductManagement(
        `${API.products.warehouses}/${id}`,
      );
      const normalizedWarehouses = normalizeWarehousesResponse([
        response.data.data,
      ])[0];
      setWarehouse(normalizedWarehouses);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: FETCH_REFERENCES,
      payload: {
        types: ['warehouse_skill', 'platform'],
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (warehouseId) {
      fetchWarehouse(warehouseId);
    }
  }, [warehouseId, fetchWarehouse]);

  const getTitle = () =>
    isCreationMode
      ? 'Create a new warehouse'
      : isLoading || !warehouse
      ? 'Loading...'
      : canEdit
      ? `Editing: ${warehouse.name}`
      : `Viewing: ${warehouse.name}`;

  const getStatuses = () => {
    const statuses = [];
    if (warehouse?.status) {
      statuses.push({
        label: warehouse.status === 'active' ? 'Active' : 'Inactive',
        color: warehouse.status === 'active' ? 'success' : null,
      });
    }
    return statuses;
  };

  const onSubmit = async (values) => {
    const data = {
      name: values.name,
      warehouse_code: values.warehouse_code,
      destination: values.destination,
      is_enabled: values.is_enabled,
      operated_by: values.operated_by,
      country_code_location: values.country_code_location,
    };

    if (isCreationMode) {
      try {
        await postProductManagement(API.products.warehouses, data);
        history.push(paths.eCommerceConfiguration.warehouses.list);
        enqueueSnackbar('Warehouse created successfully', {
          variant: SEVERITY.SUCCESS,
        });
      } catch (e) {
        enqueueSnackbar(
          e?.response?.data?.error?.message || 'An error occurred',
          {
            variant: SEVERITY.ERROR,
          },
        );
        throw e;
      }
    } else {
      try {
        await putProductManagement(
          `${API.products.warehouses}/${warehouseId}`,
          data,
        );
        history.push(paths.eCommerceConfiguration.warehouses.list);
        enqueueSnackbar('Warehouse updated successfully', {
          variant: SEVERITY.SUCCESS,
        });
      } catch (e) {
        enqueueSnackbar(
          e?.response?.data?.error?.message || 'An error occurred',
          {
            variant: SEVERITY.ERROR,
          },
        );
        throw e;
      }
    }
  };

  const onCancel = () => {
    history.push(paths.eCommerceConfiguration.warehouses.list);
  };

  const handleEnvCancel = () => {
    setShowDuplicateModal(false);
  };

  const handleConfirmCancel = () => {
    setShowConfirmDuplicateModal(false);
  };

  const handleEnvSubmit = () => {
    setShowDuplicateModal(false);
    setShowConfirmDuplicateModal(true);
  };

  const mutators = useMemo(
    () => ({
      ...formArrayMutators,
    }),
    [],
  );

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        mutators={mutators}
        showProductionWarning={canEdit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <PageFormLayout
              title={getTitle()}
              chips={getStatuses()}
              isLoading={isLoading}
              breadcrumbs={[
                {
                  label: 'Warehouses',
                  link: paths.eCommerceConfiguration.warehouses.list,
                },
                {
                  label: getTitle(),
                  isCurrent: true,
                },
              ]}
              handleCancel={onCancel}
              showSubmitButtons={true}
              buttons={
                canEdit
                  ? [
                      // TODO: https://playosmo.atlassian.net/browse/ADMIN-366
                      // {
                      //   label: 'Duplicate to...',
                      //   onClick: () => setShowDuplicateModal(true),
                      // },
                    ]
                  : null
              }
            >
              <Grid container spacing={3} alignItems="flex-start">
                <Grid container item xs={12} sm={6}>
                  <Grid item xs={12}>
                    <GeneralSection inputBase={inputBase} />
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={6}>
                  <Grid item xs={12}>
                    <StatusSection
                      warehouse={warehouse}
                      canEdit={canEdit}
                      inputBase={inputBase}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </PageFormLayout>
          </form>
        )}
      />
      <ModalComponent
        title="Duplicate"
        open={showDuplicateModal}
        handleClose={handleEnvCancel}
      >
        <DuplicateObjectForm
          handleEnvCancel={handleEnvCancel}
          handleEnvSubmit={handleEnvSubmit}
        />
      </ModalComponent>
      <ModalComponent
        title="Confirm"
        open={showConfirmDuplicateModal}
        handleClose={handleConfirmCancel}
      >
        <Confirmation envConfirmConfig={envConfirmConfig} />
      </ModalComponent>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(WarehouseView);
