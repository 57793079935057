import { Field, Form } from 'react-final-form';
// import { DateTime } from 'luxon';
import { Button, Grid, Paper } from '@mui/material';
import TextInput from 'components/common/TextInput';
// import DateRangeFilter from 'components/common/DateRangeFilter';

// const today = DateTime.now();

export const initialValues = {
  accountID: '',
  myOsmoUserID: '',
  email: '',
  phoneNumber: '',
  schoolCode: '',
  // dateRange: {
  //   start: today.minus({ hour: 1 }).toJSDate(),
  //   end: new Date(),
  // },
};

const SearchSection = ({
  onFiltersChange = () => {},
  initialValues: initialValuesFromProps,
}) => {
  const handleReset = (form) => {
    form.reset(initialValues);

    const filters = {
      // startDate: initialValues.dateRange.start,
      // endDate: initialValues.dateRange.end,
      accountID: initialValues.accountID,
      email: initialValues.email,
      phoneNumber: initialValues.phoneNumber,
      myOsmoUserID: initialValues.myOsmoUserID,
      schoolCode: initialValues.schoolCode,
    };

    onFiltersChange(filters, true);
  };

  const handleSubmit = (values) => {
    const filters = {
      // startDate: DateTime.fromJSDate(values.dateRange.start).toSeconds(),
      // endDate: DateTime.fromJSDate(values.dateRange.end).toSeconds(),
      accountID: values.accountID,
      email: values.email,
      phoneNumber: values.phoneNumber,
      myOsmoUserID: values.myOsmoUserID,
      schoolCode: values.schoolCode,
    };
    onFiltersChange(filters);
  };

  // const handleDateChange = (value, changeValue) => {
  //   // Update the date range filter value
  //   changeValue('dateRange', {
  //     start: value.start,
  //     end: new Date(),
  //   });
  // };

  return (
    <Paper className="p20" elevation={0}>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValuesFromProps || initialValues}
        render={({ form, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="accountID"
                  component={TextInput}
                  type="text"
                  label="Account ID"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="myOsmoUserID"
                  component={TextInput}
                  type="text"
                  label="myOsmo user ID"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="email"
                  component={TextInput}
                  type="email"
                  label="Email address"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="phoneNumber"
                  component={TextInput}
                  type="text"
                  label="Phone number"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="schoolCode"
                  component={TextInput}
                  type="text"
                  label="School code"
                  variant="outlined"
                />
              </Grid>
              {/* <Grid item xs={12} className="mt-10">
                <Typography variant="h6">Creation date</Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  required
                  name="dateRange"
                  component={DateRangeFilter}
                  onChange={(val) => handleDateChange(val, form.change)}
                />
              </Grid> */}
              <Grid item xs={12} className="mt-10">
                <Button
                  className="mr-20"
                  size="small"
                  variant="outlined"
                  type="button"
                  onClick={() => handleReset(form)}
                >
                  Reset
                </Button>
                <Button
                  className="button"
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Paper>
  );
};

export default SearchSection;
