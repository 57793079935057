import React from 'react';
import { Helmet } from 'react-helmet';

export default function SetPageTitle(props) {
  return (
    <Helmet>
      <title>{props.pageTitle} | Osmo Admin</title>
    </Helmet>
  );
}
