import { Form } from 'react-final-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState, useEffect, useCallback, useMemo } from 'react';
import formArrayMutators from 'final-form-arrays';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import { PLAYOSMO_PRODUCTS_WRITE } from 'config/roles';
import { normalizeCountriesResponse } from 'utils';
import {
  deleteProductManagement,
  getProductManagement,
  postProductManagement,
  putProductManagement,
} from 'api/products';
import paths from 'routes/paths';
import { API } from 'axiosClient/config';
import userSelectors from 'store/user/selectors';
import { FETCH_REFERENCES } from 'store/general/actions';

import SetPageTitle from 'components/common/SetPageTitle';
import Confirmation from 'components/common/Confirmation';
import PageFormLayout from 'components/layout/PageFormLayout';
import GeneralSection from './components/GeneralSection';
import CurrencySection from './components/CurrencySection';
import StatusSection from './components/StatusSection';
import OtherSection from './components/OtherSection';
import ModalComponent from 'components/common/ModalComponent';
import DuplicateObjectForm from 'components/common/DuplicateObjectForm';
import { envConfirmConfig } from 'components/containers/CampaignContainer/CreateCampaign/config';

const CountryView = ({
  match,
  type = 'view',
  // From Redux
  userRoles,
  userIsDeveloper,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showConfirmDuplicateModal, setShowConfirmDuplicateModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [country, setCountry] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const canEdit = userRoles && userRoles.includes(PLAYOSMO_PRODUCTS_WRITE);
  const isCreationMode = type === 'create';

  const countryId = match.params.id;

  const inputBase = {
    disabled: !canEdit,
  };

  const initialValues = {
    code: country?.countryCode || '',
    name: country?.name || '',
    note: country?.note || '',
    warehouses: country?.warehouseCodes || '',
    currency_code: country?.currencyCode || '',
    symbol_right: country?.currencySymbolRight || '',
    symbol_left: country?.currencySymbolLeft || '',
    included_tax: country?.includedTax || 0,
    is_shipping_available: country?.isShippingAvailable || false,
    is_zero_decimal_currency: country?.isZeroDecimalCurrency || false,
    is_duty_applied: country?.isDutyApplied || false,
    is_enabled: country?.status === 'active' || false,
  };

  const fetchCountry = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const response = await getProductManagement(
        `${API.products.countries}/${id}`,
      );
      const normalizedCountry = normalizeCountriesResponse([
        response.data.data,
      ])[0];
      setCountry(normalizedCountry);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: FETCH_REFERENCES,
      payload: {
        types: ['country_skill', 'platform'],
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (countryId) {
      fetchCountry(countryId);
    }
  }, [countryId, fetchCountry]);

  const getTitle = () =>
    isCreationMode
      ? 'Create a new country'
      : isLoading || !country
      ? 'Loading...'
      : canEdit
      ? `Editing: ${country.name}`
      : `Viewing: ${country.name}`;

  const getStatuses = () => {
    const statuses = [];
    if (country?.status) {
      statuses.push({
        label: country.status === 'active' ? 'Active' : 'Inactive',
        color: country.status === 'active' ? 'success' : null,
      });
    }
    if (country?.isShippingAvailable !== undefined) {
      statuses.push({
        label: country.isShippingAvailable
          ? 'Shipping enabled'
          : 'Shipping disabled',
        color: country.isShippingAvailable ? 'success' : null,
      });
    }
    return statuses;
  };

  const onSubmit = async (values) => {
    const data = {
      country_code: values.code,
      name: values.name,
      is_enabled: values.is_enabled,
      note: values.note,
      warehouse_codes: values.warehouses,
      currency_code: values.currency_code,
      symbol_right: values.symbol_right,
      symbol_left: values.symbol_left,
      included_tax: values.included_tax * 100,
      is_shipping_available: values.is_shipping_available,
      is_zero_decimal_currency: values.is_zero_decimal_currency,
      is_duty_applied: values.is_duty_applied,
    };

    if (isCreationMode) {
      try {
        await postProductManagement(API.products.countries, data);
        history.push(paths.eCommerceConfiguration.countries.list);
        enqueueSnackbar('Country created successfully', {
          variant: SEVERITY.SUCCESS,
        });
      } catch (e) {
        enqueueSnackbar(
          e?.response?.data?.error?.message || 'An error occurred',
          {
            variant: SEVERITY.ERROR,
          },
        );
        throw e;
      }
    } else {
      try {
        await putProductManagement(
          `${API.products.countries}/${countryId}`,
          data,
        );
        history.push(paths.eCommerceConfiguration.countries.list);
        enqueueSnackbar('Country updated successfully', {
          variant: SEVERITY.SUCCESS,
        });
      } catch (e) {
        enqueueSnackbar(
          e?.response?.data?.error?.message || 'An error occurred',
          {
            variant: SEVERITY.ERROR,
          },
        );
        throw e;
      }
    }
  };

  const onCancel = () => {
    history.push(paths.eCommerceConfiguration.countries.list);
  };

  const handleEnvCancel = () => {
    setShowDuplicateModal(false);
  };

  const handleConfirmCancel = () => {
    setShowConfirmDuplicateModal(false);
  };

  const handleEnvSubmit = () => {
    setShowDuplicateModal(false);
    setShowConfirmDuplicateModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteProductManagement(`${API.products.countries}/${countryId}`);
      history.push(paths.eCommerceConfiguration.countries.list);
      enqueueSnackbar('Country deleted successfully', {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      enqueueSnackbar(
        e?.response?.data?.error?.message || 'An error occurred',
        {
          variant: SEVERITY.ERROR,
        },
      );
      throw e;
    }
    setShowDeleteModal(false);
  };

  const getActionButtons = useCallback(() => {
    if (isCreationMode) return null;
    const buttons = [];
    // TODO: https://playosmo.atlassian.net/browse/ADMIN-366
    // const buttons = [
    //   {
    //     label: 'Duplicate to...',
    //     onClick: () => setShowDuplicateModal(true),
    //   },
    // ];

    if (userIsDeveloper) {
      buttons.push({
        label: 'Delete',
        onClick: () => setShowDeleteModal(true),
      });
    }

    return buttons;
  }, [userIsDeveloper, isCreationMode]);

  const deleteConfirmConfig = {
    title: 'Are you sure you want to delete this country?',
    buttonGroup: [
      {
        text: 'Cancel',
        type: 'CANCEL',
        handler: () => setShowDeleteModal(false),
      },
      {
        text: 'Confirm',
        type: 'CONFIRM',
        handler: handleDelete,
      },
    ],
  };

  const mutators = useMemo(
    () => ({
      ...formArrayMutators,
    }),
    [],
  );

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        mutators={mutators}
        showProductionWarning={canEdit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <PageFormLayout
              title={getTitle()}
              chips={getStatuses()}
              isLoading={isLoading}
              breadcrumbs={[
                {
                  label: 'Countries',
                  link: paths.eCommerceConfiguration.countries.list,
                },
                {
                  label: getTitle(),
                  isCurrent: true,
                },
              ]}
              handleCancel={onCancel}
              showSubmitButtons={true}
              buttons={canEdit ? getActionButtons() : null}
            >
              <Grid container spacing={3} alignItems="flex-start">
                <Grid container item xs={12} sm={6}>
                  <Grid item xs={12}>
                    <GeneralSection inputBase={inputBase} />
                  </Grid>
                  <Grid item xs={12}>
                    <CurrencySection inputBase={inputBase} />
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={6}>
                  <Grid item xs={12}>
                    <StatusSection canEdit={canEdit} inputBase={inputBase} />
                  </Grid>
                  <Grid item xs={12}>
                    <OtherSection canEdit={canEdit} inputBase={inputBase} />
                  </Grid>
                </Grid>
              </Grid>
            </PageFormLayout>
          </form>
        )}
      />
      <ModalComponent
        title="Duplicate"
        open={showDuplicateModal}
        handleClose={handleEnvCancel}
      >
        <DuplicateObjectForm
          handleEnvCancel={handleEnvCancel}
          handleEnvSubmit={handleEnvSubmit}
        />
      </ModalComponent>
      <ModalComponent
        title="Confirm"
        open={showConfirmDuplicateModal}
        handleClose={handleConfirmCancel}
      >
        <Confirmation envConfirmConfig={envConfirmConfig} />
      </ModalComponent>
      <ModalComponent
        title="Confirm deletion"
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
      >
        <Confirmation envConfirmConfig={deleteConfirmConfig} />
      </ModalComponent>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
  userIsDeveloper: userSelectors.getUserIsDeveloper(state),
});

export default connect(mapStateToProps)(CountryView);
