import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, Paper } from '@mui/material';

import { getAPIData } from 'api/playosmo';
import { API } from 'axiosClient/config';
import { formatDate, getLocalDateFromUTC } from 'utils';
import paths from 'routes/paths';

import {
  TableCellStates,
  tableConfig,
} from 'components/containers/CouponContainer/tableConfig';
import TableComponent from 'components/common/TableComponent';
import StatusIndicator from 'components/common/StatusIndicator';
import PageContentLayout from 'components/layout/PageContentLayout';
import SearchInput from 'components/common/SearchInput';
import Loader from 'components/common/Loader';

import SelectComponent from '../../../common/SelectDropdown/SelectComponent';
import SetPageTitle from 'components/common/SetPageTitle';
import { getWebsiteDataItem } from 'components/containers/CampaignContainer/CampaignListing/CampaignFilters/filters';

import './index.scss';
import clsx from 'clsx';

const CouponListing = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [website, setWebsite] = useState('');
  const history = useHistory();

  const websiteDataItems = getWebsiteDataItem();

  const getCouponList = async (value) => {
    setLoading(true);

    let response;
    let coupons; // it's to handle coupon id api call
    if (parseInt(value)) {
      response = await getAPIData(API.coupons + `/${value}`);
      coupons = [response.data];
    } else {
      response = await getAPIData(
        API.coupons +
          `?admin_only=false&limit=100&website=${
            website !== 'ALL' ? website : ''
          }${!!value ? `&email=${encodeURIComponent(value)}` : ''}`,
      );
      coupons = response.data.coupons;
    }

    setLoading(true);
    coupons = coupons
      .sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
      .map((coupon) => ({
        ...coupon,
        is_live:
          new Date(coupon.starts_on) < new Date() &&
          new Date() < new Date(coupon.expires_on),
        is_expired: new Date() > new Date(coupon.expires_on),
      }));
    setRows(coupons);
    setFilteredRows(coupons);
    setLoading(false);
  };

  useEffect(() => {
    getCouponList();
  }, [website]);

  const options = {
    rowsPerPage: 10,
    selectionKey: 'id',
  };
  const [filteredRows, setFilteredRows] = useState([]);
  const requestSearch = (val) => {
    const value = val.toLowerCase();
    if (val.length) {
      getCouponList(value);
    } else if (val === '') {
      setFilteredRows(rows);
      getCouponList();
    }
  };

  const onWebsiteChange = (e) => {
    setWebsite(e.target.value);
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.STATUS_INDICATOR:
        cell.render = (record, index) => {
          return (
            <StatusIndicator
              key={index}
              status={record.is_live ? 'live' : 'inactive'}
            />
          );
        };
        return cell;
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return (
            <Button
              color="primary"
              onClick={() => {
                history.push(
                  `${paths.marketing.campaigns.review}/${record.id}`,
                );
              }}
              variant="text"
              endIcon={<SearchIcon color="primary" />}
            >
              <span style={{ color: '#18bbed' }}>View</span>
            </Button>
          );
        };
        return cell;
      case TableCellStates.WEBSITE:
        cell.render = (record, index) => {
          return <span key={`${i}-${index}`}>{record.websites ?? '-'}</span>;
        };
        return cell;
      case TableCellStates.ID:
        cell.render = (record, index) => {
          return <span key={`${i}-${index}`}>{record.id}</span>;
        };
        return cell;
      case TableCellStates.CAMPAIGN:
        cell.render = (record, index) => {
          return <span key={`${i}-${index}`}>{record.campaign}</span>;
        };
        return cell;
      case TableCellStates.EMAIL:
        cell.render = (record, index) => {
          return <span key={`${i}-${index}`}>{record.created_by}</span>;
        };
        return cell;
      case TableCellStates.COUNTRIES:
        cell.render = (record, index) => {
          let countryString;
          record.countries.length
            ? (countryString = record.countries.join(', '))
            : (countryString = 'ALL');
          return <span key={`${i}-${index}`}>{countryString}</span>;
        };
        return cell;
      case TableCellStates.EXPIRATION_DATE:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {formatDate(getLocalDateFromUTC(record.expires_on))}
            </span>
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  const title = 'Coupons';

  return (
    <>
      <SetPageTitle pageTitle={title} />
      <PageContentLayout title={title}>
        <Grid container className="coupon-listing-search">
          <Grid item xs={4}>
            <SearchInput
              className="coupon-listing-search-input"
              onSubmit={(val) => requestSearch(val)}
              placeholder="Search by Email or Coupon ID"
            />
          </Grid>
          <Grid item xs={3}>
            <Paper variant="elevation" component="form" elevation={1}>
              <SelectComponent
                variant="outlined"
                className="select-website coupon-listing-search-website"
                dataItems={websiteDataItems}
                onChange={onWebsiteChange}
                label="Website"
                value={website}
              />
            </Paper>
          </Grid>
        </Grid>
        {loading ? (
          <Loader />
        ) : (
          <TableComponent
            rows={filteredRows}
            headCells={headCells}
            options={options}
          />
        )}
      </PageContentLayout>
    </>
  );
};

export default CouponListing;
