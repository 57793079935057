const sources = [
  {
    id: 'admin',
    value: 'Osmo Internal',
  },
  {
    id: 'po',
    value: 'School PO',
  },
];

export default sources;
