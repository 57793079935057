import React from 'react';
import DatePicker from 'react-datepicker';
import { Typography } from '@mui/material';

import { getUTCDate, getLocalDateFromUTC } from 'utils';
import { DATE_FORMAT_CALENDAR } from 'config';

import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

const DateRangePicker = ({
  input: { name, onChange, value, ...restInput },
  meta,
  disabled,
  timezone,
  fullWidth,
  minDate,
  ...rest
}) => {
  const hasError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const localDates = {
    start: value && value.start && getLocalDateFromUTC(value.start, timezone),
    end: value && value.end && getLocalDateFromUTC(value.end, timezone),
  };

  const setStartDate = (date) => {
    date = getUTCDate(date, timezone);
    const endDate = getUTCDate(localDates.end, timezone);
    onChange({ start: date, end: date > endDate ? date : endDate });
  };

  const setEndDate = (date) => {
    date = getUTCDate(date, timezone);
    onChange({ start: value.start, end: date });
  };

  return (
    <div
      className={`date-range-picker-wrapper ${
        fullWidth ? 'is-full-width' : ''
      }`}
    >
      <div className="container date-range-picker">
        <div className="start-date">
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
            className="mr-20 subHeader"
          >
            Start Date
          </Typography>
          <DatePicker
            selected={localDates.start}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={localDates.start}
            minDate={minDate || getLocalDateFromUTC(new Date())}
            endDate={localDates.end}
            showTimeInput
            dateFormat={DATE_FORMAT_CALENDAR}
            disabled={disabled}
            wrapperClassName="date-picker"
          />
        </div>
        <div className="end-date">
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
            className="mr-20 subHeader"
          >
            End Date
          </Typography>
          <DatePicker
            selected={localDates.end}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={localDates.start}
            endDate={localDates.end}
            minDate={localDates.start}
            showTimeInput
            dateFormat={DATE_FORMAT_CALENDAR}
            disabled={disabled}
            wrapperClassName="date-picker"
          />
        </div>
      </div>
      {hasError && <div className="error">{meta.error}</div>}
    </div>
  );
};

export default React.memo(DateRangePicker, (prev, next) => {
  return false;
});
