import { Field } from 'react-final-form';

import SelectDropdown from 'components/common/SelectDropdown';

import './styles.scss';

const generateQties = (qties) =>
  [...Array(qties + 1).keys()].map((qty) => ({
    id: qty.toString(),
    value: qty.toString(),
  }));

const CartItem = ({ sku, skuName, qties = 10, required, inputName }) => {
  return (
    <div className="cart-item" key={sku}>
      <div className="cart-item-sku">
        {skuName ? `${skuName} - ${sku}` : sku}
      </div>
      <div className="cart-item-qty">
        <Field
          name={inputName || `${sku}-qty`}
          dataItems={generateQties(qties)}
          label="Qty"
          component={SelectDropdown}
          required={required}
        />
      </div>
    </div>
  );
};

export default CartItem;
