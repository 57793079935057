import { Field } from 'react-final-form';
import { Stream } from '@cloudflare/stream-react';
import { Grid, Paper, Skeleton, Typography } from '@mui/material';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';
import FileInputForm from 'components/common/FileInput/FileInputForm';

const DemoSection = ({ game, inputBase }) => {
  const previewSize = 210;
  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Demo
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <div className="mb-10">
            <strong>Poster image</strong>
            <Field
              service="games-management"
              name="demo_still_file"
              component={FileInputForm}
              deleteFromServer={false}
              tags={{
                type: 'demo-still',
                app: game?.name,
              }}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <strong>Demo loop</strong>
          {!game?.demoCloudflareId ? (
            <Skeleton
              variant="rectangular"
              width={previewSize}
              height={previewSize}
              animation={false}
            />
          ) : (
            <Stream
              controls
              src={game.demoCloudflareId}
              poster={game.demoStillUrl}
              width={`${previewSize}px`}
              height={`${previewSize}px`}
              loop={true}
            />
          )}
          <OrderPropertiesWrapper>
            <OrderPropertyInput
              input={{
                ...inputBase,
                name: 'demo_cloudflare_id',
                required: true,
              }}
              label="Cloudflare ID"
            />
          </OrderPropertiesWrapper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DemoSection;
