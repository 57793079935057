import { format } from 'date-fns-tz';
import { DATE_FORMAT_CALENDAR } from 'config';

function getTimeZoneName(date) {
  const pattern = 'zzzz';
  return format(date, pattern, {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
}

function getTimezoneCode(timeZoneName) {
  let tzCode;
  switch (timeZoneName) {
    case 'India Standard Time':
      tzCode = 'IST';
      break;
    case 'Pacific Standard Time':
      tzCode = 'PST';
      break;
    case 'Central European Standard Time':
      tzCode = 'CET';
      break;
  }
  if (tzCode) return tzCode;

  return timeZoneName;
}

function formatDate(date, customFormat) {
  // if the value is 0001-01-01T00:00:00Z
  if (date.getUTCFullYear() === 1901) {
    return '-';
  }

  const timeZoneName = getTimeZoneName(date);
  const timeZoneCode = getTimezoneCode(timeZoneName);

  return (
    format(date, customFormat || DATE_FORMAT_CALENDAR) + ` ${timeZoneCode}`
  );
}

export default formatDate;
