import httpClient from '../axiosClient';
import { API, getProductsUrl } from '../axiosClient/config';

export const getReferences = async (type) => {
  try {
    const resp = await httpClient.get(
      `${getProductsUrl(API.products.references)}${
        !!type ? `?type=${type}` : ''
      }`,
    );
    return resp;
  } catch (e) {
    throw e;
  }
};

export const getProductManagement = async (url, limit, cursor) => {
  try {
    const resp = await httpClient.get(
      `${getProductsUrl(url)}${!!limit ? `?limit=${limit}` : ''}${
        !!cursor ? `&cursor=${cursor}` : ''
      }`,
    );
    return resp;
  } catch (e) {
    throw e;
  }
};

export const postProductManagement = async (url, data) => {
  try {
    const resp = await httpClient.post(getProductsUrl(url), {
      data,
    });
    return resp.data.data;
  } catch (e) {
    throw e;
  }
};

export const putProductManagement = async (url, data) => {
  try {
    const resp = await httpClient.put(getProductsUrl(url), { data });
    return resp.data.data;
  } catch (e) {
    throw e;
  }
};

export const deleteProductManagement = async (url, id) => {
  try {
    await httpClient.delete(getProductsUrl(url));
  } catch (e) {
    throw e;
  }
};
