import { IconButton } from '@mui/material';
import { Field } from 'react-final-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { SUPPORTED_LANGUAGES } from 'config';
import TextInput from 'components/common/TextInput';
import SelectDropdown from 'components/common/SelectDropdown';

const File = (props) => {
  const { name, disabled, onDelete } = props;
  return (
    <div className="p10 game-manual-file">
      <div className="p10 game-manual-file__url">
        <Field
          name={`${name}.url`}
          component={TextInput}
          fullWidth
          required
          type="text"
          label="PDF file URL"
          variant="outlined"
          disabled={disabled}
        />
      </div>
      <div className="p10 game-manual-file__lang">
        <Field
          name={`${name}.language`}
          component={SelectDropdown}
          label="Language"
          dataItems={SUPPORTED_LANGUAGES}
          disabled={disabled}
          variant="outlined"
        />
      </div>
      <div className="p10 game-manual-file__actions">
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default File;
