import React from 'react';
import {
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import OrderProperty from '../../OrderProperty';
import CartTable from './CartTable';
import OrderPropertiesWrapper from '../../OrderProperty/OrderPropertiesWrapper';

function CustomerSection({ order }) {
  return (
    <Paper className="order-section order-section-customer">
      <Typography variant="h2" gutterBottom>
        Cart
      </Typography>
      <div className="mb-30">
        <Accordion
          disabled={!order.orderedCart || !order.orderedCart.length}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="ordered-content"
            id="ordered-header"
          >
            <Typography>Ordered cart</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CartTable cart={order.orderedCart} giftCard={true} />
          </AccordionDetails>
        </Accordion>
        <Accordion disabled={!order.captureCart || !order.captureCart.length}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="captured-content"
            id="captured-header"
          >
            <Typography>Capture cart</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CartTable cart={order.captureCart} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!order.backorderedCart || !order.backorderedCart.length}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="backordered-content"
            id="backordered-header"
          >
            <Typography>Backordered cart</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CartTable cart={order.backorderedCart} />
          </AccordionDetails>
        </Accordion>
        <Accordion disabled={!order.shippedCart || !order.shippedCart.length}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="shipped-content"
            id="shipped-header"
          >
            <Typography>Shipment cart</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CartTable cart={order.shippedCart} />
          </AccordionDetails>
        </Accordion>
        <Accordion disabled={!order.returnedCart || !order.returnedCart.length}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="shipped-content"
            id="shipped-header"
          >
            <Typography>Returned cart</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CartTable cart={order.returnedCart} />
          </AccordionDetails>
        </Accordion>
        <Accordion disabled={!order.digitalCart || !order.digitalCart.length}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="digital-content"
            id="digital-header"
          >
            <Typography>Digital cart</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CartTable isDigital cart={order.digitalCart} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!order.subscriptionsCart || !order.subscriptionsCart.length}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="subscriptions-content"
            id="subscriptions-header"
          >
            <Typography>Subscriptions cart</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CartTable cart={order.subscriptionsCart} />
          </AccordionDetails>
        </Accordion>
      </div>
      <OrderPropertiesWrapper>
        <OrderProperty label="Coupon" value={order.coupon} />
        <OrderProperty label="Is preorder" value={order.isPreorder} />
      </OrderPropertiesWrapper>
    </Paper>
  );
}

export default CustomerSection;
