import React from 'react';
import TableComponent from 'components/common/TableComponent';
import { DisplayPrice } from '@tangibleplay/ecommerce-ui';

const keys = {
  ID: 'id',
  PRODUCT: 'product',
  PRICE_PAID: 'price_paid',
};

const ReturnProductsTable = ({ cart }) => {
  if (!cart) {
    return null;
  }
  const options = {
    pagination: false,
  };

  const createData = (item) => ({
    [keys.ID]: item.sku,
    [keys.PRODUCT]: item.productName || item.key,
    [keys.PRICE_PAID]: !item.pricePaid ? (
      '0'
    ) : (
      <DisplayPrice
        price={item.pricePaid}
        currency={item.orderCurrency}
        country={item.orderCountry}
        alwaysBold={true}
        includeMicroData={false}
      />
    ),
  });

  const rows = cart.map((item) => createData(item));
  const headCells = [
    {
      title: 'Product',
      id: keys.PRODUCT,
      render: (record) => record[keys.PRODUCT],
    },
    {
      title: 'Amount refunded',
      id: keys.PRICE_PAID,
      render: (record) => record[keys.PRICE_PAID],
    },
  ];

  return <TableComponent rows={rows} headCells={headCells} options={options} />;
};

export default ReturnProductsTable;
