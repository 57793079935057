import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Button, Grid, Paper } from '@mui/material';

import { postAPIDataDifferentOrigin } from 'api/microservices';
import { API } from 'axiosClient/config';
import { SEVERITY } from 'config';
import { SHORT_LINK_CREATED_SUCCESS } from 'config/messages';
import { SHORTLINK_READ, SHORTLINK_WRITE } from 'config/roles';
import userSelectors from 'store/user/selectors';
import TextInput from 'components/common/TextInput';
import PageContentLayout from 'components/layout/PageContentLayout';
import ToolTip from 'components/common/Tooltip';
import ShortLinkTable from './ShortLinkTable';

const ShortLinkPageContent = (props) => {
  const userRoles = props.userRoles;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const generateShortUrl = async (values) => {
    try {
      let valueObject = values;
      valueObject.title = `[${values.tag}] ${values.title}`;
      setLoading(true);
      const payload = {
        data: valueObject,
      };

      const response = await postAPIDataDifferentOrigin(
        API.microservices.shortLinks.update,
        payload,
      );

      if (!response || response.status > 208) {
        return (
          enqueueSnackbar(`Some error occurred`),
          {
            variant: SEVERITY.ERROR,
          }
        );
      }
      return enqueueSnackbar(SHORT_LINK_CREATED_SUCCESS, {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      return enqueueSnackbar(`Error occurred : ${e.message}`);
    }
  };
  const canEdit =
    userRoles.includes(SHORTLINK_READ) && userRoles.includes(SHORTLINK_WRITE);

  const handleSubmit = (values, form) => {
    generateShortUrl(values).then(() => {
      setLoading(false);
      form.reset();
      form.resetFieldState('url');
      form.resetFieldState('title');
      form.resetFieldState('tag');
      form.resetFieldState('alias');
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.url) {
      errors.url = 'URL Required';
    }
    if (!values.title) {
      errors.title = 'Description Required';
    }
    if (!values.tag) {
      errors.tag = 'JIRA Ticket Required';
    }

    return errors;
  };
  return (
    <>
      {canEdit ? (
        <Paper className="orders-filters-form mb-30">
          <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <Field
                      required={true}
                      fullWidth
                      name="url"
                      component={TextInput}
                      type="text"
                      label="Original URL"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      required={true}
                      fullWidth
                      name="title"
                      component={TextInput}
                      type="text"
                      label="Description"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      required={true}
                      fullWidth
                      name="tag"
                      component={TextInput}
                      type="text"
                      label="JIRA Ticket"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className="short-link-field-flex-display">
                      <Field
                        fullWidth
                        name="alias"
                        component={TextInput}
                        type="text"
                        label="URL alias"
                        variant="outlined"
                      />
                      <div>
                        <ToolTip
                          title="Optional but to make the short link more readable you can provide a slug for the end, for example if your alias is 'testlink' then the final link would be : https://s.playosmo.com/testlink"
                          disableInteractive
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className="button"
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      Generate Short Link
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Paper>
      ) : (
        ''
      )}

      <PageContentLayout isLoading={loading}>
        <ShortLinkTable setLoading={setLoading} />
      </PageContentLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(ShortLinkPageContent);
