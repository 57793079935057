import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useSnackbar } from 'notistack';
import { Skeleton, Button, Tooltip, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import TableComponent from 'components/common/TableComponent';

import { tableConfig, TableCellStates } from './tableConfig';
import { SEVERITY } from 'config';
import { deleteProductManagement, putProductManagement } from 'api/products';
import { API } from 'axiosClient/config';
import ModalComponent from 'components/common/ModalComponent';
import Confirmation from 'components/common/Confirmation';
import StatusIndicator from 'components/common/StatusIndicator';
import SwitchInput from 'components/common/SwitchInput';
import generalSelectors from 'store/general/selectors';
import AutoCompleteWrapper from 'components/common/AutoCompleteWrapper';

const ShippingMethodsTable = (props) => {
  const { rows, isLoading, rowCount, canEdit, reloadData } = props;
  const rowsWithKeys = rows.map((row) => ({ ...row, id: row.id }));
  const { enqueueSnackbar } = useSnackbar();
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const purchasableSkus = useSelector(generalSelectors.getPurchasableProducts);
  const purchasableSkusData = [...purchasableSkus];
  rowsWithKeys.forEach((row) => {
    if (!purchasableSkusData.find((sku) => sku.id === row.srcSkuKey)) {
      purchasableSkusData.push({
        id: row.srcSkuKey,
        name: row.srcSkuKey,
      });
    }
    if (!purchasableSkusData.find((sku) => sku.id === row.dstSkuKey)) {
      purchasableSkusData.push({
        id: row.dstSkuKey,
        name: row.dstSkuKey,
      });
    }
  });

  const initialValues = rows.reduce((acc, row) => {
    return {
      ...acc,
      [row.id]: {
        [TableCellStates.IS_ACTIVE]: row.isActive || false,
        [TableCellStates.SRC]: row.srcSkuKey,
        [TableCellStates.DST]: row.dstSkuKey,
      },
    };
  }, {});

  const options = {
    isRowSelectable: false,
    rowsPerPage: 25,
  };

  const handleSave = async (id, values, form) => {
    const rowValues = values[id];
    const initialRowValues = rows.find((row) => row.id === id);
    const payload = {
      is_active: rowValues[TableCellStates.IS_ACTIVE],
      src_sku_key: rowValues[TableCellStates.SRC],
      dst_sku_key: rowValues[TableCellStates.DST],
      warehouse_code: initialRowValues[TableCellStates.WAREHOUSE],
      condition_type: initialRowValues[TableCellStates.CONDITION],
    };

    try {
      await putProductManagement(
        API.products.substitutes.put(
          rowValues[TableCellStates.SRC],
          initialRowValues[TableCellStates.WAREHOUSE],
          initialRowValues[TableCellStates.CONDITION],
        ),
        payload,
      );
      enqueueSnackbar('Substitute successfully updated', {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      const errorMessage =
        e.response.data && typeof e.response.data === 'string'
          ? e.response.data
          : e.response.data?.error?.message;
      if (errorMessage) {
        enqueueSnackbar(errorMessage, {
          variant: SEVERITY.ERROR,
        });
      }
      throw e;
    }

    form.submit();
  };

  const handleCopy = async (id) => {
    // TODO: https://playosmo.atlassian.net/browse/ADMIN-367
  };

  const handleDelete = async () => {
    const { id, values } = showDeleteModal;
    const rowValues = values[id];
    const initialRowValues = rows.find((row) => row.id === id);
    try {
      await deleteProductManagement(
        API.products.substitutes.delete(
          rowValues[TableCellStates.SRC],
          initialRowValues[TableCellStates.WAREHOUSE],
          initialRowValues[TableCellStates.CONDITION],
        ),
      );
      enqueueSnackbar('Substitute successfully deleted', {
        variant: SEVERITY.SUCCESS,
      });
      reloadData();
      setShowDeleteModal(null);
    } catch (e) {
      const errorMessage =
        e.response.data && typeof e.response.data === 'string'
          ? e.response.data
          : e.response.data?.error?.message;
      if (errorMessage) {
        enqueueSnackbar(errorMessage, {
          variant: SEVERITY.ERROR,
        });
      }
      throw e;
    }
  };

  const deleteConfirmConfig = {
    title: 'Are you sure you want to delete this substitute?',
    buttonGroup: [
      {
        text: 'Cancel',
        type: 'CANCEL',
        handler: () => setShowDeleteModal(null),
      },
      {
        text: 'Confirm',
        type: 'CONFIRM',
        handler: handleDelete,
      },
    ],
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index, cellAdditionalProps) => {
          if (!canEdit) {
            return null;
          }

          const { values, form } = cellAdditionalProps;

          const id = record.id;

          return (
            <div>
              <Tooltip title="Copy" disableInteractive>
                <IconButton onClick={() => handleCopy(id)} size="large">
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" disableInteractive>
                <IconButton
                  onClick={() => setShowDeleteModal({ id, values })}
                  size="large"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Button
                key={`${id}-${index}`}
                onClick={() => handleSave(id, values, form)}
                color="primary"
                variant="text"
                type="submit"
              >
                Save
              </Button>
            </div>
          );
        };
        return cell;

      case TableCellStates.SRC:
      case TableCellStates.WAREHOUSE:
      case TableCellStates.CONDITION:
        cell.render = (record, index) => {
          return <span>{record[cell.id]}</span>;
        };
        return cell;

      case TableCellStates.DST:
        cell.render = (record, index) => {
          if (!canEdit) {
            return record[cell.id];
          }
          return (
            <div>
              <Field
                component={AutoCompleteWrapper}
                name={`${record.id}.${cell.id}`}
                label="Select SKU"
                options={purchasableSkusData}
                getOptionLabel={(option) => {
                  return option.id;
                }}
                renderDropdownOption={(option) =>
                  option.name + ` (${option['id']})`
                }
              />
            </div>
          );
        };
        return cell;

      case TableCellStates.IS_ACTIVE:
        cell.render = (record, index) => {
          if (canEdit) {
            return (
              <Field
                type="checkbox"
                component={SwitchInput}
                name={`${record.id}.${TableCellStates.IS_ACTIVE}`}
              />
            );
          }
          return (
            <StatusIndicator
              key={i}
              status={record[TableCellStates.IS_ACTIVE] ? 'active' : 'inactive'}
              withLabel={true}
            />
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  if (isLoading) {
    return <Skeleton height={300} animation="wave" variant="rectangular" />;
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={() => {}}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <TableComponent
              rows={rowsWithKeys}
              headCells={headCells}
              options={options}
              rowCount={rowCount}
              size="small"
              cellAdditionalProps={{ form, values }}
            />
          </form>
        )}
      />
      <ModalComponent
        title="Confirm deletion"
        open={!!showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
      >
        <Confirmation envConfirmConfig={deleteConfirmConfig} />
      </ModalComponent>
    </>
  );
};

export default ShippingMethodsTable;
