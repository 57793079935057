import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Chip, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import TextInput from 'components/common/TextInput';
import AddRow from 'components/containers/GamesContainer/GameView/components/GameManualsSection/GameManuals/AddRow';

const TabContent = ({ name, onDelete, inputBase }) => {
  return (
    <>
      <Grid container item xs={11}>
        <Grid item xs={12}>
          <Field
            label="Title"
            component={TextInput}
            name={`${name}.title`}
            {...inputBase}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Content"
            component={TextInput}
            name={`${name}.content`}
            variant="outlined"
            multiline
            fullWidth
            rows={3}
            {...inputBase}
          />
        </Grid>
      </Grid>
      <Grid container item xs={1} alignItems="center">
        <Grid item xs={7}>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

const TabsContent = ({ inputBase, baseName }) => {
  const { mutators } = useForm();

  const addRow = () => {
    mutators.push(`${baseName}.tabs`, {
      title: '',
      content: '',
    });
  };

  return (
    <div>
      <FieldArray name={`${baseName}.tabs`}>
        {({ fields }) =>
          !fields?.length ? (
            <>No tab yet</>
          ) : (
            fields.map((name, index) => {
              return (
                <Grid
                  container
                  key={name}
                  alignItems="center"
                  className="mb-10"
                >
                  <Grid item xs={1}>
                    <Chip label={index + 1} />
                  </Grid>
                  <Grid item container xs={6}>
                    <TabContent
                      onDelete={() => fields.remove(index)}
                      name={name}
                      inputBase={inputBase}
                    />
                  </Grid>
                </Grid>
              );
            })
          )
        }
      </FieldArray>
      <Grid container className="mt-10">
        <Grid item xs={7}>
          <AddRow onClick={addRow} label="Add a tab" />
        </Grid>
      </Grid>
    </div>
  );
};

export default TabsContent;
