import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { List, ListSubheader, Collapse } from '@mui/material';

import { PLAYOSMO_ADMIN } from 'config/roles';
import { hasRoles } from 'utils';
import osmoColors from 'utils/ui/colors';

import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  subheader: {
    backgroundColor: 'transparent',
    color: osmoColors.grey[80],
  },
}));

const NavBarSubCategory = ({ item, roles }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <NavItem item={item} canExpand open={open} onClick={handleClick} />
      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {item.children &&
            item.children.map(
              (subitem) =>
                hasRoles(roles, PLAYOSMO_ADMIN, subitem.roles) && (
                  <NavItem key={subitem.id} item={subitem} nested />
                ),
            )}
        </Collapse>
      )}
    </>
  );
};

export default function NavBarCategory(props) {
  const classes = useStyles();
  const { item, roles } = props;

  const {
    title,
    // icon,
  } = item;

  return (
    <List key={item.id} component="div" disablePadding>
      <ListSubheader
        className={clsx(classes.subheader, 'nav-bar-category-header')}
      >
        {/* {React.cloneElement(icon, { fontSize: 'small' })} */} {title}
      </ListSubheader>
      {item.children &&
        item.children.map(
          (item) =>
            hasRoles(roles, PLAYOSMO_ADMIN, item.roles) && (
              <Fragment key={item.id}>
                {item.type === 'category' && (
                  <NavBarSubCategory item={item} roles={roles} />
                )}
                {item.type === 'item' && <NavItem item={item} />}
              </Fragment>
            ),
        )}
    </List>
  );
}
