import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from '@mui/material';

import { getLocalDateFromUTC, formatDate } from 'utils';
import userSelectors from 'store/user/selectors';

import paths from 'routes/paths';

import TableComponent from 'components/common/TableComponent';

import { tableConfig, TableCellStates } from './tableConfig';

const EmailSubscriptionsTable = (props) => {
  const {
    rows,
    rowsPerPage,
    onChangeRowsPerPage,
    rowsTotalCount,
    onChangePage,
    currentPageNumber,
  } = props;
  const history = useHistory();

  const options = {
    rowsPerPage,
    onChangeRowsPerPage,
    onChangePage,
    currentPageNumber,
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.EMAIL:
        cell.render = (record, index) => {
          return <span key={i}>{record[TableCellStates.EMAIL]}</span>;
        };
        return cell;
      case TableCellStates.SUBSCRIBED_LIST:
        cell.render = (record, index) => {
          return <span key={i}>{record[TableCellStates.SUBSCRIBED_LIST]}</span>;
        };
        return cell;
      case TableCellStates.CONSENT_GIVEN:
        cell.render = (record, index) => {
          return <span key={i}>{record[TableCellStates.CONSENT_GIVEN]}</span>;
        };
        return cell;
      case TableCellStates.SUBSCRIBED_ON:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {formatDate(
                getLocalDateFromUTC(record[TableCellStates.SUBSCRIBED_ON]),
              )}
            </span>
          );
        };
        return cell;
      case TableCellStates.UNSUBSCRIBED_ON:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {formatDate(
                getLocalDateFromUTC(record[TableCellStates.UNSUBSCRIBED_ON]),
              )}
            </span>
          );
        };
        return cell;
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return (
            <Button
              key={`${i}-${index}`}
              onClick={() =>
                history.push(
                  `${paths.users.emailSubscriptions.edit}/${
                    record[TableCellStates.EMAIL]
                  }`,
                )
              }
              color="primary"
              variant="text"
            >
              View
            </Button>
          );
        };
        return cell;
      default:
        return cell;
    }
  });

  return (
    <TableComponent
      rows={rows}
      headCells={headCells}
      options={options}
      rowCount={rowsTotalCount}
    />
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(EmailSubscriptionsTable);
