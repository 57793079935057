import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    marginRight: 20,
    flexShrink: 0,
    fontWeight: 'bold',
  },
  headerLeft: {
    flex: 1,
    display: 'flex',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontStyle: 'italic',
    color: theme.palette.text.secondary,
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    overflow: 'hidden',
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    '&.Mui-expanded': {
      backgroundColor: '#f7f5f5',
    },
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      paddingRight: 10,
    },
  }),
);

const ExpandableSection = ({
  heading,
  secondaryHeading,
  headerContentRight,
  content,
  id,
  openByDefault = false,
}) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(openByDefault);

  const handleChange = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={isExpanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${id}-content`}
          id={`${id}-header`}
        >
          <div className={classes.headerLeft}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography className={classes.heading}>{heading}</Typography>
              {secondaryHeading && (
                <Typography className={classes.secondaryHeading}>
                  {secondaryHeading}
                </Typography>
              )}
            </Stack>
          </div>
          {headerContentRight && <div>{headerContentRight}</div>}
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ExpandableSection;
