export const tableConfig = [
  {
    title: 'First name',
    id: 'first_name',
  },
  {
    title: 'Last name',
    id: 'last_name',
  },
  {
    title: 'Email address',
    id: 'email',
  },
  {
    title: 'ID',
    id: 'id',
  },
  {
    title: 'Actions',
    id: 'actions',
    sorting: false,
    actions: [
      {
        text: 'Edit',
        type: 'Edit',
      },
      {
        text: 'Review',
        type: 'Review',
      },
    ],
  },
];

export const TableCellStates = {
  ACTIONS: 'actions',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  ID: 'id',
  EMAIL: 'email',
};
