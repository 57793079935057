import { getPurchasableProducts as getPurchasableProductsUtils } from './reducers';

const getSkuDetails = (state) => state.general.skuDetails;
const getAPIEnv = (state) => state.general.apiEnv;
const getShippingCountries = (state) => state.general.shippingCountries;
const getMailingLists = (state) => state.general.mailingLists;
const getPurchasableProducts = (state) => state.general.purchasableProducts;
const getProductsPerCountries = (state) => state.general.productsPerCountries;
const getReferences = (state) => state.general.references;
const getSubstituteConditionReferences = (state) =>
  state.general.references?.substitute_condition;
const getSkillReferences = (state) => state.general.references?.game_skill;
const getPlatformReferences = (state) => state.general.references?.platform;
const getShippingMethodReferences = (state) =>
  state.general.references?.shipping_method;
const getBrandReferences = (state) => state.general.references?.brand;
const getRedeemTypeReferences = (state) =>
  state.general.references?.redeem_type;
const getGradeReferences = (state) => state.general.references?.grade;
const getCurrencyReferences = (state) => state.general.references?.currency;
const getWebsiteReferences = (state) => state.general.references?.website;
const getSubscriptionTypeReferences = (state) =>
  state.general.references?.subscription_type;
const getSubscriptionFrequencyReferences = (state) =>
  state.general.references?.subscription_frequency;
const getStorageReferences = (state) => state.general.references?.storage;
const getBaseReferences = (state) => state.general.references?.base;
const getPurchasableProductsForCountry = (state, country) =>
  country
    ? getPurchasableProductsUtils(state.general.productsPerCountries[country])
    : state.general.purchasableProducts;
const getSkuDetailsForCountry = (state, country) =>
  country
    ? state.general.productsPerCountries[country]
    : state.general.purchasableProducts;

const generalSelectors = {
  getSkuDetails,
  getAPIEnv,
  getShippingCountries,
  getMailingLists,
  getPurchasableProducts,
  getProductsPerCountries,
  getPurchasableProductsForCountry,
  getSkuDetailsForCountry,
  getReferences,
  getSkillReferences,
  getPlatformReferences,
  getShippingMethodReferences,
  getBrandReferences,
  getRedeemTypeReferences,
  getGradeReferences,
  getCurrencyReferences,
  getWebsiteReferences,
  getSubscriptionTypeReferences,
  getSubscriptionFrequencyReferences,
  getStorageReferences,
  getBaseReferences,
  getSubstituteConditionReferences,
};

export default generalSelectors;
