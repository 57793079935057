import { contentFeatureTransform } from 'utils';

const contentSubsectionTransform = (subSectionArr) => {
  const subSection = JSON.parse(JSON.stringify(subSectionArr));
  subSection.forEach((subSection) => {
    delete subSection.id;
    subSection.heading = contentFeatureTransform([...subSection.heading]);
  });
  return subSection;
};

export default contentSubsectionTransform;
