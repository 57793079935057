import { Field } from 'react-final-form';

import CheckboxComponent from 'components/common/CheckboxComponent';
import TextInput from 'components/common/TextInput';
import SelectDropdown from 'components/common/SelectDropdown';
import AutoCompleteWrapper from 'components/common/AutoCompleteWrapper';
import SwitchInput from 'components/common/SwitchInput';
import OrderProperty from '../OrderProperty';

const OrderPropertyInput = ({ input, label, type = 'text' }) => {
  return (
    <OrderProperty
      label={`${label}${input.required ? '*' : ''}`}
      component={
        type === 'switch' ? (
          <Field type="checkbox" component={SwitchInput} {...input} />
        ) : type === 'checkbox' ? (
          <Field type="checkbox" component={CheckboxComponent} {...input} />
        ) : type === 'textarea' ? (
          <Field
            component={TextInput}
            label={label}
            variant="outlined"
            multiline
            rows={3}
            fullWidth
            {...input}
          />
        ) : type === 'select' ? (
          <Field component={SelectDropdown} {...input} />
        ) : type === 'autocomplete-select' ? (
          <Field
            component={AutoCompleteWrapper}
            getOptionLabel={(option) => {
              return option.id;
            }}
            renderDropdownOption={(option) => option.name || option.value}
            {...input}
          />
        ) : (
          <Field component={TextInput} type={type} fullWidth {...input} />
        )
      }
    />
  );
};

export default OrderPropertyInput;
