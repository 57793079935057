import React from 'react';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

import AccessDeniedImg from 'assets/image/access_denied.png';

import './style.scss';

export default function PermissionDenied(props) {
  return (
    <Paper variant="outlined" className="permission-container">
      <img src={AccessDeniedImg} alt="access denied" />
      <Typography variant="h1">Access Denied</Typography>
      <p className="subHeader">
        You do not have permission to access this page.
      </p>
    </Paper>
  );
}
