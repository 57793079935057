import httpClient from '../axiosClient';
import { apiUrl, getBaseURL } from 'axiosClient/config';

export const getAPIData = async (url) => {
  try {
    const resp = await httpClient.get(apiUrl(url));
    return resp;
  } catch (e) {
    throw e;
  }
};

export const postAPIData = async (url, data) => {
  try {
    const resp = await httpClient.post(apiUrl(url), data);
    return resp;
  } catch (e) {
    throw e;
  }
};

export const putAPIData = async (url, data) => {
  try {
    const resp = await httpClient.put(apiUrl(url), data);
    return resp;
  } catch (e) {
    throw e;
  }
};

export const deleteAPIData = async (url) => {
  try {
    const resp = await httpClient.delete(apiUrl(url));
    return resp;
  } catch (e) {
    throw e;
  }
};

export const postAPIDataWithEnv = async (url, data, env = '') => {
  try {
    const resp = await httpClient.post(getBaseURL(env) + url, data);
    return resp;
  } catch (e) {
    throw e;
  }
};
