import React from 'react';
import queryString from 'query-string';

import { withRouter } from 'react-router-dom';

export default function withRouterInfo(WrappedComponent) {
  const isActive = (props) => {
    return props.location.pathname.indexOf(props.path) === 0;
  };

  const withRouterInfo = (props) => {
    const queryParams = queryString.parse(props.location.search);
    return (
      <WrappedComponent
        active={isActive(props)}
        queryParams={queryParams}
        {...props}
      />
    );
  };
  return withRouter(withRouterInfo);
}
