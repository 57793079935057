import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Grid,
} from '@mui/material';

import { SUPPORTED_LANGUAGES } from 'config';

import ModalComponent from 'components/common/ModalComponent';
import FileInput from 'components/common/FileInput';
import Loader from 'components/common/Loader';
import SelectComponent from 'components/common/SelectDropdown/SelectComponent';

import {
  UPLOAD_FILES,
  DELETE_FILES,
} from 'components/containers/CampaignContainer/CreateCampaign/store/actions';

const AddBadgeImage = ({ addBadge, show, close, isDefault }) => {
  const { assets } = useSelector((state) => state.assetsReducer);
  const { shippingCountries } = useSelector((state) => state.general);
  const [showImgLoader, setShowImgLoader] = useState(false);
  const [getImage, setImage] = useState('');
  const [country, setCountry] = useState('');
  const [language, setLanguage] = useState('');
  const [radioValue, setRadioValue] = useState('country');
  const [disableActions, setDisableAction] = useState(false);
  const dispatch = useDispatch();

  const setBadgeImage = (file) => {
    setShowImgLoader(true);
    setDisableAction(true);
    dispatch({
      type: UPLOAD_FILES,
      payload: {
        file: file[0],
        callback: (id) => {
          setShowImgLoader(false);
          setImage(id);
          setDisableAction(false);
        },
        errorCallback: (e) => {
          setShowImgLoader(false);
          setDisableAction(false);
        },
      },
    });
  };

  const handleAddBadge = () => {
    const badge = {};

    badge.key = getImage;
    badge.country = radioValue === 'country' ? country : null;
    badge.language = radioValue === 'language' ? language : null;
    badge.default = isDefault;
    addBadge(badge);
    close();
  };

  const removeBadge = (file) => {
    setShowImgLoader(true);
    setDisableAction(true);
    dispatch({
      type: DELETE_FILES,
      payload: {
        fileId: file,
        callback: () => {
          setShowImgLoader(false);
          setImage('');
          setDisableAction(false);
        },
        errorCallback: (e) => {
          setShowImgLoader(false);
          setDisableAction(false);
        },
      },
    });
  };

  const handleClose = () => {
    close();
  };
  return (
    <ModalComponent title="Add Badge" open={show} handleClose={handleClose}>
      <Grid container>
        <Grid item xs={12}>
          {showImgLoader ? (
            <Loader />
          ) : (
            <FileInput
              onChange={setBadgeImage}
              value={
                getImage && {
                  public_url: assets[getImage],
                }
              }
              remove={removeBadge}
            />
          )}
        </Grid>
        {!isDefault && (
          <Grid item xs={12}>
            <RadioGroup
              name="countryLanguage"
              row
              value={radioValue}
              onChange={(e) => setRadioValue(e.target.value)}
            >
              <FormControlLabel
                value="country"
                control={<Radio />}
                label="Country"
              />
              <FormControlLabel
                value="language"
                control={<Radio />}
                label="Language"
              />
            </RadioGroup>
          </Grid>
        )}
        {!isDefault && (
          <Grid item xs={12}>
            {radioValue === 'country' ? (
              <SelectComponent
                className="select"
                labelId="selectCountry"
                onChange={(e) => setCountry(e.target.value)}
                label="Select Country"
                dataItems={shippingCountries}
              />
            ) : (
              <SelectComponent
                className="select"
                onChange={(e) => setLanguage(e.target.value)}
                label="Select Language"
                dataItems={SUPPORTED_LANGUAGES}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} className="button-container">
          <Button
            className="mr-20"
            size="small"
            variant="outlined"
            type="button"
            onClick={handleClose}
            disabled={disableActions}
          >
            Cancel
          </Button>
          <Button
            className="button"
            size="small"
            variant="contained"
            type="button"
            onClick={handleAddBadge}
            disabled={disableActions}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </ModalComponent>
  );
};

export default AddBadgeImage;
