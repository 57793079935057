import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Button } from '@mui/material';

import DateRangePicker from 'components/common/DateRangePicker';

const DURATIONS = {
  '1h': '1h',
  '24h': '24h',
  '7d': '7d',
  '30d': '30d',
  all_time: 'all_time',
};

const DateRangeFilter = ({ onChange, input, meta }) => {
  const [selectedDuration, setSelectedDuration] = useState();

  const calculateDurationArgAndSelectButton = (dateRange) => {
    let durationArgs;
    if (dateRange.start && dateRange.end) {
      let startDate = DateTime.fromISO(dateRange.start.toISOString());
      let endDate = DateTime.fromISO(dateRange.end.toISOString());
      let duration = endDate.diff(startDate, [
        'years',
        'months',
        'days',
        'hours',
      ]);
      let durationObject = duration.toObject();

      if (
        Math.ceil(durationObject.hours) === 1 &&
        durationObject.days === 0 &&
        durationObject.months === 0 &&
        durationObject.years === 0
      ) {
        durationArgs = DURATIONS['1h'];
      } else if (
        ((Math.ceil(durationObject.hours) === 24 ||
          (durationObject.days === 1 &&
            Math.ceil(durationObject.hours) === 1)) &&
          durationObject.days !== 6 &&
          Math.ceil(durationObject.hours) !== 24 &&
          durationArgs !== DURATIONS['1h'] &&
          durationObject.years <= 0) ||
        (durationObject.years === 0 &&
          durationObject.months === 0 &&
          durationObject.days === 1 &&
          durationObject.hours === 0)
      ) {
        // Ceil because value returned is like 23.9988
        durationArgs = DURATIONS['24h'];
      } else if (
        durationObject.days === 7 ||
        (durationObject.days === 6 &&
          Math.ceil(durationObject.hours) === 24 &&
          durationObject.days !== 1 &&
          durationObject.years <= 0 &&
          (durationArgs !== DURATIONS['24h'] ||
            durationArgs !== DURATIONS['1h']))
      ) {
        durationArgs = DURATIONS['7d'];
      } else if (
        (durationObject.months === 1 && durationObject.days === 0) ||
        durationObject.days === 30 ||
        (durationObject.days === 29 &&
          Math.ceil(durationObject.hours) === 24 &&
          (durationArgs !== DURATIONS['7d'] ||
            durationArgs !== DURATIONS['24h'] ||
            durationArgs !== DURATIONS['1h']) &&
          durationObject.years <= 0)
      ) {
        durationArgs = DURATIONS['30d'];
      } else if (durationObject.years >= 10) {
        // for all time
        durationArgs = DURATIONS.all_time;
      }
      setSelectedDuration(durationArgs);
    }
  };

  useEffect(() => {
    calculateDurationArgAndSelectButton(input.value);
  }, [input.value]);

  const setDates = (period) => {
    let newStartDate = DateTime.now();

    switch (period) {
      case DURATIONS['1h']:
        newStartDate = newStartDate.minus({ hour: 1 });
        break;
      case DURATIONS['24h']:
        newStartDate = newStartDate.minus({ hour: 24 });
        break;
      case DURATIONS['7d']:
        newStartDate = newStartDate.minus({ day: 7 });
        break;
      case DURATIONS['30d']:
        newStartDate = newStartDate.minus({ day: 30 });
        break;
      case DURATIONS['all_time']:
        newStartDate = DateTime.fromSeconds(0);
        break;

      default:
        break;
    }

    // Update the date range filter value
    onChange({
      start: newStartDate.toJSDate(),
      end: new Date(),
    });
  };

  return (
    <>
      <DateRangePicker
        name="dateRange"
        timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        minDate={DateTime.fromSeconds(0).toJSDate()}
        input={input}
        meta={meta}
        parse={(value) => {
          onChange(value);
          return value;
        }}
      />
      <div className="orders-filters-form-period-buttons">
        <Button
          variant="outlined"
          onClick={() => {
            setDates(DURATIONS['1h']);
          }}
          className={
            selectedDuration === DURATIONS['1h']
              ? 'selected-button mr-10'
              : 'mr-10'
          }
        >
          1h
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setDates(DURATIONS['24h']);
          }}
          className={
            selectedDuration === DURATIONS['24h']
              ? 'selected-button mr-10'
              : 'mr-10'
          }
        >
          24h
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setDates(DURATIONS['7d']);
          }}
          className={
            selectedDuration === DURATIONS['7d']
              ? 'selected-button mr-10'
              : 'mr-10'
          }
        >
          7d
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setDates(DURATIONS['30d']);
          }}
          className={
            selectedDuration === DURATIONS['30d']
              ? 'selected-button mr-10'
              : 'mr-10'
          }
        >
          30d
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setDates(DURATIONS.all_time);
          }}
          className={
            selectedDuration === DURATIONS.all_time
              ? 'selected-button mr-10'
              : 'mr-10'
          }
        >
          All time
        </Button>
      </div>
    </>
  );
};

export default DateRangeFilter;
