import { Grid, Paper, Typography } from '@mui/material';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';

const GeneralSection = ({ inputBase }) => {
  return (
    <Paper elevation={1} className="p20">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            General
          </Typography>
          <OrderPropertiesWrapper>
            <OrderPropertyInput
              label="SKU"
              input={{
                ...inputBase,
                name: 'sku',
                required: true,
              }}
            />
            <OrderPropertyInput
              label="SKU key"
              input={{
                ...inputBase,
                name: 'sku_key',
                required: true,
              }}
            />
            <OrderPropertyInput
              label="Name"
              input={{
                ...inputBase,
                name: 'name',
                required: true,
              }}
            />
            <OrderPropertyInput
              label="Note"
              type="textarea"
              input={{
                ...inputBase,
                name: 'note',
              }}
            />
          </OrderPropertiesWrapper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default GeneralSection;
