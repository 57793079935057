export const TableActionStates = {
  EDIT: 'Edit',
  VIEW: 'View',
  REVIEW: 'Review',
  DELETE: 'Delete',
  VIEWPRICE: 'ViewPrice',
  DUPLICATE: 'Duplicate',
};

export const TableCellStates = {
  ACTIONS: 'actions',
};
