import * as React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=","
      decimalSeparator="."
      valueIsNumericString
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const FormattedNumericInput = ({
  input: { name, required, onChange, value, ...restInput },
  meta,
  ...rest
}) => {
  const hasError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;
  return (
    <TextField
      variant="standard"
      {...rest}
      name={name}
      helperText={hasError ? meta.error || meta.submitError : undefined}
      error={hasError}
      InputProps={{
        inputComponent: NumericFormatCustom,
      }}
      onChange={onChange}
      value={value}
      required={required}
    />
  );
};

export default FormattedNumericInput;
