import React from 'react';
import { Link, Paper, Typography } from '@mui/material';

import paths from 'routes/paths';
import { IS_PROD } from 'config/environments';

function QuickLinksSection({ order }) {
  let paypalLinkPrefix;
  IS_PROD
    ? (paypalLinkPrefix = 'https://www.paypal.com')
    : (paypalLinkPrefix = 'https://www.sandbox.paypal.com');

  return (
    <Paper className="order-section order-section-overview">
      <Typography variant="h2" gutterBottom>
        Quick links
      </Typography>
      <ul>
        <li>
          <Link
            href={`https://www.playosmo.com/en/myorder/?id=${order.orderId}`}
            target="_blank"
            underline="hover"
          >
            Public status page
          </Link>
        </li>
        {order.receiptUrl && (
          <li>
            <Link href={order.receiptUrl} target="_blank" underline="hover">
              View Receipt
            </Link>
          </li>
        )}
        {order.orderSubscriptionId && (
          <li>
            <Link
              href={`${paths.orders.subscriptions.view}/${order.orderSubscriptionId}`}
              target="_blank"
              underline="hover"
            >
              Parent subscription page
            </Link>
          </li>
        )}
        {order.orderSubscriptionIds && order.orderSubscriptionIds.length ? (
          order.orderSubscriptionIds.map((subscriptionOrderId) => (
            <li>
              <Link
                href={`${paths.orders.subscriptions.view}/${subscriptionOrderId}`}
                target="_blank"
                underline="hover"
              >
                {' '}
                Child Subscription #{subscriptionOrderId}
              </Link>
            </li>
          ))
        ) : (
          <></>
        )}
        {order.billing?.paypal && (
          <li>
            <Link
              href={`${paypalLinkPrefix}/listing/transactions?searchvalue=${order.billing?.paypal?.invoiceId}&searchtype=ANY`}
              target="_blank"
              underline="hover"
            >
              Paypal
            </Link>
          </li>
        )}
        {order.billing?.stripe?.chargeId && (
          <li>
            <Link
              href={`https://manage.stripe.com/payments/${order.billing?.stripe?.chargeId}`}
              target="_blank"
              underline="hover"
            >
              Stripe
            </Link>
          </li>
        )}
        <li>
          <Link
            href={`https://www.playosmo.com/en/address/?a=${order.addressId}&count=false`}
            target="_blank"
            underline="hover"
          >
            Address Form
          </Link>
        </li>
        {!!order.coupon && (
          <li>
            <Link
              href={`${paths.marketing.campaigns.review}/${order.coupon}`}
              target="_blank"
              underline="hover"
            >
              Coupon
            </Link>
          </li>
        )}
      </ul>
    </Paper>
  );
}

export default QuickLinksSection;
