import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import paths from 'routes/paths';

import TableComponent from 'components/common/TableComponent';

import { tableConfig, TableCellStates } from './tableConfig';
import StatusIndicator from 'components/common/StatusIndicator';
import { formatDate, getLocalDateFromUTC } from 'utils';

const ProfilesTable = ({ rows }) => {
  const history = useHistory();

  const options = {
    rowsPerPage: 10,
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record) => {
          return (
            <Button
              color="primary"
              onClick={() => {
                history.push(
                  `${paths.users.profiles.edit}/${record[TableCellStates.ID]}`,
                );
              }}
              variant="text"
              endIcon={<SearchIcon color="primary" />}
            >
              View
            </Button>
          );
        };
        return cell;
      case TableCellStates.ID:
        cell.render = (record) => {
          return <span key={i}>{record[TableCellStates.ID]}</span>;
        };
        return cell;
      case TableCellStates.NAME:
        cell.render = (record) => {
          return <span key={i}>{record[TableCellStates.NAME]}</span>;
        };
        return cell;
      case TableCellStates.STATUS:
        cell.render = (record) => {
          return (
            <StatusIndicator
              key={i}
              status={record[TableCellStates.STATUS]}
              withLabel={true}
            />
          );
        };
        return cell;
      case TableCellStates.CREATED_ON:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {formatDate(
                getLocalDateFromUTC(record[TableCellStates.CREATED_ON]),
              )}
            </span>
          );
        };
        return cell;
      default:
        return cell;
    }
  });

  return <TableComponent rows={rows} headCells={headCells} options={options} />;
};

export default ProfilesTable;
