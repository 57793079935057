import React from 'react';

import './styles.scss';

const StatusIndicator = ({ status, withLabel }) => {
  const getData = () => {
    const data = {};

    switch (status) {
      case 'live':
      case 'active':
        data.label = 'Active';
        data.class = 'active';
        break;

      case 'expired':
        data.label = 'Expired';
        data.class = 'error';
        break;
      case 'deleted':
        data.label = 'Deleted';
        data.class = 'error';
        break;

      case 'inactive':
      default:
        data.label = 'Inactive';
        data.class = 'inactive';
        break;
    }
    return data;
  };

  const data = getData();

  return (
    <span className={`status-indicator ${data.class}`}>
      <span className="status-indicator-dot"></span>
      {withLabel && (
        <span className="status-indicator-label">{data.label}</span>
      )}
    </span>
  );
};

export default StatusIndicator;
