import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '@mui/material';
import { Skeleton } from '@mui/material';
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { getLocalDateFromUTC, formatDate } from 'utils';
import paths from 'routes/paths';

import TableComponent from 'components/common/TableComponent';

import { tableConfig, TableCellStates } from './tableConfig';

const ReturnsTable = (props) => {
  const { rows, isLoading, canView, disablePagination, rowCount } = props;
  const history = useHistory();
  const rowsWithKeys = rows.map((row) => ({ ...row, id: row.id }));

  const options = {
    isRowSelectable: false,
    selectionKey: 'id',
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.VIEW:
        cell.render = (record, index) => {
          if (!canView) return null;
          return (
            <Button
              key={`${i}-${index}`}
              onClick={() =>
                history.push(
                  `${paths.orders.returns.view}/${
                    record[TableCellStates.RMA_ID]
                  }`,
                )
              }
              color="primary"
              variant="text"
            >
              View <SearchIcon />
            </Button>
          );
        };
        return cell;
      case TableCellStates.RMA_ID:
        cell.render = (record, index) => {
          return <span>{record[TableCellStates.RMA_ID]}</span>;
        };
        return cell;
      case TableCellStates.ORDER_ID:
        cell.render = (record, index) => {
          return (
            <Link
              key={`${i}-${index}`}
              href={`${paths.orders.view}/${record[TableCellStates.ORDER_ID]}`}
              underline="hover"
            >
              {record[TableCellStates.ORDER_ID]}
            </Link>
          );
        };
        return cell;
      case TableCellStates.LABEL_URL:
        cell.render = (record, index) => {
          return (
            <Link
              key={`${i}-${index}`}
              href={record[TableCellStates.LABEL_URL]}
              underline="hover"
            >
              {record[TableCellStates.LABEL_URL]}
            </Link>
          );
        };
        return cell;
      case TableCellStates.CREATED_ON:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {formatDate(
                getLocalDateFromUTC(record[TableCellStates.CREATED_ON]),
              )}
            </span>
          );
        };
        return cell;
      case TableCellStates.RECEIVED_ON:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {formatDate(
                getLocalDateFromUTC(record[TableCellStates.RECEIVED_ON]),
              )}
            </span>
          );
        };
        return cell;
      case TableCellStates.STATUS:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>{record[TableCellStates.STATUS]}</span>
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  if (isLoading) {
    return <Skeleton height={300} animation="wave" variant="rectangular" />;
  }

  return (
    <TableComponent
      rows={rowsWithKeys}
      headCells={headCells}
      options={options}
      disablePagination={disablePagination}
      rowCount={rowCount}
    />
  );
};

export default ReturnsTable;
