import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TableActionStates } from 'enums/tableStates';
import { currencyFormat, percentToFraction } from 'utils';

import { campaignStateMapping } from '../config';
import {
  TableCellStates,
  tableConfig,
  tableToolBarConfig,
  tableToolBarStates,
} from './tableConfig';

import {
  DELETE_PRODUCT_DISCOUNT,
  EDIT_PRODUCT_DISCOUNT,
  SET_PRODUCT_DISCOUNT,
} from '../store/actions';

import SearchInput from 'components/common/SearchInput';
import EmptyStateMessage from 'components/common/EmptyStateMessage';
import TableToolbar from 'components/common/TableComponent/components/TableToolbar';
import TableComponent from 'components/common/TableComponent';
import TableActions from 'components/common/TableActions';
import Action from 'components/common/TableActions/Actions';
import SelectComponent from 'components/common/SelectDropdown/SelectComponent';
import PriceDetails from '../PriceDetails/PriceDetails';
import ModalComponent from 'components/common/ModalComponent';
import AddSku from '../AddSku/AddSku';
import EditSku from '../EditSku/EditSku';
import ImagePreview from '../ImagePreview';

import './style.scss';

const DiscountList = ({
  input: { value, onChange, ...restInput },
  meta,
  mode,
  ...rest
}) => {
  const review = mode === campaignStateMapping.REVIEW;

  const { skuDetails, shippingCountries } = useSelector(
    (state) => state.general,
  );

  const { discountedProducts } = useSelector(
    (state) => state.createCampaignReducer,
  );
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState([]);

  const [skuPriceDetails, setSkuPriceDetails] = useState({});
  const [showPriceDetails, setShowPriceDetails] = useState(false);
  const [displayedProductDiscount, setDisplayedProductDiscount] =
    useState(discountedProducts);

  const [canAddSku, setCanAddSku] = useState(false);
  const [showEditSku, setShowEditSku] = useState(false);
  const [editSkuDetails, setEditSkuDetails] = useState([]);
  const [selectCountry, setSelectedCountry] = useState('US');
  const [discountFilter, setDiscountFilter] = useState('');
  const [searchedValue, setSearchedValue] = useState('');
  const discountListAfterSearch = useRef([]);

  const handleDiscountFilterChange = (event) => {
    setDiscountFilter(event.target.value);
    filterDiscountByPercentage(event.target.value);
  };
  // Function to generate discount function
  const getDiscountFilterOptions = (discountedProducts) => {
    // Returns discount percentage
    let discountFilterList = discountedProducts.map((item) => {
      return Math.round(item.percent_off * 100);
    });

    // Returns unique elements
    discountFilterList = [...new Set(discountFilterList)]
      .map((item) => {
        return {
          id: item,
          value: item,
        };
      })
      .sort((a, b) => a.id - b.id);

    // Adding All value in discount dropdown after default state
    discountFilterList.push({
      id: 'none',
      value: 'All',
    });

    return discountFilterList;
  };

  const hasError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  useEffect(() => {
    setDisplayedProductDiscount(discountedProducts);
  }, [discountedProducts]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  //Getting discount filtered list by percentage
  const discountFilterList = getDiscountFilterOptions(discountedProducts);

  const filterDiscountsBySkuName = (val) => {
    const value = val.toLowerCase();
    //overriding discountedProducts array for this instance with filterDiscountByPercentage
    const discountedProductsUpdated =
      filterDiscountByPercentage(discountFilter); // returns discountedProducts array if no discount is selected and filters if the discount is selected
    const filteredDiscounts = discountedProductsUpdated.filter((discount) => {
      return (
        discount.name.toLowerCase().includes(value) ||
        discount.id.includes(value)
      );
    });
    setDisplayedProductDiscount(filteredDiscounts);
    setSearchedValue(value);
    discountListAfterSearch.current =
      getDiscountFilterOptions(filteredDiscounts);
    if (!value.length) {
      setDisplayedProductDiscount(discountedProducts);
      discountListAfterSearch.current = [];
    }
  };

  const getFilteredRowsBySku = () => {
    const value = searchedValue.toLowerCase();
    const filteredDiscounts = discountedProducts.filter((discount) => {
      return (
        discount.name.toLowerCase().includes(value) ||
        discount.id.includes(value)
      );
    });
    return filteredDiscounts;
  };

  const filterDiscountByPercentage = (val) => {
    const value = val / 100;
    const discountedProducts = getFilteredRowsBySku();
    const filteredDiscounts = discountedProducts.filter((discount) => {
      return discount.percent_off === value;
    });
    //filtered discount array will only be populated if the dropdown discount values match with the array and not if 'All' option is selected
    if (filteredDiscounts.length) {
      setDisplayedProductDiscount(filteredDiscounts);
      return filteredDiscounts;
    }

    // // Case where 'All' option is selected
    else {
      setDisplayedProductDiscount(discountedProducts);
      return discountedProducts;
    }
  };

  const toolBarData = tableToolBarConfig.map((configDetail) => {
    switch (configDetail.type) {
      case tableToolBarStates.SearchInput:
        configDetail.render = () => {
          return (
            <div className="discount-search">
              <SearchInput
                onSubmit={(val) => filterDiscountsBySkuName(val)}
                placeholder="Search by SKU or product name"
              />
            </div>
          );
        };
        return configDetail;
      case tableToolBarStates.Actions:
        configDetail.render = () => {
          return (
            <div>
              <Tooltip title="Edit" disableInteractive>
                <IconButton
                  aria-label="edit"
                  onClick={handleBatchEditSku}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" disableInteractive>
                <IconButton
                  aria-label="delete"
                  onClick={handleDelete}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        };
        return configDetail;
      case tableToolBarStates.Select:
        configDetail.render = () => {
          return (
            <div className="discount-dropdown">
              <SelectComponent
                required
                name="country"
                onChange={handleCountryChange}
                label="Select Country"
                className="discount-country"
                dataItems={shippingCountries}
                value={selectCountry}
              />
              <SelectComponent
                required
                name="Discount"
                onChange={handleDiscountFilterChange}
                label="Select Discount"
                className="discount-country"
                dataItems={
                  discountListAfterSearch.current.length
                    ? discountListAfterSearch.current
                    : discountFilterList
                }
                value={discountFilter}
              />
            </div>
          );
        };
        return configDetail;
      default:
        return configDetail;
    }
  });

  const renderActions = (cell, record) => {
    return cell.actions.map((action) => {
      switch (action.type) {
        case TableActionStates.EDIT:
          return (
            !review && (
              <Action
                text={action.text}
                action={() => handleEditSku([record])}
              />
            )
          );

        case TableActionStates.VIEW:
          return (
            review && (
              <Action
                text={action.text}
                action={() => handleEditSku([record])}
              />
            )
          );
        case TableActionStates.VIEWPRICE:
          return (
            <Action text={action.text} action={() => handleViewClick(record)} />
          );
        default:
          return null;
      }
    });
  };

  const calculateDiscount = (record, country) => {
    const { prices, percent_off } = record;
    return (
      prices[country] &&
      (prices[country].original_price / 100) *
        (1 - percentToFraction(percent_off, false) / 100)
    );
  };

  const headCells = tableConfig.map((cell) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return <TableActions>{renderActions(cell, record)}</TableActions>;
        };
        return cell;
      case TableCellStates.ORIGINAL_PRICE:
        cell.render = (record, index) => {
          const price = record.prices
            ? record.prices[selectCountry]?.original_price / 100 || 0
            : 0;
          const currency = record.prices
            ? record.prices[selectCountry]?.currency || 0
            : 0;
          return (
            <span>{(price && currencyFormat(price, currency)) || '-'}</span>
          );
        };
        return cell;
      case TableCellStates.DISCOUNTED_PRICE:
        cell.render = (record, index) => {
          const price = record.prices
            ? record.prices[selectCountry]?.original_price || 0
            : 0;
          const currency = record.prices
            ? record.prices[selectCountry]?.currency || 0
            : 0;
          return (
            <span>
              {(price &&
                currencyFormat(
                  calculateDiscount(record, selectCountry),
                  currency,
                )) ||
                '-'}
            </span>
          );
        };

        return cell;
      case TableCellStates.BADGES:
        cell.render = (record, index) => {
          const badges = (record.badges && [...record.badges]) || [];
          return (
            <span>
              <div>
                {badges && badges[0] && <ImagePreview id={badges[0].key} />}
              </div>
            </span>
          );
        };

        return cell;
      case TableCellStates.DISCOUNT:
        cell.render = (record, index) => {
          return (
            <span>
              {percentToFraction(record.percent_off, false).toFixed(2)}%
            </span>
          );
        };
        return cell;
      default:
        return cell;
    }
  });

  const onRowSelectChange = (selectedRow) => {
    setSelectedRows(selectedRow);
  };

  const options = {
    selectedRows,
    onRowSelectChange: onRowSelectChange,
    isRowSelectable: !review,
    selectionKey: 'id',
    rowsPerPage: 5,
    customToolbar: () => {
      return (
        <TableToolbar
          selectedRowsCount={selectedRows?.length || 0}
          toolBarData={toolBarData}
        />
      );
    },
  };

  const handleViewClick = (record) => {
    setSkuPriceDetails(record);
    setShowPriceDetails(true);
  };

  const handlePriceCancel = () => {
    setShowPriceDetails(false);
  };

  // Delete SKU
  const handleDelete = () => {
    const skuMarkedForDeletion = [];
    const filteredSku = discountedProducts.filter((product) => {
      if (!selectedRows.includes(product.id)) {
        return true;
      } else {
        skuMarkedForDeletion.push(product);
      }
      return false;
    });
    getBulkDeleteAssets(skuMarkedForDeletion);

    dispatch({
      type: DELETE_PRODUCT_DISCOUNT,
      payload: {
        discountedProducts: filteredSku,
      },
    });
    setSelectedRows([]);
    onChange(filteredSku);
  };

  // Functionality related to Add Sku
  const handleSaveSku = (values) => {
    const { sku, percent_off, badges } = values;
    const discountedApplied = parseInt(percent_off, 10) / 100;
    const filteredSKU = skuDetails.filter((item, i) => {
      if (sku.includes(item.id)) {
        item.percent_off = discountedApplied;
        item.badges = badges ? [...badges] : [];
        return true;
      }
      return false;
    });
    const newDiscountedProducts = [...discountedProducts, ...filteredSKU];
    dispatch({
      type: SET_PRODUCT_DISCOUNT,
      payload: {
        discountedProducts: newDiscountedProducts,
      },
    });
    setCanAddSku(false);
    onChange(newDiscountedProducts);
  };

  const handleAddSku = () => {
    setCanAddSku(true);
  };

  const handleCancelSku = () => {
    setCanAddSku(false);
  };

  // Functionality related to Edit Sku
  const handleBatchEditSku = () => {
    const skuMarkedForEdit = discountedProducts.filter((product) => {
      if (selectedRows.includes(product.id)) {
        return true;
      }
      return false;
    });
    handleEditSku(skuMarkedForEdit);
  };

  const handleEditSku = (records) => {
    setEditSkuDetails(records);
    setShowEditSku(true);
  };

  const handleSaveEditSku = (discountsObj) => {
    const discounts = [...discountedProducts].map((discount) => {
      const editedDiscount = discountsObj.find((d) => d.id === discount.id);
      if (editedDiscount) {
        return editedDiscount;
      }
      return discount;
    });
    dispatch({
      type: EDIT_PRODUCT_DISCOUNT,
      payload: {
        discountedProducts: discounts,
      },
    });
    setShowEditSku(false);
    onChange(discounts);
  };

  const handleSkuBadgeDelete = (discountObj) => {
    const discounts = [...discountedProducts];
    const discountIndex = discounts.findIndex(
      (discount) => discount.id === discountObj.id,
    );
    discounts[discountIndex] = { ...discountObj };
    dispatch({
      type: EDIT_PRODUCT_DISCOUNT,
      payload: {
        discountedProducts: discounts,
      },
    });
    setEditSkuDetails(discountObj);
    onChange(discounts);
  };

  const handleCancelEditSku = () => {
    setShowEditSku(false);
  };

  const getUniqueAssets = (deletedSkus) => {
    let uniqAssetObj = {};

    for (let sku of deletedSkus) {
      if (sku.badges && sku.badges.length && sku.badges[0].key) {
        if (!uniqAssetObj[sku.badges[0].key]) {
          uniqAssetObj[sku.badges[0].key] = 1;
        } else {
          uniqAssetObj[sku.badges[0].key]++;
        }
      }
    }
    return uniqAssetObj;
  };
  const getBulkDeleteAssets = (deletedSkus) => {
    const assetsMarkedForDeletion = getUniqueAssets(deletedSkus);
    const assetsToDelete = [];
    for (let asset of Object.keys(assetsMarkedForDeletion)) {
      let remainingAssets = discountedProducts.filter((item) => {
        return (
          item.badges.length &&
          item.badges[0].key &&
          item.badges[0].key === asset
        );
      });
      if (
        remainingAssets.length === 1 ||
        remainingAssets.length === assetsMarkedForDeletion[asset]
      ) {
        assetsToDelete.push(asset);
      }
    }
    return assetsToDelete;
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          Discounts
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {discountedProducts.length > 0 ? (
          <TableComponent
            rows={displayedProductDiscount}
            options={options}
            headCells={headCells}
          />
        ) : (
          <EmptyStateMessage message="No discount" />
        )}

        {!review && (
          <div className="discount-add">
            <Button
              className="button"
              variant="contained"
              onClick={handleAddSku}
            >
              Add Sku
            </Button>
          </div>
        )}
        {hasError && (
          <div>
            <span style={{ color: '#f44336' }}> {meta.error}</span>
          </div>
        )}
      </Grid>

      <ModalComponent
        title="Add SKU"
        open={canAddSku}
        handleClose={handleCancelSku}
      >
        <AddSku
          skuDetails={skuDetails.filter(
            (sku) => (!sku.group_of || !sku.group_of.length) && sku.prices,
          )}
          handleSaveSku={handleSaveSku}
          handleCancelSku={handleCancelSku}
        />
      </ModalComponent>

      <ModalComponent
        title="Edit SKU"
        open={showEditSku}
        handleClose={handleCancelEditSku}
      >
        <EditSku
          handleSaveEditSku={handleSaveEditSku}
          handleCancelEditSku={handleCancelEditSku}
          handleSkuBadgeDelete={handleSkuBadgeDelete}
          editSkuDetails={editSkuDetails}
          disabled={review}
        />
      </ModalComponent>
      <ModalComponent
        title="Price Details"
        open={showPriceDetails}
        handleClose={handlePriceCancel}
      >
        <PriceDetails
          skuPriceDetails={skuPriceDetails}
          handlePriceCancel={handlePriceCancel}
          shippingCountries={shippingCountries}
        />
      </ModalComponent>
    </Grid>
  );
};

export default React.memo(DiscountList);
