import { Link, Paper, Typography } from '@mui/material';

const StoragesSection = ({ profile }) => {
  if (!profile) {
    return null;
  }
  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Storages
      </Typography>
      <ul>
        <li>
          <Link
            href={`https://my.playosmo.com/api/v4/storage/profiles/${profile.id}`}
            target="_blank"
          >
            Profiles storage
          </Link>
        </li>
        <li>
          <Link
            href={`https://my.playosmo.com/api/v4/storage/prefs/${profile.id}`}
            target="_blank"
          >
            Preferences storage
          </Link>
        </li>
        <li>
          <Link
            href={`https://my.playosmo.com/api/v4/storage/achievements/${profile.id}`}
            target="_blank"
          >
            Achievements storage
          </Link>
        </li>
        <li>
          <Link
            href={`https://my.playosmo.com/api/v4/storage/state/${profile.id}`}
            target="_blank"
          >
            State storage
          </Link>
        </li>
      </ul>
    </Paper>
  );
};

export default StoragesSection;
