import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';

import './style.scss';

import ModalComponent from 'components/common/ModalComponent';
import BannerImageSection from '../BannerImageSection';
import TableComponent from 'components/common/TableComponent';
import TableToolbar from 'components/common/TableComponent/components/TableToolbar';
import ImagePreview from '../../ImagePreview';
import TableActions from 'components/common/TableActions';
import Action from 'components/common/TableActions/Actions';
import EmptyStateMessage from 'components/common/EmptyStateMessage';

import { SET_BANNER_DATA, EDIT_BANNER_DATA } from '../../store/actions';
import {
  tableConfig,
  tableDefaultConfig,
  tableToolBarConfig,
  TableCellStates,
} from './tableConfig';
import { TableActionStates } from 'enums/tableStates';
import { generateID } from 'utils';

const optionsMapping = {
  Country: 'country',
  Language: 'lang',
};

const BannerContainer = ({ tabId, bannerId }) => {
  const [typeCountry, setTypeCountry] = useState('');
  const [typeLanguage, setTypeLanguage] = useState('');
  const [showAddBanner, setShowAddBanner] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sectionData, setSectionData] = useState({});

  const dispatch = useDispatch();
  const { bannerHeader, bannerFeature } = useSelector(
    (state) => state.createCampaignReducer,
  );

  const isHeader = bannerId === 'bannerHeader';
  const isHeaderDefault = isHeader && tabId === 'default';

  const bannerTabData =
    (isHeader ? bannerHeader[tabId] : bannerFeature[tabId]) || [];
  const tableHeadConfig = isHeaderDefault ? tableDefaultConfig : tableConfig;

  const onRowSelectChange = (selectedRow) => {
    setSelectedRows(selectedRow);
  };

  const handleEditBanner = (record) => {
    setShowAddBanner(true);
    setSectionData(record);
  };

  const handleDeleteBanner = (record) => {
    const data = [...bannerTabData];
    const filteredBannerData = data.filter((obj) => obj.id !== record.id);
    dispatch({
      type: EDIT_BANNER_DATA,
      payload: {
        bannerId,
        tabId,
        bannerArr: filteredBannerData,
      },
    });
  };

  const handleBannerCancel = () => {
    setShowAddBanner(false);
    setSectionData({});
  };

  const updateSectionData = (value) => {
    setSectionData((prevState) => ({
      ...prevState,
      ...value,
    }));
  };

  const handleBannerSubmit = () => {
    if (sectionData.id) {
      const bannerArr = [...bannerTabData];
      const bannerIndex = bannerArr.findIndex(
        (banner) => banner.id === sectionData.id,
      );
      bannerArr[bannerIndex] = { ...sectionData };
      dispatch({
        type: EDIT_BANNER_DATA,
        payload: {
          bannerId,
          tabId,
          bannerArr,
        },
      });
    } else {
      sectionData.id = generateID();
      if (typeCountry.length && !typeLanguage.length) {
        sectionData.type = 'Country';
      } else if (!typeCountry.length && typeLanguage.length) {
        sectionData.type = 'Language';
      } else if (typeCountry.length && typeLanguage.length) {
        sectionData.type = 'Country/Language';
      }
      dispatch({
        type: SET_BANNER_DATA,
        payload: {
          bannerId,
          tabId,
          bannerData: sectionData,
        },
      });
    }
    setShowAddBanner(false);
    setSectionData({});
  };

  const handleAddBanner = (isDefault) => {
    setTypeLanguage('');
    setTypeCountry('');
    const bannerSectionData = { ...sectionData };
    if (isDefault) {
      bannerSectionData.type = 'Default';
    }
    setShowAddBanner(true);
    setSectionData(bannerSectionData);
  };

  const handleBannerOption = (e) => {
    const { value } = e.target;
    const bannerSectionData = { ...sectionData };
    delete bannerSectionData[optionsMapping[bannerSectionData.type]];
    bannerSectionData.type = value;
    setSectionData(bannerSectionData);
  };

  const handleCountryChange = (e) => {
    setTypeCountry('Country');
    const { value } = e.target;
    const bannerSectionData = { ...sectionData };
    bannerSectionData[optionsMapping['Country']] = value;
    setSectionData(bannerSectionData);
  };

  const handleLanguageChange = (e) => {
    setTypeLanguage('Language');
    const { value } = e.target;
    const bannerSectionData = { ...sectionData };
    bannerSectionData[optionsMapping['Language']] = value;
    setSectionData(bannerSectionData);
  };

  const options = {
    selectedRows,
    onRowSelectChange: onRowSelectChange,
    isRowSelectable: true,
    selectionKey: 'id',
    rowsPerPage: 5,
    customToolbar: () => {
      return (
        <TableToolbar
          selectedRowsCount={selectedRows?.length || 0}
          title=""
          toolBarData={tableToolBarConfig}
        />
      );
    },
  };

  const renderActions = (cell, record, index) => {
    return cell.actions.map((action) => {
      switch (action.type) {
        case TableActionStates.EDIT:
          return (
            <Action
              text={action.text}
              action={() => handleEditBanner(record)}
            />
          );

        case TableActionStates.DELETE:
          return (
            <>
              {record.type !== 'Default' && (
                <Action
                  text={action.text}
                  action={() => handleDeleteBanner(record)}
                />
              )}
            </>
          );
        default:
          return null;
      }
    });
  };

  const headCells = tableHeadConfig.map((cell) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return (
            <TableActions>{renderActions(cell, record, index)}</TableActions>
          );
        };
        return cell;
      case TableCellStates.LANGUAGE:
        cell.render = (record, index) => {
          return (
            <div>
              <span>{record.lang || '-'}</span>
            </div>
          );
        };
        return cell;
      case TableCellStates.COUNTRY:
        cell.render = (record, index) => {
          return (
            <div>
              <span>{record.country || '-'}</span>
            </div>
          );
        };
        return cell;
      case TableCellStates.BACKGROUND_COLOR:
        cell.render = (record, index) => {
          return (
            <div className="color-picker">
              <p style={{ backgroundColor: `${record.background_color}` }}></p>
              <span>{record.background_color || '-'}</span>
            </div>
          );
        };
        return cell;
      case TableCellStates.BACKGROUND_IMAGE:
        cell.render = (record, index) => {
          return <ImagePreview id={record.background_image} />;
        };
        return cell;
      case TableCellStates.CONTENT_IMAGE:
        cell.render = (record, index) => {
          return <ImagePreview id={record.content_image} />;
        };
        return cell;
      default:
        return cell;
    }
  });

  return (
    <>
      {(!bannerTabData || bannerTabData.length === 0) && (
        <>
          <EmptyStateMessage message={`No ${tabId} banner`} />
          <div className="discount-add">
            <Button
              className="button"
              variant="contained"
              onClick={() => handleAddBanner(true)}
            >
              Add Banner
            </Button>
          </div>
        </>
      )}
      {bannerTabData && bannerTabData.length > 0 && (
        <>
          <div className="discount-add">
            <Button
              className="button"
              variant="contained"
              onClick={() => handleAddBanner(false)}
            >
              Add More Banner
            </Button>
          </div>
          <TableComponent
            rows={bannerTabData}
            options={options}
            headCells={headCells}
          />
        </>
      )}
      <ModalComponent
        title="Add Banner"
        open={showAddBanner}
        handleClose={handleBannerCancel}
      >
        <BannerImageSection
          value={sectionData}
          isHeaderDefault={isHeaderDefault}
          uploadSectionData={updateSectionData}
          handleBannerSubmit={handleBannerSubmit}
          handleBannerCancel={handleBannerCancel}
          handleBannerOption={handleBannerOption}
          handleCountryChange={handleCountryChange}
          handleLanguageChange={handleLanguageChange}
        />
      </ModalComponent>
    </>
  );
};

export default React.memo(BannerContainer);
