import React, { useState } from 'react';

import { Button, Grid, Paper } from '@mui/material';
import { styled } from '@mui/styles';
import { Pagination } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';

import './MobileTableComponent.scss';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledPagination = styled(Pagination)({
  '& .MuiPagination-ul': {
    justifyContent: 'flex-end',
  },
});

const MobileTableComponent = (props) => {
  const { rows, headCells, reviewOnly, deleteCampaign, renderAction } = props;
  const perPage = 10;
  const [page, setPage] = useState(1);
  const count = Math.ceil(rows.length / perPage);
  const start = (page - 1) * perPage;
  const end = perPage * page;
  const currentRows = rows.slice(start, end);
  const handleChange = (event, value) => {
    setPage(value);
  };

  //Passing as a prop from upcoming campaign
  const renderDelete = (ids) => {
    return (
      <div style={{ marginLeft: '1em' }}>
        <Button
          startIcon={<DeleteIcon />}
          variant="contained"
          onClick={() => {
            deleteCampaign(ids);
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  return (
    <>
      {currentRows.map((rowItem, rowIndex) => {
        return (
          <div key={rowIndex} className="mobile-table-element">
            <Item>
              <Grid container alignItems="center" justifyContent="center">
                {headCells
                  .filter((filterItem) => {
                    return (
                      filterItem.title !== 'Status' &&
                      filterItem.title !== 'Actions'
                    );
                  })
                  .map((item, index) => {
                    return (
                      <Grid item xs={6} sm={6} key={index}>
                        <p className="mobile-table-p">
                          <strong>{item.title}</strong> : &nbsp;
                          {item.render ? (
                            item.render(rowItem)
                          ) : (
                            <span>{rowItem[item.id]}</span>
                          )}
                        </p>
                      </Grid>
                    );
                  })}
                {reviewOnly === false
                  ? [renderAction(rowItem, 'edit'), renderDelete([rowItem.id])]
                  : renderAction(rowItem, 'review')}
              </Grid>
            </Item>
          </div>
        );
      })}
      <Item className="mobile-table-pagination">
        <StyledPagination
          count={count}
          color="primary"
          variant="text"
          onChange={handleChange}
        />
      </Item>
    </>
  );
};

export default MobileTableComponent;
