import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import TableToolbar from 'components/common/TableComponent/components/TableToolbar';
import TableComponent from 'components/common/TableComponent';
import TableActions from 'components/common/TableActions';
import {
  tableConfig,
  TableCellStates,
  tableToolBarConfig,
  tableToolBarStates,
} from './tableConfig';
import Action from 'components/common/TableActions/Actions';
import { TableActionStates } from 'enums/tableStates';
import ImagePreview from '../ImagePreview';

const BadgesList = ({ badges, review, showEditBadge, updateBadgeList }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleDelete = (record) => {
    const badgeList = badges.filter(
      (item) => item.key !== record.key && !record.isDefault,
    );
    updateBadgeList(badgeList);
  };

  const handleBulkDelete = () => {
    const badgeList = badges.filter(
      (item) => !selectedRows.includes(item.key) && !item.isDefault,
    );
    updateBadgeList(badgeList);
    setSelectedRows([]);
  };

  const toolBarData = tableToolBarConfig.map((configDetail) => {
    switch (configDetail.type) {
      case tableToolBarStates.DeleteButton:
        configDetail.render = () => {
          return (
            <Tooltip title="Delete" disableInteractive>
              <IconButton
                aria-label="delete"
                onClick={handleBulkDelete}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          );
        };
        return configDetail;
      default:
        return configDetail;
    }
  });

  const renderActions = (cell, record) => {
    return cell.actions.map((action) => {
      switch (action.type) {
        case TableActionStates.EDIT:
          return (
            !review && (
              <Action text={action.text} action={() => showEditBadge(record)} />
            )
          );

        case TableActionStates.DELETE:
          return (
            !record.default && (
              <Action text={action.text} action={() => handleDelete(record)} />
            )
          );
        default:
          return null;
      }
    });
  };

  const headCells = tableConfig.map((cell) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return <TableActions>{renderActions(cell, record)}</TableActions>;
        };
        return cell;

      case TableCellStates.BADGES:
        cell.render = (record, index) => {
          return (
            <span>
              <div>
                <ImagePreview id={record.key} />
              </div>
            </span>
          );
        };
        return cell;
      case TableCellStates.TYPE:
        cell.render = (record, index) => {
          if (record.country) {
            return (
              <span>
                <div>Country</div>
              </span>
            );
          }
          if (record.language) {
            return (
              <span>
                <div>Language</div>
              </span>
            );
          }

          return <span>{record.default ? <div>Default</div> : null}</span>;
        };
        return cell;
      default:
        return cell;
    }
  });

  const onRowSelectChange = (selectedRow) => {
    setSelectedRows(selectedRow);
  };

  const options = {
    selectedRows,
    onRowSelectChange: onRowSelectChange,
    isRowSelectable: !review,
    selectionKey: 'key',
    rowsPerPage: 5,
    customToolbar: () => {
      return (
        <TableToolbar
          selectedRowsCount={selectedRows && selectedRows.length}
          title="Badges"
          toolBarData={toolBarData}
        />
      );
    },
  };

  return (
    <TableComponent rows={badges} options={options} headCells={headCells} />
  );
};

export default BadgesList;
