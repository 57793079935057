import { combineReducers } from 'redux';
import { generalReducer } from './general/reducers';
import { policiesReducer } from './policies/reducers';
import { userReducer } from './user/reducers';
import { authReducer } from './auth/reducers';
import { contentReducer } from './content/reducers';
import { productsReducer } from './products/reducers';
import { createCampaignReducer } from 'components/containers/CampaignContainer/CreateCampaign/store/campaignReducer';
import { assetsReducer } from 'components/containers/CampaignContainer/CreateCampaign/store/assetsReducer';
import { campaignListReducer } from 'components/containers/CampaignContainer/CampaignListing/reducer';
import { createPromoCodeReducer } from 'components/containers/PromoCodeContainer/CreatePromoCode/store/reducer';
import { promoCodeListReducer } from 'components/containers/PromoCodeContainer/PromoCodeListing/store/reducer';

const combine = () =>
  combineReducers({
    general: generalReducer,
    policies: policiesReducer,
    user: userReducer,
    auth: authReducer,
    content: contentReducer,
    createCampaignReducer,
    campaignListReducer,
    assetsReducer,
    products: productsReducer,
    promoCodeListReducer,
    createPromoCodeReducer,
  });

export default combine;
