import { takeLatest, put, call } from 'redux-saga/effects';

import { API } from 'axiosClient/config';
import { SET_LOADER } from 'store/general/actions';

import { SAVE_PROMO_CODE } from './actions';

import { postAPIData, postAPIDataWithEnv } from 'api/playosmo';

function* savePromoCodeDetails(action) {
  const { callback, errorCallback, data, couponData, envSelected } =
    action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    yield call(
      envSelected ? postAPIDataWithEnv : postAPIData,
      `${API.createPromoCode}`,
      data,
      envSelected,
    );
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    // Store the coupon data in the session storage so that when we try again we check if there's already a coupon in progress and we dont create the same coupon again
    sessionStorage.setItem(
      'couponPendingPromocode',
      JSON.stringify(couponData),
    );
    if (errorCallback) {
      yield put({
        type: SET_LOADER,
        payload: {
          isLoading: false,
        },
      });
      errorCallback(e?.response?.data?.error?.message);
    }
  }
}

export function* createPromoCodeSaga() {
  yield takeLatest(SAVE_PROMO_CODE, savePromoCodeDetails);
}
