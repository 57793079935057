import React, { useState, useRef } from 'react';
import { Checkbox, Paper, TextField } from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

export default function AutoCompleteWrapper(props) {
  const {
    input,
    meta,
    helperText,
    options = [],
    selectField = 'id',
    getOptionLabel,
    renderDropdownOption,
    disabled,
    limitTags,
    noSelectAll = false,
    focus,
    searchByNameAndID,
    ...rest
  } = props;
  const { onChange, value, multiple, ...restInput } = input;
  const [allOptionSelected, setAllOptionSelected] = useState(false);
  const error = meta.error || meta.submitError;
  const hasError = error && (meta.dirty || meta.submitFailed);
  let filter = createFilterOptions(
    searchByNameAndID
      ? { stringify: (option) => `${option.name}${option.id}` }
      : {},
  );

  let filtered;
  const finalSelected = useRef([]); // Using useRef() hook to act as a variable thats mutable since useState is not getting updated right away
  const selectAllFlag = useRef(true); // Using useRef() hook to act as a variable thats mutable since useState is not getting updated right away

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOptions.find((option) => option.value === 'select-all')) {
        finalSelected.current = [...filtered, ...finalSelected.current];
        if (finalSelected.current.length !== options.length) {
          setAllOptionSelected(false);
          selectAllFlag.current = false;
        }
        const allSelected = options.length === selectedOptions.length - 1;

        allSelected
          ? onChange([])
          : finalSelected.current.length
          ? onChange(finalSelected.current.map((option) => option[selectField]))
          : onChange(options.map((option) => option[selectField]));
        if (
          finalSelected.current.length === options.length &&
          selectedOptions
        ) {
          setAllOptionSelected(true);
        }
      } else {
        const allSelected = options.length === selectedOptions.length;
        setAllOptionSelected(allSelected);
        finalSelected.current = selectedOptions;
        onChange(selectedOptions.map((option) => option[selectField]));
      }
    } else if (reason === 'clear') {
      setAllOptionSelected(false);
      onChange([]);
      finalSelected.current = [];
    }
  };

  const renderOptionComponent = (props, option, selected, selectField) => {
    const selectAllProps =
      option.value === 'select-all' ? { checked: allOptionSelected } : {};
    return (
      <div
        title={
          option.group_of && !!option.group_of.length
            ? `This option includes: ${option.group_of.join(', ')}`
            : null
        }
        {...props}
      >
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
          disabled={disabled}
          color="primary"
        />
        {renderDropdownOption && option.value !== 'select-all'
          ? renderDropdownOption(option)
          : option[selectField]}
        {option.group_of && !!option.group_of.length && (
          <small>
            <i>{' - Use this SKU for featured section display'}</i>
          </small>
        )}
      </div>
    );
  };

  const multipleValues =
    (multiple &&
      options &&
      options.length &&
      value &&
      value.length &&
      value
        .map((val) =>
          options.find((option) => {
            if (typeof val === 'object') {
              return option.id === val.id;
            }
            return option.id === val;
          }),
        )
        .filter((val) => !!val)) || // make sure there's no undefined objects in the array
    [];

  const renderMultiSelect = () => (
    <Autocomplete
      multiple={multiple}
      disabled={disabled}
      limitTags={limitTags}
      {...input}
      {...rest}
      options={options}
      disableCloseOnSelect
      value={multipleValues}
      onChange={handleChange}
      getOptionLabel={getOptionLabel || ((option) => option[selectField])}
      PaperComponent={({ children }) => (
        <Paper children={children} elevation={1} />
      )}
      filterOptions={(options, params) => {
        filtered = filter(options, params);
        if (noSelectAll) return filtered;
        return [
          {
            [selectField]: 'Select All',
            value: 'select-all',
          },
          ...filtered,
        ];
      }}
      renderOption={(props, option, { selected }) =>
        renderOptionComponent(props, option, selected, selectField)
      }
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;
        const { startAdornment, ...restInputProps } = InputProps;
        return (
          <TextField
            variant="standard"
            autoFocus={focus}
            {...restParams}
            {...params}
            {...restInput}
            {...rest}
            helperText={hasError ? meta.error || meta.submitError : undefined}
            error={hasError}
            InputProps={{
              ...restInputProps,
              startAdornment: (
                <div
                  style={{
                    maxHeight: 300,
                    overflowY: 'auto',
                  }}
                >
                  {startAdornment}
                </div>
              ),
            }}
          />
        );
      }}
    />
  );

  const renderSingleSelect = () => (
    <Autocomplete
      multiple={multiple}
      {...input}
      {...rest}
      options={disabled ? [] : options}
      value={options.find((option) => option[selectField] === value) || null}
      getOptionLabel={getOptionLabel || ((option) => option[selectField])}
      onChange={(_e, value) => {
        onChange(value ? value[selectField] : null);
      }}
      disabled={disabled}
      PaperComponent={({ children }) => (
        <Paper children={children} elevation={1} />
      )}
      renderInput={(params) => (
        <TextField
          variant="standard"
          autoFocus={focus}
          {...params}
          {...input}
          {...rest}
          helperText={hasError ? meta.error || meta.submitError : undefined}
          error={hasError}
          disabled={disabled}
        />
      )}
    />
  );

  return (
    <React.Fragment>
      {multiple ? renderMultiSelect() : renderSingleSelect()}
    </React.Fragment>
  );
}
