export const PLAYOSMO_ADMIN = 'playosmo-admin';
export const MARKETING_READ = 'playosmo-marketing-viewer';
export const MARKETING_WRITE = 'playosmo-marketing-editor';
export const USERS_WRITE = 'permission-management-editor';
export const USERS_READ = 'permission-management-viewer';
export const ORDERS_WRITE = 'playosmo-orders-editor';
export const ORDERS_READ = 'playosmo-orders-viewer';
export const SHORTLINK_READ = 'short-links-viewer';
export const SHORTLINK_WRITE = 'short-links-editor';
export const COMMUNICATIONS_READ = 'communication-management-viewer';
export const COMMUNICATIONS_WRITE = 'communication-management-editor';
export const USERS_ACCOUNTS_READ = 'accounts-management-viewer';
export const USERS_ACCOUNTS_WRITE = 'accounts-management-editor';
export const PLAYOSMO_PRODUCTS_WRITE = 'playosmo-products-editor';
export const IP_WHITELIST_READ = 'ip-whitelist-management-viewer';
export const IP_WHITELIST_WRITE = 'ip-whitelist-management-editor';
export const DEVELOPER = 'developer';
export const ADMIN_ACCESS = 'admin-platform-access';
