import { useState, useCallback, useEffect } from 'react';
import { Field } from 'react-final-form';
import { Paper, Typography } from '@mui/material';

import { normalizeWarehousesResponse } from 'utils';
import { getProductManagement } from 'api/products';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';
import AutoCompleteWrapper from 'components/common/AutoCompleteWrapper';
import { API } from 'axiosClient/config';

const GeneralSection = ({ inputBase }) => {
  const [warehouses, setWarehouses] = useState(null);
  const warehouseDataItems =
    warehouses?.map((warehouse) => ({
      id: warehouse.code,
      name: warehouse.name,
    })) || [];

  const fetchWarehouses = useCallback(async () => {
    try {
      const response = await getProductManagement(API.products.warehouses);
      const normalizedWarehouses = normalizeWarehousesResponse(
        response.data.data,
      );
      setWarehouses(normalizedWarehouses);
    } catch (e) {
      throw e;
    }
  }, []);

  useEffect(() => {
    fetchWarehouses();
  }, [fetchWarehouses]);

  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        General
      </Typography>
      <OrderPropertiesWrapper>
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'code',
            required: true,
          }}
          label="Country code"
        />
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'name',
            required: true,
          }}
          label="Country name"
        />
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'included_tax',
          }}
          label="Included tax (%)"
        />
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'is_duty_applied',
          }}
          label="Duty applies"
          type="checkbox"
        />
      </OrderPropertiesWrapper>
      <div className="mt-20">
        <Typography variant="h3" gutterBottom>
          Warehouses
        </Typography>
        <Field
          required
          name="warehouses"
          label="Select warehouses for this country"
          variant="outlined"
          multiple={true}
          limitTags={3}
          placeholder="Search for warehouses"
          options={warehouseDataItems}
          selectField="id"
          component={AutoCompleteWrapper}
          getOptionLabel={(opt) => opt.name}
          renderDropdownOption={(opt) => opt.name}
          {...inputBase}
        />
      </div>
    </Paper>
  );
};

export default GeneralSection;
