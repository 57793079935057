import ErrorImg from 'assets/image/error.png';

export const errorHandler = {
  404: {
    title: 'Page Not Found',
    imageSrc: ErrorImg,
    description: "The page you are looking for doesn't exist..",
  },
  403: {
    title: 'Internal Server Error',
    imageSrc: ErrorImg,
    description: 'Something Went wrong',
  },
  400: {
    title: 'Bad Request',
    imageSrc: ErrorImg,
    description: 'Your request resulted in an error..',
  },

  500: {
    title: 'Internal Server Error',
    imageSrc: ErrorImg,
    description: 'Something Went wrong',
  },
};
