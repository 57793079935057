import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';
import { Warning } from '@mui/icons-material';

import generalSelectors from 'store/general/selectors';

import { envState } from 'enums/envStates';
import osmoColors from 'utils/ui/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: osmoColors.red[80],
    margin: 'auto',
    padding: '10px 24px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 10,
    },
  },
}));

const ProductionWarning = (props) => {
  const { apiEnv } = props;
  const classes = useStyles();

  if (envState.PRODUCTION !== apiEnv) {
    return null;
  }

  return (
    <Paper variant="outlined" classes={classes}>
      <Warning /> You're in production, every changes you make will impact the
      website
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  apiEnv: generalSelectors.getAPIEnv(state),
});

export default connect(mapStateToProps)(ProductionWarning);
