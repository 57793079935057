import React from 'react';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { Button } from '@mui/material';

import paths from 'routes/paths';

import TableComponent from 'components/common/TableComponent';

import { tableConfig, TableCellStates } from './tableConfig';
import StatusIndicator from 'components/common/StatusIndicator';

const WarehousesTable = (props) => {
  const { rows, isLoading, disablePagination, rowCount } = props;
  const history = useHistory();
  const rowsWithKeys = rows.map((row) => ({ ...row, id: row.id }));

  const options = {
    isRowSelectable: false,
    selectionKey: 'id',
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.EDIT:
        cell.render = (record, index) => {
          return (
            <Button
              key={`${i}-${index}`}
              onClick={() =>
                history.push(
                  `${paths.eCommerceConfiguration.warehouses.view}/${
                    record[TableCellStates.ID]
                  }`,
                )
              }
              color="primary"
              variant="text"
            >
              View
            </Button>
          );
        };
        return cell;
      case TableCellStates.ID:
        cell.render = (record, index) => {
          return <span>{record[TableCellStates.ID]}</span>;
        };
        return cell;
      case TableCellStates.NAME:
        cell.render = (record, index) => {
          return <strong>{record[TableCellStates.NAME]}</strong>;
        };
        return cell;
      case TableCellStates.STATUS:
        cell.render = (record, index) => {
          return (
            <StatusIndicator
              key={i}
              status={record[TableCellStates.STATUS]}
              withLabel={true}
            />
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  if (isLoading) {
    return <Skeleton height={300} animation="wave" variant="rectangular" />;
  }

  return (
    <TableComponent
      rows={rowsWithKeys}
      headCells={headCells}
      options={options}
      disablePagination={disablePagination}
      rowCount={rowCount}
      size="small"
    />
  );
};

export default WarehousesTable;
