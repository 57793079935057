import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';

import paths from 'routes/paths';
import { campaignListingTab } from 'enums/tabsState';
import { MARKETING_WRITE, MARKETING_READ } from 'config/roles';

import PageContentLayout from 'components/layout/PageContentLayout';
import withRouterInfo from 'components/hoc/withRouterInfo';
import { useErrorStatus } from 'components/hoc/ErrorHandler';
import TabsComponent from 'components/common/TabsComponent';

import PastCampaign from './PastCampaign';
import UpcomingCampaign from './UpcomingCampaign';

import userSelectors from 'store/user/selectors';
import { GET_UPCOMING_CAMPAIGN, GET_PAST_CAMPAIGN } from './actions';

import { tabsConfig } from './tabsConfig';

function CampaignContainer({ userRoles, openPastCampaign }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isUpcomingLoading, setUpcomingLoading] = useState(false);
  const [isPastCampaignLoading, setPastCampaignLoading] = useState(false);
  const [openPastCampaignValue, setOpenPastCampaignValue] =
    useState(openPastCampaign);

  const [tabsConfigDetails, setTabsConfigDetails] = useState([...tabsConfig]);

  const { setErrorStatusCode } = useErrorStatus();
  const reviewOnly =
    userRoles.includes(MARKETING_READ) && !userRoles.includes(MARKETING_WRITE);

  const history = useHistory();
  const dispatch = useDispatch();
  const [websitePayload, setWebsitePayload] = useState('');

  const { upcomingCampaign, pastCampaign } = useSelector(
    (state) => state.campaignListReducer,
  );

  const getUpcomingCampaign = useCallback(() => {
    setUpcomingLoading(false);
    dispatch({
      type: GET_UPCOMING_CAMPAIGN,
      payload: {
        website: websitePayload,
        callback: () => {
          setUpcomingLoading(true);
        },
        errorCallback: (error) => {
          setErrorStatusCode(error);
        },
      },
    });
  }, [dispatch, setErrorStatusCode, websitePayload]);

  const getPastCampaign = useCallback(() => {
    setPastCampaignLoading(false);
    dispatch({
      type: GET_PAST_CAMPAIGN,
      payload: {
        website: websitePayload,
        callback: () => {
          setPastCampaignLoading(true);
        },
        errorCallback: (error) => {
          setErrorStatusCode(error);
        },
      },
    });
  }, [dispatch, setErrorStatusCode, websitePayload]);

  useEffect(() => {
    getUpcomingCampaign();
    getPastCampaign();
  }, [getUpcomingCampaign, getPastCampaign]);

  useEffect(() => {
    if (isUpcomingLoading && isPastCampaignLoading && !openPastCampaignValue) {
      const tabsDetails = tabsConfig.map((tab) => {
        if (tab.id === campaignListingTab.CURRENT) {
          tab.render = () => {
            return (
              <UpcomingCampaign
                upcomingCampaign={upcomingCampaign}
                getUpcomingCampaign={getUpcomingCampaign}
                reviewOnly={reviewOnly}
                setWebsitePayload={setWebsitePayload}
              />
            );
          };
          tab.listCount = upcomingCampaign.length;
          return tab;
        }
        return tab;
      });
      setTabsConfigDetails(tabsDetails);
    }

    if (isPastCampaignLoading && openPastCampaignValue) {
      const tabsDetails = tabsConfig.map((tab) => {
        if (tab.id === campaignListingTab.PAST) {
          tab.render = () => {
            return (
              <PastCampaign
                pastCampaign={pastCampaign}
                setWebsitePayload={setWebsitePayload}
              />
            );
          };
          tab.listCount = pastCampaign.length;
          return tab;
        }
        return tab;
      });
      setTabsConfigDetails(tabsDetails);
      setSelectedTab(1);
    }
  }, [
    isUpcomingLoading,
    isPastCampaignLoading,
    getUpcomingCampaign,
    upcomingCampaign,
    pastCampaign,
    reviewOnly,
    websitePayload,
  ]);

  const handleChangeTab = (event, newValue) => {
    if (newValue === 1) {
      setOpenPastCampaignValue(false);
      history.push(paths.marketing.campaigns.listPast);
    } else {
      setOpenPastCampaignValue(true);
      history.push(paths.marketing.campaigns.list);
    }
    setSelectedTab(newValue);
  };

  const handleCreate = () => {
    history.push(paths.marketing.campaigns.create);
  };

  return (
    <PageContentLayout
      title="Campaigns"
      buttons={
        !reviewOnly && [
          {
            label: 'Create Campaign',
            onClick: handleCreate,
          },
        ]
      }
      showProductionWarning={!reviewOnly}
    >
      <TabsComponent
        selectedTab={selectedTab}
        tabsDetails={tabsConfigDetails}
        handleChangeTab={handleChangeTab}
      />
    </PageContentLayout>
  );
}

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default compose(withRouterInfo)(
  connect(mapStateToProps)(CampaignContainer),
);
