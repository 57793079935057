import { useState, useEffect, useRef } from 'react';
import { List, Paper } from '@mui/material';

import './styles.scss';

const TableActions = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const tableActions = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        tableActions.current &&
        !tableActions?.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tableActions]);

  return (
    <div className="table-action">
      <div
        className="table-action-selection"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        ...
      </div>

      {isOpen && (
        <Paper className="table-action-list" elevation={14} ref={tableActions}>
          <List component="nav">{props.children}</List>
        </Paper>
      )}
    </div>
  );
};

export default TableActions;
