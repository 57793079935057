import React, { useEffect, useState } from 'react';
import { Badge } from '@mui/material';
import { API } from 'axiosClient/config';
import { getAPIData } from 'api/playosmo';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { useIconStyles } from 'components/common/NavBar/NavItem';

const IssueNotificationBadge = () => {
  const iconClasses = useIconStyles({
    nested: true,
  });
  const [unresolvedCount, setUnresolvedCount] = useState();

  useEffect(() => {
    const getUnresolvedIssueCount = async () => {
      let response;
      let issueUrl = `${API.orders.issues}?matches_acked=false&matches_resolved=false&count_only=true`;
      response = await getAPIData(issueUrl);
      setUnresolvedCount(response.data.total);
    };
    getUnresolvedIssueCount();
  }, []);

  return (
    <Badge badgeContent={unresolvedCount} color="error">
      <NotificationImportantIcon classes={iconClasses} />
    </Badge>
  );
};

export default IssueNotificationBadge;
