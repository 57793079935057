import {
  USERS_DETAILS_SUCCESS,
  ROLES_SUCCESS,
  USER_ROLES_SUCCESS,
} from './actions';

const initialState = {
  users: {},
  roles: [],
};

export const policiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_DETAILS_SUCCESS: {
      const { users } = action.payload;

      return {
        ...state,
        users,
      };
    }

    case ROLES_SUCCESS: {
      const { roles } = action.payload;

      return {
        ...state,
        roles,
      };
    }

    case USER_ROLES_SUCCESS: {
      const { roles, id } = action.payload;

      return {
        ...state,
        users: {
          ...state.users,
          [id]: {
            ...(state.users[id] || {}),
            roles,
          },
        },
      };
    }

    default:
      return state;
  }
};
