import { takeLatest, put, call } from 'redux-saga/effects';
import {
  REQUEST_OTP,
  VERIFY_OTP,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
} from './actions';
import { SET_LOADER } from 'store/general/actions';

import { login, logout } from 'api/auth';

import { INVALID_OTP, USER_DOESNOT_EXISTS } from 'config/messages';
import { getInitialEnvironmentValue } from 'config/environments';
import { GET_USER_INFO } from 'store/user/actions';

function* requestOtp(action) {
  const { data, callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    yield call(login, data);

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (errorCallback) {
      errorCallback();
    }
  }
}

function* verifyOtp(action) {
  const { data, callback, errorCallback } = action.payload;

  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const loginResponse = yield call(login, data);

    if (loginResponse.data.data.token_id) {
      errorCallback(USER_DOESNOT_EXISTS);
      yield put({
        type: SET_LOADER,
        payload: {
          isLoading: false,
        },
      });
    } else {
      yield put({ type: LOGIN_SUCCESS, payload: { isLoggedIn: true } });
      yield put({
        type: SET_LOADER,
        payload: {
          isLoading: false,
        },
      });
      yield put({ type: GET_USER_INFO, payload: {} });

      if (callback) {
        callback();
      }
    }
  } catch (e) {
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    errorCallback(INVALID_OTP);
  }
}

function* logoutUser() {
  try {
    yield call(logout, {});
    yield put({ type: LOGOUT_SUCCESS });
    localStorage.setItem('env', getInitialEnvironmentValue());
  } catch (e) {}
}

export function* authSaga() {
  yield takeLatest(REQUEST_OTP, requestOtp);
  yield takeLatest(VERIFY_OTP, verifyOtp);
  yield takeLatest(LOGOUT, logoutUser);
}
