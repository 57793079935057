import React from 'react';
import { useSelector } from 'react-redux';

import Loader from 'components/common/Loader';

import './style.scss';

const IsLoadingHOC = (WrappedComponent) => {
  function HOC(props) {
    const { isLoading } = useSelector((state) => state.general);

    return (
      <>
        {isLoading && (
          <div className={`busy-holder backdrop`}>
            <div className="busy">
              <Loader />
            </div>
          </div>
        )}

        <WrappedComponent {...props} />
      </>
    );
  }
  return HOC;
};

export default IsLoadingHOC;
