import React from 'react';
import { Form, Field } from 'react-final-form';

import { Grid, Button, MenuItem, TextField } from '@mui/material';

import TextInput from 'components/common/TextInput';

import { ENVIRONMENTS, getCurrentEnvironment } from 'config/environments';

const DuplicateObjectForm = (props) => {
  const { handleEnvCancel, handleEnvSubmit, formDetails } = props;

  const environments = [...ENVIRONMENTS];
  const currentEnv = getCurrentEnvironment();

  const SelectInput = ({ input, label, fullWidth, required }) => (
    <TextField
      fullWidth={fullWidth}
      required={required}
      select
      name={input.name}
      onChange={(value) => input.onChange(value)}
      variant="outlined"
      label={label}
    >
      {environments.map((item) => (
        <MenuItem key={item} value={item} name={item}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );

  const handleFormSubmit = (values) => {
    handleEnvSubmit(values, formDetails);
  };

  return (
    <Form
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container>
            <Grid item xs={12}>
              <Field
                fullWidth
                required
                name="environment"
                component={SelectInput}
                type="text"
                label="Environment"
              />
            </Grid>
            {values.environment === currentEnv && (
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="new_id"
                  component={TextInput}
                  type="text"
                  label="New id"
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item xs={12} className="button-container">
              <Button
                className="mr-20"
                size="small"
                variant="outlined"
                type="button"
                onClick={handleEnvCancel}
              >
                Cancel
              </Button>
              <Button
                className="button"
                size="small"
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default DuplicateObjectForm;
