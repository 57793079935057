import { Button, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import TextInput from 'components/common/TextInput';

import './styles.scss';

const TextInputStandalone = ({ buttonDisabled, onResetClick, ...props }) => {
  return (
    <div className="text-input-standalone">
      <TextInput
        {...props}
        fullWidth
        input={{
          ...props.input,
          className: 'text-input-standalone-input',
        }}
      />
      <div className="text-input-standalone-buttons">
        {!buttonDisabled && onResetClick && (
          <IconButton onClick={onResetClick} size="small" className="mr-5">
            <CloseIcon />
          </IconButton>
        )}
        <Button
          className="text-input-standalone-button"
          size="small"
          variant="contained"
          type="submit"
          disabled={buttonDisabled}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default TextInputStandalone;
