import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TabsComponent from 'components/common/TabsComponent';

import { tabsConfig } from '../tabsConfig';
import BannerContainer from '../BannerContainer';

const BannerFeature = ({ label, bannerId }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabsDetails = tabsConfig.map((tab) => {
    tab.render = () => {
      return <BannerContainer tabId={tab.id} bannerId={bannerId} />;
    };
    return tab;
  });

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        <Typography variant="h3" gutterBottom>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        <TabsComponent
          selectedTab={selectedTab}
          tabsDetails={tabsDetails}
          handleChangeTab={handleChangeTab}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(BannerFeature);
