import { React } from 'react';
import OtpInput from 'react-otp-input';

const OtpInputWrapper = ({
  input: { name, required, onChange, value, ...restInput },
  meta,
  otpInputClass,
  otpParentClass,
  numInputBoxes,
  ...rest
}) => {
  const hasError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;
  return (
    <>
      <OtpInput
        value={value}
        onChange={(value) => onChange(value)}
        numInputs={numInputBoxes}
        separator={<span>&nbsp;</span>}
        inputStyle={otpInputClass}
        containerStyle={otpParentClass}
      />
      {hasError && <div class="error">{meta.error}</div>}
    </>
  );
};
export default OtpInputWrapper;
