import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { Card, CardContent, Grid, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { API } from 'axiosClient/config';

import { getAPIData } from 'api/playosmo';
import { ORDERS_WRITE } from 'config/roles';
import paths from 'routes/paths';

import userSelectors from 'store/user/selectors';
import normalizeOrdersResponse from 'utils/normalizers/api/normalizeOrdersResponse';

import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import PageContentLayout from 'components/layout/PageContentLayout';
import OrderProperty from 'components/containers/OrdersContainer/OrderView/components/OrderProperty';
import OrdersTable from 'components/containers/OrdersContainer/OrdersListing/OrdersTable';
import ActionsSection from './components/ActionsSection';

import './index.scss';

function SubscriptionView({ match, userRoles }) {
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState();
  const [subscriptionIdTitle, setSubscriptionIdTitle] = useState('');
  const history = useHistory();

  const subscriptionId = match.params.id;
  const canEdit = userRoles.includes(ORDERS_WRITE);

  const getSubscription = async (subscriptionId) => {
    setLoading(true);
    try {
      let response = await getAPIData(API.subscriptionDetails + subscriptionId);
      setLoading(false);
      return response.data && response.data.subscriptions[0];
    } catch (e) {}
  };

  useEffect(() => {
    setSubscriptionIdTitle('Subscription #' + subscriptionId);
    getSubscription(subscriptionId).then((data) => {
      setSubscription(data);
      setLoading(false);
    });
  }, [subscriptionStatus, subscriptionId]);

  const handleSeeOrder = () => {
    history.push(`${paths.orders.view}/${subscription.parent_order_id}`);
  };

  return (
    <>
      <PageContentLayout
        title={subscriptionIdTitle}
        isLoading={loading}
        className="subscription-view-content"
        buttons={[
          {
            label: 'See parent order',
            onClick: handleSeeOrder,
          },
        ]}
        breadcrumbs={[
          {
            label: 'Subscriptions',
            link: paths.orders.subscriptions.list,
          },
          {
            label: subscriptionIdTitle,
            isCurrent: true,
          },
        ]}
      >
        {subscription ? (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Card elevation={0}>
                <CardContent>
                  <Typography variant="h4" color="text.secondary" gutterBottom>
                    Overview
                  </Typography>
                  <Divider className="subscription-listing-divider-margin-bottom" />
                  <OrderPropertiesWrapper>
                    <OrderProperty
                      label="Current status"
                      value={subscription.status}
                    />
                    <OrderProperty
                      label="Product"
                      value={subscription.subscription_plan.name}
                    />
                    <OrderProperty
                      label="Number of boxes shipped"
                      value={subscription.subscription_order_ids.length}
                    />
                    <OrderProperty
                      label="Total number of boxes"
                      value={subscription.total_subscription_order_count}
                    />
                    <OrderProperty
                      label="Payment type"
                      value={subscription.is_prepaid ? 'Prepaid' : 'Recurring'}
                    />
                    {!subscription.is_prepaid && (
                      <>
                        <OrderProperty
                          label="Payment Frequency"
                          value={
                            subscription.subscription_plan.payment_frequency
                          }
                        />
                        <OrderProperty
                          label="Recurring Price"
                          isPrice={true}
                          value={subscription.subscription_plan.recurring_price}
                        />
                      </>
                    )}
                    <OrderProperty
                      label="Created on"
                      value={subscription.created_on}
                    />
                    <OrderProperty
                      label="Canceled on"
                      value={subscription.canceled_on}
                    />
                    <OrderProperty
                      label="Paused on"
                      value={subscription.paused_on}
                    />
                    <OrderProperty
                      label="Ended on"
                      value={subscription.ended_on}
                    />
                    <OrderProperty
                      label="Last recurring process on"
                      value={subscription.last_processed_on}
                    />
                    <OrderProperty
                      label="Last shipment on"
                      value={subscription.last_order_created_on}
                    />
                    <OrderProperty
                      label="Next shipment scheduled on"
                      value={subscription.next_shipment_scheduled_on}
                    />
                  </OrderPropertiesWrapper>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card elevation={0}>
                <CardContent>
                  <Typography variant="h4" color="text.secondary" gutterBottom>
                    Customer
                  </Typography>
                  <Divider className="subscription-listing-divider-margin-bottom" />
                  <OrderPropertiesWrapper>
                    <OrderProperty
                      label="First name"
                      value={subscription.customer.first_name}
                    />
                    <OrderProperty
                      label="Last name"
                      value={subscription.customer.last_name}
                    />
                    <OrderProperty
                      label="Email"
                      value={subscription.customer.email}
                    />
                    <OrderProperty
                      label="Phone"
                      value={subscription.customer.phone}
                    />
                  </OrderPropertiesWrapper>
                </CardContent>
              </Card>
            </Grid>
            <ActionsSection
              canEdit={canEdit}
              subscription={subscription}
              setSubscriptionStatus={setSubscriptionStatus}
            />
            <Grid item xs={6}>
              <Card elevation={0}>
                <CardContent>
                  <Typography variant="h4" color="text.secondary" gutterBottom>
                    Shipping Details
                  </Typography>
                  <Divider className="subscription-listing-divider-margin-bottom" />
                  <OrderPropertiesWrapper>
                    <OrderProperty
                      label="First Name"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.shipping_address &&
                        subscription.customer.shipping_address.first_name
                      }
                    />
                    <OrderProperty
                      label="Last Name"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.shipping_address &&
                        subscription.customer.shipping_address.last_name
                      }
                    />
                    <OrderProperty
                      label="Address line 1"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.shipping_address &&
                        subscription.customer.shipping_address.address_line_1
                      }
                    />
                    <OrderProperty
                      label="Address line 2"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.shipping_address &&
                        subscription.customer.shipping_address.address_line_2
                      }
                    />
                    <OrderProperty
                      label="Address line 3"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.shipping_address &&
                        subscription.customer.shipping_address.address_line_3
                      }
                    />
                    <OrderProperty
                      label="City"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.shipping_address &&
                        subscription.customer.shipping_address.city
                      }
                    />
                    <OrderProperty
                      label="State"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.shipping_address &&
                        subscription.customer.shipping_address.state
                      }
                    />
                    <OrderProperty
                      label="Zip Code"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.shipping_address &&
                        subscription.customer.shipping_address.zip_code
                      }
                    />
                    <OrderProperty
                      label="Country"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.shipping_address &&
                        subscription.customer.shipping_address.country_code
                      }
                    />
                  </OrderPropertiesWrapper>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card elevation={0}>
                <CardContent>
                  <Typography variant="h4" color="text.secondary" gutterBottom>
                    Billing Details
                  </Typography>
                  <Divider className="subscription-listing-divider-margin-bottom" />
                  <OrderPropertiesWrapper>
                    <OrderProperty
                      label="First Name"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.billing_address &&
                        subscription.customer.billing_address.first_name
                      }
                    />
                    <OrderProperty
                      label="Last Name"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.billing_address &&
                        subscription.customer.billing_address.last_name
                      }
                    />
                    <OrderProperty
                      label="Address line 1"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.billing_address &&
                        subscription.customer.billing_address.address_line_1
                      }
                    />
                    <OrderProperty
                      label="Address line 2"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.billing_address &&
                        subscription.customer.billing_address.address_line_2
                      }
                    />
                    <OrderProperty
                      label="Address line 3"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.billing_address &&
                        subscription.customer.billing_address.address_line_3
                      }
                    />
                    <OrderProperty
                      label="City"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.billing_address &&
                        subscription.customer.billing_address.city
                      }
                    />
                    <OrderProperty
                      label="State"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.billing_address &&
                        subscription.customer.billing_address.state
                      }
                    />
                    <OrderProperty
                      label="Zip Code"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.billing_address &&
                        subscription.customer.billing_address.zip_code
                      }
                    />
                    <OrderProperty
                      label="Country"
                      value={
                        subscription &&
                        subscription.customer &&
                        subscription.customer.billing_address &&
                        subscription.customer.billing_address.country_code
                      }
                    />
                  </OrderPropertiesWrapper>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={0}>
                <CardContent>
                  <Typography variant="h4" color="text.secondary" gutterBottom>
                    Sub-orders ({subscription.subscription_order_ids.length}/
                    {subscription.total_subscription_order_count})
                  </Typography>
                  <Divider className="subscription-listing-divider-margin-bottom" />
                  <OrdersTable
                    rows={normalizeOrdersResponse(
                      subscription.subscription_orders || [],
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </PageContentLayout>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(SubscriptionView);
