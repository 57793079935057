import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, FormSpy } from 'react-final-form';
import { Grid, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import { MARKETING_WRITE } from 'config/roles';
import {
  PROMO_CODE_DUPLICATED,
  PROMO_CODE_SAVED,
  PROMO_CODE_SAVE_ERROR,
} from 'config/messages';
import { promoCodeStateMapping } from './config';
import {
  capitalizeFirstLetter,
  campaignPostDataTransform,
  validateCampaignInformation,
} from 'utils';
import paths from 'routes/paths';

import userSelectors from 'store/user/selectors';

import {
  GET_UPCOMING_CAMPAIGN,
  GET_PAST_CAMPAIGN,
} from 'components/containers/CampaignContainer/CampaignListing/actions';
import { GET_PROMO_CODES } from 'components/containers/PromoCodeContainer/PromoCodeListing/store/actions';
import {
  SAVE_CAMPAIGN_DETAILS,
  RESET_CAMPAIGN_DETAILS,
} from 'components/containers/CampaignContainer/CreateCampaign/store/actions';
import { SAVE_PROMO_CODE } from 'components/containers/PromoCodeContainer/CreatePromoCode/store/actions';

import { useErrorStatus } from 'components/hoc/ErrorHandler';
import SetPageTitle from 'components/common/SetPageTitle';
import PageFormLayout from 'components/layout/PageFormLayout';

import InformationSection from 'components/containers/CampaignContainer/CreateCampaign/InformationSection';
import DiscountSection from 'components/containers/CampaignContainer/CreateCampaign/Discounts';
import { getCampaignKey } from 'components/containers/CampaignContainer/CreateCampaign';
import PromoCodeForm from './PromoCodeForm';
import {
  campaignStateMapping,
  envConfirmConfig,
} from 'components/containers/CampaignContainer/CreateCampaign/config';
import ModalComponent from 'components/common/ModalComponent';
import Confirmation from 'components/common/Confirmation';
import DuplicatePromocode from './DuplicatePromocode/DuplicatePromocode';
import { SET_ENV } from 'store/general/actions';
import generalSelectors from 'store/general/selectors';
import { IS_BETA, IS_DEVELOP, IS_RELC, PRODUCTION } from 'config/environments';

const CreatePromoCode = ({
  match,
  type,
  // From Redux
  dispatch,
  userRoles,
  apiEnv,
}) => {
  const [initialValues, setInitialValues] = useState({
    code: '',
    coupon_id: '',
    is_default: 'url',
    showCouponCreation: false,
    limitless: true,
    new_customer_only: false,
    dateRange: {
      start: new Date(),
      end: new Date(),
    },
  });

  const canEdit =
    userRoles.includes(MARKETING_WRITE) || type === promoCodeStateMapping.EDIT;
  const canReview = type === promoCodeStateMapping.REVIEW;
  const code = match.params.code;

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { setErrorStatusCode } = useErrorStatus();
  const { promoCodes } = useSelector((state) => state.promoCodeListReducer);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [envSelected, setEnvSelected] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [duplicateCouponData, setDuplicateCouponData] = useState([]);
  const [duplicateCampaignDetails, setDuplicateCampaignDetails] = useState([]);
  const canDuplicate =
    canEdit && apiEnv !== PRODUCTION && type !== campaignStateMapping.CREATE;

  const couponPendingPromoCodeVal = sessionStorage.getItem(
    'couponPendingPromocode',
  );
  const disableCouponForm =
    couponPendingPromoCodeVal &&
    couponPendingPromoCodeVal !== 'undefined' &&
    JSON.parse(couponPendingPromoCodeVal);

  useEffect(() => {
    // Fetch the upcoming campaigns
    dispatch({
      type: GET_UPCOMING_CAMPAIGN,
      payload: {
        callback: () => {},
        errorCallback: (error) => {
          setErrorStatusCode(error);
        },
      },
    });

    // If we're viewing an existing code
    if (code) {
      // Fetch the promo codes to get the form data
      dispatch({
        type: GET_PROMO_CODES,
        payload: {
          callback: (e) => {
            console.log({ e });
          },
          errorCallback: (error) => {
            setErrorStatusCode(error);
          },
        },
      });

      // Get the past campaigns as we could be viewing an expired code
      dispatch({
        type: GET_PAST_CAMPAIGN,
        payload: {
          callback: () => {},
          errorCallback: (error) => {
            setErrorStatusCode(error);
          },
        },
      });
    }
  }, [code, setErrorStatusCode, dispatch]);

  useEffect(() => {
    if (code && promoCodes && promoCodes.length) {
      const promoCode = promoCodes.find((promoCode) => promoCode.code === code);
      const formData = {
        code: promoCode.code,
        promo_code_id: promoCode.promo_code_id.toString(),
        coupon_id: promoCode.coupon_id.toString(),
        website: promoCode.website.toString(),
      };
      setInitialValues(formData);
    }
  }, [promoCodes, code]);

  const validate = (values) => {
    let errors = {};

    if (!values.code) {
      errors.code = 'Code is required';
    }
    if (!values.showCouponCreation && !values.coupon_id) {
      errors.coupon_id = 'Coupon is required';
    }

    if (values.showCouponCreation) {
      // coupon fields validation
      const campaignInformationErrors = validateCampaignInformation(values);
      errors = {
        ...errors,
        ...campaignInformationErrors,
      };
    }

    return errors;
  };

  const getTitle = () => {
    return capitalizeFirstLetter(type) + ' a promo code';
  };

  const handleCancel = () => {
    history.push(paths.marketing.promocodes.list);
  };

  const duplicateEnvCoupon = async (coupon, envSelected) => {
    dispatch({
      type: SAVE_CAMPAIGN_DETAILS,
      payload: {
        envSelected: envSelected,
        campaignData: coupon,
        callback: (data) => {
          let coupon_id = data.id;
          handleEnvPromoSubmit(duplicateCouponData, coupon_id);
        },
        errorCallback: (error) => {
          enqueueSnackbar(error || PROMO_CODE_SAVE_ERROR, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const handleEnvPromoSubmit = (values, coupon_id) => {
    dispatch({
      type: SAVE_PROMO_CODE,
      payload: {
        data: {
          code: values.code,
          coupon_id: coupon_id,
          type: 'coupon',
        },
        envSelected: envSelected,
        callback: () => {
          enqueueSnackbar(PROMO_CODE_DUPLICATED, {
            variant: SEVERITY.SUCCESS,
          });
          setShowConfirm(false);
          cancelDuplicatePromocode();
          localStorage.setItem('env', envSelected);
          dispatch({ type: SET_ENV, payload: { apiEnv: envSelected } });
          history.push(paths.marketing.promocodes.list);
          window.location.reload();
        },
        errorCallback: (error) => {
          enqueueSnackbar(error || PROMO_CODE_SAVE_ERROR, {
            variant: SEVERITY.ERROR,
          });
          setShowConfirm(false);
        },
      },
    });
  };

  const handleSubmit = (values) => {
    const campaignData = campaignPostDataTransform(values);
    const savePromoCode = (couponId, couponData) => {
      dispatch({
        type: SAVE_PROMO_CODE,
        payload: {
          data: {
            code: values.code,
            coupon_id: parseInt(couponId || values.coupon_id),
            type: 'coupon',
            website: values.website === 'ALL' ? '' : values.website,
          },
          couponData,
          callback: () => {
            enqueueSnackbar(PROMO_CODE_SAVED, {
              variant: SEVERITY.SUCCESS,
            });
            dispatch({
              type: RESET_CAMPAIGN_DETAILS,
            });

            // Clear form or/and go to the listing page
            history.push(paths.marketing.promocodes.list);
          },
          errorCallback: (error) => {
            enqueueSnackbar(`${PROMO_CODE_SAVE_ERROR} : ${error}`, {
              variant: SEVERITY.ERROR,
            });
          },
        },
      });
    };

    // Check if there's already a coupon created for this data so we dont create the same coupon again
    const pendingCouponData = sessionStorage.getItem('couponPendingPromocode');
    const pendingCouponObj =
      pendingCouponData &&
      pendingCouponData !== 'undefined' &&
      JSON.parse(pendingCouponData);
    let pendingCouponId;
    if (pendingCouponObj && pendingCouponObj.id) {
      pendingCouponId = pendingCouponObj.id;
    }
    // If there's no need to create a new coupon, we skip the coupon creation call
    if (pendingCouponId) {
      savePromoCode(pendingCouponId);
      sessionStorage.setItem('couponPendingPromocode', null);
    } else if (values.coupon_id) {
      savePromoCode();
    } else {
      dispatch({
        type: SAVE_CAMPAIGN_DETAILS,
        payload: {
          campaignData,
          resetCampaignDetails: false,
          callback: (couponData) => {
            savePromoCode(couponData.coupon_code, couponData);
          },
          errorCallback: (error) => {
            enqueueSnackbar(`${PROMO_CODE_SAVE_ERROR} : ${error}`, {
              variant: SEVERITY.ERROR,
            });
          },
        },
      });
    }
  };

  // Listeners to form value changes
  const getCampaignName = (formData) => {
    if (!formData.visited.campaign_name || !formData.values.campaign_name) {
      return formData.values.code;
    }
    return formData.visited.campaign_name;
  };

  const setCampaignName = (formData) => {
    formData.values.campaign_name = getCampaignName(
      formData,
      null,
      'promocode',
    );
    formData.values.campaign = getCampaignKey(formData, null, 'promocode');
  };

  const formatCodeValue = (formData) => {
    if (formData.values.code) {
      formData.values.code = formData.values.code
        .replace(' ', '_')
        .replace('-', '_')
        .toUpperCase();
    }
  };

  const resetPendingData = (formData) => {
    if (formData.values.showCouponCreation) {
      sessionStorage.setItem('couponPendingPromocode', null);
      formData.values.coupon_id = null;
    }
  };

  const duplicatePromocode = ({ environment, coupon }, data, formData) => {
    setEnvSelected(environment);
    setShowDuplicate(false);
    setShowConfirm(true);
    envConfirmConfig.title = `Please confirm to duplicate the Promo Code`;
    setDuplicateCouponData(data);
    setDuplicateCampaignDetails(formData);
  };

  const cancelDuplicatePromocode = () => {
    setShowDuplicate(false);
    setEnvSelected('');
  };

  const handleConfirmSubmit = () => {
    duplicateEnvCoupon(duplicateCampaignDetails, envSelected);
  };

  const handleConfirmCancel = () => {
    setShowConfirm(false);
  };

  const envConfirmButton = envConfirmConfig.buttonGroup.map((button, i) => {
    switch (button.type) {
      case 'CANCEL':
        button.handler = handleConfirmCancel;
        return button;
      case 'CONFIRM':
        button.handler = handleConfirmSubmit;
        return button;
      default:
        return button;
    }
  });
  envConfirmConfig.buttonGroup = envConfirmButton;

  return (
    <div>
      <SetPageTitle
        pageTitle={canEdit || canReview ? initialValues.code : getTitle()}
      />
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit, values, submitting, invalid }) => (
          <form onSubmit={handleSubmit} noValidate>
            {
              <FormSpy
                onChange={(formData) => {
                  setCampaignName(formData);
                  formatCodeValue(formData);
                  resetPendingData(formData);
                }}
              />
            }
            <PageFormLayout
              title={getTitle()}
              isLoading={false}
              breadcrumbs={[
                {
                  label: 'Promo code listing',
                  link: paths.marketing.promocodes.list,
                },
                {
                  label: getTitle(),
                  isCurrent: true,
                },
              ]}
              buttons={
                canDuplicate && (IS_RELC || IS_BETA || IS_DEVELOP)
                  ? [
                      {
                        label: 'Duplicate',
                        onClick: () => setShowDuplicate(true),
                        disabled: invalid,
                      },
                    ]
                  : null
              }
              showSubmitButtons={canEdit}
              handleCancel={handleCancel}
              showProductionWarning={canEdit}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className="p20">
                    <PromoCodeForm mode={type} values={values} />
                  </Paper>
                </Grid>
                {values.showCouponCreation && (
                  <>
                    <Grid item xs={12}>
                      <Paper className="p20">
                        <InformationSection
                          mode={
                            disableCouponForm
                              ? promoCodeStateMapping.REVIEW
                              : type
                          }
                          source="promocode"
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className="p20">
                        <DiscountSection
                          mode={
                            disableCouponForm
                              ? promoCodeStateMapping.REVIEW
                              : type
                          }
                          source="promocode"
                        />
                      </Paper>
                    </Grid>
                  </>
                )}
              </Grid>
            </PageFormLayout>
            <ModalComponent
              title="Duplicate"
              open={showDuplicate}
              handleClose={cancelDuplicatePromocode}
            >
              <DuplicatePromocode
                cancelDuplicatePromocode={cancelDuplicatePromocode}
                campaignPostDataTransform={campaignPostDataTransform}
                duplicatePromocode={duplicatePromocode}
                formDetails={values}
              />
            </ModalComponent>
            <ModalComponent
              title="Confirm"
              open={showConfirm}
              handleClose={handleConfirmCancel}
            >
              <Confirmation envConfirmConfig={envConfirmConfig} />
            </ModalComponent>
          </form>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  apiEnv: generalSelectors.getAPIEnv(state),
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(CreatePromoCode);
