export const tableDefaultConfig = [
  {
    title: 'Type',
    id: 'type',
  },
  {
    title: 'Country',
    id: 'country',
  },
  {
    title: 'Language',
    id: 'lang',
  },
  {
    title: 'Background Color',
    id: 'background_color',
  },
  {
    title: 'Background Image',
    id: 'background_image',
  },
  {
    title: 'Content Image',
    id: 'content_image',
  },
  {
    title: 'Actions',
    id: 'actions',
    actions: [
      {
        text: 'Edit',
        type: 'Edit',
      },
      {
        text: 'Delete',
        type: 'Delete',
      },
    ],
  },
];

export const tableConfig = [
  {
    title: 'Type',
    id: 'type',
  },
  {
    title: 'Country',
    id: 'country',
  },
  {
    title: 'Language',
    id: 'lang',
  },
  {
    title: 'Content Image',
    id: 'content_image',
  },
  {
    title: 'Actions',
    id: 'actions',
    actions: [
      {
        text: 'Edit',
        type: 'Edit',
      },
      {
        text: 'Delete',
        type: 'Delete',
      },
    ],
  },
];

export const tableToolBarConfig = [
  {
    type: 'Select',
    label: 'Select',
    onSelect: false,
  },
  {
    type: 'DeleteButton',
    label: 'Delete',
    onSelect: true,
  },
];

export const TableCellStates = {
  ACTIONS: 'actions',
  BACKGROUND_COLOR: 'background_color',
  BACKGROUND_IMAGE: 'background_image',
  CONTENT_IMAGE: 'content_image',
  LANGUAGE: 'lang',
  COUNTRY: 'country',
};
