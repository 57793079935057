import { React } from 'react';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';

import './style.scss';

const SelectComponent = ({ dataItems, helperText, ...props }) => {
  return (
    <FormControl variant="standard" fullWidth className="select">
      {props.label && (
        <InputLabel id={`${props.id}-label`} {...props}>
          {props.label}
        </InputLabel>
      )}
      <Select
        variant="standard"
        labelId={`${props.id}-label`}
        id={props.id}
        {...props}
      >
        {dataItems.map((item) => (
          <MenuItem
            key={item?.id || item}
            value={item?.id || item}
            name={item?.value || item}
          >
            {(props.renderItems && props.renderItems(item)) ||
              item?.value ||
              item}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectComponent;
