import { BANNERS_SUCCESS, DELETE_TEXT_BANNER } from './actions';

const initialState = {
  banners: {
    data: null,
    pagination: null,
  },
};

export const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case BANNERS_SUCCESS: {
      const { banners, pagination } = action.payload;

      return {
        ...state,
        banners: {
          ...state.banners,
          data: banners,
          pagination,
        },
      };
    }

    case DELETE_TEXT_BANNER: {
      const { id } = action.payload;

      const formattedBanners = Object.values(state.banners.data).filter(
        (banner) => banner.id !== id,
      );

      return {
        ...state,
        banners: {
          ...state.banners,
          data: formattedBanners,
        },
      };
    }

    default:
      return state;
  }
};
