export const tableConfig = [
  {
    title: 'Status',
    id: 'status',
  },
  {
    title: 'Name',
    id: 'description',
  },
  {
    title: 'Banner ID',
    id: 'id',
  },
  {
    title: 'Start Date',
    id: 'starts_on',
  },
  {
    title: 'End Date',
    id: 'ends_on',
  },
  {
    title: 'Actions',
    id: 'actions',
    sorting: false,
    actions: [
      {
        text: 'Edit',
        permissions: ['EDIT'],
        type: 'Edit',
      },
      {
        text: 'Delete',
        permissions: ['DELETE'],
        type: 'Delete',
      },
      {
        text: 'Review',
        type: 'Review',
      },
    ],
  },
];

export const TableCellStates = {
  ACTIONS: 'actions',
  BANNER_ID: 'id',
  START_DATE: 'starts_on',
  END_DATE: 'ends_on',
  BANNER_NAME: 'description',
  STATUS: 'status',
};
