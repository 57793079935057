import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';

import { getProductManagement } from 'api/products';
import { API } from 'axiosClient/config';
import PageContentLayout from 'components/layout/PageContentLayout';
import SetPageTitle from 'components/common/SetPageTitle';
import normalizeWarehousesResponse from 'utils/normalizers/api/normalizeWarehousesResponse';

import WarehousesTable from './WarehousesTable';
import SearchInput from 'components/common/SearchInput';
import paths from 'routes/paths';

const WarehousesListing = () => {
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [filteredRows, setFilteredRows] = useState(null);
  const history = useHistory();

  const getTitle = () => 'Warehouses';

  const fetchWarehouses = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getProductManagement(API.products.warehouses);
      const normalizedWarehouses = normalizeWarehousesResponse(
        response.data.data,
      );
      setWarehouses(normalizedWarehouses);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }, []);

  useEffect(() => {
    fetchWarehouses();
  }, [fetchWarehouses]);

  const handleNewWarehouse = () =>
    history.push(paths.eCommerceConfiguration.warehouses.create);

  const filterWarehousesByIdOrName = (value) => {
    if (!value?.length) {
      setFilteredRows(null);
    }

    const filteredWarehouses = warehouses.filter(
      (warehouse) =>
        warehouse.id?.toLowerCase()?.includes(value.toLowerCase()) ||
        warehouse.name?.toLowerCase()?.includes(value.toLowerCase()),
    );

    setFilteredRows(filteredWarehouses);
  };

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <PageContentLayout
        title={getTitle()}
        isLoading={loading}
        buttons={[
          {
            label: 'Add a new warehouse',
            onClick: handleNewWarehouse,
          },
        ]}
      >
        <Grid container>
          <Grid item xs={12}>
            <SearchInput
              onSubmit={(val) => filterWarehousesByIdOrName(val)}
              placeholder="Search by warehouse ID or name"
            />
          </Grid>
          <Grid item xs={12}>
            <WarehousesTable
              rows={filteredRows || warehouses || []}
              isLoading={loading}
              disablePagination={true}
              rowCount={warehouses && warehouses.length}
            />
          </Grid>
        </Grid>
      </PageContentLayout>
    </>
  );
};

export default WarehousesListing;
