import clsx from 'clsx';

const AddRow = ({ label, onClick, disabled }) => {
  return (
    <button
      type="button"
      className={clsx('add-row', {
        'add-row--disabled': disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      + {label}
    </button>
  );
};

export default AddRow;
