import { generateID, featureTransformEdit } from 'utils';

const contentSubsectionTransformEdit = (subSectionArr) => {
  subSectionArr.forEach((subSection) => {
    subSection.id = generateID();
    subSection.heading = featureTransformEdit({ ...subSection.heading });
  });
  return subSectionArr;
};

export default contentSubsectionTransformEdit;
