import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';

import { API } from 'axiosClient/config';
import { getProductManagement } from 'api/products';
import normalizeProducts from 'utils/normalizers/api/normalizeProducts';
import generalSelectors from 'store/general/selectors';
import ExpandableSection from 'components/common/ExpandableSection';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';

const ProductDetailsSection = ({ product, inputBase, games, values }) => {
  // Get skuKeys for hardware required
  const [products, setProducts] = useState(null);
  const productKeysData = products?.map((product) => ({
    id: product.id,
    value: product.id,
    name: product.name,
  }));

  // Get Brands
  const brands = useSelector(generalSelectors.getBrandReferences);
  const brandsData =
    brands?.map((brand) => ({
      id: brand.code,
      value: brand.name,
      name: brand.name,
    })) || [];

  // Get Grades
  const grades = useSelector(generalSelectors.getGradeReferences);
  const gradesData =
    grades?.map((grade) => ({
      id: grade.code,
      value: grade.name,
      name: grade.name,
    })) || [];

  // Format Games
  const gamesData = games?.map((game) => ({
    id: game.id,
    value: game.id,
    name: game.name,
  }));

  // Get redeem types
  const redeemTypeRefs = useSelector(generalSelectors.getRedeemTypeReferences);
  const redeemTypesData =
    redeemTypeRefs?.map((type) => ({
      id: type.code,
      value: type.code,
    })) || [];

  // Get selected games for marketed games input
  const includedGames = values.games_included;
  const includedGamesData =
    includedGames?.map((game) => ({
      id: game,
      value: game,
    })) || [];

  const fetchProducts = useCallback(async () => {
    let productsResponse;
    try {
      productsResponse = await getProductManagement(API.products.products.list);
    } catch (e) {
      throw e;
    }
    const normalizedProduct = normalizeProducts(productsResponse.data.data);
    setProducts(normalizedProduct);
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const renderContent = () => (
    <div>
      <Grid container>
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <OrderPropertiesWrapper>
              <OrderPropertyInput
                label="Is digital only"
                type="checkbox"
                input={{
                  ...inputBase,
                  name: 'is_digital',
                }}
              />
              <OrderPropertyInput
                label="Is a bundle"
                type="checkbox"
                input={{
                  ...inputBase,
                  name: 'is_bundle',
                }}
              />
              <OrderPropertyInput
                label="Age range"
                input={{
                  ...inputBase,
                  name: 'age_range',
                }}
              />
              <OrderPropertyInput
                label="Grades"
                type="autocomplete-select"
                input={{
                  ...inputBase,
                  name: 'grades',
                  options: gradesData,
                  multiple: true,
                  limitTags: 3,
                }}
              />
              <OrderPropertyInput
                label="Workbook count"
                input={{
                  ...inputBase,
                  name: 'workbook_count',
                  type: 'number',
                }}
              />
              <OrderPropertyInput
                label="Hardware name"
                input={{
                  ...inputBase,
                  name: 'hardware_name',
                }}
              />
              <OrderPropertyInput
                label="Brand"
                type="select"
                input={{
                  ...inputBase,
                  name: 'brand',
                  required: true,
                  dataItems: brandsData,
                }}
              />
              <OrderPropertyInput
                label="Subscriptions included"
                // TODO: make it an input type that can handle an array
                input={{
                  ...inputBase,
                  name: 'subscriptions_included',
                }}
              />
              <OrderPropertyInput
                label="Games included"
                type="autocomplete-select"
                input={{
                  ...inputBase,
                  name: 'games_included',
                  options: gamesData,
                  multiple: true,
                  limitTags: 5,
                  noSelectAll: true,
                }}
              />
              <OrderPropertyInput
                label="Marketed games"
                type="autocomplete-select"
                input={{
                  ...inputBase,
                  name: 'marketed_games',
                  options: includedGamesData,
                  multiple: true,
                  limitTags: 5,
                  noSelectAll: true,
                }}
              />
              <OrderPropertyInput
                label="Hardware required"
                type="autocomplete-select"
                input={{
                  ...inputBase,
                  name: 'hardware_required',
                  options: productKeysData,
                  multiple: true,
                  limitTags: 5,
                  noSelectAll: true,
                }}
              />
              <OrderPropertyInput
                label="Redeem flow type"
                type="select"
                input={{
                  ...inputBase,
                  name: 'redeem_type',
                  dataItems: redeemTypesData,
                }}
              />
            </OrderPropertiesWrapper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} className="mt-10">
        <Grid item xs={6}>
          <Typography variant="h3" gutterBottom>
            Pricing
          </Typography>
          <OrderPropertiesWrapper>
            <OrderPropertyInput
              label="Tax code (for the US)"
              input={{
                ...inputBase,
                name: 'tax_code',
              }}
            />
          </OrderPropertiesWrapper>
        </Grid>
      </Grid>
      <Grid item container xs={12} className="mt-10">
        <Grid item xs={6}>
          <Typography variant="h3" gutterBottom>
            Analytics
          </Typography>
          <OrderPropertiesWrapper>
            <OrderPropertyInput
              label="Product category"
              input={{
                ...inputBase,
                name: 'analytics_category',
              }}
            />
          </OrderPropertiesWrapper>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <ExpandableSection
      id={`product-${product?.id || 'creation'}`}
      heading={'Product details'}
      content={renderContent()}
      openByDefault={true}
    />
  );
};

export default ProductDetailsSection;
