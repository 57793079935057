import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import { compose } from 'recompose';

import { USERS_WRITE } from 'config/roles';

import { FETCH_USERS_WITH_ROLES } from 'store/policies/actions';
import policiesSelectors from 'store/policies/selectors';
import userSelectors from 'store/user/selectors';

import withRouterInfo from 'components/hoc/withRouterInfo';

import ModalComponent from 'components/common/ModalComponent';

import { useErrorStatus } from 'components/hoc/ErrorHandler';
import PageContentLayout from 'components/layout/PageContentLayout';
import CreateUserRole from './components/CreateUserRole';
import UsersTable from './components/UsersTable';
import SearchInput from '../../../common/SearchInput';

import './index.scss';

function UsersListing({ users = [], dispatch, userRoles }) {
  const [showCreateUser, setShowCreateUser] = useState(false);

  const { setErrorStatusCode } = useErrorStatus();

  const [filteredUsers, setFilteredUsers] = useState(users);
  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const getUsersWithRoles = useCallback(() => {
    dispatch({
      type: FETCH_USERS_WITH_ROLES,
      payload: {
        errorCallback: (error) => {
          setErrorStatusCode(error);
        },
      },
    });
  }, [dispatch, setErrorStatusCode]);

  useEffect(() => {
    getUsersWithRoles();
  }, [getUsersWithRoles]);

  const handleCreate = () => {
    setShowCreateUser(true);
  };

  const requestSearch = (val) => {
    const value = val.toLowerCase();
    if (val.length) {
      setFilteredUsers(
        users.filter((user) => {
          return (
            user.first_name.toLowerCase().includes(value) ||
            user.last_name.toLowerCase().includes(value)
          );
        }),
      );
    } else if (val === '') {
      setFilteredUsers(users);
    }
  };
  const canEdit = userRoles && userRoles.includes(USERS_WRITE);
  return (
    <PageContentLayout
      title="Users"
      buttons={
        canEdit
          ? [
              {
                label: 'Create User',
                onClick: handleCreate,
              },
            ]
          : null
      }
      showProductionWarning={canEdit}
    >
      <div className="user-listing-search">
        <SearchInput
          onSubmit={(val) => requestSearch(val)}
          placeholder="Search by first name or last name"
        />
      </div>
      <UsersTable rows={filteredUsers} />
      <ModalComponent
        title="Add roles to a new user"
        open={showCreateUser}
        handleClose={() => setShowCreateUser(false)}
      >
        <CreateUserRole handleCancel={() => setShowCreateUser(false)} />
      </ModalComponent>
    </PageContentLayout>
  );
}

const mapStateToProps = (state) => ({
  users: policiesSelectors.getUsersWithRolesArray(state),
  userRoles: userSelectors.getUserRoles(state),
});

export default compose(withRouterInfo)(connect(mapStateToProps)(UsersListing));
