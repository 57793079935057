import React, { useState } from 'react';
import { Button } from '@mui/material';
import BadgesList from './BadgesList';
import AddBadgeImage from './AddBadgeImage';
import EditBadgeImage from './EditBadgeImage';
import './styles.scss';

const Badges = ({
  input: { name, required, onChange, value, ...restInput },
  meta,
  disabled,
  deleteFiles,
  ...rest
}) => {
  const [showAddBadgeForm, setShowAddBadgForm] = useState(false);
  const [showEditBadgeForm, setShowEditBadgeForm] = useState(false);
  const [editBadgeDetails, setEditBadgeDetails] = useState({});
  const badges = value.length ? [...value] : [];

  const handleAddBadge = (badge) => {
    if (badge.key) {
      onChange([...badges, badge]);
    }
  };
  const updateBadge = (badge, key) => {
    const badgesList = [...badges];
    if (!badge.key) {
      onChange(badgesList.filter((badge) => badge.key !== key));
    } else {
      let newBadgesList = badgesList.map((item) => {
        if (item.key === key) {
          return badge;
        } else {
          return item;
        }
      });
      onChange(newBadgesList);
    }
  };

  const updateBadgeList = (badges) => {
    onChange(badges);
  };
  const showAddBadge = () => {
    setShowAddBadgForm(true);
  };

  const showEditBadge = (badgeDetails) => {
    setEditBadgeDetails(badgeDetails);
    setShowEditBadgeForm(true);
  };
  const closeAddBadge = () => {
    setShowAddBadgForm(false);
  };
  const closeEditBadge = () => {
    setShowEditBadgeForm(false);
  };
  const isDefault = badges.length === 0;
  return (
    <>
      {showAddBadgeForm && (
        <AddBadgeImage
          close={closeAddBadge}
          addBadge={handleAddBadge}
          show={showAddBadgeForm}
          isDefault={isDefault}
        />
      )}
      {showEditBadgeForm && (
        <EditBadgeImage
          close={closeEditBadge}
          updateBadge={updateBadge}
          show={showEditBadgeForm}
          badgeDetail={editBadgeDetails}
        />
      )}

      <BadgesList
        badges={badges}
        showEditBadge={showEditBadge}
        updateBadgeList={updateBadgeList}
      />

      {!disabled && (
        <div className="discount-add">
          <Button className="button" variant="contained" onClick={showAddBadge}>
            Add Badge
          </Button>
        </div>
      )}
    </>
  );
};

export default Badges;
