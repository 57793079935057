import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { navigationConfig, routesConfig } from 'routes';

import { ENVIRONMENTS, getCurrentEnvironment } from 'config/environments';

import authSelectors from 'store/auth/selectors';
import userSelectors from 'store/user/selectors';
import { SET_ENV } from 'store/general/actions';

import paths from 'routes/paths';

import AdminLayout from 'components/common/AdminLayout';
import RoutesActions from 'components/RoutesActions';

const AppContainer = (props) => {
  const { isLoggedIn, userRoles, dispatch } = props;
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = qs.parse(location.search);
    const envFromUrl = queryParams.env;
    let prevPath = '';

    // Update the env based on the `env` url param
    if (envFromUrl && ENVIRONMENTS.includes(envFromUrl)) {
      localStorage.setItem('env', envFromUrl);
      dispatch({ type: SET_ENV, payload: { apiEnv: envFromUrl } });
    }

    // Set the `env` URL param to all new location.
    // We're doing this here programmatically so that we don't have to pass it to all Link instances.
    history.listen((loc) => {
      if (!loc.pathname.includes(paths.login) && loc.pathname !== prevPath) {
        const locQueryParams = qs.parse(loc.search);
        const env = getCurrentEnvironment();
        const newQueries = { ...locQueryParams, env };
        prevPath = loc.pathname;
        history.replace({ search: qs.stringify(newQueries) });
      }
      prevPath = loc.pathname;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminLayout navigation={navigationConfig} routes={routesConfig}>
      <RoutesActions isLoggedIn={isLoggedIn} userRoles={userRoles} />
    </AdminLayout>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: authSelectors.getIsLoggedIn(state),
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(AppContainer);
