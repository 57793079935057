import { Grid } from '@mui/material';
import ExpandableSection from 'components/common/ExpandableSection';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';
import { useSelector } from 'react-redux';
import generalSelectors from 'store/general/selectors';

const DetailsSection = ({ variant, inputBase, values: currentFormValues }) => {
  const skuDetails = useSelector(generalSelectors.getSkuDetails);
  const subscriptionTypeRefs = useSelector(
    generalSelectors.getSubscriptionTypeReferences,
  );
  const subscriptionFrequencyRefs = useSelector(
    generalSelectors.getSubscriptionFrequencyReferences,
  );
  const storageRefs = useSelector(generalSelectors.getStorageReferences);
  const bases = useSelector(generalSelectors.getBaseReferences);
  const subscriptionTypesData =
    subscriptionTypeRefs?.map((type) => ({
      id: type.code,
      value: type.code,
    })) || [];
  const subscriptionFrequenciesData =
    subscriptionFrequencyRefs?.map((ref) => ({
      id: ref.code,
      value: ref.code,
    })) || [];
  const storageData =
    storageRefs?.map((storage) => ({
      id: storage.code,
      value: storage.code,
    })) || [];
  const baseData =
    bases?.map((base) => ({
      id: base.code,
      value: base.name,
      name: base.name,
    })) || [];
  const skuTypeData = [
    {
      id: 'game',
      value: 'game',
    },
    {
      id: 'kit',
      value: 'kit',
    },
    {
      id: 'bundle',
      value: 'bundle',
    },
    {
      id: 'accessory',
      value: 'accessory',
    },
  ];

  const renderContent = () => (
    <Grid container>
      <Grid item xs={7}>
        <OrderPropertiesWrapper>
          <OrderPropertyInput
            label="Is ready"
            type="checkbox"
            input={{
              ...inputBase,
              name: 'is_ready',
            }}
          />
          <OrderPropertyInput
            label="Invisible"
            type="checkbox"
            input={{
              ...inputBase,
              name: 'is_invisible',
            }}
          />
          <OrderPropertyInput
            label="Deprecated"
            type="checkbox"
            input={{
              ...inputBase,
              name: 'is_deprecated',
            }}
          />
          <OrderPropertyInput
            label="Type"
            type="select"
            input={{
              ...inputBase,
              name: 'type',
              required: true,
              dataItems: skuTypeData,
            }}
          />
          <OrderPropertyInput
            label="Is subscription"
            type="checkbox"
            input={{
              ...inputBase,
              name: 'is_subscription',
            }}
          />
          {currentFormValues.is_subscription && (
            <>
              <OrderPropertyInput
                label="Subscription type"
                type="select"
                input={{
                  ...inputBase,
                  name: 'subscription_type',
                  dataItems: subscriptionTypesData,
                }}
              />
              {currentFormValues.subscription_type === 'recurring' && (
                <OrderPropertyInput
                  label="Subscription frequency"
                  type="select"
                  input={{
                    ...inputBase,
                    name: 'subscription_frequency',
                    dataItems: subscriptionFrequenciesData,
                  }}
                />
              )}
            </>
          )}
          <OrderPropertyInput
            label="Virtual SKU components"
            type="autocomplete-select"
            input={{
              ...inputBase,
              name: 'physical_sku_keys',
              options: skuDetails, //TODO: Use SKU data from get skus endpoint
              multiple: true,
              limitTags: 5,
              getOptionLabel: (option) => {
                return `${option.name}: ${option.id}`;
              },
              renderDropdownOption: (option) => option.id,
            }}
          />
          <OrderPropertyInput
            label="Storage included"
            type="autocomplete-select"
            input={{
              ...inputBase,
              name: 'storages_included',
              options: storageData,
              multiple: true,
              limitTags: 5,
            }}
          />
          <OrderPropertyInput
            label="Bases included"
            type="autocomplete-select"
            input={{
              ...inputBase,
              name: 'bases_included',
              options: baseData,
              multiple: true,
              limitTags: 3,
            }}
          />
          <OrderPropertyInput
            label="Is proflector included"
            type="checkbox"
            input={{
              ...inputBase,
              name: 'is_proflector_included',
            }}
          />
        </OrderPropertiesWrapper>
      </Grid>
    </Grid>
  );

  return (
    <ExpandableSection
      id={`details-${variant?.id || 'create'}`}
      heading={'Variant Details'}
      secondaryHeading="Bases included, type, settings..."
      content={renderContent()}
      openByDefault={true}
    />
  );
};

export default DetailsSection;
