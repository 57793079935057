import { Form, Field } from 'react-final-form';
import { Button, Grid } from '@mui/material';
import TextInput from 'components/common/TextInput';

const IPAddressForm = ({ onSubmit, onCancel }) => {
  const initialValues = {
    ip_address: '',
    project: '*',
    reason: '',
  };

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Field
                fullWidth
                required
                name="ip_address"
                component={TextInput}
                type="text"
                label="IP address"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                required
                name="project"
                component={TextInput}
                type="text"
                label="Project"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                required
                name="reason"
                component={TextInput}
                type="text"
                label="Reason"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className="mr-20"
                size="small"
                variant="outlined"
                type="button"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                className="button"
                size="small"
                variant="contained"
                type="submit"
              >
                Whitelist IP Address
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default IPAddressForm;
