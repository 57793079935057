import { takeLatest, put, call, all } from 'redux-saga/effects';
import {
  FETCH_USERS_WITH_ROLES,
  USERS_DETAILS_SUCCESS,
  FETCH_ROLES,
  ROLES_SUCCESS,
  FETCH_USER_ROLES,
  USER_ROLES_SUCCESS,
  ASSIGN_USER_NEW_ROLE,
  UNASSIGN_USER_ROLE,
  UPDATE_USER_ROLES,
} from './actions';
import { handleException } from 'store/general/sagas';

import { API } from 'axiosClient/config';
import { SET_LOADER } from 'store/general/actions';

import {
  getAuthorizationData,
  postAuthorizationData,
  deleteAuthorizationData,
} from 'api/authorization';

function* getUsersWithRole(action) {
  const { callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const usersData = yield call(getAuthorizationData, API.getUsersWithRole);

    if (!usersData || usersData.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    const usersObj = usersData.data.reduce((acc, permission) => {
      const users = permission.users.reduce((acc, user) => {
        return {
          ...acc,
          [user.id]: user,
        };
      }, {});
      return {
        ...acc,
        ...users,
      };
    }, {});

    yield put({
      type: USERS_DETAILS_SUCCESS,
      payload: {
        users: usersObj,
      },
    });
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* getRoles(action) {
  const { callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const rolesData = yield call(getAuthorizationData, API.getUserRoles);

    if (!rolesData || rolesData.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    const roles = rolesData.data.map((role) => ({
      ...role,
      id: role.name,
    }));

    yield put({
      type: ROLES_SUCCESS,
      payload: {
        roles,
      },
    });
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* getUserRoles(action) {
  const { callback, errorCallback, id } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const userData = yield call(
      getAuthorizationData,
      `${API.assignRoleToUser}?account_id=${id}`,
    );

    if (!userData || userData.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    const roles = userData.data.roles.map((role) => ({
      name: role,
      id: role,
    }));

    yield put({
      type: USER_ROLES_SUCCESS,
      payload: {
        id,
        roles,
      },
    });
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* updateUserRoles(action) {
  const { callback, errorCallback, email, userId, newRoles, userRoles } =
    action.payload;
  const newRolesArray = newRoles.map((r) => r.id || r);
  const rolesToUnassign = userRoles.filter(
    (role) => !newRolesArray.find((r) => r === role),
  );
  const rolesToAssign = newRolesArray.filter(
    (role) => !userRoles.find((r) => r === role),
  );

  const actions = [];
  if (rolesToAssign && rolesToAssign.length) {
    actions.push(
      ...rolesToAssign.map((role) => {
        return call(assignNewRoleToUser, {
          payload: {
            userId,
            role,
            email,
            errorCallback,
          },
        });
      }),
    );
  }

  if (rolesToUnassign && rolesToUnassign.length) {
    actions.push(
      call(unassignRoleFromUser, {
        payload: {
          userId,
          roles: rolesToUnassign,
          errorCallback,
        },
      }),
    );
  }

  if (actions && actions.length) {
    yield all(actions);
  }

  if (callback) {
    callback();
  }
}

function* assignNewRoleToUser(action) {
  const { callback, errorCallback, userId, role, email } = action.payload;

  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });

  const data = {
    data: {
      account_id: userId,
      account_email: email,
      role,
    },
  };

  try {
    const userData = yield call(
      postAuthorizationData,
      API.assignRoleToUser,
      data,
    );

    if (!userData || userData.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });

    if (callback) {
      callback();
    }
  } catch (e) {
    errorCallback(e?.response?.data?.error?.message);

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
  }
}

function* unassignRoleFromUser(action) {
  const { callback, errorCallback, userId, roles } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });

  try {
    const rolesParam = roles.reduce(
      (acc, el, i) => `${acc}${i > 0 ? ',' : ''}${el}`,
      '',
    );
    const userData = yield call(
      deleteAuthorizationData,
      `${API.assignRoleToUser}?account_id=${userId}&roles=${rolesParam}`,
    );

    if (!userData || userData.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });

    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

export function* policiesSaga() {
  yield takeLatest(FETCH_USERS_WITH_ROLES, getUsersWithRole);
  yield takeLatest(FETCH_ROLES, getRoles);
  yield takeLatest(FETCH_USER_ROLES, getUserRoles);
  yield takeLatest(ASSIGN_USER_NEW_ROLE, assignNewRoleToUser);
  yield takeLatest(UNASSIGN_USER_ROLE, unassignRoleFromUser);
  yield takeLatest(UPDATE_USER_ROLES, updateUserRoles);
}
