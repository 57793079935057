import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import authSelectors from 'store/auth/selectors';
import { LOGOUT } from 'store/auth/actions';

const Logout = ({ isLoggedIn, dispatch }) => {
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch({
        type: LOGOUT,
      });
    } else {
      history.push('/');
    }
  }, [isLoggedIn, dispatch, history]);

  return <>...Logging out</>;
};

const mapStateToProps = (state) => ({
  isLoggedIn: authSelectors.getIsLoggedIn(state),
});

export default connect(mapStateToProps)(Logout);
