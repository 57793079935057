export const TableCellStates = {
  EMAIL: 'email',
  SUBSCRIBED_LIST: 'list_name',
  CONSENT_GIVEN: 'consent_given',
  SUBSCRIBED_ON: 'subscribed_on',
  UNSUBSCRIBED_ON: 'unsubscribed_on',
  ACTIONS: 'actions',
};

export const tableConfig = [
  {
    title: 'Email address',
    id: TableCellStates.EMAIL,
  },
  {
    title: 'Subscribed lists',
    id: TableCellStates.SUBSCRIBED_LIST,
  },
  {
    title: 'Consent given',
    id: TableCellStates.CONSENT_GIVEN,
  },
  {
    title: 'Subscribed on',
    id: TableCellStates.SUBSCRIBED_ON,
  },
  {
    title: 'Unsubscribed on',
    id: TableCellStates.UNSUBSCRIBED_ON,
  },
  {
    title: '',
    id: TableCellStates.ACTIONS,
  },
];
