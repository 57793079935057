const currencies = [
  'AED',
  'AUD',
  'BHD',
  'CAD',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'ILS',
  'JPY',
  'KWD',
  'NOK',
  'KRW',
  'NZD',
  'OMR',
  'SAR',
  'SEK',
  'SGD',
  'USD',
];

export const currenciesForInput = currencies.map((currency) => ({
  key: currency,
  value: currency,
}));

export default currencies;
