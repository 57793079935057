import usStateData from './usstates.json';
import caStateData from './castates.json';
import auStateData from './austates.json';

const stateData = [];
stateData['US'] = usStateData;
stateData['CA'] = caStateData;
stateData['AU'] = auStateData;

export default stateData;
