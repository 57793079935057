import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';

import { Grid, Button } from '@mui/material';

import ContentFeature from '../ContentFeature';

export default function AddSubsection(props) {
  const { handleCancel, handleSave, sectionId, editRecord } = props;
  const [formData, setformData] = useState({});

  const handleFormSubmit = (values) => {
    handleSave(values);
  };

  useEffect(() => {
    if (editRecord.skus) {
      const editForm = {};
      editForm.sku = editRecord.skus;
      setformData(editForm);
    }
  }, [editRecord.skus]);

  return (
    <div>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={formData}
        mutators={{
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        render={({ form, handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className="add-sku-form" noValidate>
            <Grid container>
              <Grid item xs={12}>
                <ContentFeature
                  sectionId={sectionId}
                  values={values}
                  setValue={form.mutators.setValue}
                />
              </Grid>
              <Grid item xs={12} className="button-container">
                <Button
                  className="mr-20"
                  size="small"
                  variant="outlined"
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="button"
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </div>
  );
}
