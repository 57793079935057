const getTabsInitialValues = (translations) =>
  translations &&
  Object.entries(translations).reduce((acc, [key, value]) => {
    if (key.includes('tab')) {
      return [
        ...acc,
        {
          title: value.title?.value,
          content: value.content?.value,
        },
      ];
    }
    return acc;
  }, []);

const normalizeTranslations = (translations) => {
  return translations?.reduce(
    (acc, translationData) => ({
      ...acc,
      [translationData.language]: {
        ...(acc[translationData.language] || {}),
        [translationData.website]: {
          ...((acc[translationData.language] || {})[translationData.website] ||
            {}),
          tabs: getTabsInitialValues(translationData.translations),
          top_blurb: translationData.translations?.top_blurb?.content?.value,
          one_liner: translationData.translations?.one_liner?.content?.value,
        },
      },
    }),
    {},
  );
};

export default normalizeTranslations;
