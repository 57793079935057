import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';

import {
  getCountryDataItems,
  getCreatorDataItems,
  getTypeDataItems,
  getWebsiteDataItem,
} from './filters';
import SelectComponent from 'components/common/SelectDropdown/SelectComponent';
import SearchInput from '../../../../common/SearchInput';
import { DATE_FORMAT_CALENDAR_CAMPAIGN } from 'config';
import 'react-datepicker/dist/react-datepicker.css';
import 'components/common/DateRangePicker/style.scss';
import useWindowSize from '../useWindowSize';
import { MOBILE_BREAK_POINT } from 'config/breakpoint';
import Checkbox from '@mui/material/Checkbox';
import './index.scss';

const CampaignFilters = (props) => {
  const { rows, filteredRows, setFilteredRows, website, setWebsite } = props;

  const selectedCampaignCreator = useRef('ALL');
  const selectedCountry = useRef('ALL');
  const selectedType = useRef('ALL');
  const searchedArray = useRef([]);
  const startDate = useRef('');
  const endDate = useRef('');
  const fullWidth = true;
  const window = useWindowSize();
  const [viewMoreFilter, setViewMoreFilter] = useState(false);

  const campaignCreatorDataItems = getCreatorDataItems(rows);
  const countryDataItems = getCountryDataItems(rows);
  const typeDataItems = getTypeDataItems();
  const websiteDataItems = getWebsiteDataItem();

  const handleCreatorChange = (e) => {
    selectedCampaignCreator.current = e.target.value;
    filterRowValues();
  };

  const handleCountryChange = (e) => {
    selectedCountry.current = e.target.value;
    filterRowValues();
  };

  const handleTypeChange = (e) => {
    selectedType.current = e.target.value;
    filterRowValues();
  };

  const requestSearch = (searchedVal) => {
    const filterRows =
      filteredRows.length === rows.length
        ? filteredRows.filter((row) => {
            return (
              row.campaign_name
                .toLowerCase()
                .includes(searchedVal.toLowerCase()) ||
              row.coupon_code.includes(searchedVal)
            );
          })
        : rows.filter((row) => {
            return (
              row.campaign_name
                .toLowerCase()
                .includes(searchedVal.toLowerCase()) ||
              row.coupon_code.includes(searchedVal)
            );
          });
    if (searchedVal.length === 0) {
      setFilteredRows(filteredRows);
      searchedArray.current = [];
      filterRowValues();
    } else {
      searchedArray.current = filterRows;
      setFilteredRows(filterRows);
    }
  };

  const addHoursAndMinutes = (dateTime, hours, minutes) => {
    dateTime.setHours(dateTime.getHours() + hours);
    dateTime.setMinutes(dateTime.getMinutes() + minutes);
    return dateTime;
  };

  const handleStartDateChange = (e) => {
    startDate.current = e;
    filterRowValues();
  };

  const handleEndDateChange = (e) => {
    const date = new Date(e);
    endDate.current = addHoursAndMinutes(date, 23, 59);
    filterRowValues();
  };

  const handleWebsiteChange = (e) => {
    setWebsite(e.target.value);
  };

  const resetDate = () => {
    startDate.current = '';
    endDate.current = '';
    filterRowValues();
  };

  function getFilteredRowValuesUsingSearchedArray(row, filterObject) {
    return (
      (filterObject.country === 'ALL'
        ? true
        : row.countries.includes(filterObject.country)) &&
      (filterObject.creator === 'ALL'
        ? true
        : row.created_by.includes(filterObject.creator)) &&
      (filterObject.type === 'ALL'
        ? true
        : filterObject.type.includes(String(row.is_default))) &&
      (filterObject.startDateValue !== '' && filterObject.endDateValue !== ''
        ? filterObject.startDateValue <= row.starts_on &&
          filterObject.endDateValue >= row.expires_on
        : true)
    );
  }

  const filterRowValues = () => {
    const filterObject = {
      country: selectedCountry.current,
      creator: selectedCampaignCreator.current,
      type: selectedType.current,
      startDateValue: startDate.current ? startDate.current.toISOString() : '',
      endDateValue: endDate.current ? endDate.current.toISOString() : '',
    };
    const filterRows = searchedArray.current.length
      ? searchedArray.current.filter((row) => {
          return getFilteredRowValuesUsingSearchedArray(row, filterObject);
        })
      : rows.filter((row) => {
          return getFilteredRowValuesUsingSearchedArray(row, filterObject);
        });
    setFilteredRows(filterRows);
  };

  useEffect(() => {
    filterRowValues();
  }, [filteredRows, rows]);

  const toggleFilterView = (e) => {
    setViewMoreFilter(e.target.checked);
  };
  return (
    <Paper className="campaign-filters-wrapper">
      <div style={{ marginBottom: '2em' }}>
        {window.width >= MOBILE_BREAK_POINT ? (
          <div>
            <div className="campaign-filters">
              <div className="campaign-filters-search-input">
                <SearchInput
                  onSubmit={(searchVal) => requestSearch(searchVal)}
                  placeholder="Search by Campaign name or Coupon ID"
                />
              </div>
              <div className="campaign-filters-dropdown">
                <SelectComponent
                  name="creator"
                  onChange={handleCreatorChange}
                  label="Select Creator"
                  className="discount-country"
                  dataItems={campaignCreatorDataItems}
                  value={selectedCampaignCreator.current}
                />
                <SelectComponent
                  name="country"
                  onChange={handleCountryChange}
                  label="Select Country"
                  className="discount-country"
                  dataItems={countryDataItems}
                  value={selectedCountry.current}
                />
                <SelectComponent
                  name="type"
                  onChange={handleTypeChange}
                  label="Select Type"
                  className="discount-country"
                  dataItems={typeDataItems}
                  value={selectedType.current}
                />
              </div>
            </div>
            <div
              className={`date-range-picker-wrapper ${
                fullWidth ? 'is-full-width' : ''
              }`}
            >
              <div className="container date-range-picker">
                <div className="start-date">
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    className="mr-20 subHeader"
                  >
                    Start Date
                  </Typography>
                  <DatePicker
                    selected={startDate.current}
                    onChange={handleStartDateChange}
                    wrapperClassName="date-picker"
                    dateFormat={DATE_FORMAT_CALENDAR_CAMPAIGN}
                  />
                </div>
                <div className="end-date">
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    className="mr-20 subHeader"
                  >
                    End Date
                  </Typography>
                  <DatePicker
                    onChange={handleEndDateChange}
                    selected={endDate.current}
                    minDate={startDate.current}
                    dateFormat={DATE_FORMAT_CALENDAR_CAMPAIGN}
                    wrapperClassName="date-picker"
                  />
                </div>
                <div className="website-select">
                  <SelectComponent
                    name="website"
                    onChange={handleWebsiteChange}
                    label="Select Website"
                    className="discount-country"
                    dataItems={websiteDataItems}
                    value={website}
                  />
                </div>
                {startDate.current || endDate.current ? (
                  <div className="reset-date">
                    <IconButton onClick={resetDate} size="large">
                      <Close />
                    </IconButton>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="campaign-filters-search-input">
              <SearchInput
                onSubmit={(searchVal) => requestSearch(searchVal)}
                placeholder="Search by Campaign name or Coupon ID"
              />
            </div>
            <div className="campaign-filters-dropdown-mobile">
              <SelectComponent
                name="creator"
                onChange={handleCreatorChange}
                label="Select Creator"
                className="discount-country"
                dataItems={campaignCreatorDataItems}
                value={selectedCampaignCreator.current}
              />
              <SelectComponent
                name="country"
                onChange={handleCountryChange}
                label="Select Country"
                className="discount-country"
                dataItems={countryDataItems}
                value={selectedCountry.current}
              />
            </div>
            <div className="campaign-filters-dropdown-mobile">
              <SelectComponent
                name="type"
                onChange={handleTypeChange}
                label="Select Type"
                className="discount-country"
                dataItems={typeDataItems}
                value={selectedType.current}
              />
              <SelectComponent
                name="website"
                onChange={handleWebsiteChange}
                label="Select Website"
                className="discount-country"
                dataItems={websiteDataItems}
                value={website}
              />
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={viewMoreFilter}
                      onChange={toggleFilterView}
                    />
                  }
                  label="View More Filters"
                />
              </FormGroup>
            </div>
            {viewMoreFilter ? (
              <div
                className={`date-range-picker-wrapper ${
                  fullWidth ? 'is-full-width' : ''
                }`}
              >
                <div className="container date-range-picker">
                  <div className="start-date">
                    <Typography
                      color="inherit"
                      variant="subtitle1"
                      component="div"
                      className="mr-20 subHeader"
                    >
                      Start Date
                    </Typography>
                    <DatePicker
                      selected={startDate.current}
                      onChange={handleStartDateChange}
                      wrapperClassName="date-picker"
                      dateFormat={DATE_FORMAT_CALENDAR_CAMPAIGN}
                    />
                  </div>
                  <div className="end-date">
                    <Typography
                      color="inherit"
                      variant="subtitle1"
                      component="div"
                      className="mr-20 subHeader"
                    >
                      End Date
                    </Typography>
                    <DatePicker
                      onChange={handleEndDateChange}
                      selected={endDate.current}
                      minDate={startDate.current}
                      dateFormat={DATE_FORMAT_CALENDAR_CAMPAIGN}
                      wrapperClassName="date-picker"
                    />
                  </div>
                  {startDate.current || endDate.current ? (
                    <div className="reset-date">
                      <IconButton onClick={resetDate} size="large">
                        <Close />
                      </IconButton>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </Paper>
  );
};
export default CampaignFilters;
