export const tableConfig = [
  {
    title: 'Status',
    id: 'status_indicator',
  },
  {
    title: 'Coupon ID',
    id: 'id',
  },
  {
    title: 'Campaign',
    id: 'campaign',
  },
  {
    title: 'Email',
    id: 'email',
  },
  {
    title: 'Countries',
    id: 'countries',
  },
  {
    title: 'Expiration Date',
    id: 'expires_on',
  },
  {
    title: 'Website',
    id: 'websites',
  },
  {
    title: 'Actions',
    id: 'actions',
    sorting: false,
    actions: [
      {
        text: 'Review',
        type: 'Review',
      },
    ],
  },
];

export const TableCellStates = {
  STATUS_INDICATOR: 'status_indicator',
  ACTIONS: 'actions',
  ID: 'id',
  CAMPAIGN: 'campaign',
  EXPIRATION_DATE: 'expires_on',
  EMAIL: 'created_by',
  COUNTRIES: 'countries',
  WEBSITE: 'websites',
};
