import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useSnackbar } from 'notistack';
import { Button, Grid, Paper, Typography } from '@mui/material';

import { SEVERITY } from 'config';
import { ROLES_UPDATED, ROLES_SAVE_ERROR } from 'config/messages';
import { campaignStateMapping } from 'components/containers/CampaignContainer/CreateCampaign/config';
import paths from 'routes/paths';

import { useErrorStatus } from 'components/hoc/ErrorHandler';
import SetPageTitle from 'components/common/SetPageTitle';
import ModalComponent from 'components/common/ModalComponent';
import Confirmation from 'components/common/Confirmation';
import PageFormLayout from 'components/layout/PageFormLayout';
import { envConfirmConfig } from 'components/containers/CampaignContainer/CreateCampaign/config';

import {
  FETCH_USER_ROLES,
  UNASSIGN_USER_ROLE,
  FETCH_USERS_WITH_ROLES,
  UPDATE_USER_ROLES,
} from 'store/policies/actions';
import policiesSelectors from 'store/policies/selectors';

import UpdateUserForm from './components/UpdateUserForm';

const UpdateUser = ({ match, type, dispatch, users, roles }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    roles: [],
  });
  const [showDeleteAllRolesConfirmation, setShowDeleteAllRolesConfirmation] =
    useState(false);

  const { setErrorStatusCode } = useErrorStatus();

  const userId = match.params.userId;
  const user = users[userId];

  useEffect(() => {
    if (user) {
      setInitialValues({
        roles: user.roles,
      });
    }
  }, [user]);

  useEffect(() => {
    setLoading(true);
    dispatch({
      type: FETCH_USERS_WITH_ROLES,
      payload: {
        callback: () => {
          dispatch({
            type: FETCH_USER_ROLES,
            payload: {
              id: userId,
              callback: () => {
                setLoading(false);
              },
              errorCallback: (error) => {
                setErrorStatusCode(error);
              },
            },
          });
        },
        errorCallback: (error) => {
          setErrorStatusCode(error);
        },
      },
    });
  }, [dispatch, setErrorStatusCode, userId]);

  const canEdit = type === campaignStateMapping.EDIT;
  const canReview = type === campaignStateMapping.REVIEW;

  const history = useHistory();

  const submitRoles = (values) => {
    setLoading(true);
    dispatch({
      type: UPDATE_USER_ROLES,
      payload: {
        userId,
        newRoles: values.roles,
        userRoles: user.roles.map((role) => role.id),
        callback: () => {
          setLoading(false);
          history.push(paths.tools.permissions.list);
          enqueueSnackbar(ROLES_UPDATED, {
            variant: SEVERITY.SUCCESS,
          });
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${ROLES_SAVE_ERROR} : ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const deleteAllRoles = () => {
    dispatch({
      type: UNASSIGN_USER_ROLE,
      payload: {
        userId,
        roles: Object.values(user.roles).map((role) => role.id),
        callback: () => {
          setShowDeleteAllRolesConfirmation(false);
          history.push(paths.tools.permissions.list);
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${ROLES_SAVE_ERROR} : ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const deleteAllRolesConfirmation = () => {
    setShowDeleteAllRolesConfirmation(true);
  };

  const getTitle = () => {
    return type.charAt(0).toUpperCase() + type.slice(1);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.roles || !values.roles.length) {
      errors.roles = 'Required';
    }
    return errors;
  };

  const handleCancel = () => {
    history.push(paths.tools.permissions.list);
  };

  const confirmConfig = envConfirmConfig;
  const confirmButton = envConfirmConfig.buttonGroup.map((button, i) => {
    switch (button.type) {
      case 'CANCEL':
        button.handler = () => setShowDeleteAllRolesConfirmation(false);
        return button;
      case 'CONFIRM':
        button.handler = deleteAllRoles;
        return button;
      default:
        return button;
    }
  });
  confirmConfig.buttonGroup = confirmButton;

  return (
    <div>
      {(canEdit || canReview) && (
        <SetPageTitle
          pageTitle={`${getTitle()} user: ${
            user ? `${user.first_name} ${user.last_name}` : ''
          }`}
        />
      )}
      <Form
        onSubmit={submitRoles}
        validate={validate}
        initialValues={initialValues}
        mutators={{
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <PageFormLayout
              title={`${getTitle()} User`}
              isLoading={loading}
              breadcrumbs={[
                {
                  label: 'Users listing',
                  link: paths.tools.permissions.list,
                },
                {
                  label: `${type} user`,
                  isCurrent: true,
                },
              ]}
              showSubmitButtons={canEdit}
              handleCancel={handleCancel}
              showProductionWarning={canEdit}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className="p20">
                    <Grid container spacing={3}>
                      <Grid item xs={10}>
                        <Typography variant="h2" gutterBottom>
                          User:{' '}
                          {user ? (
                            <>
                              {user.first_name} {user.last_name} - {user.email}
                            </>
                          ) : (
                            'Not found'
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} className="p20">
                        <UpdateUserForm
                          roles={roles}
                          userRoles={user?.roles}
                          canReview={canReview}
                        />
                      </Grid>
                      <Grid item xs={4} className="p20">
                        {canEdit && user && user.roles && !!user.roles.length && (
                          <Button
                            className="button"
                            size="small"
                            variant="contained"
                            onClick={deleteAllRolesConfirmation}
                            color="secondary"
                          >
                            Delete all roles
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </PageFormLayout>
          </form>
        )}
      />
      <ModalComponent
        title="Confirm"
        open={showDeleteAllRolesConfirmation}
        handleClose={() => setShowDeleteAllRolesConfirmation(false)}
      >
        <Confirmation envConfirmConfig={confirmConfig} />
      </ModalComponent>
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: policiesSelectors.getUsersWithRoles(state),
  roles: policiesSelectors.getRoles(state),
});

export default connect(mapStateToProps)(UpdateUser);
