import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PermissionDenied from 'components/common/ErrorComponent/Component/PermissionDenied';
import IsAuth from 'components/common/IsAuth';

import IsLoadingHOC from 'components/hoc/IsLoadingHOC';
import { ErrorHandler, useErrorStatus } from 'components/hoc/ErrorHandler';

import { routesConfig, redirectsConfig } from 'routes';

import { hasRoles } from 'utils';
import { PLAYOSMO_ADMIN } from 'config/roles';

const ErrorRedirect = (err) => {
  const { setErrorStatusCode } = useErrorStatus();
  setErrorStatusCode(err);
  return null;
};

const RoutesActions = (props) => {
  const { userRoles } = props;
  return (
    <ErrorHandler>
      <IsAuth>
        <Switch>
          {redirectsConfig.map((redirect, index) => {
            return (
              <Redirect
                key={index}
                from={redirect.from}
                exact={redirect.exact}
                strict={redirect.strict}
                sensitive={redirect.sensitive}
                push={redirect.push}
                to={{
                  ...redirect.to,
                }}
              />
            );
          })}
          {routesConfig.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <>
                    {userRoles &&
                    hasRoles(userRoles, PLAYOSMO_ADMIN, route.roles) ? (
                      route.render({ ...props })
                    ) : (
                      <PermissionDenied />
                    )}
                  </>
                )}
              />
            );
          })}
          <Route component={() => ErrorRedirect(404)} />
        </Switch>
      </IsAuth>
    </ErrorHandler>
  );
};

export default IsLoadingHOC(RoutesActions);
