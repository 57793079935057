import { useCallback, useState } from 'react';
import { useForm } from 'react-final-form';

import { API } from 'axiosClient/config';
import { getProductManagement } from 'api/products';
import normalizePrices from 'utils/normalizers/api/skus/normalizePrices';
import ExpandableSection from 'components/common/ExpandableSection';
import SectionFilters from 'components/containers/ProductsContainer/ProductView/components/SectionFilters';
import PricingTable from './PricingTable';

const PricingSection = ({
  variant,
  inputBase: inputBaseProp,
  values,
  importFromSkusData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mutators } = useForm();
  const isRecurringPrice = values.subscription_type === 'recurring';
  const inputBase = {
    ...inputBaseProp,
    disabled: isLoading,
  };

  const importPricing = useCallback(
    async (id) => {
      setIsLoading(true);
      try {
        const pricesResponse = await getProductManagement(
          API.products.skus.getPricing(id),
        );
        const pricing = normalizePrices(pricesResponse.data.data);
        mutators.importPricing(pricing);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [mutators],
  );

  const onFilterChange = useCallback(
    (type, value) => {
      if (type === 'import') {
        importPricing(value);
      }
    },
    [importPricing],
  );

  const renderContent = () => (
    <>
      <SectionFilters
        filters={['import']}
        onChange={onFilterChange}
        importData={importFromSkusData}
      />
      <PricingTable inputBase={inputBase} isRecurringPrice={isRecurringPrice} />
    </>
  );

  return (
    <ExpandableSection
      id={`pricing-${variant?.id || 'create'}`}
      heading={'Pricing'}
      content={renderContent()}
      openByDefault={false}
    />
  );
};

export default PricingSection;
