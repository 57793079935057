import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';

import paths from 'routes/paths';

import StatusIndicator from 'components/common/StatusIndicator';
import TableComponent from 'components/common/TableComponent';

import { TableCellStates, tableConfig } from './tableConfig';

const ProductsTable = ({ rows, rowsPerPage, rowCount, onChangePage }) => {
  const history = useHistory();
  const options = {
    rowsPerPage,
    onChangePage,
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.RENDER:
        cell.render = (record) => {
          return record[TableCellStates.RENDER] ? (
            <img src={record[TableCellStates.RENDER]} alt="" />
          ) : null;
        };
        return cell;

      case TableCellStates.ACTIONS:
        cell.render = (record) => {
          return (
            <Button
              color="primary"
              onClick={() => {
                history.push(
                  `${paths.catalog.products.view}/${
                    record[TableCellStates.ID]
                  }`,
                );
              }}
              variant="text"
            >
              View
            </Button>
          );
        };
        return cell;

      case TableCellStates.ID:
        cell.render = (record) => {
          return <span key={i}>{record[TableCellStates.ID]}</span>;
        };
        return cell;

      case TableCellStates.NAME:
        cell.render = (record) => {
          return <span key={i}>{record[TableCellStates.NAME]}</span>;
        };
        return cell;

      case TableCellStates.STATUS:
        cell.render = (record) => {
          return (
            <StatusIndicator
              key={i}
              status={record[TableCellStates.STATUS]}
              withLabel={true}
            />
          );
        };
        return cell;

      case TableCellStates.WEBSITES:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {record[TableCellStates.WEBSITES]?.length
                ? record[TableCellStates.WEBSITES]
                : '-'}
            </span>
          );
        };
        return cell;

      case TableCellStates.VARIANTS:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {record[TableCellStates.VARIANTS].length}
            </span>
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  return (
    <TableComponent
      rows={rows}
      headCells={headCells}
      options={options}
      rowCount={rowCount}
    />
  );
};

export default ProductsTable;
