import httpClient from '../axiosClient';
import { API, getIPProtectionUrl } from '../axiosClient/config';

export const getIPWhitelists = async () => {
  try {
    const resp = await httpClient.get(
      getIPProtectionUrl(API.ipWhitelists.list),
    );
    return resp;
  } catch (e) {
    throw e;
  }
};

export const postIPWhitelist = async ({ project, ip }) => {
  try {
    const resp = await httpClient.post(
      getIPProtectionUrl(API.ipWhitelists.add),
      {
        data: {
          project,
          ip,
        },
      },
    );
    return resp.data.data;
  } catch (e) {
    throw e;
  }
};

export const deleteIPWhitelist = async (id) => {
  try {
    await httpClient.delete(
      getIPProtectionUrl(`${API.ipWhitelists.delete}/${id}`),
    );
  } catch (e) {
    throw e;
  }
};
