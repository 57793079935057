import React from 'react';
import { useHistory } from 'react-router-dom';
import { Skeleton, Button } from '@mui/material';
import { CountryWithFlag } from '@tangibleplay/core-ui';

import paths from 'routes/paths';

import TableComponent from 'components/common/TableComponent';

import { tableConfig, TableCellStates } from './tableConfig';
import StatusIndicator from 'components/common/StatusIndicator';

const CountriesTable = (props) => {
  const { rows, isLoading, disablePagination, rowCount } = props;
  const history = useHistory();
  const rowsWithKeys = rows.map((row) => ({ ...row, id: row.id }));

  const options = {
    isRowSelectable: false,
    selectionKey: 'id',
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.EDIT:
        cell.render = (record, index) => {
          return (
            <Button
              key={`${i}-${index}`}
              onClick={() =>
                history.push(
                  `${paths.eCommerceConfiguration.countries.view}/${
                    record[TableCellStates.ID]
                  }`,
                )
              }
              color="primary"
              variant="text"
            >
              View
            </Button>
          );
        };
        return cell;
      case TableCellStates.STATUS:
        cell.render = (record, index) => {
          return (
            <StatusIndicator
              key={i}
              status={record[TableCellStates.STATUS]}
              withLabel={true}
            />
          );
        };
        return cell;
      case TableCellStates.NAME:
        cell.render = (record, index) => {
          return (
            <CountryWithFlag
              country={{
                code: record[TableCellStates.CODE],
                name: record[TableCellStates.NAME],
              }}
            />
          );
        };
        return cell;
      case TableCellStates.IS_SHIPPING_AVAILABLE:
        cell.render = (record, index) => {
          return (
            <span>
              {record[TableCellStates.IS_SHIPPING_AVAILABLE].toString()}
            </span>
          );
        };
        return cell;
      case TableCellStates.WAREHOUSES:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {record[TableCellStates.WAREHOUSES]
                ? record[TableCellStates.WAREHOUSES].join(', ')
                : ''}
            </span>
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  if (isLoading) {
    return <Skeleton height={300} animation="wave" variant="rectangular" />;
  }

  return (
    <TableComponent
      rows={rowsWithKeys}
      headCells={headCells}
      options={options}
      disablePagination={disablePagination}
      rowCount={rowCount}
      size="small"
    />
  );
};

export default CountriesTable;
