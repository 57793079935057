const validateOrder = (values) => {
  let errors = {};

  if (!values.description?.length) {
    errors.description = 'Required';
  }
  if (!values.skus?.length) {
    errors.skus = 'Required';
  } else {
    const noSkuWithQty = values.skus.reduce((acc, sku) => {
      const skuQty = values[`${sku}-qty`];

      if (!!skuQty && skuQty !== '0') {
        return false;
      }

      return acc;
    }, true);

    if (noSkuWithQty) {
      errors.general = 'Please add at least one product';
    }

    const skusWithoutQty = values.skus.filter((sku) => {
      const skuQty = values[`${sku}-qty`];
      return !skuQty;
    });

    if (skusWithoutQty.length) {
      skusWithoutQty.forEach((sku) => {
        errors[`${sku}-qty`] = 'Required';
      });
    }
  }
  if (!values.email?.length) {
    errors.email = 'Required';
  }
  if (!values.country?.length) {
    errors.country = 'Required';
  }
  if (!values.shipping_method?.length) {
    errors.shipping_method = 'Required';
  }
  if (!values.zip?.length) {
    errors.zip = 'Required';
  }

  return errors;
};

export default validateOrder;
