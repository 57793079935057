function createDiscountedProductsList(skuDetails, discounted_skus) {
  const discount = skuDetails.map((sku) => {
    const skuItem = { ...sku };
    skuItem.percent_off = discounted_skus[sku.id]
      ? discounted_skus[sku.id].percent_off
      : 0;
    skuItem.badges = discounted_skus[sku.id]
      ? discounted_skus[sku.id].badges
      : [];

    return skuItem;
  });

  // Filter out all skus that have no discount and no badge
  return discount.filter(
    (sku) =>
      (!isNaN(sku.percent_off) && sku.percent_off > 0) ||
      (sku.badges && sku.badges.length),
  );
}

export default createDiscountedProductsList;
