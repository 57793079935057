const prefix = 'POLICIES_';

export const FETCH_USERS_WITH_ROLES = `${prefix}FETCH_USERS_WITH_ROLES`;
export const FETCH_ROLES = `${prefix}FETCH_ROLES`;
export const FETCH_USER_ROLES = `${prefix}FETCH_USER_ROLES`;
export const ASSIGN_USER_NEW_ROLE = `${prefix}ASSIGN_USER_NEW_ROLE`;
export const UNASSIGN_USER_ROLE = `${prefix}UNASSIGN_USER_ROLE`;
// Sagas:
export const USERS_DETAILS_SUCCESS = `${prefix}USERS_DETAILS_SUCCESS`;
export const ROLES_SUCCESS = `${prefix}ROLES_SUCCESS`;
export const USER_ROLES_SUCCESS = `${prefix}USER_ROLES_SUCCESS`;
export const UPDATE_USER_ROLES = `${prefix}UPDATE_USER_ROLES`;
