import {
  SET_EDITING_PRODUCT,
  RESET_EDITING_PRODUCT,
  UPDATE_EDITING_PRODUCT_VALUES,
  UPDATE_EDITING_PRODUCT_VARIANT,
  ADD_EDITING_PRODUCT_VARIANT,
  SET_EDITING_PRODUCT_VARIANTS,
} from './actions';

const initialState = {
  currentProduct: {
    id: null,
    variants: null,
    values: null,
  },
};

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDITING_PRODUCT: {
      const { productID } = action.payload;

      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          id: productID,
        },
      };
    }

    case RESET_EDITING_PRODUCT: {
      return initialState;
    }

    case SET_EDITING_PRODUCT_VARIANTS: {
      const { variants } = action.payload;

      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          variants,
        },
      };
    }
    case UPDATE_EDITING_PRODUCT_VALUES: {
      const { values } = action.payload;

      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          values,
        },
      };
    }
    case UPDATE_EDITING_PRODUCT_VARIANT: {
      const { variant } = action.payload;

      const variants = state.currentProduct?.variants?.map((sku) => {
        if (variant.id === sku.id) {
          return variant;
        }
        return sku;
      });

      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          variants,
        },
      };
    }
    case ADD_EDITING_PRODUCT_VARIANT: {
      const { variant } = action.payload;

      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          variants: [...(state.currentProduct.variants || []), variant],
        },
      };
    }

    default:
      return state;
  }
};
