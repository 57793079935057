const prefix = 'ORDERS_';

// Sagas
export const GET_ORDERS = `${prefix}GET_ORDERS`;
export const UPDATE_ORDER = `${prefix}UPDATE_ORDER`;
export const CREATE_ORDER = `${prefix}CREATE_ORDER`;
export const UPDATE_ORDER_ADDRESS = `${prefix}UPDATE_ORDER_ADDRESS`;
export const PERFORM_ORDER_ACTION = `${prefix}PERFORM_ORDER_ACTION`;
export const ADD_NOTE = `${prefix}ADD_NOTE`;
export const PARTIALLY_REFUND_ORDER = `${prefix}PARTIALLY_REFUND_ORDER`;
export const REVIEW_ORDER = `${prefix}REVIEW_ORDER`;
export const FIX_ORDER = `${prefix}FIX_ORDER`;
export const CREATE_RMA = `${prefix}CREATE_RMA`;
export const GET_RETURNS = `${prefix}GET_RETURNS`;
