import { Paper, Typography } from '@mui/material';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';

const CurrencySection = ({ inputBase }) => {
  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Currency
      </Typography>
      <OrderPropertiesWrapper>
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'currency_code',
            required: true,
          }}
          label="Currency code"
        />
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'symbol_left',
          }}
          label="Currency symbol left"
        />
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'symbol_right',
          }}
          label="Currency symbol right"
        />
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'is_zero_decimal_currency',
          }}
          label="Use zero decimal"
          type="checkbox"
        />
      </OrderPropertiesWrapper>
    </Paper>
  );
};

export default CurrencySection;
