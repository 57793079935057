import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';

import PageContentLayout, {
  propTypes as pageContentLayoutPropTypes,
} from '../PageContentLayout';

import './styles.scss';

function PageFormLayout({
  showSubmitButtons,
  handleCancel,
  children,
  // Passed to the PageContentLayout component
  ...pageLayoutProps
}) {
  return (
    <PageContentLayout className="page-form-layout" {...pageLayoutProps}>
      {children}
      {showSubmitButtons && (
        <Grid container spacing={3}>
          <Grid item xs={12} className="page-form-layout-buttons mt-20">
            <Button
              className="mr-20"
              size="small"
              variant="outlined"
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>

            <Button
              className="button"
              size="small"
              variant="contained"
              type="submit"
              color="primary"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </PageContentLayout>
  );
}

export const propTypes = {
  ...pageContentLayoutPropTypes,
  showSubmitButtons: PropTypes.bool,
  handleCancel: PropTypes.func,
};

PageFormLayout.propTypes = propTypes;

export default memo(PageFormLayout);
