export const TableCellStates = {
  ACTIONS: 'actions',
  NAME: 'name',
  CREATED_ON: 'created_on',
  ID: 'id',
  STATUS: 'status',
};

export const tableConfig = [
  {
    title: 'ID',
    id: TableCellStates.ID,
  },
  {
    title: 'Name',
    id: TableCellStates.NAME,
  },
  {
    title: 'Status',
    id: TableCellStates.STATUS,
  },
  {
    title: 'Created on',
    id: TableCellStates.CREATED_ON,
  },
  {
    title: 'Actions',
    id: TableCellStates.ACTIONS,
  },
];
