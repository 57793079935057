import { React } from 'react';
import SelectComponent from './SelectComponent';

const SelectDropdown = ({
  input,
  meta,
  dataItems,
  handleChange,
  selectedData,
  label,
  ...rest
}) => {
  const hasError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;
  return (
    <SelectComponent
      {...rest}
      {...input}
      label={label}
      value={selectedData || input.value}
      onChange={handleChange || input.onChange}
      helperText={hasError ? meta.error || meta.submitError : undefined}
      error={hasError}
      dataItems={dataItems}
    />
  );
};

export default SelectDropdown;
