import React, { useEffect, useState } from 'react';
import { TableCellStates, tableConfig } from './tableConfig';
import { Link, Typography } from '@mui/material';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import TableComponent from 'components/common/TableComponent';
import { getAPIData } from 'api/playosmo';
import { API } from 'axiosClient/config';
import { getByjusCouponURL, getCouponURL } from 'config/environments';
import PageContentLayout from 'components/layout/PageContentLayout';
import 'components/containers/CampaignContainer/CampaignListing/index.scss';
import paths from 'routes/paths';
import './index.scss';

export const UpcomingCampaignListing = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const COUPON_URL = getCouponURL();
  const BYJUS_COUPON_URL = getByjusCouponURL();

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.COUNTRIES:
        cell.render = (record, index) => {
          return (
            <span
              className="campaign-test-link campaign-cell-text-short"
              key={i}
            >
              {record.countries.join(', ')}
            </span>
          );
        };
        return cell;
      case TableCellStates.URL:
        cell.render = (record, index) => {
          return (
            <span key={i}>
              <Link
                className="dashboard-campaign-table-link"
                href={
                  record.websites && record.websites.includes('byjuslearning')
                    ? `${BYJUS_COUPON_URL}${record.coupon_code}`
                    : `${COUPON_URL}${record.coupon_code}`
                }
                target="_blank"
                rel="noreferrer"
                underline="hover"
              >
                {record.websites && record.websites.includes('byjuslearning')
                  ? `${BYJUS_COUPON_URL}${record.coupon_code}`
                  : `${COUPON_URL}${record.coupon_code}`}
                <OpenInNewIcon />
              </Link>
            </span>
          );
        };
        return cell;
      case TableCellStates.CAMPAIGN_NAME:
        cell.render = (record, index) => {
          return (
            <span key={i}>
              <Link
                href={`${paths.marketing.campaigns.edit}/${record.id}`}
                target="_blank"
                rel="noreferrer"
                className="campaign-test-link campaign-cell-text-short"
                underline="hover"
              >
                {record.campaign_name}
              </Link>
            </span>
          );
        };
        return cell;
      default:
        return cell;
    }
  });
  const options = {
    rowsPerPage: 5,
    isRowSelectable: false,
  };

  const getUpcomingCampaign = async (value) => {
    let response;
    try {
      response = await getAPIData(API.coupons + `?limit=&cursor=&ended=false`);
      setLoading(false);
      return response.data.coupons;
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getUpcomingCampaign().then((campaigns) => {
      setRows(campaigns);
    });
  }, []);
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Active Campaigns
      </Typography>
      <PageContentLayout isLoading={loading}>
        {!loading ? (
          <TableComponent
            size="small"
            rows={rows}
            headCells={headCells}
            options={options}
          />
        ) : (
          <></>
        )}
      </PageContentLayout>
    </>
  );
};
