import { TextField } from '@mui/material';

const TextInput = ({
  input: { name, required, onChange, value, ...restInput },
  meta,
  ...rest
}) => {
  const hasError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;
  return (
    <TextField
      variant="standard"
      {...rest}
      name={name}
      helperText={hasError ? meta.error || meta.submitError : undefined}
      error={hasError}
      inputProps={restInput}
      onChange={onChange}
      value={value}
      required={required}
    />
  );
};

export default TextInput;
