import { Field } from 'react-final-form';
import { Grid, Paper, Typography } from '@mui/material';
import TextInput from 'components/common/TextInput';

const OtherSection = ({ inputBase }) => {
  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Other
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <Field
            component={TextInput}
            placeholder="Add a note here"
            name="note"
            label="Note"
            variant="outlined"
            multiline
            maxRows={Infinity}
            fullWidth
            disabled={inputBase.disabled}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OtherSection;
