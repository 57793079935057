import httpClient from '../axiosClient';
import { API, getBannersUrl, getBannersURL } from '../axiosClient/config';

export const getBanners = async (limit, cursor) => {
  try {
    const resp = await httpClient.get(
      `${getBannersUrl(API.textBanners)}${!!limit ? `?limit=${limit}` : ''}${
        !!cursor ? `&cursor=${cursor}` : ''
      }`,
    );
    return resp;
  } catch (e) {
    throw e;
  }
};

export const postBanner = async (data) => {
  try {
    const resp = await httpClient.post(getBannersUrl(API.textBanners), {
      data,
    });
    return resp.data.data;
  } catch (e) {
    throw e;
  }
};

export const putBanner = async (id, data) => {
  try {
    const resp = await httpClient.put(
      getBannersUrl(`${API.textBanners}?id=${id}`),
      { data },
    );
    return resp.data.data;
  } catch (e) {
    throw e;
  }
};

export const deleteBanner = async (id) => {
  try {
    await httpClient.delete(getBannersUrl(`${API.textBanners}?id=${id}`));
  } catch (e) {
    throw e;
  }
};

// Duplication to new env
export const postBannerWithEnv = async (data, env = '') => {
  try {
    const resp = await httpClient.post(
      getBannersURL(env) + `${API.textBanners}`,
      { data },
    );
    return resp;
  } catch (e) {
    throw e;
  }
};
