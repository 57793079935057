import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteIPWhitelist } from 'api/ipProtection';
import TableComponent from 'components/common/TableComponent';
import { TableCellStates, tableConfig } from './tableConfig';
import { SEVERITY } from 'config';

const IPAddressesTable = ({ addresses, refreshData, canEdit }) => {
  const { enqueueSnackbar } = useSnackbar();

  const options = {
    rowsPerPage: 10,
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteIPWhitelist(id);
      enqueueSnackbar('IP address successfully deleted', {
        variant: SEVERITY.SUCCESS,
      });
      refreshData();
    } catch (e) {
      console.warn({ e });
      enqueueSnackbar(e.response.data?.error?.message, {
        variant: SEVERITY.ERROR,
      });
    }
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.IP_ADDRESS:
        cell.render = (record, index) => {
          return (
            <span key={`${record.id}-${i}-${index}`}>
              {record[TableCellStates.IP_ADDRESS]}
            </span>
          );
        };
        return cell;
      case TableCellStates.REASON:
        cell.render = (record, index) => {
          return (
            <span key={`${record.id}-${i}-${index}`}>
              {record[TableCellStates.REASON] || '-'}
            </span>
          );
        };
        return cell;
      case TableCellStates.PROJECT:
        cell.render = (record, index) => {
          return (
            <span key={`${record.id}-${i}-${index}`}>
              {record[TableCellStates.PROJECT]}
            </span>
          );
        };
        return cell;
      case TableCellStates.ID:
        cell.render = (record, index) => {
          return (
            <span key={`${record.id}-${i}-${index}`}>
              {record[TableCellStates.ID]}
            </span>
          );
        };
        return cell;
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          if (!canEdit) return null;
          return (
            <IconButton key={record.id} onClick={() => handleDelete(record.id)}>
              <DeleteIcon />
            </IconButton>
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  return (
    <TableComponent rows={addresses} headCells={headCells} options={options} />
  );
};

export default IPAddressesTable;
