import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { Field } from 'react-final-form';

import EmptyStateMessage from 'components/common/EmptyStateMessage';
import ModalComponent from 'components/common/ModalComponent';
import AddHeading from '../AddHeading';
import ListContentHeading from '../ListContentHeading';
import AutoCompleteWrapper from 'components/common/AutoCompleteWrapper';
import CheckboxComponent from 'components/common/CheckboxComponent';
import SortableTable from 'components/common/SortableTable';

import './styles.scss';

const ContentFeature = ({ sectionId, values, setValue }) => {
  const [addContent, setAddContent] = useState(false);
  const [headingDetails, setHeadingDetails] = useState(null);
  const [isDefault, setDefault] = useState(false);
  const { skuDetails } = useSelector((state) => state.general);
  const { contentFeature, contentSection } = useSelector(
    (state) => state.createCampaignReducer,
  );
  const skuFieldName = sectionId === 'contentFeature' ? 'featureSku' : 'sku';

  const listData =
    sectionId === 'contentSection' ? contentSection : contentFeature;

  const handleAddMore = () => {
    setDefault(false);
    setAddContent(true);
  };

  const handleCancelHeading = () => {
    setAddContent(false);
  };

  const handleSaveHeading = () => {
    setAddContent(false);
    setHeadingDetails(null);
  };

  const handleEditList = (record) => {
    if (record.language === 'default') {
      setDefault(true);
    } else {
      setDefault(false);
    }
    setHeadingDetails(record);
    setAddContent(true);
  };

  const handleAddDefault = () => {
    setDefault(true);
    setAddContent(true);
  };

  const handleRowsChange = (rows) => {
    const newSkusOrder = rows.map((row) => row.id);
    setValue(skuFieldName, newSkusOrder);
  };

  return (
    <Grid item container spacing={3}>
      {sectionId === 'contentFeature' && (
        <Grid item xs={12}>
          <Field
            name="hideFeature"
            label="Hide Existing Featured Sections"
            component={CheckboxComponent}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h4">Heading</Typography>
        <ListContentHeading
          handleAddDefault={handleAddDefault}
          handleAddMore={handleAddMore}
          handleEditList={handleEditList}
          listData={listData}
          sectionId={sectionId}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">Product Listing</Typography>
        {sectionId === 'contentFeature' && (
          <Typography variant="body1" className="mb-10">
            Only add products here if you're not planning to add any subsection.
          </Typography>
        )}
        <Field
          multiple={true}
          limitTags={2}
          name={skuFieldName}
          label="Select SKU"
          placeholder="Search SKU"
          options={skuDetails.filter((sku) => sku.prices)}
          selectField="id"
          component={AutoCompleteWrapper}
          variant="outlined"
          getOptionLabel={(option) => {
            return `${option.name}: ${option.id}`;
          }}
          renderDropdownOption={(option) => option.name + ` (${option['id']})`}
        />
      </Grid>
      {values[skuFieldName] && !!values[skuFieldName].length ? (
        <Grid item xs={12}>
          <Typography variant="h5">Products order</Typography>
          <SortableTable
            className="products-order"
            rows={values[skuFieldName].map((sku) => ({
              id: sku,
              text: sku,
            }))}
            onRowsChange={handleRowsChange}
          />
        </Grid>
      ) : (
        <EmptyStateMessage message="No selected products for now" />
      )}
      <ModalComponent
        title="Add a Heading"
        open={addContent}
        handleClose={handleCancelHeading}
      >
        <AddHeading
          isDefault={isDefault}
          headingDetails={headingDetails}
          handleSave={handleSaveHeading}
          handleCancel={handleCancelHeading}
          sectionId={sectionId}
          listData={listData}
        />
      </ModalComponent>
    </Grid>
  );
};

export default React.memo(ContentFeature);
