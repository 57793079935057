import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Typography } from '@mui/material';
import ModalComponent from 'components/common/ModalComponent';
import SubsectionList from '../SubsectionList';
import {
  CANCEL_CONTENT_SECTION_DATA,
  SUBMIT_CONTENT_SECTION_DATA,
  EDIT_CONTENT_HEADING_DATA,
} from '../../store/actions';
import AddSubsection from '../AddSubsection';
import { generateID } from 'utils';

const ContentSubSection = (props) => {
  const [addSubSection, setSubSection] = useState(false);
  const [editRecord, setEditRecord] = useState({});

  const sectionId = 'contentSection';

  const { contentSubsectionsData, contentSection } = useSelector(
    (state) => state.createCampaignReducer,
  );

  const dispatch = useDispatch();

  const handleAddSubSection = () => {
    setSubSection(true);
  };

  const handleCancelSection = () => {
    setSubSection(false);
    setEditRecord({});
    dispatch({
      type: CANCEL_CONTENT_SECTION_DATA,
    });
  };

  const handleSaveSection = (val) => {
    if (editRecord.id) {
      const contentData = [...contentSubsectionsData];
      const contentIndex = contentData.findIndex(
        (item) => item.id === editRecord.id,
      );
      contentData[contentIndex].heading = contentSection;
      contentData[contentIndex].skus = [...val.sku];
      dispatch({
        type: SUBMIT_CONTENT_SECTION_DATA,
        payload: {
          contentArr: contentData,
        },
      });
      setEditRecord({});
    } else {
      const contentArr = [...contentSubsectionsData];
      const obj = {
        id: generateID(),
        heading: [...contentSection],
        skus: [...val.sku],
      };
      contentArr.push(obj);
      dispatch({
        type: SUBMIT_CONTENT_SECTION_DATA,
        payload: {
          contentArr,
        },
      });
    }
    setSubSection(false);
  };

  const handleEditList = (record) => {
    dispatch({
      type: EDIT_CONTENT_HEADING_DATA,
      payload: {
        contentData: record.heading,
        sectionId,
      },
    });
    setEditRecord(record);
    setSubSection(true);
  };

  return (
    <Grid item xs={12} className="p20">
      <Typography variant="h3" gutterBottom>
        Subsections
      </Typography>
      <SubsectionList handleEditList={handleEditList} />
      <div className="discount-add">
        <Button
          className="button"
          variant="contained"
          onClick={handleAddSubSection}
        >
          Add a subsection
        </Button>
      </div>
      <ModalComponent
        title="Add a subsection"
        open={addSubSection}
        handleClose={handleCancelSection}
      >
        <AddSubsection
          handleCancel={handleCancelSection}
          handleSave={handleSaveSection}
          sectionId={sectionId}
          editRecord={editRecord}
        />
      </ModalComponent>
    </Grid>
  );
};

export default React.memo(ContentSubSection);
