import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from '@mui/material';

import { TableActionStates } from 'enums/tableStates';
import { getLocalDateFromUTC, formatDate } from 'utils';
import { MARKETING_WRITE, MARKETING_READ } from 'config/roles';
import paths from 'routes/paths';

import userSelectors from 'store/user/selectors';

import TableComponent from 'components/common/TableComponent';
import TableActions from 'components/common/TableActions';
import Action from 'components/common/TableActions/Actions';
import StatusIndicator from 'components/common/StatusIndicator';

import { tableConfig, TableCellStates } from './tableConfig';

function PromoCodeTable(props) {
  const { rows, userRoles } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const history = useHistory();
  const rowsWithKeys = rows.map((row) => ({ ...row, id: row.promo_code_id }));
  const reviewOnly =
    userRoles.includes(MARKETING_READ) && !userRoles.includes(MARKETING_WRITE);

  const onRowSelectChange = (selectedRow) => {
    setSelectedRows(selectedRow);
  };

  const options = {
    selectedRows,
    onRowSelectChange: onRowSelectChange,
    isRowSelectable: false,
    rowsPerPage: 10,
    selectionKey: 'id',
  };

  const renderActions = (cell, record) => {
    return cell.actions.map((action, index) => {
      switch (action.type) {
        case TableActionStates.EDIT:
          const canEdit = record.status !== 'expired' && !reviewOnly;
          if (!canEdit) return null;
          return (
            <Action
              key={index}
              text={action.text}
              action={() => {
                history.push(
                  `${paths.marketing.promocodes.edit}/${record.code}`,
                );
              }}
            />
          );
        case TableActionStates.REVIEW:
          const canReview = record.status === 'expired' || reviewOnly;
          if (!canReview) return canReview;
          return (
            <Action
              key={index}
              text={action.text}
              action={() => {
                history.push(
                  `${paths.marketing.promocodes.review}/${record.code}`,
                );
              }}
            />
          );

        default:
          return cell;
      }
    });
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return (
            <TableActions key={`${i}-${index}`}>
              {renderActions(cell, record)}
            </TableActions>
          );
        };
        return cell;
      case TableCellStates.PROMO_CODE_ID:
        cell.render = (record, index) => {
          return <span key={`${i}-${index}`}>{record.promo_code_id}</span>;
        };
        return cell;
      case TableCellStates.WEBSITE:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {record.website ? record.website : '-'}
            </span>
          );
        };
        return cell;
      case TableCellStates.COUPON_ID:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              <Link
                href={`${paths.marketing.campaigns.review}/${record.coupon_id}`}
                underline="hover"
              >
                {record.coupon_id}
              </Link>
            </span>
          );
        };
        return cell;
      case TableCellStates.STATUS:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              <StatusIndicator status={record.status} withLabel={true} />
            </span>
          );
        };
        return cell;
      case TableCellStates.CREATION_DATE:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {formatDate(getLocalDateFromUTC(record.created_on))}
            </span>
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  return (
    <>
      <TableComponent
        rows={rowsWithKeys}
        headCells={headCells}
        options={options}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(PromoCodeTable);
