import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Divider, Grid } from '@mui/material';
import PageContentLayout from 'components/layout/PageContentLayout';
import { API } from 'axiosClient/config';
import { getAPIData, putAPIData } from 'api/playosmo';
import Typography from '@mui/material/Typography';
import OrderProperty from '../OrdersContainer/OrderView/components/OrderProperty';
import userSelectors from 'store/user/selectors';
import { connect } from 'react-redux';
import { ORDERS_WRITE } from 'config/roles';
import paths from 'routes/paths';
import { useHistory } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import OrderPropertiesWrapper from '../OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';

const IssueDetailView = ({ match, userRoles }) => {
  const [issueDetail, setIssueDetail] = useState();
  const [loading, setLoading] = useState(true);
  const canEdit = userRoles.includes(ORDERS_WRITE);
  const issueId = match.params.id;
  const history = useHistory();

  const RenderActions = (record) => {
    if (canEdit) {
      if (record.acked === false && record.resolved === false) {
        return (
          <div className="action-flex">
            <Button
              variant="outlined"
              onClick={() => updateIssues(true, false, false, record.issue_id)}
            >
              Take it
            </Button>
          </div>
        );
      }

      if (record.acked === true && record.resolved === false) {
        return (
          <div className="action-flex">
            <Button
              variant="outlined"
              onClick={() => updateIssues(true, true, false, record.issue_id)}
            >
              Resolve
            </Button>
          </div>
        );
      }

      if (record.acked === true && record.resolved === true) {
        return (
          <div className="action-flex">
            <Button
              variant="outlined"
              onClick={() => updateIssues(true, true, true, record.issue_id)}
            >
              Reopen
            </Button>
          </div>
        );
      }
    }
  };
  const RenderStatusValue = ({ record }) => {
    if (record.acked === false && record.resolved === false) {
      return (
        <Chip
          className="issue-status-button-error chip-border-radius-remove "
          label="PENDING"
        />
      );
    }

    if (record.acked === true && record.resolved === false) {
      return (
        <Chip
          className="issue-status-button-warning chip-border-radius-remove "
          label="IN PROGRESS"
        />
      );
    }

    if (record.acked === true && record.resolved === true) {
      return (
        <Chip
          className="issue-status-button-success chip-border-radius-remove"
          label="RESOLVED"
        />
      );
    }
    return '-';
  };

  const updateIssues = async (acked, resolved, reopened, issueId) => {
    let updateIssuePayload = {
      acked: acked,
      resolved: resolved,
      reopened: reopened,
      issue_id: issueId,
    };
    setLoading(true);
    await putAPIData(API.orders.issues, updateIssuePayload);
    setLoading(false);
    setLoading(false);
    window.location.reload();
  };

  useEffect(
    () => {
      const fetchIssue = async () => {
        let response;
        let issueUrl = `${API.orders.issues}?matches_acked=&matches_resolved=&count_only=false&issue_id=${issueId}`;

        response = await getAPIData(issueUrl);
        return response.data.issues[0];
      };
      fetchIssue().then((data) => {
        setIssueDetail(data);
        setLoading(false);
      });
    },
    // eslint-disable-next-line
    [],
  );

  const title = `Issue #${issueId}`;

  const handleSeeOrder = () => {
    history.push(
      `${paths.orders.view}/${
        issueDetail.references && issueDetail.references.order
      }`,
    );
  };
  return (
    <>
      <PageContentLayout
        title={title}
        isLoading={loading}
        RenderStatusValue={RenderStatusValue}
        record={issueDetail}
        buttons={[
          {
            label: 'See parent order',
            onClick: handleSeeOrder,
          },
        ]}
        breadcrumbs={[
          {
            label: 'Issues listing',
            link: paths.orders.issues.list,
          },
          {
            label: title,
            isCurrent: true,
          },
        ]}
      >
        {issueDetail && (
          <Grid container spacing={3}>
            <Grid item xs>
              <Card>
                <CardContent>
                  <Typography variant="h3" gutterBottom>
                    Issue Details
                  </Typography>
                  <Divider className="subscription-listing-divider-margin-bottom" />
                  <OrderPropertiesWrapper>
                    <OrderProperty
                      label="Issue ID"
                      value={issueDetail.issue_id}
                    />
                    <OrderProperty
                      label="Issue Context"
                      value={issueDetail.context}
                    />
                    <OrderProperty label="Issue " value={issueDetail.issue} />
                    <OrderProperty
                      label="Category"
                      value={issueDetail.issue_category}
                    />
                    <OrderProperty
                      label="Acknowledged By "
                      value={issueDetail.acked_by}
                    />
                    <OrderProperty
                      label="Acknowledged On "
                      value={issueDetail.acked_on}
                    />
                    <OrderProperty
                      label="Resolved By "
                      value={issueDetail.resolved_by}
                    />
                    <OrderProperty
                      label="Resolved On "
                      value={issueDetail.resolved_on}
                    />
                  </OrderPropertiesWrapper>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <Typography variant="h3" gutterBottom>
                    Actions
                  </Typography>
                  <Divider className="subscription-listing-divider-margin-bottom" />
                  {RenderActions(issueDetail)}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </PageContentLayout>
    </>
  );
};
const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});
export default connect(mapStateToProps)(IssueDetailView);
