import React from 'react';
import { Form, Field } from 'react-final-form';

import { Grid, Button, MenuItem, TextField } from '@mui/material';

import TextInput from 'components/common/TextInput';

import { ENVIRONMENTS, getCurrentEnvironment } from 'config/environments';

import './style.scss';

export default function DuplicateCampaign(props) {
  const { handleEnvCancel, handleEnvSubmit, formDetails } = props;

  const environments = [...ENVIRONMENTS];
  const currentEnv = getCurrentEnvironment();

  const SelectInput = ({ input, label }) => (
    <TextField
      className="duplicate-campaign-form"
      select
      name={input.name}
      onChange={(value) => input.onChange(value)}
      variant="outlined"
      label={label}
    >
      {environments.map((item) => (
        <MenuItem key={item} value={item} name={item}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );

  const validate = (values) => {
    const errors = {};
    if (!values.environment) {
      errors.environment = 'Required';
    }
    if (!values.campaign && values.environment === currentEnv) {
      errors.campaign = 'Required';
    }
    if (values.campaign && values.campaign.indexOf(' ') >= 0) {
      errors.campaign = 'The key should not include spaces';
    }
    return errors;
  };

  const handleFormSubmit = (values) => {
    handleEnvSubmit(values, formDetails);
  };

  return (
    <div className="duplicate-campaign">
      <Form
        onSubmit={handleFormSubmit}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <form
            onSubmit={handleSubmit}
            className="duplicate-campaign-form"
            noValidate
          >
            <Grid container>
              <Grid item xs={12} className="row">
                <Field
                  fullWidth
                  required
                  name="environment"
                  component={SelectInput}
                  type="text"
                  label="Environment"
                />
              </Grid>
              {values.environment === currentEnv && (
                <Grid item xs={12} className="row">
                  <Field
                    fullWidth
                    name="campaign"
                    component={TextInput}
                    type="text"
                    label="New campaign Key"
                    variant="outlined"
                  />
                </Grid>
              )}
              <Grid item xs={12} className="button-container">
                <Button
                  className="mr-20"
                  size="small"
                  variant="outlined"
                  type="button"
                  onClick={handleEnvCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="button"
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </div>
  );
}
