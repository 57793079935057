const prefix = 'CONTENT_ACTIONS_';

export const BANNERS_SUCCESS = `${prefix}BANNERS_SUCCESS`;
export const DELETE_TEXT_BANNER = `${prefix}DELETE_TEXT_BANNER`;

// SAGAS
export const UPDATE_TEXT_BANNER = `${prefix}UPDATE_TEXT_BANNER`;
export const SAVE_TEXT_BANNER = `${prefix}SAVE_TEXT_BANNER`;
export const GET_TEXT_BANNERS = `${prefix}GET_TEXT_BANNERS`;
export const DELETE_TEXT_BANNER_SAGA = `${prefix}DELETE_TEXT_BANNER_SAGA`;
