import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';

import { compose } from 'recompose';

import { MARKETING_WRITE, MARKETING_READ } from 'config/roles';
import getCampaignStatus from 'utils/campaigns/getCampaignStatus';
import paths from 'routes/paths';

import PageContentLayout from 'components/layout/PageContentLayout';
import withRouterInfo from 'components/hoc/withRouterInfo';
import { useErrorStatus } from 'components/hoc/ErrorHandler';

import userSelectors from 'store/user/selectors';
import { GET_PROMO_CODES } from './store/actions';

import {
  GET_PAST_CAMPAIGN,
  GET_UPCOMING_CAMPAIGN,
} from 'components/containers/CampaignContainer/CampaignListing/actions';

import PromoCodeTable from './PromoCodeTable';
import { Button, Grid, Paper } from '@mui/material';
import { Field, Form } from 'react-final-form';
import TextInput from '../../../common/TextInput';
import SelectDropdown from '../../../common/SelectDropdown';
import { getWebsiteDataItem } from '../../CampaignContainer/CampaignListing/CampaignFilters/filters';

function PromoCodeListing(props) {
  const {
    history,
    // From Redux
    userRoles,
  } = props;
  const { setErrorStatusCode } = useErrorStatus();
  const [loading, setLoading] = useState(false);
  const [filterPayload, setFilterPayload] = useState({
    promocode: '',
    website: '',
  });
  const dispatch = useDispatch();

  const reviewOnly =
    userRoles.includes(MARKETING_READ) && !userRoles.includes(MARKETING_WRITE);

  const getPromoCodes = useCallback(() => {
    setLoading(true);
    dispatch({
      type: GET_PROMO_CODES,
      payload: {
        filterPayload,
        callback: (e) => {
          setLoading(false);
        },
        errorCallback: (error) => {
          setErrorStatusCode(error);
        },
      },
    });
    // Get the past campaigns as we could be viewing an expired code
    dispatch({
      type: GET_PAST_CAMPAIGN,
      payload: {
        callback: () => {},
        errorCallback: (error) => {
          setErrorStatusCode(error);
        },
      },
    });
    dispatch({
      type: GET_UPCOMING_CAMPAIGN,
      payload: {
        callback: () => {},
        errorCallback: (error) => {
          setErrorStatusCode(error);
        },
      },
    });
  }, [dispatch, setErrorStatusCode, filterPayload]);

  useEffect(() => {
    getPromoCodes();
  }, [getPromoCodes, filterPayload]);

  const { promoCodes } = useSelector((state) => state.promoCodeListReducer);

  const { upcomingCampaign, pastCampaign } = useSelector(
    (state) => state.campaignListReducer,
  );
  const campaigns = [...upcomingCampaign, ...pastCampaign];

  const promoCodesWithCoupons = promoCodes.map((promoCode) => {
    const campaign = campaigns.find((c) => c.id === promoCode.coupon_id);
    return {
      ...promoCode,
      id: promoCode.coupon_id,
      status: campaign && getCampaignStatus(campaign),
    };
  });

  const handleCreate = () => {
    history.push(paths.marketing.promocodes.create);
  };

  const handleSubmit = (values, form) => {
    if (values.website === 'ALL') {
      values.website = '';
    }
    setFilterPayload({
      promocode: values.promocode ?? '',
      website: values.website ?? '',
    });
  };

  const handleReset = (form) => {
    form.reset();
    setFilterPayload({ promocode: '', website: '' });
  };

  return (
    <PageContentLayout
      title="Promo Codes"
      isLoading={loading}
      buttons={
        !reviewOnly
          ? [
              {
                label: 'Create Promo Code',
                onClick: handleCreate,
              },
            ]
          : null
      }
      showProductionWarning={!reviewOnly}
    >
      <Paper className="orders-filters-form mb-30">
        <Form
          initialValues={filterPayload}
          onSubmit={handleSubmit}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Field
                    fullWidth
                    name="promocode"
                    component={TextInput}
                    type="text"
                    label="Search by Promo Code"
                    variant="outlined"
                    value={filterPayload.promocode}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    fullWidth
                    name="website"
                    component={SelectDropdown}
                    dataItems={getWebsiteDataItem()}
                    type="text"
                    label="Search By Website"
                    variant="outlined"
                    value={filterPayload.website}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        className="button"
                        type="submit"
                        variant="contained"
                        color="secondary"
                      >
                        Search
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => handleReset(form)}
                        variant="outlined"
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Paper>
      <PromoCodeTable rows={promoCodesWithCoupons} />
    </PageContentLayout>
  );
}

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default compose(withRouterInfo)(
  connect(mapStateToProps)(PromoCodeListing),
);
