import {
  LOAD_CAMPAIGN_ASSETS,
  SAVE_CAMPAIGN_ASSETS,
  DELETE_CAMPAIGN_ASSETS,
  BULK_DELETE_CAMPAIGN_ASSETS,
  CLEAN_ADDED_ASSETS,
  CLEAN_DELETED_ASSETS,
} from './actions';

const initialState = {
  assets: {},
  deletedAssets: {},
  addedAssets: {},
};

export const assetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMPAIGN_ASSETS: {
      let assets = Object.assign({}, state.assets);
      assets = { ...assets, ...action.payload };
      return {
        ...state,
        assets,
      };
    }
    case SAVE_CAMPAIGN_ASSETS: {
      let assets = Object.assign({}, state.assets);
      const addedAssets = Object.assign({}, state.addedAssets);
      const assetId = Object.keys(action.payload)[0];
      assets = { ...assets, ...action.payload };
      addedAssets[`${assetId}`] = assetId;

      return {
        ...state,
        assets,
        addedAssets,
      };
    }

    case DELETE_CAMPAIGN_ASSETS: {
      const { fileId } = action.payload;
      const assets = Object.assign({}, state.assets);
      const addedAssets = Object.assign({}, state.addedAssets);
      const deletedAssets = Object.assign({}, state.deletedAssets);

      delete assets[fileId];
      delete addedAssets[fileId];
      deletedAssets[`${fileId}`] = fileId;

      return {
        ...state,
        assets,
        addedAssets,
        deletedAssets,
      };
    }

    case BULK_DELETE_CAMPAIGN_ASSETS: {
      const { fileIds } = action.payload;
      const assets = Object.assign({}, state.assets);
      const addedAssets = Object.assign({}, state.addedAssets);
      const deletedAssets = Object.assign({}, state.deletedAssets);
      fileIds.forEach((item) => {
        delete assets[item];
        delete addedAssets[item];
        deletedAssets[`${item}`] = item;
      });
      return {
        ...state,
        assets,
        addedAssets,
        deletedAssets,
      };
    }

    case CLEAN_DELETED_ASSETS: {
      const { fileId } = action.payload;
      const deletedAssets = Object.assign({}, state.deletedAssets);
      delete deletedAssets[fileId];

      return {
        ...state,
        deletedAssets,
      };
    }

    case CLEAN_ADDED_ASSETS: {
      const { fileId } = action.payload;
      const addedAssets = Object.assign({}, state.addedAssets);
      delete addedAssets[fileId];

      return {
        ...state,
        addedAssets,
      };
    }

    default: {
      return state;
    }
  }
};
