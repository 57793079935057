import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';

import { GET_ORDERS } from 'store/orders/actions';
import userSelectors from 'store/user/selectors';
import paths from 'routes/paths';
import { ORDERS_WRITE } from 'config/roles';

import OrderPageLayout from 'components/layout/OrderPageLayout';
import SetPageTitle from 'components/common/SetPageTitle';
import { useErrorStatus } from 'components/hoc/ErrorHandler';

import ReviewSection from './components/sections/ReviewSection';
import CustomerSection from './components/sections/CustomerSection';
import CartSection from './components/sections/CartSection';
import ShipmentSection from './components/sections/ShipmentSection';
import PaymentSection from './components/sections/PaymentSection';
import OverviewSection from './components/sections/OverviewSection';
import QuickLinksSection from './components/sections/QuickLinksSection';
import DeveloperLinksSection from './components/sections/DeveloperLinksSection';
import OrderDetailsSection from './components/sections/OrderDetailsSection';
import ActionsSection from './components/sections/ActionsSection';
import NotesSection from './components/sections/NotesSection';
import { getAPIData } from 'api/playosmo';
import { API } from 'axiosClient/config';
import './styles.scss';

function OrderView({
  match,
  // From Redux
  dispatch,
  userRoles,
  userIsDeveloper,
}) {
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState('1');
  const [order, setOrder] = useState({});
  const [latestRMACreated, setLatestRMACreated] = useState(null);
  const [RMAKeys, setRMAKeys] = useState([]);
  const [RMAs, setRMAs] = useState([]);
  const { setErrorStatusCode } = useErrorStatus();
  const history = useHistory();
  const canEdit = userRoles.includes(ORDERS_WRITE);

  const updateRMAData = (rmaId) => setLatestRMACreated(rmaId);

  const fetchOrder = (id) => {
    setLoading(true);
    dispatch({
      type: GET_ORDERS,
      payload: {
        data: {
          orderID: parseInt(id),
          limit: 100,
          withDetails: true,
        },
        callback: (orders) => {
          setLoading(false);
          setOrder(orders[0]);
        },
        errorCallback: (error) => {
          setErrorStatusCode(error);
          setLoading(false);
        },
      },
    });
  };

  useEffect(() => {
    const orderId = match.params.id;
    // Get the orders based on the parameters
    if (orderId) {
      fetchOrder(orderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, orderStatus]);

  useEffect(() => {
    const fetchRMAs = async () => {
      try {
        const response = await getAPIData(
          `${API.returns}/${order.orderId}?type=order_id`,
        );
        if (!response || response.status > 208) {
          return;
        }
        return response.data.data;
      } catch (e) {
        //
      }
    };

    if (order.orderId) {
      let rmaKeyArray = [];
      fetchRMAs().then((RMA) => {
        setRMAs(RMA);
        RMA &&
          RMA.map((product) => {
            return product.cart.map((cartItems) => {
              return rmaKeyArray.push(cartItems && cartItems.key);
            });
          });
        setRMAKeys(Array.from(new Set(rmaKeyArray)));
      });
    }
  }, [latestRMACreated, order]);
  const getTitle = () =>
    order?.orderId ? `Order #${order?.orderId}` : 'Loading...';

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <OrderPageLayout
        title={getTitle()}
        orderId={order?.orderId?.toString()}
        status={`${order?.status}${
          order?.backorderedCart?.length ? ' (BACKORDERED)' : ''
        }`}
        createdOn={order?.createdOn}
        buttons={
          order?.parentOrderId
            ? [
                {
                  label: 'View parent order',
                  onClick: () =>
                    history.push(
                      `${paths.orders.view}/${order?.parentOrderId}`,
                    ),
                },
              ]
            : order?.childOrderIds
            ? [
                {
                  label: 'View child orders',
                  options: order?.childOrderIds.map((id) => ({
                    label: id,
                    onClick: () => history.push(`${paths.orders.view}/${id}`),
                  })),
                },
              ]
            : null
        }
        isLoading={loading}
        handleClickBack={() =>
          history.length > 2
            ? history.goBack()
            : history.push(paths.orders.list)
        }
      >
        {canEdit && order?.needsReview && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ReviewSection order={order} setOrderStatus={setOrderStatus} />
            </Grid>
          </Grid>
        )}

        {order ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <CustomerSection order={order} canEdit={canEdit} />
              <OrderDetailsSection order={order} />
              <CartSection order={order} />
              <ShipmentSection
                order={order}
                latestRMACreated={latestRMACreated}
                canEdit={canEdit}
                RMAs={RMAs}
              />
              <PaymentSection order={order} />
              <ActionsSection
                order={order}
                canEdit={canEdit}
                updateRMAData={updateRMAData}
                RMAKeys={RMAKeys}
              />
              <NotesSection order={order} canEdit={canEdit} />
            </Grid>
            <Grid item xs={12} md={4}>
              <OverviewSection order={order} />
              <QuickLinksSection order={order} />
              {userIsDeveloper && <DeveloperLinksSection order={order} />}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              Order not found
            </Grid>
          </Grid>
        )}
      </OrderPageLayout>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
  userIsDeveloper: userSelectors.getUserIsDeveloper(state),
});

export default connect(mapStateToProps)(OrderView);
