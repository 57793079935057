import httpClient from '../axiosClient';
import { getAuthorizationUrl } from '../axiosClient/config';

export const getAuthorizationData = async (url) => {
  try {
    const resp = await httpClient.get(getAuthorizationUrl(url));
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const postAuthorizationData = async (url, data) => {
  try {
    const resp = await httpClient.post(getAuthorizationUrl(url), data);
    return resp;
  } catch (e) {
    throw e;
  }
};

export const deleteAuthorizationData = async (url) => {
  try {
    const resp = await httpClient.delete(getAuthorizationUrl(url));
    return resp;
  } catch (e) {
    throw e;
  }
};
