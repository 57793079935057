import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';

import paths from 'routes/paths';
import { UPDATE_EDITING_PRODUCT_VALUES } from 'store/products/actions';
import ExpandableSection from 'components/common/ExpandableSection';
import AddRow from 'components/containers/GamesContainer/GameView/components/GameManualsSection/GameManuals/AddRow';

import Variant from './Variant';

const VariantsSection = ({ product, values, skus, isCreationMode }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const productKey = product?.id || values.product_key;

  const addRow = () => {
    // store the product form values to be able to get them again after variant has been added/edited
    dispatch({
      type: UPDATE_EDITING_PRODUCT_VALUES,
      payload: {
        values,
      },
    });
    history.push({
      pathname: `${paths.catalog.variants.create}/${productKey}`,
      search: `?isProductNew=${isCreationMode}`,
    });
  };

  const renderContent = () => {
    return (
      <>
        <Typography component="p" sx={{ marginBottom: 2, marginTop: 1 }}>
          A product should have at least 1 variant
        </Typography>
        {skus?.map((skuItem) => {
          return (
            <Variant
              key={skuItem.id}
              {...skuItem}
              productKey={productKey}
              values={values}
              isUnsaved={!!skuItem.action}
              isCreationMode={isCreationMode}
            />
          );
        })}
        <AddRow
          onClick={addRow}
          label="Add a variant"
          disabled={!values.product_key}
        />
      </>
    );
  };

  return (
    <ExpandableSection
      id={`variants-${product?.id || 'creation'}`}
      heading={'Variants'}
      content={renderContent()}
      openByDefault={true}
    />
  );
};

export default VariantsSection;
