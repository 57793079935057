export const tableConfig = [
  {
    title: 'Section Name',
    id: 'sectionName',
  },
  {
    title: 'Section Heading',
    id: 'sectionHeading',
  },
  {
    title: 'Actions',
    id: 'actions',
    actions: [
      {
        text: 'Edit',
        type: 'Edit',
      },
      {
        text: 'Delete',
        type: 'Delete',
      },
    ],
  },
];

export const TableCellStates = {
  ACTIONS: 'actions',
  SECTION_NAME: 'sectionName',
  SECTION_HEADING: 'sectionHeading',
};
