export const tabsConfig = [
  {
    title: 'Default',
    id: 'default',
  },
  {
    title: 'Mobile',
    id: 'mobile',
  },
  {
    title: 'Tablet',
    id: 'tablet',
  },
];
