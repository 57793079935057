import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';

import { Grid, Button } from '@mui/material';

import AutoCompleteWrapper from 'components/common/AutoCompleteWrapper';
import TextInput from 'components/common/TextInput';

import './style.scss';
import Badges from '../Badges';
import { CLEANUP_ASSET } from '../store/actions';

export default function AddSku(props) {
  const { handleCancelSku, handleSaveSku, skuDetails } = props;

  const dispatch = useDispatch();
  const [focus, setFocus] = useState(true);

  const handleFormSubmit = (values) => {
    handleSaveSku(values);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.sku || !values.sku.length) {
      errors.sku = 'Required';
    }
    if (!values.percent_off) {
      errors.percent_off = 'Required';
    }
    return errors;
  };

  const cancelAddSku = (values) => {
    if (values.badges && values.badges.length) {
      dispatch({
        type: CLEANUP_ASSET,
        payload: { fileId: values.badges[0].key },
      });
    }
    handleCancelSku();
  };

  return (
    <div className="add-sku">
      <Form
        onSubmit={handleFormSubmit}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className="add-sku-form" noValidate>
            <Grid container>
              <Grid item xs={12}>
                <Field
                  onClick={() => setFocus(true)}
                  focus={focus}
                  multiple={true}
                  limitTags={2}
                  name="sku"
                  label="Select SKU"
                  placeholder="Search SKU"
                  options={skuDetails}
                  selectField="id"
                  component={AutoCompleteWrapper}
                  variant="outlined"
                  getOptionLabel={(option) => {
                    return `${option.name}: ${option.id}`;
                  }}
                  renderDropdownOption={(option) =>
                    option.name + ` (${option['id']})`
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  onClick={() => setFocus(false)}
                  fullWidth
                  required
                  name="percent_off"
                  component={TextInput}
                  type="text"
                  label="Discount (%)"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="badges"
                  component={Badges}
                  label="Badges"
                />
              </Grid>
              <Grid item xs={12} className="button-container">
                <Button
                  className="mr-20"
                  size="small"
                  variant="outlined"
                  type="button"
                  onClick={(e) => cancelAddSku(values)}
                >
                  Cancel
                </Button>
                <Button
                  className="button"
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </div>
  );
}
