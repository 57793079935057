export const tableConfig = [
  {
    title: 'Language',
    id: 'language',
  },
  {
    title: 'Heading',
    id: 'heading',
  },
  {
    title: 'Actions',
    id: 'actions',
    actions: [
      {
        text: 'Edit',
        type: 'Edit',
      },
      {
        text: 'Delete',
        type: 'Delete',
      },
    ],
  },
];

export const TableCellStates = {
  ACTIONS: 'actions',
};
