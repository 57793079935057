function isObjectEmpty(obj) {
  return !Object.keys(obj).length && obj.constructor === Object;
}

function removeEmptyProps(obj, excludeProps) {
  for (let prop in obj) {
    if (!excludeProps.includes(prop)) {
      if (obj[prop] === undefined || obj[prop] === null) {
        delete obj[prop];
      } else if (typeof obj[prop] === 'object') {
        if (
          (Array.isArray(obj[prop]) && !obj[prop].length) ||
          isObjectEmpty(obj[prop])
        ) {
          delete obj[prop];
        } else {
          removeEmptyProps(obj[prop], excludeProps);
        }
      }
    }
  }
}

export default removeEmptyProps;
