import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useSnackbar } from 'notistack';
import { Skeleton, Button, Tooltip, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DisplayPrice } from '@tangibleplay/ecommerce-ui';

import TableComponent from 'components/common/TableComponent';
import CheckboxComponent from 'components/common/CheckboxComponent';
import TextInput from 'components/common/TextInput';

import { tableConfig, TableCellStates } from './tableConfig';
import { SEVERITY } from 'config';
import { deleteProductManagement, putProductManagement } from 'api/products';
import { API } from 'axiosClient/config';
import ModalComponent from 'components/common/ModalComponent';
import Confirmation from 'components/common/Confirmation';

const ShippingMethodsTable = (props) => {
  const { rows, isLoading, disablePagination, rowCount, canEdit, reloadData } =
    props;
  const rowsWithKeys = rows.map((row) => ({ ...row, id: row.id }));
  const { enqueueSnackbar } = useSnackbar();
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const initialValues = rows.reduce((acc, row) => {
    return {
      ...acc,
      [row.shippingMethodCode + row.countryCode]: {
        [TableCellStates.METHOD]: row.shippingMethodCode,
        [TableCellStates.COUNTRY]: row.countryCode,
        [TableCellStates.NEW_THRESHOLD]: row.displayAsNewThreshold || false,
        [TableCellStates.THRESHOLD]: row.freeShippingThreshold,
        [TableCellStates.INCREMENT]: row.incrementPrice,
        [TableCellStates.DELAY]: row.delayDays,
        [TableCellStates.PRICE]: row.basePrice,
      },
    };
  }, {});

  const options = {
    isRowSelectable: false,
  };

  const handleSave = async (id, values, form) => {
    const rowValues = values[id];
    const payload = {
      shipping_method_code: rowValues[TableCellStates.METHOD],
      delay_days: rowValues[TableCellStates.DELAY]
        ?.replace(' ', '')
        .split('-')
        .map((numb) => parseInt(numb)),
      base_price: rowValues[TableCellStates.PRICE]
        ? parseInt(rowValues[TableCellStates.PRICE])
        : 0,
      increment_price: rowValues[TableCellStates.INCREMENT]
        ? parseInt(rowValues[TableCellStates.INCREMENT])
        : 0,
      free_shipping_threshold: rowValues[TableCellStates.THRESHOLD]
        ? parseInt(rowValues[TableCellStates.THRESHOLD])
        : 0,
      display_as_new_threshold: rowValues[TableCellStates.NEW_THRESHOLD],
    };

    try {
      await putProductManagement(
        API.products.shippingMethods.put(
          rowValues[TableCellStates.COUNTRY],
          payload.shipping_method_code,
        ),
        payload,
      );
      enqueueSnackbar('Shipping method successfully updated', {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      const errorMessage =
        e.response.data && typeof e.response.data === 'string'
          ? e.response.data
          : e.response.data?.error?.message;
      if (errorMessage) {
        enqueueSnackbar(errorMessage, {
          variant: SEVERITY.ERROR,
        });
      }
      throw e;
    }

    form.submit();
  };

  const handleCopy = async (id) => {
    // TODO: https://playosmo.atlassian.net/browse/ADMIN-386
  };

  const handleDelete = async () => {
    const { id, values } = showDeleteModal;
    const rowValues = values[id];
    try {
      await deleteProductManagement(
        API.products.shippingMethods.delete(
          rowValues[TableCellStates.COUNTRY],
          rowValues[TableCellStates.METHOD],
        ),
      );
      enqueueSnackbar('Shipping method successfully deleted', {
        variant: SEVERITY.SUCCESS,
      });
      reloadData();
      setShowDeleteModal(null);
    } catch (e) {
      const errorMessage =
        e.response.data && typeof e.response.data === 'string'
          ? e.response.data
          : e.response.data?.error?.message;
      if (errorMessage) {
        enqueueSnackbar(errorMessage, {
          variant: SEVERITY.ERROR,
        });
      }
      throw e;
    }
  };

  const deleteConfirmConfig = {
    title: 'Are you sure you want to delete this country?',
    buttonGroup: [
      {
        text: 'Cancel',
        type: 'CANCEL',
        handler: () => setShowDeleteModal(null),
      },
      {
        text: 'Confirm',
        type: 'CONFIRM',
        handler: handleDelete,
      },
    ],
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index, cellAdditionalProps) => {
          if (!canEdit) {
            return null;
          }

          const { values, form } = cellAdditionalProps;

          const id = record.shippingMethodCode + record.countryCode;

          return (
            <div>
              <Tooltip title="Copy" disableInteractive>
                <IconButton onClick={() => handleCopy(id)} size="large">
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" disableInteractive>
                <IconButton
                  onClick={() => setShowDeleteModal({ id, values })}
                  size="large"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Button
                key={`${id}-${index}`}
                onClick={() => handleSave(id, values, form)}
                color="primary"
                variant="text"
                type="submit"
              >
                Save
              </Button>
            </div>
          );
        };
        return cell;

      case TableCellStates.COUNTRY:
        cell.render = (record, index) => {
          return <strong>{record[TableCellStates.COUNTRY]}</strong>;
        };
        return cell;

      case TableCellStates.METHOD:
        cell.render = (record, index) => {
          return <span>{record[TableCellStates.METHOD]}</span>;
        };
        return cell;

      case TableCellStates.NEW_THRESHOLD:
        cell.render = (record, index) => {
          return (
            <div>
              <Field
                disableTypography
                disabled={!canEdit}
                type="checkbox"
                name={`${record.shippingMethodCode + record.countryCode}.${
                  TableCellStates.NEW_THRESHOLD
                }`}
                component={CheckboxComponent}
                color="primary"
              />
            </div>
          );
        };
        return cell;

      case TableCellStates.PRICE:
      case TableCellStates.INCREMENT:
      case TableCellStates.DELAY:
      case TableCellStates.THRESHOLD:
        cell.render = (record, index) => {
          if (canEdit) {
            return (
              <Field
                variant="standard"
                component={TextInput}
                name={`${record.shippingMethodCode + record.countryCode}.${
                  cell.id
                }`}
              />
            );
          }

          return record[cell.id] ? (
            <DisplayPrice price={record[cell.id]} includeMicroData={false} />
          ) : (
            '-'
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  if (isLoading) {
    return <Skeleton height={300} animation="wave" variant="rectangular" />;
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={() => {}}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <TableComponent
              rows={rowsWithKeys}
              headCells={headCells}
              options={options}
              disablePagination={disablePagination}
              rowCount={rowCount}
              size="small"
              cellAdditionalProps={{ form, values }}
            />
          </form>
        )}
      />
      <ModalComponent
        title="Confirm deletion"
        open={!!showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
      >
        <Confirmation envConfirmConfig={deleteConfirmConfig} />
      </ModalComponent>
    </>
  );
};

export default ShippingMethodsTable;
