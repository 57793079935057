import { Field } from 'react-final-form';
import { Grid, Paper, Typography } from '@mui/material';
import SwitchInput from 'components/common/SwitchInput';

const StatusSection = ({ inputBase }) => {
  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Status
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <Field
            component={SwitchInput}
            name="is_enabled"
            label="Is active"
            disabled={inputBase.disabled}
          />
          <Field
            component={SwitchInput}
            name="is_shipping_available"
            label="Is shipping available"
            disabled={inputBase.disabled}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StatusSection;
