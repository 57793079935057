import { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Grid, Skeleton } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import qs from 'query-string';

import { USERS_ACCOUNTS_WRITE } from 'config/roles';
import userSelectors from 'store/user/selectors';

import { SEVERITY } from 'config';
import { getAccount } from 'api/auth';
import { API } from 'axiosClient/config';
import normalizeCreditBalances from 'utils/normalizers/api/normalizeCreditBalances';

import PageContentLayout from 'components/layout/PageContentLayout';
import SetPageTitle from 'components/common/SetPageTitle';

import BalancesList from './components/BalancesList';
import CreateTransactionSection from './components/CreateTransactionSection';
import SearchSection from './components/SearchSection';
import NoData from '../../../common/NoData';

const CreditsView = ({
  // Redux
  userRoles,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [creditsData, setCreditsData] = useState(null);
  const [noDataReturned, setNoDataReturned] = useState(false);
  const canWrite = userRoles.includes(USERS_ACCOUNTS_WRITE);
  const getTitle = () => 'Credits';
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const accountIdFromURL = urlParams.get('accountId');

  const fetchBalances = useCallback(
    async (id) => {
      try {
        const response = await getAccount(
          `${
            API.microservices.account.credits.balances
          }?account_id=${encodeURIComponent(id)}`,
        );

        if (!response || response.status > 208) {
          setIsLoading(false);
          return;
        }
        setNoDataReturned(false);
        return normalizeCreditBalances(response.data.data);
      } catch (e) {
        setNoDataReturned(true);
        enqueueSnackbar(
          `An error occurred when fetching the balances: ${e.response.data.error.message}`,
          {
            variant: SEVERITY.ERROR,
          },
        );
        setIsLoading(false);
        throw e;
      }
    },
    [enqueueSnackbar, setIsLoading],
  );

  const fetchData = useCallback(
    async (id) => {
      const balances = await fetchBalances(id);
      setCreditsData(balances);
      setIsLoading(false);
    },
    [setCreditsData, setIsLoading, fetchBalances],
  );

  useEffect(() => {
    if (accountIdFromURL) {
      fetchData(accountIdFromURL);
    }
  }, [accountIdFromURL, fetchData]);

  const handleFormSubmit = (values) => {
    if (values.account_id) {
      setIsLoading(true);
      const queryParams = qs.parse(location.search);
      const newQueries = { ...queryParams, accountId: values.account_id };
      history.push({ search: qs.stringify(newQueries) });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <PageContentLayout title={getTitle()}>
        <Grid container className="mb-20">
          <Grid item xs={12}>
            <SearchSection
              setIsLoading={setIsLoading}
              setCreditsData={setCreditsData}
              onSubmit={handleFormSubmit}
              accountIdFromURL={accountIdFromURL}
              setNoDataReturned={setNoDataReturned}
            />
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid container>
            <Grid item xs={12}>
              <Skeleton height={800} variant="rect" />
            </Grid>
          </Grid>
        ) : !!creditsData && !noDataReturned ? (
          <>
            <Grid container>
              <Grid item xs={12}>
                <BalancesList
                  data={creditsData}
                  setCreditsData={setCreditsData}
                />
              </Grid>
            </Grid>
            {canWrite && (
              <Grid container className="mb-20">
                <Grid item xs={12} sm={8}>
                  <CreateTransactionSection
                    reloadData={() => fetchData(accountIdFromURL)}
                    balances={creditsData}
                  />
                </Grid>
              </Grid>
            )}
          </>
        ) : noDataReturned ? (
          <NoData />
        ) : null}
      </PageContentLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(CreditsView);
