import React from 'react';
import { Form, Field } from 'react-final-form';

import { Grid, Button, MenuItem, TextField } from '@mui/material';

import TextInput from 'components/common/TextInput';

import { ENVIRONMENTS, getCurrentEnvironment } from 'config/environments';
import { getAPIData } from 'api/playosmo';
import { API } from 'axiosClient/config';

export default function DuplicatePromocode(props) {
  const { cancelDuplicatePromocode, duplicatePromocode, formDetails } = props;
  let environments = [...ENVIRONMENTS];
  const currentEnv = getCurrentEnvironment();
  environments = environments.filter((env) => {
    return env !== currentEnv;
  });

  const getCoupon = async (couponId) => {
    const response = await getAPIData(API.coupons + `/${couponId}`);
    return response.data;
  };

  const handleFormSubmit = (values) => {
    let couponId = formDetails.coupon_id;
    getCoupon(couponId).then((couponData) => {
      duplicatePromocode(values, formDetails, couponData);
    });
  };

  const SelectInput = ({ input, label }) => (
    <TextField
      className="duplicate-campaign-form"
      select
      name={input.name}
      onChange={(value) => input.onChange(value)}
      variant="outlined"
      label={label}
    >
      {environments.map((item) => (
        <MenuItem key={item} value={item} name={item}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
  return (
    <div className="duplicate-campaign">
      <Form
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, values }) => (
          <form
            onSubmit={handleSubmit}
            className="duplicate-campaign-form"
            noValidate
          >
            <Grid container>
              <Grid item xs={12} className="row">
                <Field
                  fullWidth
                  required
                  name="environment"
                  component={SelectInput}
                  type="text"
                  label="Environment"
                />
              </Grid>
              {values.environment === currentEnv && (
                <Grid item xs={12} className="row">
                  <Field
                    fullWidth
                    name="campaign"
                    component={TextInput}
                    type="text"
                    label="New campaign Key"
                    variant="outlined"
                  />
                </Grid>
              )}
              <Grid item xs={12} className="button-container">
                <Button
                  className="mr-20"
                  size="small"
                  variant="outlined"
                  type="button"
                  onClick={cancelDuplicatePromocode}
                >
                  Cancel
                </Button>
                <Button
                  className="button"
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </div>
  );
}
