const formatBannerFormData = (data) => {
  if (!data) {
    return {};
  }

  return {
    languages: data.languages?.length ? data.languages : null,
    countries: data.countries?.length ? data.countries : null,
    starts_on: data.dateRange.start,
    ends_on: data.dateRange.end,
    message: data.message,
    description: data.description,
  };
};

export default formatBannerFormData;
