import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
  Divider,
} from '@mui/material';
import {
  ArrowForwardIosOutlined,
  InsertLink,
  SearchRounded,
} from '@mui/icons-material';

import paths from 'routes/paths';
import {
  MARKETING_READ,
  MARKETING_WRITE,
  ORDERS_READ,
  ORDERS_WRITE,
  SHORTLINK_READ,
  USERS_ACCOUNTS_READ,
} from 'config/roles';
import userSelectors from 'store/user/selectors';
import SearchInput from 'components/common/SearchInput';
import Loader from 'components/common/Loader';
import { postAPIData } from 'api/playosmo';
import { API } from 'axiosClient/config';
import { UpcomingCampaignListing } from '../UpcomingCampaignListing';
import { useSnackbar } from 'notistack';
import { ORDER_ACTION_ERROR } from 'config/messages';
import { SEVERITY } from 'config';
import './index.scss';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import StoreIcon from '@mui/icons-material/Store';
import PeopleIcon from '@mui/icons-material/People';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

const quickLinksObject = [
  {
    name: 'Campaigns',
    writeLink: paths.marketing.campaigns.create,
    writeRole: MARKETING_WRITE,
    readLink: paths.marketing.campaigns.list,
    readRole: MARKETING_READ,
    icon: <RecordVoiceOverIcon />,
  },
  {
    name: 'Promo Codes',
    writeLink: paths.marketing.promocodes.create,
    writeRole: MARKETING_WRITE,
    readLink: paths.marketing.promocodes.list,
    readRole: MARKETING_READ,
    icon: <ConfirmationNumberIcon />,
  },
  {
    name: 'Text Banners',
    writeLink: paths.content.textBanners.create,
    writeRole: MARKETING_WRITE,
    readLink: paths.content.textBanners.list,
    readRole: MARKETING_READ,
    icon: <AnnouncementIcon />,
  },
  {
    name: 'Orders',
    writeLink: paths.orders.create,
    writeRole: ORDERS_WRITE,
    readLink: paths.orders.list,
    readRole: ORDERS_READ,
    icon: <StoreIcon />,
  },
  {
    name: 'Short Links',
    readLink: paths.marketing.shortlinks.list,
    readRole: SHORTLINK_READ,
    icon: <InsertLink />,
  },
  {
    name: 'Accounts',
    readLink: paths.users.accounts.list,
    readRole: USERS_ACCOUNTS_READ,
    icon: <PeopleIcon />,
  },
  {
    name: 'Credits',
    readLink: paths.users.credits,
    readRole: USERS_ACCOUNTS_READ,
    icon: <LocalAtmIcon />,
  },
];

const DashboardView = (props) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const firstName = props.userInfo.first_name;
  const todayDate = new Date().toISOString().slice(0, 10);
  const userRoles = props.userRoles;
  const canViewLinks =
    userRoles.includes(MARKETING_WRITE) || userRoles.includes(ORDERS_WRITE);

  const searchOrder = (orderId) => {
    if (orderId === '') {
    } else {
      if (Number(orderId)) {
        setLoading(true);
        getOrderDetails(Number(orderId));
      } else {
        enqueueSnackbar(`Please input an Order ID`, {
          variant: SEVERITY.ERROR,
        });
      }
    }
  };

  const getOrderDetails = async (orderId) => {
    try {
      const response = await postAPIData(API.orders.query, {
        order_number: orderId,
      });
      if (!response || response.status > 208) {
        setLoading(false);
        return;
      }
      setLoading(false);

      if (!response.data.orders.length) {
        enqueueSnackbar(`The Order does not exist`, {
          variant: SEVERITY.ERROR,
        });
      } else {
        window.location.href = `${paths.orders.view}/${response.data.orders[0].order_number}`;
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(`${ORDER_ACTION_ERROR} : ${e}`, {
        variant: SEVERITY.ERROR,
      });
    }
  };

  return (
    <>
      <Typography variant="h1" gutterBottom>
        Hello, {firstName}!
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Date: {todayDate}
      </Typography>
      <Divider className="mb-40 mt-20" />
      {canViewLinks ? (
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h2" gutterBottom>
            Quick Links
          </Typography>
          <Grid container spacing={2}>
            {quickLinksObject.map((linkObj) => {
              if (userRoles.includes(linkObj.readRole)) {
                return (
                  <Grid item xs={3} sm={3} key={linkObj.name}>
                    <Card elevation={0} className="dashboard-card">
                      <CardContent className="dashboard-card-heading">
                        <Typography
                          variant="h4"
                          color="text.secondary"
                          gutterBottom
                        >
                          {linkObj.icon}
                        </Typography>
                        <Typography
                          variant="h3"
                          color="text.secondary"
                          gutterBottom
                        >
                          {linkObj.name}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          endIcon={<SearchRounded />}
                          color="grey"
                          href={linkObj.readLink}
                        >
                          View
                        </Button>
                        {linkObj.writeLink &&
                        userRoles.includes(linkObj.writeRole) ? (
                          <Button
                            variant="contained"
                            endIcon={<ArrowForwardIosOutlined />}
                            color="grey"
                            href={linkObj.writeLink}
                          >
                            Create
                          </Button>
                        ) : (
                          ''
                        )}
                      </CardActions>
                      {linkObj.name === 'Orders' ? (
                        <CardActions>
                          <SearchInput
                            variant="outlined"
                            onSubmit={(e) => searchOrder(e)}
                            placeholder="Search by Order ID"
                          />
                          <br />
                          {loading ? <Loader /> : <></>}
                        </CardActions>
                      ) : (
                        ''
                      )}
                    </Card>
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
          <Divider className="mb-40 mt-20" />
          <UpcomingCampaignListing />
        </Box>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userInfo: userSelectors.getUserInfo(state),
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(DashboardView);
