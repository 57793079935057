import React from 'react';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import clsx from 'clsx';

import './styles.scss';

export default function TableToolbar(props) {
  const { selectedRowsCount, title, toolBarData } = props;

  return (
    <Toolbar
      className={clsx('toolbar-container', {
        'toolbar-container--highlight': selectedRowsCount > 0,
      })}
    >
      {selectedRowsCount > 0 ? (
        <>
          <Typography color="inherit" variant="subtitle1" component="div">
            {selectedRowsCount} selected
          </Typography>
          {toolBarData.map((data, i) => (
            <React.Fragment key={i}>
              {data.render && data.onSelect ? data.render() : null}
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          {!!title && (
            <Typography
              className="toolbar-container-title"
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {title}
            </Typography>
          )}
          {toolBarData.map((data, i) => (
            <React.Fragment key={i}>
              {data.render && !data.onSelect ? data.render() : null}
            </React.Fragment>
          ))}
        </>
      )}
    </Toolbar>
  );
}
