import { Button, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import SelectComponent from 'components/common/SelectDropdown/SelectComponent';

import './styles.scss';

const SelectInputStandalone = ({
  buttonDisabled,
  onResetClick,
  options,
  input: inputProps,
  ...props
}) => {
  return (
    <div className="select-input-standalone">
      <SelectComponent
        {...props}
        dataItems={options}
        inputProps={inputProps}
        className="select-input-standalone-input"
      />
      <div className="select-input-standalone-buttons">
        {!buttonDisabled && onResetClick && (
          <IconButton onClick={onResetClick} size="small" className="mr-5">
            <CloseIcon />
          </IconButton>
        )}
        <Button
          className="select-input-standalone-button"
          size="small"
          variant="contained"
          type="submit"
          disabled={buttonDisabled}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default SelectInputStandalone;
