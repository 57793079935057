export const TableCellStates = {
  CODE: 'code',
  NAME: 'name',
  STATUS: 'status',
  EDIT: 'edit',
  ID: 'id',
  LOCATION: 'location',
  DESTINATION: 'destination',
  OPERATED_BY: 'operatedBy',
};

export const tableConfig = [
  {
    title: 'Name',
    id: TableCellStates.NAME,
  },
  {
    title: 'Code',
    id: TableCellStates.CODE,
  },
  {
    title: 'Location',
    id: TableCellStates.LOCATION,
  },
  {
    title: 'Operated by',
    id: TableCellStates.OPERATED_BY,
  },
  {
    title: 'Destination',
    id: TableCellStates.DESTINATION,
  },
  {
    title: 'Status',
    id: TableCellStates.STATUS,
  },
  {
    title: '',
    id: TableCellStates.EDIT,
  },
];
