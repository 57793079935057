const formatVariantTranslationsForAPI = (translations, isCreation) => {
  const translationsOutput = [];
  Object.entries(translations).forEach(([key, value]) => {
    Object.entries(value).forEach(([website, translations]) => {
      const tabs = translations.tabs?.reduce((acc, tab, i) => {
        return {
          ...acc,
          [`tab_${i + 1}`]: tab,
        };
      }, {});

      translationsOutput.push({
        action: !isCreation ? 'update' : undefined, // What about "delete"?
        language: key,
        website,
        is_ready: true,
        translations: {
          metadata: {
            title: translations.google_merchant_feed_desc,
            content: translations.google_merchant_feed_desc,
          },
          ...tabs,
          top_blurb: {
            content: translations.top_blurb,
          },
          one_liner: {
            content: translations.one_liner,
          },
        },
      });
    });
  });
  return translationsOutput;
};

const formatProductTranslationsForAPI = (values, isCreation) => {
  const translationsOutput = [];
  Object.entries(values).forEach(([key, value]) => {
    // At the moment, only the language keys have a - in them
    // TODO: look through a list of predefined language codes (we don't have that list yet)
    if (key.includes('-')) {
      Object.entries(value).forEach(([website, translations]) => {
        translationsOutput.push({
          action: !isCreation ? 'update' : undefined, // What about "delete"?
          language: key,
          website,
          is_ready: true,
          translations: {
            google_merchant: {
              content: translations.google_merchant_feed_desc,
            },
            seo: {
              title: translations.seo_title,
              content: translations.seo_description,
            },
            twitter: {
              title: translations.twitter_title,
              content: translations.twitter_description,
              image: translations.twitter_preview_url,
            },
            fb: {
              title: translations.fb_title,
              content: translations.fb_description,
              image: translations.fb_preview_url,
            },
          },
        });
      });
    }
  });
  return translationsOutput;
};

const formatProductVariantsForAPI = (values, variants, isCreation) => {
  const getMedias = (variant) => {
    const compliance = [];
    if (variant.complianceDoc) {
      compliance.push({
        action: 'update',
        media_type: 'compliance',
        key: 'doc',
        url: variant.complianceDoc,
        mime_type: 'application/pdf',
      });
    }
    if (variant.complianceRender) {
      compliance.push({
        action: 'update',
        media_type: 'compliance',
        key: 'image',
        url: variant.complianceRender,
        mime_type: 'image',
      });
    }

    const medias = Object.entries(variant.medias).reduce(
      (acc, [key, value]) => {
        const newMedias = [];
        Object.entries(value).forEach(([mediaKey, media]) => {
          if (media.action) {
            newMedias.push({
              action: media.action,
              media_type: key,
              key: mediaKey,
              mime_type: media.mimeType,
              url: media.url,
              metadata: {
                alt: media.alt,
                open_in_popup: media.open_in_popup,
                youtube_id: media.youtube_id,
                cloudflare_id: media.cloudflare_id,
              },
            });
          }
        });

        return [...acc, ...newMedias];
      },
      [],
    );

    return [...medias, ...compliance];
  };

  const variantsOutput = variants
    .filter((variant) => !!variant.action)
    .map((variant) => ({
      action: variant.action,
      sku_key: variant.skuKey,
      sku_id: variant.sku,
      name: variant.name,
      type: variant.type,
      note: variant.note,
      is_proflector_included: variant.isProflectorIncluded,
      is_invisible: variant.isInvisible,
      is_frontend_active: variant.isFrontendActive,
      is_deprecated: variant.isDeprecated,
      is_ready: variant.isReady,
      order_limit: parseInt(variant.orderLimit),
      websites: variant.websites,
      warehouse_codes: variant.warehouses,
      bases_included: variant.basesIncluded,
      storages_included: variant.storageIncluded,
      subscription_frequency: variant.subscriptionFrequency,
      subscription_type: variant.subscriptionType,
      external_provider_code: variant.externalProviderCode,
      external_provider_url: variant.externalProviderUrl,
      medias: variant.medias && getMedias(variant),
      physical_sku_keys: variant.physicalSkuKeys,
      inventory: variant.inventory,
      pricing: variant.pricing,
      translations: formatVariantTranslationsForAPI(
        variant.translations,
        isCreation,
      ),
    }));

  return variantsOutput;
};

const formatProductForAPI = (values, variants, isCreation) => {
  return {
    product_key: values.product_key,
    name: values.name,
    shopping_name: values.shopping_name,
    brand: values.brand,
    age_range: values.age_range
      ? values.age_range.split('-').map((age) => parseInt(age))
      : undefined,
    is_ready: values.is_ready,
    is_digital: values.is_digital,
    is_bundle: values.is_bundle,
    url_paths: {
      playosmo: values.playosmo_path,
      byjus_learning: values.byjus_path,
    },
    analytics_category: values.analytics_category,
    redeem_type: values.redeem_type,
    avalara_tax_code: values.tax_code,
    grades: values.grades,
    websites: values.websites,
    workbook_count: values.workbook_count && parseInt(values.workbook_count),
    hardware_name: values.hardware_name,
    subscriptions_included: [values.subscriptions_included],
    games: values.games_included?.map((gameId) => ({
      game_id: gameId,
      is_marketed: values.marketed_games.includes(gameId),
    })),
    require_hardware: values.hardware_required,
    skus: formatProductVariantsForAPI(values, variants, isCreation),
    translations: formatProductTranslationsForAPI(values, isCreation),
  };
};

export default formatProductForAPI;
