import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { Grid, Button, CircularProgress, Typography } from '@mui/material';

import { normalizeImportedOrder } from 'utils';
import sources from 'enums/orders/sources';
import shippingMethods from 'enums/orders/shippingMethods';

import generalSelectors from 'store/general/selectors';
import { FETCH_SKU_DETAILS } from 'store/general/actions';
import { postAPIData } from 'api/playosmo';
import { API } from 'axiosClient/config';

import ErrorDisplay from 'components/common/DisplayError';
import TextInput from 'components/common/TextInput';
import SelectDropdown from 'components/common/SelectDropdown';
import AutoCompleteWrapper from 'components/common/AutoCompleteWrapper';
import CheckboxComponent from 'components/common/CheckboxComponent';
import CartItem from 'components/common/CartItem';
import { getWebsiteDataItem } from 'components/containers/CampaignContainer/CampaignListing/CampaignFilters/filters';

import './styles.scss';

const OrderForm = (props) => {
  const {
    form,
    values,
    purchasableProducts,
    onCountryChange,
    // From Redux
    shippingCountries,
    dispatch,
  } = props;

  const [isImporting, setIsImporting] = useState(false);
  const [importError, setImportError] = useState(null);
  const websiteDataItems = getWebsiteDataItem();

  useEffect(() => {
    dispatch({
      type: FETCH_SKU_DETAILS,
      payload: {
        includeAdminSkus: true,
        callback: () => {},
        errorCallback: (error) => {},
      },
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: FETCH_SKU_DETAILS,
      payload: {
        includeAdminSkus: true,
        country: values.country,
        callback: () => {},
        errorCallback: (error) => {},
      },
    });
    onCountryChange(values.country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.country, dispatch]);

  const importOrder = async () => {
    setImportError(null);
    setIsImporting(true);
    try {
      const payload = {
        order_number: values.import_order_id
          ? parseInt(values.import_order_id)
          : null,
        include_details: true,
      };

      const response = await postAPIData(API.orders.query, payload);

      if (!response || response.status > 208) {
        return;
      }

      const orderResponse = response.data?.orders[0];

      if (orderResponse) {
        dispatch({
          type: FETCH_SKU_DETAILS,
          payload: {
            includeAdminSkus: true,
            country: orderResponse.country,
            callback: (res) => {
              const order = normalizeImportedOrder(orderResponse, res.products);
              Object.entries(order).forEach(([key, value]) => {
                form.change(key, value);
              });
            },
            errorCallback: (error) => {},
          },
        });

        setIsImporting(false);
      } else {
        setIsImporting(false);
        setImportError('Order not found');
      }
    } catch (e) {
      setIsImporting(false);
      setImportError('Error: ' + e.response.data);
    }
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Import an existing order
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <Field
            fullWidth
            name="import_order_id"
            component={TextInput}
            type="number"
            label="Order ID"
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            disabled={!values?.import_order_id?.length || isImporting}
            onClick={importOrder}
          >
            Import
          </Button>
        </Grid>
        {(isImporting || importError) && (
          <Grid item>
            {isImporting && <CircularProgress size={24} />}
            {importError && <ErrorDisplay message={importError} />}
          </Grid>
        )}
      </Grid>

      <Typography variant="h2" gutterBottom className="mt-40">
        Order
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Field
            name="country"
            label="Shipping country"
            dataItems={shippingCountries}
            component={SelectDropdown}
            variant="outlined"
            required
          />
        </Grid>
        {!!values.country?.length && (
          <Grid item xs={12} sm={6} md={3}>
            <Field
              name="source"
              label="Source"
              dataItems={sources}
              component={SelectDropdown}
              variant="outlined"
            />
          </Grid>
        )}
      </Grid>
      {!!values.country?.length && (
        <>
          <Typography variant="h3" className="mt-25" gutterBottom>
            Cart
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                multiple={true}
                limitTags={4}
                name="skus"
                label="SKUs*"
                placeholder="Select SKUs"
                options={purchasableProducts}
                selectField="id"
                component={AutoCompleteWrapper}
                variant="outlined"
                getOptionLabel={(option) => {
                  return option.id;
                }}
                renderDropdownOption={(option) =>
                  option.name + ` (${option['id']})`
                }
                searchByNameAndID={true}
              />
              <div className="order-form-cart mt-20">
                {values.skus?.map((sku) => (
                  <CartItem sku={sku} key={sku} required={true} />
                ))}
              </div>
            </Grid>
          </Grid>
          <Typography variant="h3" className="mt-25" gutterBottom>
            Order Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="email"
                component={TextInput}
                type="email"
                label="Email address*"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="description"
                component={TextInput}
                type="text"
                label="Description*"
                placeholder="Reason or reference"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                fullWidth
                name="price_paid"
                component={TextInput}
                type="number"
                label="Total Paid"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                fullWidth
                name="shipping_cost"
                component={TextInput}
                type="number"
                label="Shipping/Tax Paid"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                fullWidth
                name="coupon"
                component={TextInput}
                type="text"
                label="Coupon code"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                name="website"
                label="Website"
                dataItems={websiteDataItems}
                component={SelectDropdown}
                variant="outlined"
              />
            </Grid>
            {values.source === 'po' && (
              <Grid item xs={12} sm={6} md={3}>
                <Field
                  fullWidth
                  name="po_number"
                  component={TextInput}
                  type="text"
                  label="Purchase order"
                  placeholder="Purchase Order Number"
                  variant="outlined"
                />
              </Grid>
            )}
          </Grid>
          <Typography variant="h3" className="mt-25" gutterBottom>
            Shipping Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                name="shipping_method"
                label="Shipping Method"
                dataItems={shippingMethods}
                component={SelectDropdown}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                fullWidth
                name="zip"
                label="Shipping zip code*"
                component={TextInput}
                type="text"
                variant="outlined"
                required
              />
            </Grid>
            {!!values.shipping &&
              Object.entries(values.shipping).map(([key, value]) => (
                <Grid key={key} item xs={12} sm={6} md={3}>
                  <Field
                    fullWidth
                    name={`shipping.${key}`}
                    label={key}
                    component={TextInput}
                    type="text"
                    variant="outlined"
                  />
                </Grid>
              ))}
          </Grid>
          {!!values.billing && (
            <>
              <Typography variant="h3" className="mt-25" gutterBottom>
                Billing Information
              </Typography>
              <Grid container spacing={2}>
                {Object.entries(values.billing).map(([key, value]) => (
                  <Grid key={key} item xs={12} sm={6} md={3}>
                    <Field
                      fullWidth
                      name={`billing.${key}`}
                      label={key}
                      component={TextInput}
                      type="text"
                      variant="outlined"
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Typography variant="h3" className="mt-25" gutterBottom>
            Other
          </Typography>
          <Typography variant="h4">Send Emails</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Field
                name="send_emails"
                component={CheckboxComponent}
                label="Confirmation & follow up"
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  shippingCountries: generalSelectors.getShippingCountries(state),
});

export default connect(mapStateToProps)(OrderForm);
