import React from 'react';

import { TableBody, TableCell, TableRow, Checkbox } from '@mui/material';

import { stableSort, getComparator } from 'utils';

export default function TableBodyComponent(props) {
  const {
    rows,
    headCells,
    order,
    orderBy,
    pageNumber,
    rowsPerPage,
    selected,
    isRowSelectable,
    handleClick,
    selectionKey,
    pagination,
    resetPageNumber,
    totalRowCount,
    cellAdditionalProps,
  } = props;

  if (!rows) {
    return null;
  }

  const isSelected = (name) => selected && selected.indexOf(name) !== -1;
  const filteredData =
    !pagination || totalRowCount > rows.length
      ? stableSort(rows, getComparator(order, orderBy))
      : stableSort(rows, getComparator(order, orderBy)).slice(
          pageNumber * rowsPerPage,
          pageNumber * rowsPerPage + rowsPerPage,
        );

  if (!filteredData?.length && pageNumber !== 0) {
    resetPageNumber();
  }

  return (
    <TableBody>
      {filteredData.map((row, index) => {
        const isItemSelected = isSelected(
          row[selectionKey] ? row[selectionKey] : index,
        );
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id + index}
            selected={isItemSelected}
          >
            {isRowSelectable && (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isItemSelected}
                  onClick={(event) =>
                    handleClick(
                      event,
                      row[selectionKey] ? row[selectionKey] : index,
                    )
                  }
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
            )}

            {headCells.map((cells, i) => (
              <TableCell
                key={cells.id + i + index}
                component="th"
                id={labelId}
                scope="row"
                style={cells.style}
              >
                {cells.render
                  ? cells.render(row, index, cellAdditionalProps)
                  : row[cells.id]}
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
}
