import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { Paper, Typography } from '@mui/material';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';
import generalSelectors from 'store/general/selectors';
import AutoCompleteWrapper from 'components/common/AutoCompleteWrapper';
import CheckboxComponent from 'components/common/CheckboxComponent';

const GeneralSection = ({
  inputBase,
  // From Redux
  skills,
  platforms,
}) => {
  const platformDataItems =
    platforms?.map((platform) => ({
      id: platform.code,
      name: platform.name,
    })) || [];
  const skillDataItems =
    skills?.map((skill) => ({
      id: skill.code,
      name: skill.name,
    })) || [];

  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        General
      </Typography>
      <OrderPropertiesWrapper>
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'id',
            required: true,
          }}
          label="ID"
        />
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'name',
            required: true,
          }}
          label="Name"
        />
      </OrderPropertiesWrapper>
      <div className="mt-20">
        <Typography variant="h3" gutterBottom>
          Platforms
        </Typography>
        <Field
          required
          name="platforms"
          label="Select compatible platforms"
          variant="outlined"
          multiple={true}
          limitTags={10}
          placeholder="Search for platforms"
          options={platformDataItems}
          selectField="id"
          component={AutoCompleteWrapper}
          getOptionLabel={(opt) => opt.name}
          renderDropdownOption={(opt) => opt.name}
          {...inputBase}
        />
        <Field
          name="is_fire8_compatible"
          component={CheckboxComponent}
          label="Compatible with Fire 8"
          {...inputBase}
        />
        <Field
          name="is_fire_proflector_compatible"
          component={CheckboxComponent}
          label="Compatible with Fire proflector"
          {...inputBase}
        />
      </div>
      <div className="mt-20">
        <Typography variant="h3" gutterBottom>
          Skills
        </Typography>
        <Field
          required
          name="skills"
          label="Select skills for this game"
          variant="outlined"
          multiple={true}
          limitTags={10}
          placeholder="Search for skills"
          options={skillDataItems}
          selectField="id"
          noSelectAll={true}
          component={AutoCompleteWrapper}
          getOptionLabel={(opt) => opt.name}
          renderDropdownOption={(opt) => opt.name}
          {...inputBase}
        />
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  skills: generalSelectors.getSkillReferences(state),
  platforms: generalSelectors.getPlatformReferences(state),
});

export default connect(mapStateToProps)(GeneralSection);
