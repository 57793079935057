import React, { useEffect, useState } from 'react';
import { Button, FormControlLabel, FormGroup } from '@mui/material';
import { Checkbox } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';

import TableComponent from 'components/common/TableComponent';
import ToolTip from 'components/common/Tooltip';
import PageContentLayout from 'components/layout/PageContentLayout';
import SearchInput from 'components/common/SearchInput';
import SetPageTitle from 'components/common/SetPageTitle';

import IssueListingTabs from './IssueListingTabs';

import { TableCellStates, tableConfig } from './tableConfig';
import { useSnackbar } from 'notistack';
import { getAPIData, putAPIData } from 'api/playosmo';
import { ORDERS_READ, ORDERS_WRITE } from 'config/roles';
import { SEVERITY } from 'config';
import { API } from 'axiosClient/config';
import paths from 'routes/paths';

import userSelectors from 'store/user/selectors';

import './index.scss';

const IssueListing = ({
  // Redux
  userRoles,
  user,
}) => {
  const [showMyIssueOnly, setShowMyIssueOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [status, setStatus] = useState({
    type: 'Pending',
    acked: false,
    resolved: false,
  });
  const [value, setValue] = useState('Pending');
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const userEmail = user.email;
  const canEdit = userRoles.includes(ORDERS_WRITE);
  const canView = userRoles.includes(ORDERS_READ);

  const renderTab = (value) => {
    if (value === 'Pending') {
      setValue(value);
      setStatus({
        type: 'Pending',
        acked: false,
        resolved: false,
      });
    }
    if (value === 'In Progress') {
      setValue(value);
      setStatus({
        type: 'In Progress',
        acked: true,
        resolved: false,
      });
    }
    if (value === 'Resolved') {
      setValue(value);
      setStatus({
        type: 'Resolved',
        acked: true,
        resolved: true,
      });
    }
    if (value === 'All') {
      setValue(value);
      setStatus({
        type: 'All',
        acked: '',
        resolved: '',
      });
    }
  };

  const fetchIssues = async () => {
    setLoading(true);
    const issueUrl = `${API.orders.issues}?matches_acked=${status.acked}&matches_resolved=${status.resolved}&count_only=false`;
    const response = await getAPIData(issueUrl);
    setRows(response.data.issues);
    setFilteredRows(response.data.issues);
    setLoading(false);
  };

  const updateIssues = async (
    acked,
    resolved,
    reopened,
    issueId,
    value = '',
  ) => {
    let updateIssuePayload = {
      acked: acked,
      resolved: resolved,
      reopened: reopened,
      issue_id: issueId,
    };
    setLoading(true);

    try {
      await putAPIData(API.orders.issues, updateIssuePayload);
      renderTab(value);
      setLoading(false);
      return enqueueSnackbar(`Issue Status : ${value}`, {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      setLoading(false);
      return enqueueSnackbar(`Error occurred : ${e.message}`, {
        variant: SEVERITY.ERROR,
      });
    }
  };

  useEffect(
    () => {
      fetchIssues();
    },
    // eslint-disable-next-line
    [status],
  );

  const options = {
    rowsPerPage: 10,
    selectionKey: 'id',
  };

  const renderStatusValue = (record) => {
    if (record.acked === false && record.resolved === false) {
      return <Chip className="issue-status-button-error" label="Pending" />;
    }

    if (record.acked === true && record.resolved === false) {
      return (
        <Chip label="In Progress" className="issue-status-button-warning" />
      );
    }

    if (record.acked === true && record.resolved === true) {
      return <Chip className="issue-status-button-success" label="Resolved" />;
    }
    return '-';
  };

  const renderActions = (record) => {
    if (canEdit) {
      if (record.acked === false && record.resolved === false) {
        return (
          <div className="action-flex">
            <Button
              className="action-button"
              variant="text"
              onClick={() =>
                updateIssues(true, false, false, record.issue_id, 'In Progress')
              }
            >
              Take it
            </Button>
            <Button
              className="action-button"
              variant="text"
              onClick={() => {
                history.push(`${paths.orders.issues.view}/${record.issue_id}`);
              }}
            >
              View
            </Button>
          </div>
        );
      }

      if (record.acked === true && record.resolved === false) {
        return (
          <div className="action-flex">
            <Button
              className="action-button"
              variant="text"
              onClick={() =>
                updateIssues(true, true, false, record.issue_id, 'Resolved')
              }
            >
              Resolve
            </Button>
            <Button
              className="action-button"
              variant="text"
              onClick={() => {
                history.push(`${paths.orders.issues.view}/${record.issue_id}`);
              }}
            >
              View
            </Button>
          </div>
        );
      }

      if (record.acked === true && record.resolved === true) {
        return (
          <div className="action-flex">
            <Button
              className="action-button"
              variant="text"
              onClick={() =>
                updateIssues(true, true, true, record.issue_id, 'All')
              }
            >
              Reopen
            </Button>
            <Button
              className="action-button"
              variant="text"
              onClick={() => {
                history.push(`${paths.orders.issues.view}/${record.issue_id}`);
              }}
            >
              View
            </Button>
          </div>
        );
      }
    } else if (canView) {
      return (
        <Button
          className="action-button"
          variant="text"
          onClick={() => {
            history.push(`${paths.orders.issues.view}/${record.issue_id}`);
          }}
        >
          View
        </Button>
      );
    } else {
      return <>-</>;
    }
  };

  let headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return renderActions(record);
        };
        return cell;
      case TableCellStates.STATUS:
        cell.render = (record, index) => {
          return renderStatusValue(record);
        };
        return cell;
      case TableCellStates.ORDER_ID:
        cell.render = (record, index) => {
          return <span key={`${i}-${index}`}>{record.references.order}</span>;
        };
        return cell;
      case TableCellStates.ISSUE:
        cell.render = (record, index) => {
          return (
            <div className="issue-listing-flex-display">
              <span key={`${i}-${index}`}>{record.issue}</span>
              <div>
                <ToolTip title={record.context} />
              </div>
            </div>
          );
        };
        return cell;
      case TableCellStates.TAKEN_BY:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {record.acked_by.length ? record.acked_by : '-'}
            </span>
          );
        };
        return cell;
      default:
        return cell;
    }
  });

  if (value === 'Pending') {
    headCells = headCells.filter((cell) => {
      return cell.title !== 'Taken By';
    });
  }

  const requestSearch = (searchedVal) => {
    let filterRows;
    if (searchedVal.length) {
      if (parseInt(searchedVal)) {
        if (showMyIssueOnly) {
          filterRows = getMyIssuesRows();
          filterRows = getFilterRowsForSearchValue(filterRows, searchedVal);
          setFilteredRows(filterRows);
        } else {
          filterRows = getFilterRowsForSearchValue(rows, searchedVal);
          setFilteredRows(filterRows);
        }
      }
    } else {
      if (showMyIssueOnly) {
        filterRows = getMyIssuesRows();
        setFilteredRows(filterRows);
      } else {
        setFilteredRows(rows);
      }
    }
  };

  const getFilterRowsForSearchValue = (rows, searchedVal) => {
    return rows.filter((row) => {
      return row.references.order.includes(searchedVal);
    });
  };

  const handleMyIssues = (e) => {
    setShowMyIssueOnly(e.target.checked);
    filterMyIssues(e.target.checked);
  };

  const filterMyIssues = (showMyIssueOnly) => {
    let filterRows;
    if (showMyIssueOnly) {
      filterRows = getMyIssuesRows();
      setFilteredRows(filterRows);
    } else {
      setFilteredRows(rows);
    }
  };

  const getMyIssuesRows = () => {
    return rows.filter((row) => {
      return (
        row.acked_by.includes(userEmail) || row.resolved_by.includes(userEmail)
      );
    });
  };

  return (
    <>
      <SetPageTitle pageTitle="Issues" />
      <PageContentLayout title="Issues" isLoading={loading}>
        <IssueListingTabs value={value} renderTab={renderTab} />
        <div className="subscription-listing-search issue-filter-tab">
          <SearchInput
            onSubmit={(val) => requestSearch(val)}
            placeholder="Search by Order ID"
          />
          {value !== 'Pending' ? (
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="secondary" />}
                label="My Issues"
                onChange={handleMyIssues}
              />
            </FormGroup>
          ) : (
            ''
          )}
        </div>
        <TableComponent
          rows={filteredRows}
          headCells={headCells}
          options={options}
        />
      </PageContentLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
  user: userSelectors.getUserInfo(state),
});

export default connect(mapStateToProps)(IssueListing);
