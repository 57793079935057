import { Form, FormSpy } from 'react-final-form';
import { Button, Paper, Link, Typography } from '@mui/material';
import OrderProperty from 'components/containers/OrdersContainer/OrderView/components/OrderProperty';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';

const AppsSection = ({ account, updateAccount, canEdit }) => {
  const inputBase = {
    disabled: false,
  };

  const initialValues = {
    masterpiece_web_search:
      account.masterpiece_web_search !== 'disabled' || false,
  };

  const onSubmit = (values, form) => {
    updateAccount(values);
    form.reset(values);
  };

  const onResetClick = (form) => {
    form.reset();
  };

  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Apps
      </Typography>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <OrderPropertiesWrapper>
              <OrderProperty label="Owned apps" value={account.owned_apps} />
              <OrderPropertyInput
                label="Masterpiece web search"
                type="checkbox"
                input={{
                  ...inputBase,
                  name: 'masterpiece_web_search',
                  label: 'Enabled',
                  disabled: !canEdit,
                }}
              />
              <OrderProperty
                label="Analytics"
                component={
                  <Link
                    href={`https://my.playosmo.com/analytics/detail?user_id=${account.user_id}`}
                  >
                    View Periscope dashboard
                  </Link>
                }
              />
              <OrderProperty
                label="Activity"
                component={
                  <Link
                    href={`https://my.playosmo.com/myadmin/traces/user/${account.user_id}`}
                  >
                    Inspect all events
                  </Link>
                }
              />
              <OrderProperty
                label="Captures"
                component={
                  <Link
                    href={`https://vision-tool.playosmo.com/pick?search=user%3A${account.user_id}`}
                  >
                    Inspect vision captures
                  </Link>
                }
              />
            </OrderPropertiesWrapper>
            {canEdit && (
              <FormSpy subscription={{ pristine: true }}>
                {(props) => (
                  <div className="mt-20">
                    <Button
                      onClick={() => onResetClick(form)}
                      variant="text"
                      disabled={props.pristine}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleSubmit}
                      className="ml-10"
                      disabled={props.pristine}
                    >
                      Update
                    </Button>
                  </div>
                )}
              </FormSpy>
            )}
          </form>
        )}
      />
    </Paper>
  );
};

export default AppsSection;
