import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import { ORDER_ACTION_ERROR, ORDER_ACTION_SUCCESS } from 'config/messages';

import { CREATE_RMA } from 'store/orders/actions';

import ErrorDisplay from 'components/common/DisplayError';
import RMAItem from './components/RMAItem';

import './RMACreationModal.scss';

function RMACreationModal({ order, updateRMAData, handleClose, RMAKeys }) {
  const [generalError, setGeneralError] = useState('');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleFormSubmit = (values) => {
    const items =
      values.price &&
      Object.keys(values.price).reduce((acc, sku) => {
        if (!values[sku] || !parseInt(values[sku])) {
          return acc;
        }

        const key = order.shippedCart.find((p) => p.sku === sku).key;

        const newItems = values.price[sku].reduce((acc, price) => {
          if (typeof price === 'undefined' || price === null || isNaN(price)) {
            return acc;
          }

          return [
            ...acc,
            {
              sku,
              key: key,
              price_paid: parseInt(parseFloat(price) * 100),
            },
          ];
        }, []);

        return [...acc, ...newItems];
      }, []);

    if (!items?.length) {
      setGeneralError('Please select at least one product');
      return;
    }

    // Check if the amount to refund is not above the price paid
    const refundAmountHigherThanPrice = items.reduce((acc, item) => {
      if (acc) return acc;

      const orderItem = order.shippedCart.find((p) => p.sku === item.sku);
      return item.price_paid > orderItem.pricePaid;
    }, false);

    if (refundAmountHigherThanPrice) {
      setGeneralError(
        'Please check that the refund amounts are not higher than the price paid',
      );
      return;
    }

    setGeneralError('');

    dispatch({
      type: CREATE_RMA,
      payload: {
        data: {
          orderId: order.orderId,
          items,
        },
        callback: (id) => {
          updateRMAData(id);
          handleClose();
          enqueueSnackbar(ORDER_ACTION_SUCCESS, {
            variant: SEVERITY.SUCCESS,
          });
        },
        errorCallback: (status, error) => {
          setGeneralError(error.message);
          enqueueSnackbar(ORDER_ACTION_ERROR, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const handleCancel = () => {
    handleClose();
  };

  const setPriceLines = () => {
    handleClose();
  };

  return (
    <div>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{}}
        mutators={{
          setPriceLines,
        }}
        render={({ form, handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className="rma-form" noValidate>
            <Grid container>
              {RMAKeys.length !== order.shippedCart.length ? (
                <>
                  <Grid item xs={12} className="rma-form__description">
                    Select the quantities to be returned to generate a RMA and a
                    return label:
                  </Grid>
                  <Grid item xs={12} className="rma-form__product-listing">
                    {order.shippedCart.map((product) => {
                      if (!RMAKeys.includes(product.key)) {
                        const selectedQty = parseInt(values[product.sku]);
                        const price = product.pricePaid / product.qty;
                        return (
                          <RMAItem
                            key={product.key}
                            product={product}
                            selectedQty={selectedQty}
                            price={price}
                            currency={order.orderCurrency}
                            className="rma-form__product"
                          />
                        );
                      }
                      return null;
                    })}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} className="rma-form__description">
                  RMAs have been generated for all the ordered SKUs
                </Grid>
              )}
              {!!generalError?.length && (
                <Grid item xs={12} className="">
                  <ErrorDisplay message={generalError} />
                </Grid>
              )}
              <Grid item xs={12} className="button-container">
                <Button
                  className="mr-20"
                  size="small"
                  variant="outlined"
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                {RMAKeys.length !== order.shippedCart.length ? (
                  <Button
                    className="button"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    Create RMA
                  </Button>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      />
    </div>
  );
}

export default RMACreationModal;
