const shippingMethods = [
  {
    id: 'default',
    value: 'Default',
  },
  {
    id: 'ground',
    value: 'US Ground',
  },
  {
    id: 'intl',
    value: 'International',
  },
  {
    id: 'two_days_us',
    value: 'US 2 days',
  },
  {
    id: 'one_day_us',
    value: 'US 1 day',
  },
];

export default shippingMethods;
