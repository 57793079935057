import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { Button, CircularProgress } from '@mui/material';

import { getAPIData } from 'api/playosmo';
import { API } from 'axiosClient/config';

import {
  TableCellStates,
  tableConfig,
} from 'components/containers/SubscriptionContainer/tableConfig';
import TableComponent from 'components/common/TableComponent';
import PageContentLayout from 'components/layout/PageContentLayout';
import SearchInput from 'components/common/SearchInput';

import SetPageTitle from 'components/common/SetPageTitle';
import { formatDate, getLocalDateFromUTC } from 'utils';
import withRouterInfo from 'components/hoc/withRouterInfo';
import paths from 'routes/paths';

import './index.scss';
import { compose } from 'recompose';

const SubscriptionListing = () => {
  const [rows, setRows] = useState([]);
  const [cursor, setCursor] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();

  const options = {
    rowsPerPage: 10,
    selectionKey: 'id',
  };

  const getSubscriptions = async (
    { email, subscriptionId } = {},
    loadMore = false,
  ) => {
    if (!loadMore) {
      setLoading(true);
    }

    let url = API.subscriptions.query;
    if (email || email === '') {
      url = `${url}?email=${encodeURIComponent(email)}&cursor=${cursor}`;
    } else if (subscriptionId) {
      url = `${url}?subscription_id=${subscriptionId}&cursor=${cursor}`;
    } else {
      url = `${url}?email=${encodeURIComponent(emailValue)}&cursor=${cursor}`;
    }

    try {
      const response = await getAPIData(url);
      setCursor(response?.data?.cursor || '');
      if (!response?.data?.cursor) {
        setDisabled(true);
      }
      if (loadMore) {
        setRows(rows.concat(response?.data?.subscriptions));
        setDisabled(false);
      } else {
        setRows(response?.data?.subscriptions || []);
      }
      setLoading(false);
      setIsLoadingMore(false);
    } catch (e) {
      setDisabled(true);
      setRows([]);
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  const requestSearch = (searchedVal) => {
    setSearchQuery(searchedVal);
    if (searchedVal.length) {
      if (parseInt(searchedVal)) {
        getSubscriptions({ subscriptionId: searchedVal });
      } else {
        setEmailValue(searchedVal);
        getSubscriptions({ email: searchedVal });
      }
    } else {
      setDisabled(false);
      setEmailValue('');
      setCursor('');
      getSubscriptions({ email: '', subscriptionId: '' });
    }
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  const handleLoadMoreData = () => {
    setDisabled(true);
    setIsLoadingMore(true);
    getSubscriptions({}, true);
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return (
            <Button
              color="primary"
              onClick={() => {
                history.push(`${paths.orders.subscriptions.view}/${record.id}`);
              }}
              variant="text"
              endIcon={<SearchIcon color="primary" />}
            >
              <span style={{ color: '#18bbed' }}>View</span>
            </Button>
          );
        };
        return cell;
      case TableCellStates.SUBSCRIPTION_ID:
        cell.render = (record, index) => {
          return <span key={`${i}-${index}`}>{record.id}</span>;
        };
        return cell;
      case TableCellStates.EMAIL:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {record && record.customer && record.customer.email
                ? record.customer.email
                : '-'}
            </span>
          );
        };
        return cell;
      case TableCellStates.SUBSCRIPTION_NAME:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>{record.subscription_plan.name}</span>
          );
        };
        return cell;
      case TableCellStates.STATUS:
        cell.render = (record, index) => {
          return <span key={`${i}-${index}`}>{record.status}</span>;
        };
        return cell;
      case TableCellStates.ORDERS:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {record?.subscription_order_ids?.length || 0}/
              {record.total_subscription_order_count}
            </span>
          );
        };
        return cell;
      case TableCellStates.CREATED_ON:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {formatDate(getLocalDateFromUTC(record.created_on))}
            </span>
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  return (
    <>
      <SetPageTitle pageTitle="Subscriptions" />
      <PageContentLayout isLoading={loading} title="Subscriptions">
        <div className="subscription-listing-search">
          <SearchInput
            onSubmit={(val) => requestSearch(val)}
            defaultValue={searchQuery}
            placeholder="Search by Email or ID"
          />
        </div>
        <TableComponent
          rows={rows}
          headCells={headCells}
          options={options}
          disablePagination={true}
          rowCount={rows && rows.length}
        />
        <div className="load-more-btn">
          <div className="btn-flex">
            <Button
              disabled={disabled}
              type="button"
              className="button"
              variant="contained"
              size="small"
              onClick={handleLoadMoreData}
              color="primary"
            >
              Load More
            </Button>
            {isLoadingMore ? <CircularProgress size={30} /> : <></>}
          </div>
        </div>
      </PageContentLayout>
    </>
  );
};
export default compose(withRouterInfo)(SubscriptionListing);
