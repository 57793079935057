import { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import clsx from 'clsx';
import update from 'immutability-helper';

import Row from './Row';

import './styles.scss';

const SortableTable = ({ rows = [], onRowsChange, className }) => {
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = rows[dragIndex];
      const newOrder = update(rows, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      onRowsChange(newOrder);
    },
    [onRowsChange, rows],
  );

  const renderRow = (row, index) => {
    return (
      <Row
        key={row.id}
        index={index}
        id={row.id}
        text={row.text}
        moveRow={moveRow}
      />
    );
  };

  return (
    <div className={clsx(className, 'sortable-table')}>
      {rows.map((row, i) => renderRow(row, i))}
    </div>
  );
};

const SortableTableContainer = (props) => (
  <DndProvider backend={HTML5Backend}>
    <SortableTable {...props} />
  </DndProvider>
);

export default SortableTableContainer;
