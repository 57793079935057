import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import { ORDER_ACTION_ERROR, ORDER_ACTION_SUCCESS } from 'config/messages';

import { PARTIALLY_REFUND_ORDER } from 'store/orders/actions';

import SelectDropdown from 'components/common/SelectDropdown';

function PriceMatchTool({ order, disabled }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleFormSubmit = (values) => {
    dispatch({
      type: PARTIALLY_REFUND_ORDER,
      payload: {
        data: {
          campaign: values.coupon,
          orderId: order.orderId,
        },
        callback: () => {
          enqueueSnackbar(ORDER_ACTION_SUCCESS, {
            variant: SEVERITY.SUCCESS,
          });
        },
        errorCallback: (error) => {
          enqueueSnackbar(ORDER_ACTION_ERROR, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const couponsPriceMatchList =
    order.couponDiscounts &&
    Object.keys(order.couponDiscounts).map((couponKey) => {
      const amount = order.couponDiscounts[couponKey];
      return {
        id: couponKey,
        value: couponKey,
        amount,
      };
    });

  return (
    <>
      {couponsPriceMatchList && (
        <Form
          onSubmit={handleFormSubmit}
          initialValues={{
            coupon: null,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate className="mb-20">
              <Field
                name="coupon"
                component={SelectDropdown}
                label="Price match tool"
                dataItems={couponsPriceMatchList}
                renderItems={(item) => `${item.id} - ${item.amount}`}
                disabled={disabled}
              />
              <div className="mt-10">
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  disabled={disabled}
                >
                  Apply
                </Button>
              </div>
            </form>
          )}
        />
      )}
    </>
  );
}

export default PriceMatchTool;
