import React from 'react';
import { makeStyles } from '@mui/styles';
import { List } from '@mui/material';

import { PLAYOSMO_ADMIN } from 'config/roles';
import { hasRoles } from 'utils';
import osmoColors from 'utils/ui/colors';

import NavItem from './NavItem';
import NavBarCategory from './NavBarCategory';

import './styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    color: osmoColors.grey[80],
    paddingTop: theme.spacing(2),
  },
}));

export default function NavBar(props) {
  const { navigation, roles } = props;
  const classes = useStyles();

  return navigation.map(
    (item) =>
      roles &&
      hasRoles(roles, PLAYOSMO_ADMIN, item.roles) && (
        <List
          key={item.id}
          component="div"
          disablePadding
          className={classes.root}
        >
          {item.type === 'item' &&
            hasRoles(roles, PLAYOSMO_ADMIN, item.roles) && (
              <NavItem item={item} roles={roles} />
            )}
          {item.type === 'category' && (
            <NavBarCategory item={item} roles={roles} />
          )}
        </List>
      ),
  );
}
