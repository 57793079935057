export const tableConfig = [
  {
    title: 'Country',
    id: 'country',
  },
  {
    title: 'Currency',
    id: 'currency',
  },
  {
    title: 'Discount',
    id: 'percent_off',
    render: (record) => <span>{record.percent_off}%</span>,
  },
  {
    title: 'Original Price',
    id: 'price',
  },
  {
    title: 'Discounted Price',
    id: 'discountedPrice',
  },
];
