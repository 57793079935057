import React from 'react';
import { useDispatch } from 'react-redux';

import { Card, CardContent, Grid, Divider, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import {
  SUBSCRIPTION_ACTION_SUCCESS,
  SUBSCRIPTION_ACTION_ERROR,
} from 'config/messages';

import { UPDATE_SUBSCRIPTION } from 'store/subscriptions/actions';

function ActionsSection({ subscription, canEdit, setSubscriptionStatus }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { id, status } = subscription;

  function updateStatus(newStatus) {
    dispatch({
      type: UPDATE_SUBSCRIPTION,
      payload: {
        data: {
          subscriptionId: id,
          status: newStatus,
        },
        callback: () => {
          enqueueSnackbar(SUBSCRIPTION_ACTION_SUCCESS, {
            variant: SEVERITY.SUCCESS,
          });
          setSubscriptionStatus(newStatus);
        },
        errorCallback: (error) => {
          enqueueSnackbar(SUBSCRIPTION_ACTION_ERROR, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  }

  return (
    <Grid item xs={3}>
      <Card elevation={0}>
        <CardContent>
          <Typography variant="h5" color="text.secondary" gutterBottom>
            Actions
          </Typography>
          <Divider className="subscription-listing-divider-margin-bottom" />

          {status === 'active' && (
            <>
              <Button
                onClick={() => updateStatus('paused')}
                variant="outlined"
                disabled={!canEdit}
                className="mr-10"
              >
                Pause subscription
              </Button>
              <Button
                onClick={() => updateStatus('canceled')}
                variant="outlined"
                disabled={!canEdit}
                className="mr-10"
              >
                Cancel subscription
              </Button>
            </>
          )}
          {status === 'disrupted' && (
            <>
              <Button
                onClick={() => updateStatus('canceled')}
                variant="outlined"
                disabled={!canEdit}
                className="mr-10"
              >
                Cancel subscription
              </Button>
            </>
          )}

          {status === 'paused' && (
            <>
              <Button
                onClick={() => updateStatus('active')}
                variant="outlined"
                disabled={!canEdit}
                className="mr-10"
              >
                Resume subscription
              </Button>
            </>
          )}

          {(status === 'ended' || status === 'canceled') && (
            <Typography paragraph={true}>No actions available</Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}

export default ActionsSection;
