import { useState } from 'react';

import AddRow from 'components/containers/GamesContainer/GameView/components/GameManualsSection/GameManuals/AddRow';
import Media from './Media';
import MediaModal from './MediaModal';

const MediaWrapper = ({
  input: { name, onChange, value, ...restInput },
  meta,
  disabled,
  withVideoOption,
  skuKey,
  sku,
  onDelete,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleSave = (media) => {
    onChange(media);
  };

  if (!value) {
    const handleAddBadge = () => {
      setModalOpen(true);
    };

    return (
      <div>
        No media yet
        <AddRow onClick={handleAddBadge} label="Add a media" />
        <MediaModal
          open={!!modalOpen}
          setOpen={setModalOpen}
          withVideoOption={name.includes('carousel')}
          action="add"
          onSave={handleSave}
        />
      </div>
    );
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    } else {
      onChange('');
    }
  };

  return (
    <>
      <Media
        name={value.url}
        content={value.alt || value.existing_alt}
        mediaUrl={value.url}
        onEdit={() => setEditModalOpen(true)}
        onDelete={handleDelete}
        skuKey={skuKey}
        sku={sku}
      />
      <MediaModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        value={value}
        withVideoOption={withVideoOption}
        action="edit"
        onSave={handleSave}
      />
    </>
  );
};

export default MediaWrapper;
