import { Grid } from '@mui/material';
import ExpandableSection from 'components/common/ExpandableSection';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';

const GeneralSection = ({ product, inputBase, isCreationMode }) => {
  const renderContent = () => (
    <Grid container>
      <Grid item xs={6}>
        <OrderPropertiesWrapper>
          <OrderPropertyInput
            label="Product name"
            input={{
              ...inputBase,
              name: 'name',
              required: true,
            }}
          />
          <OrderPropertyInput
            label="Shopping name"
            input={{
              ...inputBase,
              name: 'shopping_name',
            }}
          />
          <OrderPropertyInput
            label="Product key"
            input={{
              ...inputBase,
              disabled: !isCreationMode,
              name: 'product_key',
              required: true,
            }}
          />
          <OrderPropertyInput
            label="PlayOsmo path"
            input={{
              ...inputBase,
              name: 'playosmo_path',
            }}
          />
          <OrderPropertyInput
            label="BYJU's learning path"
            input={{
              ...inputBase,
              name: 'byjus_path',
            }}
          />
          <OrderPropertyInput
            label="Is ready"
            type="switch"
            input={{
              ...inputBase,
              name: 'is_ready',
            }}
          />
        </OrderPropertiesWrapper>
      </Grid>
    </Grid>
  );

  return (
    <ExpandableSection
      id={`general-${product?.id || 'creation'}`}
      heading={'General'}
      content={renderContent()}
      openByDefault={true}
    />
  );
};

export default GeneralSection;
