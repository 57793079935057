import { Paper, Typography } from '@mui/material';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';

const GeneralSection = ({ inputBase }) => {
  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        General
      </Typography>
      <OrderPropertiesWrapper>
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'name',
            required: true,
          }}
          label="Warehouse name"
        />
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'warehouse_code',
            required: true,
          }}
          label="Warehouse code"
        />
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'country_code_location',
          }}
          label="Location"
        />
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'destination',
          }}
          label="Destination (eg. playosmo, edu, etc.)"
        />
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'operated_by',
            dataItems: [
              'Osmo',
              'ByjusWMS',
              'Shipwire',
              'RushOrder',
              'ModusLink',
              'BeyondTechSouthKorea',
            ],
          }}
          type="select"
          label="Operated by"
        />
      </OrderPropertiesWrapper>
    </Paper>
  );
};

export default GeneralSection;
