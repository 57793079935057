import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Paper, Typography } from '@mui/material';

import TextInput from 'components/common/TextInput';

const SearchSection = ({
  setCreditsData,
  accountIdFromURL,
  onSubmit,
  setNoDataReturned,
}) => {
  const history = useHistory();

  const initialValues = {
    account_id: accountIdFromURL || '',
  };

  const handleReset = (form) => {
    form.reset();
    setNoDataReturned(false);
    setCreditsData(null);
    history.push({ search: null });
  };

  return (
    <Paper className="p20" elevation={0}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ form, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>
                  Search for credit balances
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="account_id"
                  component={TextInput}
                  type="text"
                  label="Account ID"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  className="mr-20"
                  size="small"
                  variant="outlined"
                  type="button"
                  onClick={() => handleReset(form)}
                >
                  Reset
                </Button>
                <Button
                  className="button"
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Paper>
  );
};

export default SearchSection;
