import { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Paper, Grid, Typography, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SUPPORTED_LANGUAGES, SEVERITY } from 'config';
import {
  SUBSCRIPTIONS_SAVE_ERROR,
  SUBSCRIPTIONS_SAVE_SUCCESS,
} from 'config/messages';
import paths from 'routes/paths';
import { API } from 'axiosClient/config';
import {
  getAPIDataDifferentOrigin,
  postAPIDataDifferentOrigin,
} from 'api/microservices';

import { COMMUNICATIONS_WRITE } from 'config/roles';
import { FETCH_MAILING_LISTS, FETCH_SKU_DETAILS } from 'store/general/actions';
import userSelectors from 'store/user/selectors';
import generalSelectors from 'store/general/selectors';
import SetPageTitle from 'components/common/SetPageTitle';
import EditableProperty from 'components/common/EditableProperty';
import PageFormLayout from 'components/layout/PageFormLayout';
import OrderProperty from 'components/containers/OrdersContainer/OrderView/components/OrderProperty';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';

import SubscriptionsTable from './SubscriptionsTable';

const EmailSubscriptionView = ({ match, userRoles, mailingLists }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [emailData, setEmailData] = useState({});
  const dispatch = useDispatch();
  const { shippingCountries } = useSelector((state) => state.general);
  const canEdit = userRoles.includes(COMMUNICATIONS_WRITE);

  // We need to fetch the products to get the available countries
  useEffect(() => {
    dispatch({
      type: FETCH_MAILING_LISTS,
      payload: {
        callback: () => {},
        errorCallback: (error) => {},
      },
    });
    dispatch({
      type: FETCH_SKU_DETAILS,
      payload: {
        callback: () => {},
        errorCallback: (error) => {},
      },
    });
  }, [dispatch]);

  useEffect(() => {
    const emailAddress = match.params.email;
    // Get the orders based on the parameters
    if (emailAddress) {
      setIsLoading(true);

      const fetchEmailData = async () => {
        const response = await getAPIDataDifferentOrigin(
          `${
            API.microservices.communications.emailSubscriptions.view
          }?email=${encodeURIComponent(emailAddress)}`,
        );

        if (!response || response.status > 208) {
          return;
        }

        setIsLoading(false);
        const data = response.data.data;
        setEmailData(data);
      };

      fetchEmailData();
    }
  }, [match.params.email]);

  const getTitle = () =>
    emailData?.email ? `Subscriptions for ${emailData?.email}` : 'Loading...';

  const handleSettingsUpdate = async (setting, value) => {
    const payload = {
      data: {
        ...emailData,
        [setting]: value,
      },
    };
    try {
      const url = `${API.microservices.communications.emailSubscriptions.update}`;
      const response = await postAPIDataDifferentOrigin(url, payload);

      if (!response || response.status > 208) {
        enqueueSnackbar(response.message || SUBSCRIPTIONS_SAVE_ERROR, {
          variant: SEVERITY.ERROR,
        });
      } else {
        enqueueSnackbar(SUBSCRIPTIONS_SAVE_SUCCESS, {
          variant: SEVERITY.SUCCESS,
        });
      }
    } catch (e) {
      enqueueSnackbar(e.message || SUBSCRIPTIONS_SAVE_ERROR, {
        variant: SEVERITY.ERROR,
      });
    }
  };

  const updateSubscriptionsData = (subscriptionToUpdate) => {
    setEmailData({
      ...emailData,
      subscriptions: emailData.subscriptions.map((subscription) => {
        if (subscription.list_id === subscriptionToUpdate.list_id) {
          return subscriptionToUpdate;
        }
        return subscription;
      }),
    });
  };

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <PageFormLayout
        title={getTitle()}
        isLoading={isLoading}
        breadcrumbs={[
          {
            label: 'Subscriptions',
            link: paths.users.emailSubscriptions.list,
          },
          {
            label: getTitle(),
            isCurrent: true,
          },
        ]}
        showSubmitButtons={false}
      >
        <Paper className="p20">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                General
              </Typography>
              <OrderPropertiesWrapper>
                <OrderProperty label="Email address" value={emailData.email} />
                {shippingCountries ? (
                  <EditableProperty
                    id="country"
                    label="Country"
                    value={emailData.country}
                    options={shippingCountries}
                    disabled={!canEdit}
                    onSubmit={(values) =>
                      handleSettingsUpdate('country', values.country)
                    }
                  />
                ) : (
                  <OrderProperty label="Country" value={emailData.country} />
                )}
                <EditableProperty
                  id="language"
                  label="Language"
                  value={emailData.language}
                  disabled={!canEdit}
                  onSubmit={(values) =>
                    handleSettingsUpdate('language', values.language)
                  }
                  options={SUPPORTED_LANGUAGES}
                />
                <OrderProperty
                  label="Consent given"
                  value={emailData.consent_given}
                />
              </OrderPropertiesWrapper>
            </Grid>
          </Grid>
          <Divider className="mb-20 mt-20" />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Subscriptions
              </Typography>
              <SubscriptionsTable
                canEdit={canEdit}
                mailingLists={mailingLists}
                token={emailData.token}
                subscriptions={emailData?.subscriptions?.map((sub) => ({
                  ...sub,
                  id: sub.list_id,
                }))}
                updateSubscriptionsData={updateSubscriptionsData}
              />
            </Grid>
          </Grid>
        </Paper>
      </PageFormLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
  mailingLists: generalSelectors.getMailingLists(state),
});

export default connect(mapStateToProps)(EmailSubscriptionView);
