import React from 'react';
import { Form, Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Grid, Button, Paper, Chip } from '@mui/material';

import { percentToFraction } from 'utils';
import { CLEANUP_ASSET } from '../store/actions';

import TextInput from 'components/common/TextInput';
import Badges from 'components/containers/CampaignContainer/CreateCampaign/Badges';

import './style.scss';

export default function EditSku({
  handleSaveEditSku,
  handleCancelEditSku,
  handleSkuBadgeDelete,
  editSkuDetails,
  disabled,
}) {
  const dispatch = useDispatch();

  const handleFormSubmit = (values) => {
    let skuDetails = [...editSkuDetails];
    const discountedApplied = percentToFraction(values.percent_off, true);
    skuDetails = skuDetails.map((sku) => ({
      ...sku,
      percent_off: discountedApplied,
      badges: [...values.badges],
    }));
    handleSaveEditSku(skuDetails);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.percent_off) {
      errors.percent_off = 'Required';
    }
    return errors;
  };

  // Deletes newly created file and adds deleted files on cancel.
  // Logic to be decided.

  const cancelEditSku = (values) => {
    if (values.badges.length) {
      dispatch({
        type: CLEANUP_ASSET,
        payload: { fileId: values.badges[0].key },
      });
    }
    handleCancelEditSku();
  };

  const deleteFiles = (value) => {
    const skuDetails = { ...editSkuDetails };
    skuDetails.badges = [];
    handleSkuBadgeDelete(skuDetails);
  };

  const displayedSkusCount = 10;
  const totalSkusCount = editSkuDetails.length;
  const hiddenSkusCount =
    totalSkusCount > displayedSkusCount
      ? totalSkusCount - displayedSkusCount
      : 0;
  const displayedSkus =
    totalSkusCount > displayedSkusCount
      ? editSkuDetails.slice(0, displayedSkusCount)
      : editSkuDetails;

  return (
    <React.Fragment>
      <div className="edit-sku">
        <Form
          onSubmit={handleFormSubmit}
          initialValues={{
            percent_off: percentToFraction(
              editSkuDetails[0]?.percent_off,
              false,
            ),
            badges: editSkuDetails[0]?.badges
              ? editSkuDetails[0].badges.map((badge) => {
                  badge.id = badge.key;
                  return badge;
                })
              : [],
          }}
          validate={validate}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className="add-sku-form" noValidate>
              <Grid container>
                {editSkuDetails && editSkuDetails.length > 1 && (
                  <Grid item xs={12} className="row">
                    If the different selected SKUs have different discounts and
                    badges, the default values will be the one from the first
                    selected SKU. All existing values will be replaced by the
                    values below.
                  </Grid>
                )}
                <Grid item xs={12} className="row">
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="edit-sku-label">
                      SKUs
                    </Grid>
                    <Paper component="ul" className="edit-sku-list">
                      {displayedSkus.map((data) => {
                        return (
                          <li key={data.id}>
                            <Chip
                              variant="outlined"
                              size="small"
                              label={data.id}
                              className="edit-sku-list-item"
                            />
                          </li>
                        );
                      })}
                      {!!hiddenSkusCount && (
                        <Chip
                          size="small"
                          label={`+${hiddenSkusCount} SKUs`}
                          className="edit-sku-list-item"
                        />
                      )}
                    </Paper>
                  </Grid>
                </Grid>

                <Grid item xs={12} className="row">
                  <Field
                    fullWidth
                    required
                    name="percent_off"
                    component={TextInput}
                    type="text"
                    label="Discount (%)"
                    variant="outlined"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} className="row">
                  <Field
                    fullWidth
                    required
                    name="badges"
                    component={Badges}
                    label="Badges"
                    disabled={disabled}
                    deleteFiles={deleteFiles}
                  />
                </Grid>
                <Grid item xs={12} className="button-container">
                  <Button
                    className="mr-20"
                    size="small"
                    variant="outlined"
                    type="button"
                    onClick={(e) => cancelEditSku(values)}
                  >
                    Cancel
                  </Button>
                  {!disabled && (
                    <Button
                      className="button"
                      size="small"
                      variant="contained"
                      type="submit"
                    >
                      Submit
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>
    </React.Fragment>
  );
}
