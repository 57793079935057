import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import clsx from 'clsx';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

import TableHeadComponent from './components/TableHeadComponent';
import TableBodyComponent from './components/TableBodyComponent';
import NoData from '../NoData';

import { ROWS_PER_PAGE_OPTIONS } from 'config';

function TableComponent(props) {
  const {
    size,
    rows,
    headCells,
    options,
    className,
    disablePagination,
    rowCount,
    elevation = 1,
    cellAdditionalProps,
  } = props;
  const {
    isRowSelectable = false,
    pagination = true,
    onRowSelectChange,
    selectionKey,
    selectedRows,
    currentPageNumber = 0,
    rowsPerPage: initialRowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    customToolbar = () => {},
  } = options;
  const [pageNumber, setPageNumberValue] = useState(currentPageNumber);
  const [rowsPerPage, setRowsPerPageValue] = useState(initialRowsPerPage || 10);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const history = useHistory();
  const location = useLocation();

  const setPageNumber = (value) => {
    setPageNumberValue(value);
    updateUrlParams({ page: value });
  };

  const setRowsPerPage = (value) => {
    setRowsPerPageValue(value);
    updateUrlParams({ rowsPerPage: value });
  };

  const updateUrlParams = (newParams) => {
    const queryParams = qs.parse(location.search);
    const newQueries = { ...queryParams, ...newParams };
    history.push({ search: qs.stringify(newQueries) });
  };

  useEffect(() => {
    const queryParams = qs.parse(location.search);
    if (queryParams.page) {
      const pageNumber = parseInt(queryParams.page);
      setPageNumber(pageNumber);
      if (pageNumber && onChangePage) {
        onChangePage(null, pageNumber);
      }
    }
    if (queryParams.rowsPerPage) {
      const rowsPerPageArg = parseInt(queryParams.rowsPerPage);
      setRowsPerPage(rowsPerPageArg);
      if (rowsPerPageArg && onChangeRowsPerPage) {
        onChangeRowsPerPage(null, rowsPerPageArg);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPageNumber = () => {
    handleChangePage(null, 0);
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    if (onChangePage) {
      onChangePage(event, newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    setPageNumber(0);
    setRowsPerPage(parseInt(value, 10));
    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(event, value);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n, index) =>
        selectionKey ? n[selectionKey] : index,
      );
      onRowSelectChange(newSelected);
      return;
    }
    onRowSelectChange([]);
  };

  const handleClick = (event, name) => {
    const selected = selectedRows;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    onRowSelectChange(newSelected);
  };

  return (
    <div className={clsx(className, 'table-container')}>
      <Paper className="table-container-paper" elevation={elevation}>
        {customToolbar()}
        <TableContainer>
          <Table
            className="table-container-content"
            aria-labelledby="tableTitle"
            size={size ? size : 'medium'}
            aria-label="enhanced table"
          >
            <TableHeadComponent
              selectedRowsCount={selectedRows?.length || 0}
              isRowSelectable={isRowSelectable}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowCount || rows?.length || 0}
              headCells={headCells}
            />
            <TableBodyComponent
              rows={rows}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              pageNumber={pageNumber}
              selected={selectedRows}
              selectionKey={selectionKey}
              isRowSelectable={isRowSelectable}
              rowsPerPage={disablePagination ? rowCount : rowsPerPage}
              handleClick={handleClick}
              pagination={pagination}
              totalRowCount={rowCount}
              resetPageNumber={resetPageNumber}
              cellAdditionalProps={cellAdditionalProps}
            />
          </Table>
        </TableContainer>
        {disablePagination ? (
          <></>
        ) : (
          pagination &&
          !!rows?.length && (
            <TablePagination
              rowsPerPageOptions={
                rowsPerPage && ROWS_PER_PAGE_OPTIONS.includes(rowsPerPage)
                  ? ROWS_PER_PAGE_OPTIONS
                  : [rowsPerPage, ...ROWS_PER_PAGE_OPTIONS]
              }
              component="div"
              count={rowCount || rows?.length || 0}
              rowsPerPage={rowsPerPage}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )
        )}
        {!rows?.length && <NoData />}
      </Paper>
    </div>
  );
}

export default TableComponent;
