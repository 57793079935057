import { Form, FormSpy } from 'react-final-form';
import { Button, Paper, Typography } from '@mui/material';
import OrderProperty from 'components/containers/OrdersContainer/OrderView/components/OrderProperty';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';

const AccountSection = ({ account, updateAccount, canEdit }) => {
  const inputBase = {
    disabled: false,
  };

  const initialValues = {
    username: account.username || '',
    firstname: account.firstname || '',
    lastname: account.lastname || '',
    email: account.email || '',
    phone_number: account.phone_number || '',
    skip_onboarding: account.skip_onboarding || false,
  };

  const onSubmit = (values, form) => {
    updateAccount(values);
    form.reset(values);
  };

  const onResetClick = (form) => {
    form.reset();
  };

  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Account
      </Typography>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <OrderPropertiesWrapper>
              <OrderProperty label="Account ID" value={account.account_id} />
              <OrderProperty label="myOsmo user ID" value={account.user_id} />
              <OrderPropertyInput
                label="Display name"
                input={{
                  ...inputBase,
                  name: 'username',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="First name"
                input={{
                  ...inputBase,
                  name: 'firstname',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="Last name"
                input={{
                  ...inputBase,
                  name: 'lastname',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="Email"
                input={{
                  ...inputBase,
                  name: 'email',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="Phone number"
                input={{
                  ...inputBase,
                  name: 'phone_number',
                  disabled: !canEdit,
                }}
              />
              <OrderProperty label="School code" value={account.code} />
              <OrderPropertyInput
                label="Verifications"
                type="checkbox"
                input={{
                  ...inputBase,
                  name: 'skip_onboarding',
                  label: 'Skip verifications',
                  disabled: !canEdit,
                }}
              />
            </OrderPropertiesWrapper>
            {canEdit && (
              <FormSpy subscription={{ pristine: true }}>
                {(props) => (
                  <div className="mt-20">
                    <Button
                      onClick={() => onResetClick(form)}
                      variant="text"
                      disabled={props.pristine}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleSubmit}
                      className="ml-10"
                      disabled={props.pristine}
                    >
                      Update
                    </Button>
                  </div>
                )}
              </FormSpy>
            )}
          </form>
        )}
      />
    </Paper>
  );
};

export default AccountSection;
