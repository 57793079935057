import React, { useEffect, useState } from 'react';
import { Button, Link } from '@mui/material';
import {
  deleteAPIDataDifferentOrigin,
  getAPIDataDifferentOrigin,
} from 'api/microservices';
import { TableCellStates, tableConfig } from './tableConfig';
import TableComponent from 'components/common/TableComponent';
import ModalComponent from 'components/common/ModalComponent';
import Action from 'components/common/TableActions/Actions';
import TableActions from 'components/common/TableActions';
import ShortLinkUpdateForm from '../ShortLinkUpdateForm';
import { SHORT_LINK_DELETED_SUCCESS } from 'config/messages';
import { SEVERITY } from 'config';
import { API } from 'axiosClient/config';
import { useSnackbar } from 'notistack';
import { TableActionStates } from 'enums/tableStates';
import userSelectors from 'store/user/selectors';
import { connect } from 'react-redux';
import './index.scss';
import { SHORTLINK_READ, SHORTLINK_WRITE } from 'config/roles';

const ShortLinkTable = (props) => {
  const userRoles = props.userRoles;
  const [updatedData, setUpdatedData] = useState();
  const [rows, setRows] = useState([]);
  const [updateObject, setUpdateObject] = useState();
  const [shortLinkId, setShortLinkId] = useState();
  const [showOpen, setShowOpen] = useState(false);
  const [showDeleteConfirm, setShowConfirmDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const canEdit =
    userRoles.includes(SHORTLINK_READ) && userRoles.includes(SHORTLINK_WRITE);

  const getShortLinks = async () => {
    let shortLinks;
    let response;

    response = await getAPIDataDifferentOrigin(
      API.microservices.shortLinks.get,
    );

    shortLinks = [response.data.data];
    setRows(shortLinks[0]);
  };

  const deleteShortLink = async (id) => {
    try {
      await deleteAPIDataDifferentOrigin(
        API.microservices.shortLinks.update + `/${id}`,
      );
      setShowOpen(false);
      window.location.reload();
      return enqueueSnackbar(SHORT_LINK_DELETED_SUCCESS, {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      return enqueueSnackbar(`Error occurred : ${e.message}`, {
        variant: SEVERITY.ERROR,
      });
    }
  };

  useEffect(() => {
    getShortLinks();
  }, [updatedData]);

  const options = {
    rowsPerPage: 10,
    selectionKey: 'id',
  };

  const renderActions = (cell, record) => {
    return cell.actions.map((action, index) => {
      switch (action.type) {
        case TableActionStates.EDIT:
          if (canEdit) {
            return (
              <Action
                key={index}
                text={action.text}
                action={() => {
                  setShowOpen(true);
                  setShortLinkId(record.id);
                  setUpdateObject({
                    title: record.title.replace(`[${record.tag}] `, ''),
                    tag: record.tag,
                    alias: record.alias,
                  });
                }}
              />
            );
          } else {
            return null;
          }
        case TableActionStates.DELETE:
          if (canEdit) {
            return (
              <Action
                key={index}
                text={action.text}
                action={() => {
                  setShowConfirmDelete(true);
                  setShortLinkId(record.id);
                }}
              />
            );
          } else {
            return null;
          }
        default:
          return cell;
      }
    });
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.URL:
        cell.render = (record, index) => {
          return (
            <span className="short-link-table-url" key={`${i}-${index}`}>
              <Link href={record.url} underline="hover">
                {record.url}
              </Link>
            </span>
          );
        };
        return cell;
      case TableCellStates.SHORT_LINK:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              <Link href={record.short_link} underline="hover">
                {record.short_link}
              </Link>
            </span>
          );
        };
        return cell;
      case TableCellStates.ALIAS:
        cell.render = (record, index) => {
          return <span key={`${i}-${index}`}>{record.alias}</span>;
        };
        return cell;
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          if (canEdit) {
            return <TableActions>{renderActions(cell, record)}</TableActions>;
          }
        };
        return cell;
      case TableCellStates.TITLE:
        cell.render = (record, index) => {
          return <span key={`${i}-${index}`}>{record.title}</span>;
        };
        return cell;

      default:
        return cell;
    }
  });
  return (
    <>
      <TableComponent rows={rows} headCells={headCells} options={options} />
      <ModalComponent
        title="Update a short link"
        open={showOpen}
        handleClose={() => setShowOpen(false)}
      >
        <ShortLinkUpdateForm
          setUpdatedData={setUpdatedData}
          id={shortLinkId}
          shortLinkUpdateObj={updateObject}
          setShowOpen={setShowOpen}
        />
      </ModalComponent>
      <ModalComponent
        title="Are you sure?"
        open={showDeleteConfirm}
        handleClose={() => setShowConfirmDelete(false)}
      >
        <div className="short-link-modal-delete-box">
          <Button onClick={() => deleteShortLink(shortLinkId)}>Yes</Button>
          <Button onClick={() => setShowConfirmDelete(false)}>No</Button>
        </div>
      </ModalComponent>
    </>
  );
};
const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(ShortLinkTable);
