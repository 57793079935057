// Error Messages

export const INVALID_EMAIL = 'Required valid Osmo email address';
export const INVALID_OTP = 'Invalid verification code';
export const USER_DOESNOT_EXISTS = 'User does not exists';
export const CAMPAIGN_SAVE_ERROR = 'Error occurred in saving campaign';
export const ROLES_SAVE_ERROR = 'Error occurred in updating permissions';
export const PROMO_CODE_SAVE_ERROR = 'Error occurred in saving promo code';
export const TEXT_BANNER_SAVE_ERROR = 'Error occurred when saving text banner';
export const CAMPAIGN_DELETE_ERROR =
  'Something went wrong while deleting campaign';
export const BANNERS_DELETE_ERROR =
  'Something went wrong while deleting the banner';
export const ORDER_ACTION_ERROR = 'Something went wrong';
export const SUBSCRIPTION_ACTION_ERROR = 'Something went wrong';
export const EMAIL_CONFIRMATION_ERROR = 'Something went wrong';
export const SUBSCRIPTIONS_SAVE_ERROR =
  "Something went wrong, the subscription couldn't be updated";

// Success Messages

export const CAMPAIGN_SAVED = 'Campaign saved successfully';
export const PROMO_CODE_SAVED = 'Promo code saved successfully';
export const PROMO_CODE_DUPLICATED = 'Promo code duplicated successfully';
export const TEXT_BANNER_SAVED = 'Text banner saved successfully';
export const ROLES_UPDATED = 'User permissions updated successfully';
export const CAMPAIGN_DELETE_SUCCESS = 'Campaign was successfully deleted';
export const BANNERS_DELETE_SUCCESS = 'Banner was successfully deleted';
export const ORDER_ACTION_SUCCESS = 'Order was successfully updated';
export const SUBSCRIPTION_ACTION_SUCCESS =
  'Subscription was successfully updated';
export const EMAIL_CONFIRMATION_SUCCESS = 'Email sent successfully';
export const SUBSCRIPTIONS_SAVE_SUCCESS =
  'Successfully updated the subscription';

// Tooltip messages
export const BACKGROUND_IMAGE_TOOLTIP = `Background Image will be displayed as the main banner image for the Desktop, Mobile or Tablet.`;
export const CONTENT_IMAGE_TOOLTIP = `Content Image will be an image over a background image if any.`;
export const COLOR_TOOLTIP = `Color will be the background color for the banner image. E.g a image will blue background may have a blue color to the areas not covered by the image in case the image is short of banner space.`;
export const BANNER_CONTENT_TOOLTIP = `You can use the following tags to format your text: **bold** _italic_ [link label](link url)`;
export const BANNER_NAME_TOOLTIP = `The name will not be displayed to the users. It's used for internal use only.`;

//Short link messages
export const SHORT_LINK_CREATED_SUCCESS = `Short link created successfully`;
export const SHORT_LINK_DELETED_SUCCESS = `Short link deleted successfully`;
export const SHORT_LINK_UPDATED_SUCCESS = `Short link updated successfully`;
