import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { Grid, Typography } from '@mui/material';

import { SUPPORTED_LANGUAGES, DATE_INFO } from 'config';
import { BANNER_CONTENT_TOOLTIP, BANNER_NAME_TOOLTIP } from 'config/messages';

import { FETCH_SKU_DETAILS } from 'store/general/actions';

import AutoCompleteWrapper from 'components/common/AutoCompleteWrapper';
import DateRangePicker from 'components/common/DateRangePicker';
import TextInput from 'components/common/TextInput';
import ToolTip from 'components/common/Tooltip';
import { textBannersStateMapping } from '../config';

const TextBannerForm = (props) => {
  const { mode } = props;
  const { shippingCountries } = useSelector((state) => state.general);

  const dispatch = useDispatch();
  const canReview = mode === textBannersStateMapping.REVIEW;

  // We need to fetch the products to get the available countries
  useEffect(() => {
    dispatch({
      type: FETCH_SKU_DETAILS,
      payload: {
        callback: () => {},
        errorCallback: (error) => {},
      },
    });
  }, [dispatch]);

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          General
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Field
          multiple={true}
          limitTags={2}
          name="countries"
          label="Select Countries"
          placeholder="Search Countries"
          options={shippingCountries}
          component={AutoCompleteWrapper}
          variant="outlined"
          getOptionLabel={(option) => option.value}
          renderDropdownOption={(option) => option.value}
          disabled={canReview}
        />
      </Grid>
      <Grid item xs={4}>
        <Field
          multiple={true}
          limitTags={2}
          name="languages"
          component={AutoCompleteWrapper}
          label="Select Language"
          placeholder="Search Language"
          options={SUPPORTED_LANGUAGES.map((lang) => ({
            value: lang,
            id: lang,
          }))}
          variant="outlined"
          getOptionLabel={(option) => option.value}
          renderDropdownOption={(option) => option.value}
          disabled={canReview}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          required
          name="dateRange"
          component={DateRangePicker}
          disabled={canReview}
        />
        <Typography variant="caption">{DATE_INFO}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Name <ToolTip title={BANNER_NAME_TOOLTIP} disableInteractive />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          required
          name="description"
          component={TextInput}
          type="text"
          label="Name"
          variant="outlined"
          disabled={canReview}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Content <ToolTip title={BANNER_CONTENT_TOOLTIP} disableInteractive />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          required
          name="message"
          component={TextInput}
          placeholder={BANNER_CONTENT_TOOLTIP}
          type="text"
          label="Content"
          variant="outlined"
          disabled={canReview}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(TextBannerForm);
