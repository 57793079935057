export const TableCellStates = {
  ID: 'id',
  SRC: 'srcSkuKey',
  DST: 'dstSkuKey',
  WAREHOUSE: 'warehouseCode',
  CONDITION: 'conditionType',
  IS_ACTIVE: 'isActive',
  ACTIONS: 'actions',
};

export const tableConfig = [
  {
    title: 'From SKU',
    id: TableCellStates.SRC,
    style: { minWidth: '200px' },
  },
  {
    title: 'To SKU',
    id: TableCellStates.DST,
    style: { minWidth: '200px' },
  },
  {
    title: 'Condition',
    id: TableCellStates.CONDITION,
  },
  {
    title: 'Warehouse',
    id: TableCellStates.WAREHOUSE,
  },
  {
    title: 'Active',
    id: TableCellStates.IS_ACTIVE,
  },
  {
    title: 'Actions',
    id: TableCellStates.ACTIONS,
    style: { minWidth: '200px' },
  },
];
