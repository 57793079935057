import { SET_USER_INFO } from './actions';

const initialState = {
  userRoles: null,
  userInfo: {},
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO: {
      const { userInfo } = action.payload;
      const userRoles = (userInfo.roles && [...userInfo.roles]) || [];

      return {
        ...state,
        userInfo,
        userRoles,
      };
    }

    default:
      return state;
  }
};
