const paths = {
  home: {
    dashboard: '/dashboard',
  },
  content: {
    textBanners: {
      list: '/text-banners',
      create: '/text-banner/create',
      edit: '/text-banner/edit',
      review: '/text-banner/review',
    },
  },
  marketing: {
    campaigns: {
      list: '/campaigns',
      listPast: '/campaigns-past',
      create: '/campaign/create',
      edit: '/campaign/edit',
      review: '/campaign/review',
    },
    promocodes: {
      list: '/promocodes',
      create: '/promocode/create',
      edit: '/promocode/edit',
      review: '/promocode/review',
    },
    coupons: {
      list: '/coupons',
    },
    shortlinks: {
      list: '/short-links',
    },
  },
  catalog: {
    products: {
      list: '/catalog/products',
      view: '/catalog/product',
      create: '/catalog/product/create',
    },
    variants: {
      view: '/catalog/variant',
      create: '/catalog/variant/create',
    },
  },
  eCommerceConfiguration: {
    games: {
      list: '/ecommerce/games',
      create: '/ecommerce/game/create',
      view: '/ecommerce/game/edit',
    },
    warehouses: {
      list: '/ecommerce/warehouses',
      create: '/ecommerce/warehouse/create',
      view: '/ecommerce/warehouse/edit',
    },
    countries: {
      list: '/ecommerce/countries',
      create: '/ecommerce/country/create',
      view: '/ecommerce/country/edit',
    },
    shippingMethods: '/ecommerce/shipping-methods',
    substitutes: '/ecommerce/sku-substitutes',
  },
  orders: {
    list: '/orders',
    view: '/order',
    create: '/order/create',
    returns: {
      list: '/returns',
      view: '/return',
    },
    issues: {
      list: '/issues',
      view: '/issue',
    },
    subscriptions: {
      list: '/subscriptions',
      view: '/subscription',
    },
  },
  users: {
    accounts: {
      list: '/users/accounts',
      edit: '/users/account',
    },
    emailSubscriptions: {
      list: '/users/communications/subscriptions',
      edit: '/users/communications/subscription',
    },
    profiles: {
      list: '/users/profiles',
      edit: '/users/profile',
    },
    credits: '/users/credits',
  },
  tools: {
    permissions: {
      list: '/users',
      edit: '/user/edit',
      review: '/user/review',
    },
    ipWhitelist: {
      list: '/ip-whitelist',
    },
  },
  login: '/login',
  logout: '/logout',
};

export default paths;
