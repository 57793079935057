import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, FormSpy } from 'react-final-form';
import { Grid, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';

import { validateOrder } from 'utils';
import { SEVERITY } from 'config';
import { ORDERS_WRITE } from 'config/roles';
import { ORDER_ACTION_ERROR, ORDER_ACTION_SUCCESS } from 'config/messages';
import paths from 'routes/paths';

import generalSelectors from 'store/general/selectors';
import userSelectors from 'store/user/selectors';
import { CREATE_ORDER } from 'store/orders/actions';

import SetPageTitle from 'components/common/SetPageTitle';
import PageFormLayout from 'components/layout/PageFormLayout';
import ErrorDisplay from 'components/common/DisplayError';

import OrderForm from './components/OrderForm';

const initialValues = {
  import_order_id: '',
  skus: null,
  email: '',
  description: '',
  source: '',
  coupon: '',
  po_number: '',
  price_paid: null,
  shipping_cost: null,
  shipping_method: 'default',
  country: '',
  zip: '',
  send_emails: false,
  shipping: null,
  billing: null,
};

const CreateOrder = ({
  // From Redux
  dispatch,
  userRoles,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const formRef = useRef(null);
  const purchasableProducts = useSelector((state) =>
    generalSelectors.getSkuDetailsForCountry(state, selectedCountry),
  );
  const canEdit = userRoles.includes(ORDERS_WRITE);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // When the shipping country changes,
    // update the skus value to only keep available skus in the new shipping country
    const skus = formRef.current.getFieldState('skus')?.value;
    if (skus && purchasableProducts) {
      const filteredSkus = skus.filter((sku) =>
        purchasableProducts.find((product) => sku === product.id),
      );
      formRef.current.change('skus', filteredSkus);
    }
  }, [selectedCountry, purchasableProducts]);

  const getTitle = () => {
    return 'Create an order';
  };

  const handleCancel = () => {
    history.push(paths.orders.list);
  };

  const handleSubmit = (values) => {
    let formValues = { ...values };
    const cart = values.skus.reduce((acc, sku) => {
      const skuQty = values[`${sku}-qty`];

      delete formValues[`${sku}-qty`]; // We don't want to send this value to the API

      if (!skuQty || skuQty === '0') {
        return acc;
      }

      const product = purchasableProducts.find((prod) => prod.id === sku);
      const price =
        product.price?.discounted_price || product.price?.original_price || 0;

      return {
        ...acc,
        [sku]: {
          quantity: parseInt(skuQty),
          item_price: price,
          price_paid: price * parseInt(skuQty),
        },
      };
    }, {});

    const shippingData = formValues.shipping || {};
    const billingData = formValues.billing || {};

    // Delete values we don't want to send to the API
    delete formValues.import_order_id;
    delete formValues.skus;
    delete formValues.billing;
    delete formValues.shipping;

    const data = {
      ...formValues,
      cart,
      ...shippingData,
      ...billingData,
    };

    dispatch({
      type: CREATE_ORDER,
      payload: {
        data,
        callback: (orderId) => {
          history.push(`${paths.orders.view}/${orderId}`);
          enqueueSnackbar(ORDER_ACTION_SUCCESS, {
            variant: SEVERITY.SUCCESS,
          });
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${ORDER_ACTION_ERROR} : ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const setCountry = (args, state, utils) => {
    const country = args[0];
    setSelectedCountry(country);
  };

  const setEmail = (formData) => {
    if (
      !formData?.values?.email?.length &&
      formData.values.source === 'admin'
    ) {
      formData.values.email = 'manual-order@playosmo.com';
    }
  };

  return (
    <div>
      <SetPageTitle pageTitle={getTitle()} />
      <Form
        onSubmit={handleSubmit}
        validate={validateOrder}
        initialValues={initialValues}
        mutators={{
          setCountry,
        }}
        render={({ handleSubmit, values, errors, form }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit} noValidate>
              {
                <FormSpy
                  onChange={(formData) => {
                    setEmail(formData);
                  }}
                />
              }
              <PageFormLayout
                title={getTitle()}
                isLoading={false}
                breadcrumbs={[
                  {
                    label: 'Orders listing',
                    link: paths.orders.list,
                  },
                  {
                    label: getTitle(),
                    isCurrent: true,
                  },
                ]}
                showSubmitButtons={canEdit}
                handleCancel={handleCancel}
                showProductionWarning={canEdit}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} className="mb-30">
                    <Paper className="p20">
                      <OrderForm
                        values={values}
                        form={form}
                        purchasableProducts={purchasableProducts}
                        onCountryChange={(country) =>
                          form.mutators.setCountry(country)
                        }
                      />
                      {errors?.general && (
                        <Grid item xs={12} className="">
                          <ErrorDisplay message={errors.general} />
                        </Grid>
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              </PageFormLayout>
            </form>
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(CreateOrder);
