import React from 'react';
import { Grid, Typography } from '@mui/material';

import ContentFeature from './ContentFeature';
import ContentSubSection from './ContentSubSection';

const ContentSection = ({ type, values, setValue }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          Content
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Featured Section
        </Typography>
        <ContentFeature
          sectionId="contentFeature"
          values={values}
          setValue={setValue}
        />
      </Grid>
      <ContentSubSection />
    </Grid>
  );
};

export default React.memo(ContentSection);
