import osmoColors from 'utils/ui/colors';

const appBarStyles = (theme) => ({
  backdropFilter: 'blur(6px)',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  boxShadow: 'none',
  borderBottom: `1px solid ${osmoColors.grey[70]}`,
  color: osmoColors.grey[10],
});

export default appBarStyles;
