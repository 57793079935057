import { Field, Form } from 'react-final-form';
import { Button, Grid, Paper } from '@mui/material';
import TextInput from 'components/common/TextInput';

export const initialValues = {
  myOsmoUserID: '',
  profileID: '',
};

const ProfilesSearchForm = ({
  onFiltersChange,
  initialValues: initialValuesFromProps,
}) => {
  const handleSubmit = (values) => {
    onFiltersChange(values);
  };
  const handleReset = (form) => {
    form.reset(initialValues);
    onFiltersChange(initialValues, true);
  };
  return (
    <Paper className="p20">
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValuesFromProps || initialValues}
        render={({ form, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="myOsmoUserID"
                  component={TextInput}
                  type="text"
                  label="myOsmo user ID"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="profileID"
                  component={TextInput}
                  type="text"
                  label="Profile ID"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  className="mr-20"
                  size="small"
                  variant="outlined"
                  type="button"
                  onClick={() => handleReset(form)}
                >
                  Reset
                </Button>
                <Button
                  className="button"
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Paper>
  );
};

export default ProfilesSearchForm;
