const getUsersWithRoles = (state) => state.policies.users;
const getRoles = (state) => state.policies.roles;
const getUsersWithRolesArray = (state) =>
  Object.values(state.policies.users).sort((a, b) => {
    const aFN = a.first_name.toLowerCase();
    const bFN = b.first_name.toLowerCase();
    if (aFN < bFN) return -1;
    if (aFN > bFN) return 1;
    return 0;
  });

const policiesSelectors = {
  getUsersWithRoles,
  getRoles,
  getUsersWithRolesArray,
};

export default policiesSelectors;
