import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_UPCOMING_CAMPAIGN,
  GET_PAST_CAMPAIGN,
  UPCOMING_CAMPAIGN_SUCCESS,
  PAST_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN,
} from './actions';
import { handleException } from 'store/general/sagas';
import { SET_LOADER } from 'store/general/actions';

import { getAPIData, deleteAPIData } from 'api/playosmo';
import { API } from 'axiosClient/config';

function* getUpcomingCampaign(action) {
  const { callback, errorCallback, website } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const upcomingCampaign = yield call(
      getAPIData,
      API.coupons +
        `?limit=&cursor=&ended=false&website=${
          website === 'ALL' ? '' : website
        }`,
    );

    if (!upcomingCampaign || upcomingCampaign.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    const campaign = {
      upcomingCampaign: upcomingCampaign.data.coupons
        .sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
        .map((coupon) => ({
          ...coupon,
          is_live:
            new Date(coupon.starts_on) < new Date() &&
            new Date() < new Date(coupon.expires_on),
          is_expired: new Date() > new Date(coupon.expires_on),
        })),
    };

    yield put({ type: UPCOMING_CAMPAIGN_SUCCESS, payload: { campaign } });

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });

    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* getPastCampaign(action) {
  const { callback, errorCallback, website } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const pastCampaign = yield call(
      getAPIData,
      API.coupons +
        `?limit=&cursor=&ended=true&website=${website ? website : ''}`,
    );

    if (!pastCampaign || pastCampaign.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback();
      }
      return;
    }

    const campaign = {
      pastCampaign: pastCampaign.data.coupons
        .sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
        .map((coupon) => ({
          ...coupon,
          is_live:
            new Date(coupon.starts_on) < new Date() &&
            new Date() < new Date(coupon.expires_on),
          is_expired: new Date() > new Date(coupon.expires_on),
        })),
    };

    yield put({ type: PAST_CAMPAIGN_SUCCESS, payload: { campaign } });

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* deleteCampaign(action) {
  const { callback, errorCallback, id } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const response = yield call(deleteAPIData, `${API.coupons}/${id}`);
    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback();
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    if (errorCallback) {
      yield put({
        type: SET_LOADER,
        payload: {
          isLoading: false,
        },
      });
      errorCallback();
    }
  }
}

export function* campaignListSaga() {
  yield takeLatest(GET_UPCOMING_CAMPAIGN, getUpcomingCampaign);
  yield takeLatest(GET_PAST_CAMPAIGN, getPastCampaign);
  yield takeLatest(DELETE_CAMPAIGN, deleteCampaign);
}
