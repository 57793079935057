export const tableConfig = [
  {
    title: 'Alias',
    id: 'alias',
  },
  {
    title: 'Description',
    id: 'title',
  },
  {
    title: 'Short Link',
    id: 'short_link',
  },
  {
    title: 'URL',
    id: 'url',
  },

  {
    title: 'Actions',
    id: 'actions',
    sorting: false,
    actions: [
      {
        text: 'Edit',
        type: 'Edit',
      },
      {
        text: 'Delete',
        type: 'Delete',
      },
    ],
  },
];

export const TableCellStates = {
  ACTIONS: 'actions',
  URL: 'url',
  SHORT_LINK: 'short_link',
  TITLE: 'title',
  ALIAS: 'alias',
};
