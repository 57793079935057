import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import { ORDER_ACTION_ERROR, ORDER_ACTION_SUCCESS } from 'config/messages';

import { PERFORM_ORDER_ACTION } from 'store/orders/actions';

import CheckboxComponent from 'components/common/CheckboxComponent';

function ActionsForm({ order, disabled }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleActionFormSubmit = (values) => {
    dispatch({
      type: PERFORM_ORDER_ACTION,
      payload: {
        data: {
          orderId: order.orderId,
          applyToAll: values.applyToParentChild,
          cancel: values.cancel,
          cancelDigitalRedeem: values.cancelDigital,
          cancelSubscriptions: values.cancelSubscriptions,
          cancelShipment: values.property,
          refund: values.refund,
        },
        callback: () => {
          enqueueSnackbar(ORDER_ACTION_SUCCESS, {
            variant: SEVERITY.SUCCESS,
          });
          window.location.reload();
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${ORDER_ACTION_ERROR} : ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  return (
    <Form
      onSubmit={handleActionFormSubmit}
      initialValues={{
        refund: false,
        cancel: false,
        cancelDigital: false,
        cancelSubscriptions: false,
        cancelShipment: false,
        applyToParentChild: false,
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate className="mb-20">
          <div className="mb-10">
            <Field name="refund" component={CheckboxComponent} label="Refund" />
            <Field name="cancel" component={CheckboxComponent} label="Cancel" />
            <Field
              name="cancelDigital"
              component={CheckboxComponent}
              label="Cancel Digital"
            />
            <Field
              name="cancelSubscriptions"
              component={CheckboxComponent}
              label="Cancel Subscriptions"
            />
            <Field
              name="cancelShipment"
              component={CheckboxComponent}
              label="Cancel Shipment"
            />
            <Field
              name="applyToParentChild"
              component={CheckboxComponent}
              label="Apply to Parent and Child Order(s)"
            />
          </div>
          <Button
            size="small"
            variant="contained"
            type="submit"
            disabled={disabled}
          >
            Submit
          </Button>
        </form>
      )}
    />
  );
}

export default ActionsForm;
