import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { DELETE_SECTION_HEADING_DATA } from '../../store/actions';
import Action from 'components/common/TableActions/Actions';
import TableComponent from 'components/common/TableComponent';
import EmptyStateMessage from 'components/common/EmptyStateMessage';
import { tableConfig, TableCellStates } from './tableConfig';
import TableActions from 'components/common/TableActions';
import { TableActionStates } from 'enums/tableStates';

const SubsectionList = (props) => {
  const { handleEditList } = props;
  const { contentSubsectionsData } = useSelector(
    (state) => state.createCampaignReducer,
  );
  const [selectedRows, setSelectedRows] = useState([]);

  const dispatch = useDispatch();

  const onRowSelectChange = (selectedRow) => {
    setSelectedRows(selectedRow);
  };

  const handleEdit = (record) => {
    handleEditList(record);
  };

  const handleDelete = (record) => {
    const filteredRecord = contentSubsectionsData.filter(
      (item) => item.id !== record.id,
    );
    dispatch({
      type: DELETE_SECTION_HEADING_DATA,
      payload: {
        contentData: filteredRecord,
      },
    });
  };

  const options = {
    selectedRows,
    onRowSelectChange: onRowSelectChange,
    selectionKey: 'id',
    rowsPerPage: 5,
  };

  const renderActions = (cell, record) => {
    return cell.actions.map((action) => {
      switch (action.type) {
        case TableActionStates.EDIT:
          return (
            <Action text={action.text} action={() => handleEdit(record)} />
          );

        case TableActionStates.DELETE:
          return (
            <Action text={action.text} action={() => handleDelete(record)} />
          );
        default:
          return null;
      }
    });
  };

  const headCells = tableConfig.map((cell) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return <TableActions>{renderActions(cell, record)}</TableActions>;
        };
        return cell;
      case TableCellStates.SECTION_NAME:
        cell.render = (record, index) => {
          return <div>section-{index + 1}</div>;
        };
        return cell;
      case TableCellStates.SECTION_HEADING:
        cell.render = (record, index) => {
          return <div>{record?.heading[0]?.heading}</div>;
        };
        return cell;
      default:
        return cell;
    }
  });

  return (
    <Grid item xs={12}>
      {!contentSubsectionsData.length && (
        <EmptyStateMessage message="No subsections for now" />
      )}
      {contentSubsectionsData.length > 0 && (
        <TableComponent
          rows={contentSubsectionsData}
          options={options}
          headCells={headCells}
        />
      )}
    </Grid>
  );
};

export default React.memo(SubsectionList);
