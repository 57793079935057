import React from 'react';
import './style.scss';

const ColorPicker = ({ value, onChange, label, disabled }) => {
  const color = !!value ? value : '#ddd';
  const handleChange = (e) => {
    const { value } = e.target;
    onChange(value);
  };
  return (
    <div>
      <div>
        <label className="subHeader">{label}</label>
      </div>
      <label className="color-selector mt-10">
        <span className="circle" style={{ background: color }} />
        <span>{color}</span>
        <input
          type="color"
          value={color}
          onChange={handleChange}
          className="hidden"
          disabled={disabled}
        />
      </label>
    </div>
  );
};

export default ColorPicker;
