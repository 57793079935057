import {
  SET_PRODUCT_DISCOUNT,
  DELETE_PRODUCT_DISCOUNT,
  EDIT_PRODUCT_DISCOUNT,
  CAMPAIGN_DETAILS_SUCCESS,
  RESET_CAMPAIGN_DETAILS,
  SET_BANNERS_HEADERS,
  SET_CONTENT_HEADING_DATA,
  EDIT_CONTENT_HEADING_DATA,
  DELETE_CONTENT_HEADING_DATA,
  CANCEL_CONTENT_SECTION_DATA,
  SUBMIT_CONTENT_SECTION_DATA,
  DELETE_SECTION_HEADING_DATA,
  SET_BANNER_DATA,
  EDIT_BANNER_DATA,
} from './actions';

import {
  contentSubsectionTransformEdit,
  createDiscountedProductsList,
  featureTransformEdit,
  createBannerSection,
} from 'utils';

const initialState = {
  discountedProducts: [],
  contentFeature: [],
  contentSection: [],
  contentSubsectionsData: [],
  bannerHeader: {
    default: [],
    tablet: [],
    mobile: [],
  },
  bannerFeature: {
    default: [],
    tablet: [],
    mobile: [],
  },
  campaignData: {
    type: 'discount',
    countries: [], // List of supported countries

    discount_original_price: true, // Always set to true
    campaign: '', // Campaign key (used to identify orders)
    campaign_name: '',
    created_by: '',
    starts_on: '', // Timestamp of the start date & time
    expires_on: '', // Timestamp of the end date & time
    show_on_pages: '',
    hide_on_pages: '',
    hide_on_shopping_tabs: '',
    link_to: '',
    is_default: 'global', // Is this campaign available by default on the website (without special link
    limitless: true,
    website: '',
  },
};

export const createCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_DETAILS_SUCCESS: {
      const { campaignDetails, skuDetails } = action.payload;
      const discounted_skus = campaignDetails.discounted_skus
        ? campaignDetails.discounted_skus
        : [];
      const discountedProducts = createDiscountedProductsList(
        skuDetails,
        discounted_skus,
      );

      const contentFeature = campaignDetails.content?.featured_sections?.heading
        ? featureTransformEdit(
            campaignDetails.content.featured_sections.heading,
          )
        : [];

      const hideFeature =
        campaignDetails.content?.featured_sections
          ?.hide_existing_featured_sections;

      const contentSubsectionsData = campaignDetails.content?.featured_sections
        ?.subsections
        ? contentSubsectionTransformEdit(
            campaignDetails.content.featured_sections.subsections,
          )
        : [];
      const {
        bannerHeader = {},
        show_on_pages,
        hide_on_pages,
        hide_on_shopping_tabs,
        link_to,
        bannerFeature = {},
      } = createBannerSection(campaignDetails.banners);

      const campaignData = Object.assign({}, state.campaignData);
      campaignData.campaign_name = campaignDetails.campaign_name;
      campaignData.countries = [...campaignDetails.countries];
      campaignData.is_default = campaignDetails.is_default ? 'global' : 'url';
      campaignData.starts_on = campaignDetails.starts_on;
      campaignData.expires_on = campaignDetails.expires_on;
      campaignData.campaign = campaignDetails.campaign;
      campaignData.hideFeature = hideFeature;
      campaignData.banners = { ...campaignDetails.banners };
      campaignData.featureSku =
        campaignDetails.content?.featured_sections?.skus || [];
      campaignData.show_on_pages = show_on_pages;
      campaignData.hide_on_pages = hide_on_pages;
      campaignData.hide_on_shopping_tabs = hide_on_shopping_tabs;
      campaignData.link_to = link_to;
      campaignData.limitless = !!campaignDetails.limitless;
      campaignData.units = campaignDetails.units;
      campaignData.orders_per_customer = campaignDetails.orders_per_customer;
      campaignData.new_customer_only = campaignDetails.new_customer_only;
      campaignData.website = campaignDetails.websites[0];
      return {
        ...state,
        discountedProducts,
        campaignData,
        contentFeature,
        contentSubsectionsData,
        bannerHeader,
        bannerFeature,
      };
    }

    case SET_PRODUCT_DISCOUNT: {
      const { discountedProducts } = action.payload;
      return {
        ...state,
        discountedProducts: [...discountedProducts],
      };
    }

    case DELETE_PRODUCT_DISCOUNT: {
      const { discountedProducts } = action.payload;
      return {
        ...state,
        discountedProducts: [...discountedProducts],
      };
    }

    case EDIT_PRODUCT_DISCOUNT: {
      const { discountedProducts } = action.payload;
      return {
        ...state,
        discountedProducts: [...discountedProducts],
      };
    }

    case RESET_CAMPAIGN_DETAILS: {
      return {
        ...initialState,
      };
    }

    case SET_BANNERS_HEADERS: {
      let campaignData = Object.assign({}, state.campaignData);
      campaignData.banners.header.images = action.payload;

      return {
        ...state,
        campaignData,
      };
    }
    case SET_CONTENT_HEADING_DATA: {
      const { headingObj, sectionId } = action.payload;
      return {
        ...state,
        [sectionId]: [...state[sectionId], { ...headingObj }],
      };
    }
    case EDIT_CONTENT_HEADING_DATA: {
      const { contentData, sectionId } = action.payload;
      return {
        ...state,
        [sectionId]: [...contentData],
      };
    }
    case DELETE_CONTENT_HEADING_DATA: {
      const { contentData, sectionId } = action.payload;
      return {
        ...state,
        [sectionId]: [...contentData],
      };
    }
    case CANCEL_CONTENT_SECTION_DATA: {
      return {
        ...state,
        contentSection: [],
      };
    }
    case SUBMIT_CONTENT_SECTION_DATA: {
      const { contentArr } = action.payload;
      return {
        ...state,
        contentSection: [],
        contentSubsectionsData: [...contentArr],
      };
    }
    case DELETE_SECTION_HEADING_DATA: {
      const { contentData } = action.payload;
      return {
        ...state,
        contentSubsectionsData: [...contentData],
      };
    }
    case SET_BANNER_DATA: {
      const { bannerData, tabId, bannerId } = action.payload;
      return {
        ...state,
        [bannerId]: {
          ...state[bannerId],
          [tabId]:
            state[bannerId] && state[bannerId][tabId]
              ? [...state[bannerId][tabId], { ...bannerData }]
              : [{ ...bannerData }],
        },
      };
    }
    case EDIT_BANNER_DATA: {
      const { bannerArr, tabId, bannerId } = action.payload;
      return {
        ...state,
        [bannerId]: {
          ...state[bannerId],
          [tabId]: [...bannerArr],
        },
      };
    }
    default:
      return state;
  }
};
