import React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material';

import generalSelectors from 'store/general/selectors';

import theme from './theme';

function AppTheme(props) {
  const { apiEnv } = props;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="app-container">{props.children}</div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const mapStateToProps = (state) => ({
  apiEnv: generalSelectors.getAPIEnv(state),
});

export default connect(mapStateToProps)(AppTheme);
