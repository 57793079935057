const DEV_COUPON_URL = `https://develop.dev.playosmo.com/--/shopping/?c=`;
const BETA_DEV_COUPON_URL = `https://beta.dev.playosmo.com/--/shopping/?c=`;
const HOTFIX_DEV_COUPON_URL = `https://hotfix.dev.playosmo.com/--/shopping/?c=`;
const RELC_DEV_COUPON_URL = `https://relc.dev.playosmo.com/--/shopping/?c=`;
const COUPON_URL = `https://www.playosmo.com/--/shopping/?c=`;

const DEV_BYJUS_COUPON_URL = `https://develop.dev.byjuslearning.com?c=`;
const BETA_DEV_BYJUS_COUPON_URL = `https://beta.dev.byjuslearning.com?c=`;
const HOTFIX_DEV_BYJUS_COUPON_URL = `https://hotfix.dev.byjuslearning.com?c=`;
const RELC_DEV_BYJUS_COUPON_URL = `https://relc.dev.byjuslearning.com?c=`;
const BYJUS_COUPON_URL = `https://www.byjuslearning.com?c=`;

export const LOCAL = 'LOCAL';
export const DEVELOP = 'DEVELOP';
export const RELC = 'RELC';
export const HOTFIX = 'HOTFIX';
export const BETA = 'BETA';
export const PRODUCTION = 'PRODUCTION';
export const LATHA = 'LATHA';
export const MARION = 'MARION';
export const BINIT = 'BINIT';
export const CLEMENT = 'CLEMENT';
export const ERWAN = 'ERWAN';
export const ERWAN_LE = 'ERWAN LE';
export const ABHIJIT = 'ABHIJIT';

export const ENVIRONMENTS = [BETA, RELC, HOTFIX, PRODUCTION];
export const DEVELOPER_ENVS = [
  LOCAL,
  DEVELOP,
  LATHA,
  MARION,
  BINIT,
  CLEMENT,
  ERWAN,
  ERWAN_LE,
  ABHIJIT,
];

// Get the actual environment based on the URL
export const getActualEnvironment = () => {
  let env = window.location.hostname;
  switch (env) {
    case 'local.dev.admin.playosmo.com':
      return LOCAL;
    case 'develop.dev.admin.playosmo.com':
      return DEVELOP;
    case 'hotfix.dev.admin.playosmo.com':
      return HOTFIX;
    case 'relc.dev.admin.playosmo.com':
      return RELC;
    case 'admin.playosmo.com':
      return PRODUCTION;
    default:
      return null;
  }
};

// Get the default environment value used to select the initial environment data loaded (campaigns from relc for instance)
export const getInitialEnvironmentValue = () => {
  let env = window.location.hostname;
  switch (env) {
    case 'local.dev.admin.playosmo.com':
      return LOCAL;
    case 'develop.dev.admin.playosmo.com':
      return DEVELOP;
    case 'hotfix.dev.admin.playosmo.com':
      return HOTFIX;
    case 'relc.dev.admin.playosmo.com':
      return RELC;
    case 'admin.playosmo.com':
      return PRODUCTION;
    default:
      return null;
  }
};

// Set default coupon environment based on the admin tool URL.
// The "env" value in the local storage is updated later when the user changes it by duplicating or switching env in the header bar.
let env = localStorage.getItem('env');
if (!env) {
  env = getInitialEnvironmentValue();
  localStorage.setItem('env', env);
}

export const getCurrentEnvironment = () => {
  return localStorage.getItem('env');
};

export const getCouponURL = () => {
  let env = getCurrentEnvironment();
  switch (env) {
    case LOCAL:
    case DEVELOP:
      return DEV_COUPON_URL;
    case BETA:
      return BETA_DEV_COUPON_URL;
    case HOTFIX:
      return HOTFIX_DEV_COUPON_URL;
    case RELC:
      return RELC_DEV_COUPON_URL;
    case PRODUCTION:
      return COUPON_URL;
    default:
      return null;
  }
};
export const getByjusCouponURL = () => {
  let env = getCurrentEnvironment();
  switch (env) {
    case LOCAL:
    case DEVELOP:
      return DEV_BYJUS_COUPON_URL;
    case BETA:
      return BETA_DEV_BYJUS_COUPON_URL;
    case HOTFIX:
      return HOTFIX_DEV_BYJUS_COUPON_URL;
    case RELC:
      return RELC_DEV_BYJUS_COUPON_URL;
    case PRODUCTION:
      return BYJUS_COUPON_URL;
    default:
      return null;
  }
};

// These variables reflect the environment currently selected for which we're displaying the data (campaigns from relc for instance)
export const IS_LOCAL = getCurrentEnvironment() === LOCAL;
export const IS_DEVELOP = getCurrentEnvironment() === DEVELOP;
export const IS_BETA = getCurrentEnvironment() === BETA;
export const IS_HOTFIX = getCurrentEnvironment() === HOTFIX;
export const IS_RELC = getCurrentEnvironment() === RELC;
export const IS_PROD = getCurrentEnvironment() === PRODUCTION;

// These variables reflect the current server environment (ie. RELC for https://relc.dev.admin.playosmo.com/)
export const IS_LOCAL_SERVER = getActualEnvironment() === LOCAL;
export const IS_DEVELOP_SERVER = getActualEnvironment() === DEVELOP;
export const IS_BETA_SERVER = getActualEnvironment() === BETA;
export const IS_HOTFIX_SERVER = getActualEnvironment() === HOTFIX;
export const IS_RELC_SERVER = getActualEnvironment() === RELC;
export const IS_PROD_SERVER = getActualEnvironment() === PRODUCTION;
