import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import { AppBar, Button, Grid, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { makeStyles } from '@mui/styles';

import appBarStyles from 'utils/ui/appBarStyles';

import Menu from 'components/common/Menu';
import Container from 'components/common/Container';

import './styles.scss';

const useStyles = makeStyles((theme) => ({
  appbar: appBarStyles(theme),
}));

function OrderPageLayout({
  className = '',
  title,
  status,
  createdOn,
  buttons,
  isLoading,
  handleClickBack,
  children,
}) {
  const hasButtons = !!(buttons && buttons.length);
  const classes = useStyles();

  const getButton = (button) => {
    const isSelectButton = !!button.options;

    if (isSelectButton) {
      return (
        <Menu
          key={button.key || button.label.replace(' ', '')}
          id="child-orders-menu"
          button={button}
          options={button.options}
        />
      );
    }

    return (
      <Button
        key={button.label.replace(' ', '')}
        type={button.type || 'button'}
        variant={button.variant || 'contained'}
        disableElevation
        size={button.size || 'small'}
        onClick={button.onClick}
        disabled={button.disabled}
        color={button.color}
        className="ml-10"
      >
        {button.label}
      </Button>
    );
  };

  return (
    <div className={clsx('order-page-layout', className)}>
      <div className="order-page-layout-header mb-30">
        <AppBar position="sticky" className={classes.appbar}>
          <div className="order-page-layout-info">
            <Grid container alignItems="center">
              <Grid
                item
                xs={12}
                md="auto"
                className="order-page-layout-info-left"
              >
                {handleClickBack && (
                  <Button onClick={handleClickBack} variant="text">
                    <ChevronLeftIcon />
                    Back
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} md className="order-page-layout-info-left">
                <Typography
                  variant="h1"
                  className="order-page-layout-info-title"
                >
                  {title}
                </Typography>
                {!!status && !!status.length && (
                  <div
                    className={`order-page-layout-info-status ${status
                      .toLowerCase()
                      .replaceAll('_', '-')}`}
                  >
                    {status}
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md="auto"
                className="order-page-layout-info-right"
              >
                {hasButtons && (
                  <div className="order-page-layout-info-buttons">
                    {buttons.map((button) => getButton(button))}
                  </div>
                )}
                {!!createdOn && (
                  <div>
                    Created on{' '}
                    {DateTime.fromISO(createdOn).toLocaleString({
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: '2-digit',
                      timeZoneName: 'short',
                    })}
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </AppBar>
      </div>

      <Container className="order-page-layout-content">
        {!isLoading ? (
          children
        ) : (
          <Skeleton height={300} animation="wave" variant="rectangular" />
        )}
      </Container>
    </div>
  );
}

export const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
  createdOn: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      color: PropTypes.string,
      type: PropTypes.string,
      options: PropTypes.array,
      onClick: PropTypes.func,
    }),
  ),
  handleClickBack: PropTypes.func,
  isLoading: PropTypes.bool,
};

OrderPageLayout.propTypes = propTypes;

export default memo(OrderPageLayout);
