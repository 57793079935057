export const tableConfig = [
  {
    title: 'Status',
    id: 'status',
  },
  {
    title: 'Order ID',
    id: 'references.order',
  },
  {
    title: 'Issue',
    id: 'issue',
  },
  {
    title: 'Stage',
    id: 'issue_category',
  },
  {
    title: 'Taken By',
    id: 'acked_by',
  },

  {
    title: 'Actions',
    id: 'actions',
    sorting: false,
    actions: [
      {
        text: 'Edit',
        type: 'Edit',
      },
      {
        text: 'Delete',
        type: 'Delete',
      },
    ],
  },
];

export const TableCellStates = {
  STATUS: 'status',
  ACTIONS: 'actions',
  ORDER_ID: 'references.order',
  STAGE: 'issue_category',
  TAKEN_BY: 'acked_on',
  ISSUE: 'issue',
};
