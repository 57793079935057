import React from 'react';
import { DisplayPrice } from '@tangibleplay/ecommerce-ui';
import { InfoRounded } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import TableComponent from 'components/common/TableComponent';
import CartModal from './CartModal';
import './index.scss';
const keys = {
  ID: 'id',
  PRODUCT: 'product',
  QTY: 'qty',
  ITEM_PRICE: 'item_price',
  PRICE_PAID: 'price_paid',
  DISCOUNT: 'discount',
  REDEEM_CODES: 'redeem_code',
  GIFT_CARD_DETAILS: 'gift_card_details',
};

const CartTable = ({ cart, isDigital, giftCard }) => {
  if (!cart) {
    return null;
  }
  const options = {
    pagination: false,
  };

  const createData = (item) => ({
    [keys.ID]: item.sku,
    [keys.QTY]: item.qty || '-',
    [keys.PRODUCT]: (
      <span className="cart-tooltip">
        {item.productName}
        <Tooltip title={`${item.sku} - ${item.key}`}>
          <InfoRounded fontSize="small" />
        </Tooltip>
      </span>
    ),
    [keys.QTY]: item.qty,
    [keys.ITEM_PRICE]: !item.itemPrice ? (
      '-'
    ) : (
      <DisplayPrice
        price={item.itemPrice}
        currency={item.orderCurrency}
        country={item.orderCountry}
        alwaysBold={true}
        includeMicroData={false}
      />
    ),
    [keys.PRICE_PAID]: !item.pricePaid ? (
      '-'
    ) : (
      <DisplayPrice
        price={item.pricePaid}
        currency={item.orderCurrency}
        country={item.orderCountry}
        alwaysBold={true}
        includeMicroData={false}
      />
    ),
    [keys.DISCOUNT]: !item.discount ? (
      '-'
    ) : (
      <DisplayPrice
        price={item.discount}
        currency={item.orderCurrency}
        country={item.orderCountry}
        alwaysBold={true}
        includeMicroData={false}
      />
    ),
    [keys.REDEEM_CODES]: item.redeemCodes,
    [keys.GIFT_CARD_DETAILS]: (
      <div>
        {giftCard &&
        item.giftCardDetails &&
        item.giftCardDetails.gift_sender_name.length ? (
          <CartModal giftCardDetails={item.giftCardDetails} />
        ) : (
          ''
        )}
      </div>
    ),
  });

  const rows = cart.map((item) => createData(item));
  const headCells = [
    {
      title: 'Product',
      id: keys.PRODUCT,
      render: (record) => record[keys.PRODUCT],
    },
    {
      title: 'Quantity',
      id: keys.QTY,
      render: (record) => record[keys.QTY],
    },
    {
      title: 'Item price',
      id: keys.ITEM_PRICE,
      render: (record) => record[keys.ITEM_PRICE],
    },
    {
      title: 'Price paid',
      id: keys.PRICE_PAID,
      render: (record) => record[keys.PRICE_PAID],
    },
    {
      title: 'Discount',
      id: keys.DISCOUNT,
      render: (record) => record[keys.DISCOUNT],
    },
    {
      title: '',
      id: keys.GIFT_CARD_DETAILS,
      render: (record) => record[keys.GIFT_CARD_DETAILS],
    },
  ];

  if (isDigital) {
    headCells.push({
      title: 'Redeem codes',
      id: keys.REDEEM_CODES,
      render: (record) => record[keys.REDEEM_CODES]?.join(', '),
    });
  }

  return (
    <TableComponent
      className="order-cart-table"
      rows={rows}
      headCells={headCells}
      options={options}
    />
  );
};

export default CartTable;
