import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';

import { getProductManagement } from 'api/products';
import { API } from 'axiosClient/config';
import PageContentLayout from 'components/layout/PageContentLayout';
import SetPageTitle from 'components/common/SetPageTitle';
import normalizeGamesResponse from 'utils/normalizers/api/normalizeGamesResponse';

import GamesTable from './GamesTable';
import SearchInput from 'components/common/SearchInput';
import paths from 'routes/paths';

const GamesListing = () => {
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState([]);
  const [filteredRows, setFilteredRows] = useState(null);
  const history = useHistory();

  const getTitle = () => 'Games';

  const fetchGames = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getProductManagement(API.products.games);
      const normalizedGames = normalizeGamesResponse(response.data.data);
      setGames(normalizedGames);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }, []);

  useEffect(() => {
    fetchGames();
  }, [fetchGames]);

  const handleNewGame = () =>
    history.push(paths.eCommerceConfiguration.games.create);

  const filterGamesByIdOrName = (value) => {
    if (!value?.length) {
      setFilteredRows(null);
    }

    const filteredGames = games.filter(
      (game) => game.id.includes(value) || game.name.includes(value),
    );

    setFilteredRows(filteredGames);
  };

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <PageContentLayout
        title={getTitle()}
        isLoading={loading}
        buttons={[
          {
            label: 'Add a new game',
            onClick: handleNewGame,
          },
        ]}
      >
        <Grid container>
          <Grid item xs={12}>
            <SearchInput
              onSubmit={(val) => filterGamesByIdOrName(val)}
              placeholder="Search by game ID or name"
            />
          </Grid>
          <Grid item xs={12}>
            <GamesTable
              rows={filteredRows || games || []}
              isLoading={loading}
              disablePagination={true}
              rowCount={games && games.length}
            />
          </Grid>
        </Grid>
      </PageContentLayout>
    </>
  );
};

export default GamesListing;
