import { generateID } from 'utils';

const featureTransformEdit = (featureHeading) => {
  const heading = [];
  for (const key in featureHeading) {
    let obj = {};
    obj.language = key;
    obj.heading = featureHeading[key];
    obj.id = generateID();
    heading.push(obj);
  }
  return heading;
};

export default featureTransformEdit;
