import React from 'react';
import { Tabs, Tab, Paper } from '@mui/material';

import TabPanel from './Component/TabPanel';

import './style.scss';

export default function TabsComponent(props) {
  const { selectedTab, tabsDetails, handleChangeTab, elevation = 1 } = props;

  return (
    <div>
      <Paper elevation={elevation}>
        <Tabs
          value={selectedTab}
          centered={true}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="inherit"
        >
          {tabsDetails.map((tab) => (
            <Tab
              key={tab.id}
              label={`${tab.title} ${
                tab?.listCount ? `(${tab?.listCount})` : ''
              }`}
            />
          ))}
        </Tabs>
      </Paper>
      {tabsDetails.map((tab, index) => (
        <TabPanel key={tab.id} value={selectedTab} index={index}>
          {tab.render && tab.render()}
        </TabPanel>
      ))}
    </div>
  );
}
