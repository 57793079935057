import { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { DateTime } from 'luxon';
import { Grid, Button, Paper } from '@mui/material';

import generalSelectors from 'store/general/selectors';
import { FETCH_SKU_DETAILS } from 'store/general/actions';

import TextInput from 'components/common/TextInput';
import SelectDropdown from 'components/common/SelectDropdown';
import DateRangeFilter from 'components/common/DateRangeFilter';
import { getWebsiteDataItem } from 'components/containers/CampaignContainer/CampaignListing/CampaignFilters/filters';

import './styles.scss';

const today = DateTime.now();

export const initialValues = {
  email: '',
  phoneNumber: '',
  firstName: '',
  lastName: '',
  orderID: '',
  dateRange: {
    start: today.minus({ hour: 1 }).toJSDate(),
    end: new Date(),
  },
  website: '',
};

const websiteDataItems = getWebsiteDataItem();

const OrdersFiltersForm = (props) => {
  const {
    onFiltersChange,
    initialValues: initialValuesFromProps,
    // From Redux
    dispatch,
    shippingCountries,
  } = props;

  useEffect(() => {
    if (!shippingCountries?.length) {
      dispatch({
        type: FETCH_SKU_DETAILS,
        payload: {
          includeAdminSkus: true,
          callback: () => {},
          errorCallback: (error) => {},
        },
      });
    }
  }, [dispatch, shippingCountries?.length]);

  const handleReset = (form) => {
    form.reset();

    const filters = {
      startDate: initialValues.dateRange.start,
      endDate: initialValues.dateRange.end,
      orderID: initialValues.orderID,
      email: initialValues.email,
      phoneNumber: initialValues.phoneNumber,
      firstName: initialValues.firstName,
      lastName: initialValues.lastName,
      country: initialValues.country,
      website: initialValues.website,
    };
    onFiltersChange(filters, true);
  };

  const handleSubmit = (values) => {
    const filters = {
      startDate: DateTime.fromJSDate(values.dateRange.start).toSeconds(),
      endDate: DateTime.fromJSDate(values.dateRange.end).toSeconds(),
      orderID: values.orderID,
      email: values.email,
      phoneNumber: values.phoneNumber,
      firstName: values.firstName,
      lastName: values.lastName,
      country: values.country === 'all' ? null : values.country,
      website: values.website?.id === '' ? '' : values.website,
    };
    onFiltersChange(filters);
  };

  const handleDateChange = (value, changeValue) => {
    // Update the date range filter value
    changeValue('dateRange', {
      start: value.start,
      end: new Date(),
    });
  };

  return (
    <Paper className="orders-filters-form mb-30">
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValuesFromProps || initialValues}
        render={({ handleSubmit, form, values, utils }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="email"
                  component={TextInput}
                  type="text"
                  label="Email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="orderID"
                  component={TextInput}
                  type="text"
                  label="Order ID"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="phoneNumber"
                  component={TextInput}
                  type="phone"
                  label="Phone number"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="firstName"
                  component={TextInput}
                  type="text"
                  label="First name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="lastName"
                  component={TextInput}
                  type="text"
                  label="Last name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="country"
                  label="Shipping country"
                  dataItems={[
                    {
                      id: 'all',
                      value: 'All countries',
                    },
                    ...shippingCountries,
                  ]}
                  component={SelectDropdown}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="website"
                  label="Website"
                  dataItems={websiteDataItems}
                  component={SelectDropdown}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  required
                  name="dateRange"
                  component={DateRangeFilter}
                  onChange={(val) => handleDateChange(val, form.change)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className="mt-30"
            >
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      onClick={() => {
                        handleReset(form);
                      }}
                      variant="outlined"
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className="button"
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  shippingCountries: generalSelectors.getShippingCountries(state),
});

export default connect(mapStateToProps)(memo(OrdersFiltersForm));
