const normalizeWarehousesResponse = (data) =>
  data.map((warehouse) => ({
    location: warehouse.country_code_location,
    status: warehouse.is_enabled ? 'active' : 'inactive',
    name: warehouse.name,
    operatedBy: warehouse.operated_by,
    destination: warehouse.destination,
    code: warehouse.warehouse_code,
    id: warehouse.warehouse_code,
  }));

export default normalizeWarehousesResponse;
