import React from 'react';
import { Grid, Typography } from '@mui/material';
import BannerFeature from './Banners/BannerFeature';
import BannerHeader from './Banners/BannerHeader';

const AssetsSection = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          Assets
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <BannerHeader label="Top banner" bannerId="bannerHeader" />
      </Grid>
      <Grid item xs={12}>
        <BannerFeature label="Featured page" bannerId="bannerFeature" />
      </Grid>
    </Grid>
  );
};

export default React.memo(AssetsSection);
