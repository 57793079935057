import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, TextField } from '@mui/material';
import {
  SET_CONTENT_HEADING_DATA,
  EDIT_CONTENT_HEADING_DATA,
} from '../../store/actions';
import SelectComponent from 'components/common/SelectDropdown/SelectComponent';

import './style.scss';
import { SUPPORTED_LANGUAGES } from 'config';
import { generateID } from 'utils';

const AddHeading = (props) => {
  const {
    handleCancel,
    handleSave,
    headingDetails,
    isDefault,
    sectionId,
    listData,
  } = props;
  const [language, setLanguage] = useState('');
  const [heading, setHeading] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (headingDetails) {
      setLanguage(headingDetails.language);
      setHeading(headingDetails.heading);
    }
  }, [headingDetails]);

  const handleSubmit = () => {
    if (headingDetails) {
      const contentData = [...listData];
      const contentIndex = contentData.findIndex(
        (item) => item.id === headingDetails.id,
      );
      if (headingDetails.language !== 'default') {
        contentData[contentIndex].language = language;
      }
      contentData[contentIndex].heading = heading;
      dispatch({
        type: EDIT_CONTENT_HEADING_DATA,
        payload: {
          contentData,
          sectionId,
        },
      });
    } else {
      const headingObj = {
        language: isDefault ? 'default' : language,
        heading,
        id: generateID(),
      };
      dispatch({
        type: SET_CONTENT_HEADING_DATA,
        payload: {
          headingObj,
          sectionId,
        },
      });
    }
    handleSave();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {!isDefault && (
          <SelectComponent
            required
            name="language"
            onChange={(e) => setLanguage(e.target.value)}
            label="Select Language"
            className="discount-country"
            dataItems={SUPPORTED_LANGUAGES}
            value={language}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          type="text"
          label="Heading"
          variant="outlined"
          value={heading}
          onChange={(e) => setHeading(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} className="button-container">
        <Button
          className="mr-20"
          size="small"
          variant="outlined"
          type="button"
          onClick={handleCancel}
        >
          Cancel
        </Button>

        <Button
          className="button"
          size="small"
          variant="contained"
          type="button"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(AddHeading);
