import { normalizeCart } from './normalizeOrdersResponse';

const normalizeReturnsResponse = (returns) => {
  return {
    data: returns.data
      .map((ret) => ({
        id: ret.id,
        orderId: ret.order_id,
        cart: normalizeCart(ret.cart),
        status: ret.status,
        createdOn: ret.created_on,
        receivedOn: ret.received_on,
        returnLabelUrl: ret.label_url,
        carrier: ret.carrier,
        trackingNumber: ret.tracking_number,
      }))
      .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn)),
    cursor: returns.next,
  };
};

export default normalizeReturnsResponse;
