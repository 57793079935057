import { useState } from 'react';

import { fileUpload, deleteFile as deleteFileAPI } from 'api/fileUpload';
import Loader from 'components/common/Loader';

import FileInput from './FileInput';

const FileInputWrapper = ({
  service,
  value,
  tags,
  deleteFromServer = true,
  onChange = () => {},
  onLoad = () => {},
  onError = () => {},
  inputType,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const setLoading = (value) => {
    setIsLoading(value);
    onLoad(value);
  };

  const uploadFile = async (file) => {
    setLoading(true);
    try {
      const fileResp = await fileUpload(service, file[0], tags);

      if (fileResp.data.data) {
        const fileData = fileResp.data.data;
        setLoading(false);
        onChange(fileData);
      } else {
        onError();
        setLoading(false);
      }
    } catch (e) {
      onError();
      setLoading(false);
    }
  };

  const deleteFile = async (file) => {
    if (!deleteFromServer) {
      onChange('');
      return;
    }

    setLoading(true);
    try {
      await deleteFileAPI(service, file.id);
      setLoading(false);
      onChange('');
    } catch (e) {
      setLoading(false);
      onError();
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <FileInput
      onChange={uploadFile}
      value={value}
      remove={deleteFile}
      type={inputType}
      {...rest}
    />
  );
};

export default FileInputWrapper;
