const prefix = 'GENERAL_';

export const SKU_DETAILS_SUCCESS = `${prefix}SKU_DETAILS_SUCCESS`;
export const COUNTRY_SKU_DETAILS_SUCCESS = `${prefix}COUNTRY_SKU_DETAILS_SUCCESS`;
export const ADD_MAILING_LISTS = `${prefix}ADD_MAILING_LISTS`;
export const ADD_REFERENCE_LIST = `${prefix}ADD_REFERENCE_LIST`;
export const SET_ENV = `${prefix}SET_ENV`;
export const SET_LOADER = `${prefix}SET_LOADER`;

// Sagas:
export const FETCH_MAILING_LISTS = `${prefix}FETCH_MAILING_LISTS`;
export const FETCH_SKU_DETAILS = `${prefix}FETCH_SKU_DETAILS`;
export const FETCH_REFERENCE = `${prefix}FETCH_REFERENCE`;
export const FETCH_REFERENCES = `${prefix}FETCH_REFERENCES`;
