import { useSelector } from 'react-redux';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import generalSelectors from 'store/general/selectors';
import TextInput from 'components/common/TextInput';
import AddRow from 'components/containers/GamesContainer/GameView/components/GameManualsSection/GameManuals/AddRow';
import SelectDropdown from 'components/common/SelectDropdown';

import './PricingTable.scss';

const PricingTableRow = ({ name, onDelete, inputBase, isRecurringPrice }) => {
  const currencies = useSelector(generalSelectors.getCurrencyReferences);
  const currenciesData =
    currencies?.map((currency) => ({
      id: currency.code,
      value: currency.name,
    })) || [];

  return (
    <div className="pricing-table-row">
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <Field
            label="Currency"
            component={SelectDropdown}
            name={`${name}.currency_code`}
            dataItems={currenciesData}
            {...inputBase}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            label="MSRP Value"
            component={TextInput}
            type="number"
            name={`${name}.msrp_value`}
            {...inputBase}
            disabled={isRecurringPrice}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            label="Selling Price"
            component={TextInput}
            type="number"
            name={`${name}.selling_price`}
            {...inputBase}
            disabled={isRecurringPrice}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            label="Recurring Price"
            component={TextInput}
            type="number"
            name={`${name}.recurring_price`}
            {...inputBase}
            disabled={!isRecurringPrice}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

const PricingTable = ({ inputBase, isRecurringPrice }) => {
  const { mutators } = useForm();

  const addRow = () => {
    mutators.push('pricing', {
      currency_code: '',
      msrp_value: '',
      selling_price: '',
      recurring_price: '',
    });
  };

  return (
    <div className="pricing-table">
      <Grid container className="mt-10">
        <Grid item xs={8}>
          <FieldArray name={'pricing'}>
            {({ fields }) =>
              !fields?.length ? (
                <>No prices yet</>
              ) : (
                fields.map((name, index) => {
                  return (
                    <PricingTableRow
                      key={name}
                      onDelete={() => fields.remove(index)}
                      name={name}
                      inputBase={inputBase}
                      isRecurringPrice={isRecurringPrice}
                    />
                  );
                })
              )
            }
          </FieldArray>
          <AddRow onClick={addRow} label="Add a row" />
        </Grid>
      </Grid>
    </div>
  );
};

export default PricingTable;
