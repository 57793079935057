import React from 'react';
import Search from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button } from '@mui/material';

import paths from '../../../routes/paths';

export function renderCampaignAction(rowItem, type) {
  return (
    <Box display="flex" alignItems="center">
      <a
        style={{ textDecoration: 'none' }}
        href={`${
          type === 'review'
            ? paths.marketing.campaigns.review
            : paths.marketing.campaigns.edit
        }/${rowItem.id}`}
      >
        <Button
          variant="contained"
          startIcon={type === 'review' ? <Search /> : <EditIcon />}
        >
          {type === 'review' ? 'Review' : 'Edit'}
        </Button>
      </a>
    </Box>
  );
}

export function renderOrderAction(rowItem) {
  return (
    <Box display="flex" alignItems="center">
      <a
        style={{ textDecoration: 'none' }}
        href={`${paths.orders.view}/${rowItem.orderId}`}
      >
        <Button variant="contained" startIcon={<Search />}>
          View
        </Button>
      </a>
    </Box>
  );
}

export function renderPromoAction(rowItem) {
  return (
    <Box display="flex" alignItems="center">
      <a
        style={{ textDecoration: 'none' }}
        href={`${paths.marketing.promocodes.review}/${rowItem.code}`}
      >
        <Button variant="contained" startIcon={<Search />}>
          View
        </Button>
      </a>
    </Box>
  );
}

const actions = { renderCampaignAction, renderOrderAction, renderPromoAction };

export default actions;
