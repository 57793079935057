const osmoColors = {
  red: {
    10: '#5d0f0b',
    20: '#89181a',
    30: '#c01414',
    40: '#e21e1e',
    50: '#ea3729',
    60: '#f1564b',
    70: '#ff6e63',
    80: '#ff958d',
  },
  orange: {
    10: '#57260e',
    20: '#d22f27',
    30: '#ed4224',
    40: '#f77219',
    50: '#ff8a33',
    60: '#ff9a52',
    70: '#f9a96f',
    80: '#ffc499',
  },
  yellow: {
    10: '#653915',
    20: '#e97624',
    30: '#fb911d',
    40: '#fdb519',
    50: '#ffc933',
    60: '#ffd459',
    70: '#fee382',
    80: '#ffeeb3',
    90: '#fffae9',
  },
  blue: {
    10: '#1f1956',
    20: '#0c4d98',
    30: '#026bd7',
    40: '#0096ec',
    50: '#18bbed',
    60: '#90d6ef',
    70: '#b7e2f2',
    80: '#daf0f7',
    90: '#f2fcff',
  },
  grey: {
    10: '#373737',
    20: '#595959',
    30: '#818181',
    40: '#aaaaaa',
    50: '#c9c9c9',
    60: '#e1e1e1',
    70: '#eeeeee',
    80: '#f5f5f5',
  },
  white: '#fff',
  black: '#000',
};

export default osmoColors;
