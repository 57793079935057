import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';

import PageContentLayout from 'components/layout/PageContentLayout';
import SetPageTitle from 'components/common/SetPageTitle';

import { API } from 'axiosClient/config';
import { postAPIDataDifferentOrigin } from 'api/microservices';

import userSelectors from 'store/user/selectors';
import { useErrorStatus } from 'components/hoc/ErrorHandler';

import EmailSubscriptionsTable from './components/EmailSubscriptionsTable';
import SearchInput from 'components/common/SearchInput';

// import './index.scss';

const EmailSubscriptionsListing = ({
  // Redux
  userRoles,
  user,
}) => {
  const { setErrorStatusCode, setErrorMessage } = useErrorStatus();
  const [loading, setLoading] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(40);
  const [rowsTotalCount, setRowsTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const fetchEmails = async ({ emailAddress, limit, pageNumber } = {}) => {
    setLoading(true);
    const payload = {
      data: {
        emails: emailAddress ? [emailAddress] : null,
      },
    };
    const url = `${API.microservices.communications.emailSubscriptions.get}?page=${pageNumber}&limit=${limit}&sort=subscribed_on,desc`;
    const response = await postAPIDataDifferentOrigin(url, payload);

    if (!response || response.status > 208) {
      setErrorStatusCode(response.status || 500);
      setErrorMessage(response.message);
      setLoading(false);
    } else {
      setRows(response.data.data);
      setRowsTotalCount(response.data?.pagination?.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmails({ emailAddress: searchedValue, limit, pageNumber });
  }, [limit, pageNumber, searchedValue]);

  const handleSearch = (value) => {
    setSearchedValue(value);
  };

  const getTitle = () => 'Email Subscriptions';
  const displayedRows = rows.map((row) => ({ ...row, id: row.account_id }));

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <PageContentLayout title={getTitle()} isLoading={loading}>
        <Grid container>
          <Grid item xs={12} className="mb-20">
            <SearchInput
              onSubmit={(val) => handleSearch(val)}
              placeholder="Search by email address"
              defaultValue={searchedValue}
            />
          </Grid>
        </Grid>
        <EmailSubscriptionsTable
          rows={displayedRows}
          rowsPerPage={limit}
          onChangeRowsPerPage={(event, value) => setLimit(value)}
          rowsTotalCount={rowsTotalCount}
          onChangePage={(event, number) => setPageNumber(number)}
          currentPageNumber={pageNumber}
        />
      </PageContentLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
  user: userSelectors.getUserInfo(state),
});

export default connect(mapStateToProps)(EmailSubscriptionsListing);
