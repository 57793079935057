import { generateID } from 'utils';

function bannerDataTransform(bannerArr) {
  bannerArr.forEach(function (banner) {
    banner.id = generateID();
    if (banner.hasOwnProperty('default')) {
      banner.type = 'Default';
    }
    if (banner.hasOwnProperty('country')) {
      banner.type = 'Country';
    }
    if (banner.hasOwnProperty('lang')) {
      banner.type = 'Country';
    }
  });
  return bannerArr;
}

function arrayToStr(arr) {
  return arr.join(',');
}

function transformList(sectionObj) {
  Object.keys(sectionObj).forEach((item) =>
    bannerDataTransform(sectionObj[item]),
  );
  return sectionObj;
}

function createBannerSection(banners) {
  if (!banners) return {};
  const bannerDetails = { ...banners };
  const {
    header: {
      images,
      show_on_pages,
      hide_on_pages,
      hide_on_shopping_tabs,
      link_to,
    },
    featured,
  } = bannerDetails;
  return {
    bannerHeader: transformList(images),
    show_on_pages: arrayToStr(show_on_pages),
    hide_on_pages: arrayToStr(hide_on_pages),
    hide_on_shopping_tabs: arrayToStr(hide_on_shopping_tabs),
    link_to,
    bannerFeature: transformList(featured),
  };
}

export default createBannerSection;
