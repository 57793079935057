import React from 'react';
import { Link, Paper, Typography } from '@mui/material';

import OrderProperty from '../../OrderProperty';
import EditableOrderProperty from '../../EditableOrderProperty';
import paths from 'routes/paths';
import OrderPropertiesWrapper from '../../OrderProperty/OrderPropertiesWrapper';

function CustomerSection({ order, canEdit }) {
  return (
    <Paper className="order-section order-section-customer">
      <Typography variant="h2" gutterBottom>
        Customer
      </Typography>
      <OrderPropertiesWrapper>
        <OrderProperty
          label="First name"
          value={order.billing?.firstName || order.shipping?.firstName}
        />
        <OrderProperty
          label="Last name"
          value={order.billing?.lastName || order.shipping?.lastName}
        />
        <EditableOrderProperty
          order={order}
          id="email"
          label="Email address"
          value={order.email}
          disabled={!canEdit}
        />
        <EditableOrderProperty
          order={order}
          id="phone"
          label="Phone number"
          value={order.billing?.phone || order.shipping?.phone}
          disabled={!canEdit}
          isAddressData={true}
        />
        <OrderProperty label="User ID" value={order.userId} />
        <OrderProperty label="Is repeat" value={order.isRepeat} />
      </OrderPropertiesWrapper>
      {order.isRepeat && (
        <div className="mt-15">
          <Link
            href={`${paths.orders.list}?email=${order.email}&startDate=0`}
            underline="hover"
          >
            View more orders from this customer
          </Link>
        </div>
      )}
    </Paper>
  );
}

export default CustomerSection;
