import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { Grid } from '@mui/material';

import paths from 'routes/paths';
import { getProductManagement } from 'api/products';
import { API } from 'axiosClient/config';
import normalizeProducts from 'utils/normalizers/api/normalizeProducts';
import { RESET_EDITING_PRODUCT } from 'store/products/actions';

import SetPageTitle from 'components/common/SetPageTitle';
import PageContentLayout from 'components/layout/PageContentLayout';
import SearchInput from 'components/common/SearchInput';
import SelectComponent from 'components/common/SelectDropdown/SelectComponent';

import ProductsTable from './components/ProductsTable';

const websitesData = [
  {
    id: 'playosmo',
    value: 'PlayOsmo',
  },
  {
    id: 'byjus-learning',
    value: "BYJU's Learning",
  },
];

export const getWebsiteData = (withAll = false) => {
  const output = [...websitesData];

  if (withAll) {
    output.push({
      id: 'ALL',
      value: 'ALL',
    });
  }

  return output;
};

const statusData = [
  {
    id: 'active',
    value: 'active',
  },
  {
    id: 'inactive',
    value: 'inactive',
  },
  {
    id: 'ALL',
    value: 'ALL',
  },
];

const ProductsListing = () => {
  const getTitle = () => 'Products';
  const rowsPerPage = 20;
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const [page, setPage] = useState(0);
  const [filtersValue, setFiltersValue] = useState({
    search: '',
    status: '',
    website: '',
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchProducts = useCallback(
    async (filters) => {
      setIsLoading(true);

      try {
        const isReady =
          filters.status === 'active'
            ? true
            : filters.status === 'inactive'
            ? false
            : '';
        const website = filters.website === 'ALL' ? '' : filters.website;
        const search = filters.search;
        const response = await getProductManagement(
          `${API.products.products.list}?page=${page}&limit=${rowsPerPage}&is_ready=${isReady}&website=${website}&search=${search}`,
        );

        if (!response?.data?.data || response.status > 208) {
          setRows([]);
          setIsLoading(false);
          return;
        }

        setRows(normalizeProducts(response?.data?.data));
        setRowCount(response?.data?.pagination?.total);
        setIsLoading(false);
      } catch (e) {
        console.log({ e });
        setIsLoading(false);
      }
    },
    [page],
  );

  useEffect(() => {
    fetchProducts(filtersValue);
  }, [filtersValue, fetchProducts]);

  useEffect(() => {
    dispatch({
      type: RESET_EDITING_PRODUCT,
    });
  }, [dispatch]);

  const setFilter = (value, type) => {
    setFiltersValue({
      ...filtersValue,
      [type]: value,
    });
  };

  const handleNewProduct = () => {
    history.push(paths.catalog.products.create);
  };

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <PageContentLayout
        title={getTitle()}
        isLoading={isLoading}
        buttons={[
          {
            label: 'Add a new product',
            onClick: handleNewProduct,
          },
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <SelectComponent
              id="website"
              name="website"
              variant="outlined"
              onChange={(e) => setFilter(e.target.value, 'website')}
              label="Filter by website"
              dataItems={getWebsiteData(true)}
              value={filtersValue.website}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectComponent
              id="status"
              name="status"
              variant="outlined"
              onChange={(e) => setFilter(e.target.value, 'status')}
              label="Filter by status"
              dataItems={statusData}
              value={filtersValue.status}
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <SearchInput
              onSubmit={(val) => setFilter(val, 'search')}
              placeholder="Search by product name or key"
              defaultValue={filtersValue.search}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductsTable
              rows={rows}
              rowsPerPage={rowsPerPage}
              rowCount={rowCount}
              onChangePage={(e, pageNumber) => setPage(pageNumber)}
            />
          </Grid>
        </Grid>
      </PageContentLayout>
    </>
  );
};

export default ProductsListing;
