import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from '@mui/material';
import paths from 'routes/paths';
import { UPDATE_EDITING_PRODUCT_VALUES } from 'store/products/actions';

const Variant = ({
  productKey,
  skuKey,
  sku,
  name,
  warehouses,
  websites,
  render,
  values,
  isUnsaved,
  isCreationMode,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleEditButton = () => {
    dispatch({
      type: UPDATE_EDITING_PRODUCT_VALUES,
      payload: {
        values,
      },
    });
    history.push({
      pathname: `${paths.catalog.variants.view}/${productKey}/${skuKey}`,
      search: `?isProductNew=${isCreationMode}`,
    });
  };

  return (
    <Card sx={{ display: 'flex' }} variant="outlined" className="mb-10">
      <CardMedia component="img" sx={{ width: 151 }} image={render} alt="" />
      <CardContent
        sx={{
          flex: '1 0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography component="div" variant="h4" className="mb-10">
          {name}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', paddingRight: 4 }}
          >
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              SKU: {sku}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Key: {skuKey}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Warehouses: {warehouses?.length ? warehouses.join(', ') : '-'}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Websites: {websites?.length ? websites.join(', ') : '-'}
            </Typography>
            {isUnsaved && (
              <Chip label="Unsaved changes" size="small" variant="outlined" />
            )}
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleEditButton}>
          Edit
        </Button>
      </CardActions>
    </Card>
  );
};

export default Variant;
