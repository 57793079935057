import React from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Typography } from '@mui/material';

import './style.scss';

export default function NoData(props) {
  return (
    <div className="noData-container">
      <ReportProblemIcon />
      <Typography paragraph={true}>No Data</Typography>
    </div>
  );
}
