import React from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import { ORDER_ACTION_ERROR, ORDER_ACTION_SUCCESS } from 'config/messages';

import { UPDATE_ORDER, UPDATE_ORDER_ADDRESS } from 'store/orders/actions';

import EditableProperty from 'components/common/EditableProperty';
import { getInitialValues as getAddressToUpdateValues } from '../sections/ShipmentSection/components/UpdateShippingAddressForm';

import './styles.scss';

const EditableOrderProperty = ({ id, order, isAddressData, ...inputProps }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleFormSubmit = (values) => {
    let data = {
      order_number: order.orderId,
      address_request_id: order.addressId,
      [id]: values[id],
    };

    if (isAddressData) {
      data = {
        ...getAddressToUpdateValues(order),
        ...data,
      };
    }

    dispatch({
      type: isAddressData ? UPDATE_ORDER_ADDRESS : UPDATE_ORDER,
      payload: {
        data,
        callback: () => {
          enqueueSnackbar(ORDER_ACTION_SUCCESS, {
            variant: SEVERITY.SUCCESS,
          });
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${ORDER_ACTION_ERROR} : ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  return (
    <EditableProperty id={id} onSubmit={handleFormSubmit} {...inputProps} />
  );
};

export default EditableOrderProperty;
