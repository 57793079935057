import { useSelector } from 'react-redux';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import AddRow from 'components/containers/GamesContainer/GameView/components/GameManualsSection/GameManuals/AddRow';
import SelectDropdown from 'components/common/SelectDropdown';
import CheckboxComponent from 'components/common/CheckboxComponent';

import './InventoryTable.scss';

const InventoryTableRow = ({ name, onDelete, inputBase }) => {
  const { shippingCountries } = useSelector((state) => state.general);
  const inventoryStatusData = [
    {
      id: 'is_available',
      value: 'is_available',
    },
    {
      id: 'notify_when_available',
      value: 'notify_when_available',
    },
    {
      id: 'not_available',
      value: 'not_available',
    },
  ];

  return (
    <div className="inventory-table-row">
      <Grid container>
        <Grid item xs={3} className="inventory-table-row__country">
          <Field
            label="Country"
            component={SelectDropdown}
            name={`${name}.country`}
            dataItems={shippingCountries}
            {...inputBase}
          />
        </Grid>
        <Grid item xs={4} className="inventory-table-row__status">
          <Field
            label="playosmo"
            component={SelectDropdown}
            name={`${name}.values.playosmo.status`}
            dataItems={inventoryStatusData}
            {...inputBase}
          />
          <Field
            name={`${name}.values.playosmo.isNew`}
            component={CheckboxComponent}
            type="checkbox"
            label="Is new"
            size="small"
            {...inputBase}
          />
        </Grid>
        <Grid item xs={4} className="inventory-table-row__status">
          <Field
            label="byjuslearning"
            component={SelectDropdown}
            name={`${name}.values.byjuslearning.status`}
            dataItems={inventoryStatusData}
            {...inputBase}
          />
          <Field
            name={`${name}.values.byjuslearning.isNew`}
            component={CheckboxComponent}
            type="checkbox"
            label="Is new"
            size="small"
            {...inputBase}
          />
        </Grid>
        <Grid
          item
          xs={1}
          className="inventory-table-row__action"
          alignSelf="center"
        >
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

const InventoryTable = ({ inputBase }) => {
  const { mutators } = useForm();

  const addRow = () => {
    mutators.push('inventory', {
      country: '',
      values: {
        playosmo: {
          status: '',
          isNew: false,
        },
        byjuslearning: {
          status: '',
          isNew: false,
        },
      },
    });
  };

  return (
    <div className="inventory-table">
      <Grid container className="mt-10">
        <Grid item xs={8}>
          <FieldArray name={'inventory'}>
            {({ fields }) =>
              !fields?.length ? (
                <>No inventory yet</>
              ) : (
                fields.map((name, index) => {
                  return (
                    <InventoryTableRow
                      key={name}
                      onDelete={() => fields.remove(index)}
                      name={name}
                      inputBase={inputBase}
                    />
                  );
                })
              )
            }
          </FieldArray>
          <AddRow onClick={addRow} label="Add a row" />
        </Grid>
      </Grid>
    </div>
  );
};

export default InventoryTable;
