import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { store } from 'store';
import { MAX_SNACK } from 'config';
import AppContainer from 'components/containers/AppContainer';
import AppTheme from 'components/common/AppTheme';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'assets/styles/base.scss';
import 'App.scss';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppTheme>
          <SnackbarProvider maxSnack={MAX_SNACK}>
            <AppContainer />
            <div id="modal-container"></div>
          </SnackbarProvider>
        </AppTheme>
      </Router>
    </Provider>
  );
}

export default App;
