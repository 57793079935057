import { useState, useEffect, useCallback } from 'react';
import { Grid } from '@mui/material';

import { SUPPORTED_LANGUAGES } from 'config';

import { getWebsiteData } from 'components/containers/ProductsContainer/ProductsListing/ProductsListing';
import SelectComponent from 'components/common/SelectDropdown/SelectComponent';

import './SectionFilters.scss';

const SectionFilters = ({
  onChange,
  filters = ['import', 'website', 'language'],
  importData,
}) => {
  const [importFrom, setImportFrom] = useState('');
  const [website, setWebsite] = useState('playosmo');
  const [language, setLanguage] = useState('en-US');

  if (filters.includes('import') && !importData) {
    console.warn(
      'Warning in SectionFilters: importData prop is required when you want to display the "import from" select input.',
    );
  }

  const handleChange = useCallback(
    (type, value) => {
      if (type === 'import') {
        setImportFrom(value);
      }
      if (type === 'website') {
        setWebsite(value);
      }
      if (type === 'language') {
        setLanguage(value);
      }
      onChange(type, value);
    },
    [setImportFrom, setWebsite, setLanguage, onChange],
  );

  useEffect(() => {
    handleChange('website', website);
    handleChange('language', language);
    // eslint-disable-next-line
  }, [handleChange]);

  return (
    <div className="section-filters">
      <Grid container justifyContent="flex-end">
        <Grid item xs={3}>
          {!!importData?.length && filters.includes('import') && (
            <SelectComponent
              id="import"
              name="import"
              onChange={(e) => handleChange('import', e.target.value)}
              label="Import from"
              dataItems={importData}
              value={importFrom}
            />
          )}
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={2}>
          {filters.includes('website') && (
            <SelectComponent
              id="website"
              name="website"
              onChange={(e) => handleChange('website', e.target.value)}
              label="Select a website"
              dataItems={getWebsiteData()}
              value={website}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {filters.includes('language') && (
            <SelectComponent
              id="language"
              name="language"
              onChange={(e) => handleChange('language', e.target.value)}
              label="Select a language"
              dataItems={SUPPORTED_LANGUAGES}
              value={language}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SectionFilters;
