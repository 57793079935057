import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useSnackbar } from 'notistack';
import { Paper } from '@mui/material';

import formatBannerFormData from '../utils/formatBannerFormData';

import { IS_DEVELOP, IS_RELC, IS_BETA, PRODUCTION } from 'config/environments';
import { SEVERITY } from 'config';
import { TEXT_BANNER_SAVE_ERROR, TEXT_BANNER_SAVED } from 'config/messages';
import { textBannersStateMapping } from './config';

import paths from 'routes/paths';

import generalSelectors from 'store/general/selectors';
import contentSelectors from 'store/content/selectors';
import {
  GET_TEXT_BANNERS,
  SAVE_TEXT_BANNER,
  UPDATE_TEXT_BANNER,
} from 'store/content/actions';

import { useErrorStatus } from 'components/hoc/ErrorHandler';
import SetPageTitle from 'components/common/SetPageTitle';
import PageFormLayout from 'components/layout/PageFormLayout';
import DuplicateBannerModals from './DuplicateBannerModals';
import TextBannerForm from './TextBannerForm';

const getTextBannerFormValues = (banner) => {
  if (!banner) {
    return {
      languages: null,
      countries: null,
      dateRange: {
        start: new Date(),
        end: new Date(),
      },
      message: '',
      description: '',
    };
  }

  return {
    languages: banner.languages || [],
    countries: banner.countries || [],
    dateRange: {
      start: banner.starts_on,
      end: banner.ends_on,
    },
    message: banner.message,
    description: banner.description,
  };
};

const CreateTextBanner = ({
  type,
  // From Redux
  apiEnv,
  banners,
  banner,
}) => {
  const [initialValues, setInitialValues] = useState(getTextBannerFormValues());
  const [showDuplicate, setShowDuplicate] = useState(false);

  const { setErrorStatusCode } = useErrorStatus();

  const isCreate = type === textBannersStateMapping.CREATE;
  const canEdit = type === textBannersStateMapping.EDIT || isCreate;
  const canReview = type === textBannersStateMapping.REVIEW;
  const canDuplicate = !isCreate && canEdit && apiEnv !== PRODUCTION;

  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const fetchBanners = useCallback(() => {
    dispatch({
      type: GET_TEXT_BANNERS,
      payload: {
        errorCallback: (error) => {
          setErrorStatusCode(error);
        },
      },
    });
  }, [dispatch, setErrorStatusCode]);

  useEffect(() => {
    if (!banners || !banners.length) {
      fetchBanners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (banner) {
      setInitialValues(getTextBannerFormValues(banner));
    }
  }, [banner]);

  const validate = (values) => {
    let errors = {};

    if (!values.message) {
      errors.message = 'Message is required';
    }
    if (!values.description) {
      errors.description = 'Name is required';
    }

    return errors;
  };

  const submitBanner = (values) => {
    let data = formatBannerFormData(values);

    // Update
    if (banner) {
      dispatch({
        type: UPDATE_TEXT_BANNER,
        payload: {
          data,
          id: banner.id,
          callback: () => {
            enqueueSnackbar(TEXT_BANNER_SAVED, {
              variant: SEVERITY.SUCCESS,
            });

            // Go to the listing page
            history.push(paths.content.textBanners.list);
          },
          errorCallback: (error) => {
            enqueueSnackbar(`${TEXT_BANNER_SAVE_ERROR} : ${error}`, {
              variant: SEVERITY.ERROR,
            });
          },
        },
      });
      return;
    }

    // Create
    dispatch({
      type: SAVE_TEXT_BANNER,
      payload: {
        data,
        callback: () => {
          enqueueSnackbar(TEXT_BANNER_SAVED, {
            variant: SEVERITY.SUCCESS,
          });

          // Go to the listing page
          history.push(paths.content.textBanners.list);
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${TEXT_BANNER_SAVE_ERROR} : ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const getTitle = () => {
    return type.charAt(0).toUpperCase() + type.slice(1);
  };

  const handleCancel = () => {
    history.push(paths.content.textBanners.list);
  };

  return (
    <div>
      {(canEdit || canReview) && (
        <SetPageTitle
          pageTitle={
            !!banner
              ? `Text banners - ${banner?.description}`
              : 'Create a text banner'
          }
        />
      )}
      <Form
        onSubmit={submitBanner}
        validate={validate}
        initialValues={initialValues}
        mutators={{
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <PageFormLayout
              title={`${getTitle()} Banner`}
              isLoading={false}
              breadcrumbs={[
                {
                  label: 'Text banners listing',
                  link: paths.content.textBanners.list,
                },
                {
                  label: `${type} banner`,
                  isCurrent: true,
                },
              ]}
              buttons={
                canDuplicate && (IS_RELC || IS_BETA || IS_DEVELOP)
                  ? [
                      {
                        label: 'Duplicate',
                        onClick: () => setShowDuplicate(true),
                      },
                    ]
                  : null
              }
              showSubmitButtons={canEdit}
              handleCancel={handleCancel}
              showProductionWarning={canEdit}
            >
              <Paper className="p20">
                <TextBannerForm mode={type} />
              </Paper>
            </PageFormLayout>

            <DuplicateBannerModals
              setShow={setShowDuplicate}
              show={showDuplicate}
              values={values}
            />
          </form>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  apiEnv: generalSelectors.getAPIEnv(state),
  banners: contentSelectors.getBannersArray(state),
  banner: contentSelectors.getBannerById(state, props?.match?.params?.id),
});

export default connect(mapStateToProps)(CreateTextBanner);
