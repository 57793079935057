const normalizeGamesResponse = (data) => {
  return data.map((game) => ({
    demoCloudflareId: game.demo_cloudflare_id,
    platforms: game.platforms_availability,
    demoStillUrl: game.demo_still_url,
    id: game.game_id,
    isFire8Compatible: game.is_fire8_compatible,
    isFireProflectorCompatible: !game.is_fire_proflector_incompatible,
    isReady: game.is_ready,
    manuals: game.manuals,
    skills: game.skills,
    name: game.name,
    appIcon: (game.medias?.app_icon && game.medias?.app_icon['']?.url) || null,
    iosAppLink:
      (game.medias?.store_url && game.medias?.store_url['ios']?.url) || null,
    fireAppLink:
      (game.medias?.store_url && game.medias?.store_url['fire']?.url) || null,
  }));
};

export default normalizeGamesResponse;
