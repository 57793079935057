import {
  MARKETING_READ,
  MARKETING_WRITE,
  USERS_WRITE,
  USERS_READ,
  ORDERS_WRITE,
  ORDERS_READ,
  SHORTLINK_READ,
  SHORTLINK_WRITE,
  COMMUNICATIONS_READ,
  COMMUNICATIONS_WRITE,
  USERS_ACCOUNTS_WRITE,
  USERS_ACCOUNTS_READ,
  PLAYOSMO_PRODUCTS_WRITE,
  IP_WHITELIST_READ,
  IP_WHITELIST_WRITE,
} from 'config/roles';

import TextBannerListing from 'components/containers/TextBannerContainer/TextBannerListing';
import CreateTextBanner from 'components/containers/TextBannerContainer/CreateTextBanner';
import CampaignListing from 'components/containers/CampaignContainer/CampaignListing';
import CreateCampaign from 'components/containers/CampaignContainer/CreateCampaign';
import PromoCodeListing from 'components/containers/PromoCodeContainer/PromoCodeListing';
import CreatePromoCode from 'components/containers/PromoCodeContainer/CreatePromoCode';
import UsersListing from 'components/containers/UsersContainer/UsersListing';
import UpdateUser from 'components/containers/UsersContainer/UpdateUser';
import OrdersListing from 'components/containers/OrdersContainer/OrdersListing/OrdersListing';
import OrderView from 'components/containers/OrdersContainer/OrderView';
import CreateOrder from 'components/containers/OrdersContainer/CreateOrder';
import ReturnsListing from 'components/containers/OrdersContainer/ReturnsListing';
import ReturnView from 'components/containers/OrdersContainer/ReturnView';
import DashboardView from 'components/containers/DashboardContainer/DashboardView';
import CouponListing from 'components/containers/CouponContainer/CouponListing';
import SubscriptionListing from 'components/containers/SubscriptionContainer/SubscriptionListing';
import SubscriptionView from 'components/containers/SubscriptionContainer/SubscriptionView/SubscriptionView';
import EmailSubscriptionsListing from 'components/containers/EmailSubscriptionsContainer/EmailSubscriptionsListing';
import EmailSubscriptionView from 'components/containers/EmailSubscriptionsContainer/EmailSubscriptionView';
import ProfileView from 'components/containers/ProfilesContainer/ProfileView';
import ProfilesListing from 'components/containers/ProfilesContainer/ProfilesListing';
import CreditsView from 'components/containers/CreditsContainer/CreditsView';
import ShortLinkView from 'components/containers/ShortLinkContainer/ShortLinkView';
import IssueListing from 'components/containers/IssueContainer/IssueListing';
import IssueDetailView from 'components/containers/IssueContainer/IssueDetailView';
import AccountsListing from 'components/containers/AccountsContainer/AccountsListing';
import AccountView from 'components/containers/AccountsContainer/AccountView';
import GamesListing from 'components/containers/GamesContainer/GamesListing';
import GameView from 'components/containers/GamesContainer/GameView';
import IPWhitelistContainer from 'components/containers/IPWhitelistContainer';
import Logout from 'components/containers/Logout';
import paths from './paths';
import CountryView from 'components/containers/CountriesContainer/CountryView';
import CountriesListing from 'components/containers/CountriesContainer/CountriesListing';
import WarehouseView from 'components/containers/WarehousesContainer/WarehouseView';
import WarehousesListing from 'components/containers/WarehousesContainer/WarehousesListing';
import ShippingMethodsListing from 'components/containers/ShippingCountriesContainer/ShippingMethodsListing';
import SkuSubstitutesListing from 'components/containers/SkuSubstitutesContainer/SkuSubstitutesListing';
import ProductsListing from 'components/containers/ProductsContainer/ProductsListing';
import ProductView from 'components/containers/ProductsContainer/ProductView';
import VariantView from 'components/containers/ProductsContainer/VariantView';

export const routesConfig = [
  {
    path: paths.home.dashboard,
    exact: true,
    title: 'Dashboard',
    render: (props) => <DashboardView {...props} />,
  },
  {
    path: paths.marketing.campaigns.list,
    exact: true,
    title: 'Campaign',
    roles: [MARKETING_READ, MARKETING_WRITE],
    render: (props) => <CampaignListing {...props} />,
  },
  {
    path: paths.marketing.coupons.list,
    exact: true,
    title: 'Coupons',
    roles: [ORDERS_READ],
    render: (props) => <CouponListing />,
  },
  {
    path: paths.orders.subscriptions.list,
    exact: true,
    title: 'Subscriptions',
    roles: [ORDERS_WRITE, ORDERS_READ],
    render: (props) => <SubscriptionListing />,
  },
  {
    path: paths.marketing.campaigns.listPast,
    exact: true,
    title: 'Past Campaign',
    roles: [MARKETING_READ, MARKETING_WRITE],
    render: (props) => <CampaignListing openPastCampaign={true} {...props} />,
  },
  {
    path: paths.logout,
    exact: true,
    render: (props) => <Logout {...props} />,
  },
  {
    path: paths.content.textBanners.list,
    exact: true,
    title: 'Text Banners',
    roles: [MARKETING_WRITE, MARKETING_READ],
    render: (props) => <TextBannerListing {...props} />,
  },
  {
    path: paths.content.textBanners.create,
    exact: true,
    title: 'Create Text Banner',
    roles: [MARKETING_WRITE],
    render: (props) => <CreateTextBanner type="create" {...props} />,
  },
  {
    path: `${paths.content.textBanners.edit}/:id`,
    exact: true,
    title: 'Edit Text Banner',
    roles: [MARKETING_WRITE],
    render: (props) => <CreateTextBanner type="edit" {...props} />,
  },
  {
    path: `${paths.content.textBanners.review}/:id`,
    exact: true,
    title: 'Review Text Banner',
    roles: [MARKETING_WRITE, MARKETING_READ],
    render: (props) => <CreateTextBanner type="review" {...props} />,
  },
  {
    path: paths.marketing.campaigns.create,
    exact: true,
    title: 'Create Campaign',
    roles: [MARKETING_WRITE],
    render: (props) => <CreateCampaign type="create" {...props} />,
  },
  {
    path: `${paths.marketing.campaigns.edit}/:id(\\d+)`,
    exact: true,
    title: 'Edit Campaign',
    roles: [MARKETING_WRITE],
    render: (props) => <CreateCampaign type="edit" {...props} />,
  },
  {
    path: `${paths.marketing.campaigns.review}/:id(\\d+)`,
    exact: true,
    title: 'Review Campaign',
    roles: [MARKETING_WRITE, MARKETING_READ],
    render: (props) => <CreateCampaign type="review" {...props} />,
  },
  {
    path: paths.marketing.promocodes.list,
    exact: true,
    title: 'Promo Code Listing',
    roles: [MARKETING_WRITE, MARKETING_READ],
    render: (props) => <PromoCodeListing {...props} />,
  },
  {
    path: paths.marketing.promocodes.create,
    exact: true,
    title: 'Create Promo Code',
    roles: [MARKETING_WRITE],
    render: (props) => <CreatePromoCode type="create" {...props} />,
  },
  {
    path: `${paths.marketing.promocodes.edit}/:code`,
    exact: true,
    title: 'Edit Promo Code',
    roles: [MARKETING_WRITE],
    render: (props) => <CreatePromoCode type="edit" {...props} />,
  },
  {
    path: `${paths.marketing.promocodes.review}/:code`,
    exact: true,
    title: 'Review Promo Code',
    roles: [MARKETING_WRITE, MARKETING_READ],
    render: (props) => <CreatePromoCode type="review" {...props} />,
  },
  {
    path: paths.marketing.shortlinks.list,
    exact: true,
    title: 'Short Links',
    roles: [SHORTLINK_READ, SHORTLINK_WRITE],
    render: (props) => <ShortLinkView />,
  },
  {
    path: paths.orders.list,
    exact: true,
    title: 'Orders Listing',
    roles: [ORDERS_WRITE, ORDERS_READ],
    render: (props) => <OrdersListing {...props} />,
  },
  {
    path: paths.orders.create,
    exact: true,
    title: 'Create Order',
    roles: [ORDERS_WRITE],
    render: (props) => <CreateOrder {...props} />,
  },
  {
    path: paths.orders.returns.list,
    exact: true,
    title: 'Returns',
    roles: [ORDERS_WRITE],
    render: (props) => <ReturnsListing {...props} />,
  },
  {
    path: `${paths.orders.returns.view}/:id`,
    exact: true,
    title: 'Return',
    roles: [ORDERS_WRITE],
    render: (props) => <ReturnView {...props} />,
  },
  {
    name: 'order_view',
    path: `${paths.orders.view}/:id`,
    exact: true,
    title: 'View order',
    roles: [ORDERS_WRITE, ORDERS_READ],
    render: (props) => <OrderView {...props} />,
    removeLayoutPadding: true,
  },
  {
    path: paths.orders.issues.list,
    exact: true,
    title: 'Issues Listing',
    roles: [ORDERS_WRITE, ORDERS_READ],
    render: () => <IssueListing />,
  },
  {
    path: `${paths.orders.issues.view}/:id`,
    exact: true,
    title: 'Issue Detail',
    roles: [ORDERS_WRITE, ORDERS_READ],
    render: (props) => <IssueDetailView {...props} />,
  },
  {
    name: 'subscription_view',
    path: `${paths.orders.subscriptions.view}/:id`,
    exact: true,
    title: 'View Subscription',
    roles: [ORDERS_WRITE, ORDERS_READ],
    render: (props) => <SubscriptionView {...props} />,
  },
  {
    path: paths.tools.ipWhitelist.list,
    exact: true,
    title: 'IP whitelist',
    roles: [IP_WHITELIST_WRITE, IP_WHITELIST_READ],
    render: (props) => <IPWhitelistContainer {...props} />,
  },
  {
    path: paths.tools.permissions.list,
    exact: true,
    title: 'List Users',
    roles: [USERS_WRITE, USERS_READ],
    render: (props) => <UsersListing type="review" {...props} />,
  },
  {
    path: `${paths.tools.permissions.edit}/:userId`,
    exact: true,
    title: 'Edit User',
    roles: [USERS_WRITE],
    render: (props) => <UpdateUser type="edit" {...props} />,
  },
  {
    path: `${paths.tools.permissions.review}/:userId`,
    exact: true,
    title: 'Review User',
    roles: [USERS_WRITE, USERS_READ],
    render: (props) => <UpdateUser type="review" {...props} />,
  },
  {
    path: paths.users.accounts.list,
    exact: true,
    title: 'Accounts',
    roles: [USERS_ACCOUNTS_READ, USERS_ACCOUNTS_WRITE],
    render: (props) => <AccountsListing {...props} />,
  },
  {
    path: `${paths.users.accounts.edit}/:id`,
    exact: true,
    title: 'Account View',
    roles: [USERS_ACCOUNTS_READ, USERS_ACCOUNTS_WRITE],
    render: (props) => <AccountView {...props} />,
  },
  {
    path: paths.users.emailSubscriptions.list,
    exact: true,
    title: 'Email subscriptions',
    roles: [COMMUNICATIONS_READ, COMMUNICATIONS_WRITE],
    render: (props) => <EmailSubscriptionsListing {...props} />,
  },
  {
    path: `${paths.users.emailSubscriptions.edit}/:email`,
    exact: true,
    title: 'Email subscription view',
    roles: [COMMUNICATIONS_READ, COMMUNICATIONS_WRITE],
    render: (props) => <EmailSubscriptionView {...props} />,
  },
  {
    path: paths.users.profiles.list,
    exact: true,
    title: 'Profiles',
    roles: [USERS_ACCOUNTS_READ, USERS_ACCOUNTS_WRITE],
    render: (props) => <ProfilesListing {...props} />,
  },
  {
    path: `${paths.users.profiles.edit}/:id`,
    exact: true,
    title: 'Profile view',
    roles: [USERS_ACCOUNTS_READ, USERS_ACCOUNTS_WRITE],
    render: (props) => <ProfileView {...props} />,
  },
  {
    path: paths.users.credits,
    exact: true,
    title: 'Credits',
    roles: [USERS_ACCOUNTS_READ, USERS_ACCOUNTS_WRITE],
    render: (props) => <CreditsView {...props} />,
  },
  {
    path: paths.eCommerceConfiguration.games.list,
    exact: true,
    title: 'Games',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <GamesListing {...props} />,
  },
  {
    path: `${paths.eCommerceConfiguration.games.view}/:id`,
    exact: true,
    title: 'Edit game',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <GameView {...props} />,
  },
  {
    path: paths.eCommerceConfiguration.games.create,
    exact: true,
    title: 'Create game',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <GameView {...props} type="create" />,
  },
  {
    path: paths.eCommerceConfiguration.warehouses.list,
    exact: true,
    title: 'Warehouses',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <WarehousesListing {...props} />,
  },
  {
    path: `${paths.eCommerceConfiguration.warehouses.view}/:id`,
    exact: true,
    title: 'Edit warehouse',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <WarehouseView {...props} />,
  },
  {
    path: paths.eCommerceConfiguration.warehouses.create,
    exact: true,
    title: 'Create warehouse',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <WarehouseView {...props} type="create" />,
  },
  {
    path: paths.eCommerceConfiguration.countries.list,
    exact: true,
    title: 'Countries',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <CountriesListing {...props} />,
  },
  {
    path: `${paths.eCommerceConfiguration.countries.view}/:id`,
    exact: true,
    title: 'Edit country',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <CountryView {...props} />,
  },
  {
    path: paths.eCommerceConfiguration.countries.create,
    exact: true,
    title: 'Create country',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <CountryView {...props} type="create" />,
  },
  {
    path: paths.eCommerceConfiguration.shippingMethods,
    exact: true,
    title: 'Shipping Methods',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <ShippingMethodsListing {...props} />,
  },
  {
    path: paths.eCommerceConfiguration.substitutes,
    exact: true,
    title: 'SKU Substitutes',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <SkuSubstitutesListing {...props} />,
  },
  {
    path: paths.catalog.products.list,
    exact: true,
    title: 'Products Listing',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <ProductsListing {...props} />,
  },
  {
    path: paths.catalog.products.create,
    exact: true,
    title: 'Create a product',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <ProductView {...props} type="create" />,
    removeLayoutPadding: true,
  },
  {
    path: `${paths.catalog.products.view}/:key`,
    exact: true,
    title: 'Product view',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <ProductView {...props} />,
    removeLayoutPadding: true,
  },
  {
    path: `${paths.catalog.variants.create}/:productKey`,
    exact: true,
    title: 'Create a variant',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <VariantView {...props} type="create" />,
    removeLayoutPadding: true,
  },
  {
    path: `${paths.catalog.variants.view}/:productKey/:key`,
    exact: true,
    title: 'Variant view',
    roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
    render: (props) => <VariantView {...props} />,
    removeLayoutPadding: true,
  },
];
