import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field } from 'react-final-form';

import AutoCompleteWrapper from 'components/common/AutoCompleteWrapper';

import { FETCH_ROLES } from 'store/policies/actions';
import { Typography } from '@mui/material';

const UpdateUserForm = ({ roles, canReview, userRoles }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: FETCH_ROLES,
      payload: {},
    });
  }, [dispatch]);

  if (canReview) {
    if (userRoles && userRoles.length) {
      return (
        <ul>
          {userRoles.map((role) => (
            <li key={role.name}>{role.name}</li>
          ))}
        </ul>
      );
    }

    return <Typography paragraph={true}>No roles assigned</Typography>;
  }

  return (
    <Field
      multiple={true}
      limitTags={3}
      name="roles"
      label="Select roles"
      placeholder="Search for a role"
      options={roles}
      selectField="id"
      component={AutoCompleteWrapper}
      variant="outlined"
      noSelectAll={true}
      getOptionLabel={(opt) => opt.name}
      renderDropdownOption={(opt) => opt.name}
    />
  );
};

export default UpdateUserForm;
