import React from 'react';
import SetPageTitle from 'components/common/SetPageTitle';
import PageContentLayout from 'components/layout/PageContentLayout';
import ShortLinkPageContent from './ShortLinkPageContent';

const ShortLinkView = () => {
  return (
    <>
      <SetPageTitle pageTitle="Short Links" />
      <PageContentLayout title="Short Links">
        <ShortLinkPageContent />
      </PageContentLayout>
    </>
  );
};
export default ShortLinkView;
