import Balance from '../Balance';

const BalancesList = ({ data }) => {
  return (
    <div>
      {data.map((balance) => (
        <div key={balance.id} className="mb-20">
          <Balance
            balancesCount={data.length}
            balance={balance}
            creditsData={data}
          />
        </div>
      ))}
    </div>
  );
};

export default BalancesList;
