import Box from '@mui/material/Box';

import './styles.scss';

export default function TabPanel(props) {
  const { children, value, index, ...rest } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      className="tab-panel"
      {...rest}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
