import axios from 'axios';

import responseCode from 'config/ResponseCodes';
import { LOGOUT_SUCCESS } from 'store/auth/actions';
import authSelectors from 'store/auth/selectors';
import { store } from 'store';

const httpClient = axios.create({
  withCredentials: true,
});

const successCallback = (response) => {
  return response;
};

const errorCallback = (error) => {
  const { status } = error?.response;
  const { isLoggedIn } = authSelectors.getIsLoggedIn(store.getState());

  if (
    (status === responseCode.forbidden ||
      status === responseCode.unauthorized) &&
    isLoggedIn
  ) {
    store.dispatch({ type: LOGOUT_SUCCESS });
  }

  return Promise.reject(error);
};

httpClient.interceptors.response.use(
  (response) => successCallback(response),
  (error) => errorCallback(error),
);

export default httpClient;
