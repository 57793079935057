import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { ENVIRONMENTS, DEVELOPER_ENVS, PRODUCTION } from 'config/environments';
import {
  ORDERS_WRITE,
  ORDERS_READ,
  MARKETING_WRITE,
  DEVELOPER,
} from '../../config/roles';
import { SET_ENV } from 'store/general/actions';
import SelectComponent from 'components/common/SelectDropdown/SelectComponent';
import { getCurrentEnvironment } from 'config/environments';

import './style.scss';

const PRODUCTION_DOMAIN = 'admin.playosmo.com';
const productionEnvKey = 'PRODUCTION';

const AppBarActions = ({ userInfo }) => {
  const userRoles = userInfo?.roles;
  const ifUserIsDeveloper = userRoles?.includes(DEVELOPER);
  const ifUserIsCX =
    (userRoles?.includes(ORDERS_WRITE) || userRoles?.includes(ORDERS_READ)) &&
    !userRoles?.includes(MARKETING_WRITE);
  const DEVELOPER_ENVIRONMENTS = ifUserIsDeveloper ? DEVELOPER_ENVS : [];
  const [selectedEnv, setEnv] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const environments = [...DEVELOPER_ENVIRONMENTS, ...ENVIRONMENTS];

  const handleChange = (e) => {
    const selectedEnv = e.target.value;
    const selectedEnvIdx = environments.indexOf(selectedEnv);
    const selectedApiEnv = environments[selectedEnvIdx];
    const locQueryParams = qs.parse(location.search);
    const newQueries = { ...locQueryParams, env: selectedApiEnv };
    setEnv(selectedEnvIdx);
    localStorage.setItem('env', selectedApiEnv);
    dispatch({ type: SET_ENV, payload: { apiEnv: selectedApiEnv } });
    history.replace({ search: qs.stringify(newQueries) });
    window.location.reload();
  };

  const setProductionEnv = () => {
    const productionEnvIndex = environments.indexOf(productionEnvKey);
    const productionApiEnv = environments[productionEnvIndex];
    const productionEnvQuery = { env: productionEnvKey };
    setEnv(productionEnvIndex);
    localStorage.setItem('env', productionApiEnv);
    dispatch({ type: SET_ENV, payload: { apiEnv: productionApiEnv } });
    history.replace({ search: qs.stringify(productionEnvQuery) });
  };

  // Life cycle
  useEffect(() => {
    const apiEnv = localStorage.getItem('env');
    if (ifUserIsCX && window.location.hostname === PRODUCTION_DOMAIN) {
      if (getCurrentEnvironment() !== PRODUCTION) {
        setProductionEnv();
      }
    } else if (apiEnv) {
      const index = environments.findIndex((env) => env === apiEnv);
      if (index > -1) {
        setEnv(index);
      } else {
        setEnv(0);
      }
      dispatch({
        type: SET_ENV,
        payload: {
          apiEnv: apiEnv,
        },
      });
    } else if (window.location.hostname === PRODUCTION_DOMAIN) {
      setProductionEnv();
    }
  }, [dispatch, environments, ifUserIsCX]);

  return (
    <div className="app-bar-actions">
      {!ifUserIsCX ? (
        <div className="app-bar-actions-env">
          <SelectComponent
            onChange={handleChange}
            label="Current Environment"
            dataItems={environments}
            value={environments[selectedEnv]}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AppBarActions;
