import { GET_PROMO_CODES_SUCCESS } from './actions';

const initialState = {
  promoCodes: [],
};

export const promoCodeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROMO_CODES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        promoCodes: [...data.promoCodes],
      };
    }

    default:
      return state;
  }
};
