import { memo } from 'react';
import PropTypes from 'prop-types';

import { Typography, Breadcrumbs as BreadcrumbsUI, Link } from '@mui/material';

import { capitalizeFirstLetter } from 'utils';

import './styles.scss';

// Data Structure:
// [
//   {
//     label: '',
//     link: '',
//     isCurrent: false,
//   },
//   {
//     label: '',
//     isCurrent: true,
//   },
// ]

const Breadcrumbs = (props) => {
  const { data } = props;

  return (
    <BreadcrumbsUI aria-label="breadcrumb">
      {data.map((item) => {
        if (item.isCurrent) {
          return (
            <Typography key={item.label.replace(' ', '')} color="textPrimary">
              {capitalizeFirstLetter(item.label)}
            </Typography>
          );
        }

        return (
          <Link
            key={item.label.replace(' ', '')}
            href={item.link}
            className="breadcrumb-link"
            underline="hover"
          >
            {item.label}
          </Link>
        );
      })}
    </BreadcrumbsUI>
  );
};

export const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
      isCurrent: PropTypes.bool,
    }),
  ),
};

Breadcrumbs.propTypes = propTypes;

export default memo(Breadcrumbs);
