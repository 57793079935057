import { FormControlLabel, Switch } from '@mui/material';

const SwitchInput = ({
  input: { name, required, onChange, value, checked, ...restInput },
  meta,
  label,
  ...rest
}) => {
  const checkedValue = typeof checked !== 'undefined' ? checked : value;

  return (
    <FormControlLabel
      control={
        <Switch
          {...rest}
          inputProps={restInput}
          onChange={onChange}
          checked={checkedValue}
          required={required}
          name={name}
        />
      }
      label={label}
    />
  );
};

export default SwitchInput;
