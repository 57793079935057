import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import qs from 'query-string';

import { compose } from 'recompose';

import { ORDERS_WRITE } from 'config/roles';
import paths from 'routes/paths';
import generalSelectors from 'store/general/selectors';
import userSelectors from 'store/user/selectors';
import { GET_ORDERS } from 'store/orders/actions';

import PageContentLayout from 'components/layout/PageContentLayout';
import SetPageTitle from 'components/common/SetPageTitle';
import withRouterInfo from 'components/hoc/withRouterInfo';
import { useErrorStatus } from 'components/hoc/ErrorHandler';

import OrdersFiltersForm, {
  initialValues as formInitialValues,
} from './OrdersFiltersForm/OrdersFiltersForm';
import OrdersTable from './OrdersTable';

function OrdersListing({
  // From Redux
  dispatch,
  userRoles,
}) {
  const [loading, setLoading] = useState(false);
  const [initialFiltersValue, setInitialFiltersValue] = useState(null);
  const [orders, setOrders] = useState([]);
  const { setErrorStatusCode } = useErrorStatus();
  const history = useHistory();
  const location = useLocation();

  const fetchOrders = (filters) => {
    setLoading(true);
    dispatch({
      type: GET_ORDERS,
      payload: {
        data: {
          ...filters,
          endDate:
            filters.endDate !== null
              ? filters.endDate
              : parseInt(DateTime.now().toSeconds()),
          startDate:
            filters.startDate !== null
              ? filters.startDate
              : parseInt(DateTime.now().minus({ day: 30 }).toSeconds()),
          limit: filters.startDate !== null ? 500 : 100,
        },
        callback: (orders) => {
          setLoading(false);
          setOrders(orders);
        },
        errorCallback: (error) => {
          setErrorStatusCode(error);
          setLoading(false);
        },
      },
    });
  };

  useEffect(() => {
    // Check if there is any parameters in the URL
    const urlParams = new URLSearchParams(location.search);

    const filtersFromUrl = {
      startDate: urlParams.get('startDate'),
      endDate: urlParams.get('endDate'),
      orderID: urlParams.get('orderID'),
      email: urlParams.get('email'),
      phoneNumber: urlParams.get('phoneNumber'),
      firstName: urlParams.get('firstName'),
      lastName: urlParams.get('lastName'),
      country: urlParams.get('country'),
      website: urlParams.get('website'),
    };

    // If we have an end date in the url, we use it, otherwise we add 1h to the start date in the url, else, we fallback to the default initial value
    const initialStartDateValue = filtersFromUrl.startDate
      ? DateTime.fromSeconds(parseInt(filtersFromUrl.startDate)).toJSDate()
      : filtersFromUrl.endDate
      ? DateTime.fromSeconds(parseInt(filtersFromUrl.endDate))
          .minus({ hour: 1 })
          .toJSDate()
      : formInitialValues.dateRange.start;

    const initialValue = {
      email: filtersFromUrl.email || formInitialValues.email,
      phoneNumber: filtersFromUrl.phoneNumber || formInitialValues.phoneNumber,
      firstName: filtersFromUrl.firstName || formInitialValues.firstName,
      lastName: filtersFromUrl.lastName || formInitialValues.lastName,
      country: filtersFromUrl.country || formInitialValues.country,
      orderID: parseInt(filtersFromUrl.orderID) || formInitialValues.orderID,
      dateRange: {
        start: initialStartDateValue,
        end: filtersFromUrl.endDate
          ? DateTime.fromSeconds(parseInt(filtersFromUrl.endDate)).toJSDate()
          : formInitialValues.dateRange.end,
      },
      website: filtersFromUrl.website || formInitialValues.website,
    };

    setInitialFiltersValue(initialValue);

    // Get the orders based on the parameters
    fetchOrders(filtersFromUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFiltersChange = (filters, isReset) => {
    // fetch a new batch of orders
    fetchOrders(filters);
    // Update the URL params
    const queryParams = qs.parse(location.search);
    const newQueries = { ...queryParams, ...filters };
    history.push({ search: isReset ? null : qs.stringify(newQueries) });
  };

  const handleCreate = () => {
    history.push(paths.orders.create);
  };

  const formattedOrders = orders;

  return (
    <>
      <SetPageTitle pageTitle="Orders" />
      <PageContentLayout
        title="Orders"
        buttons={
          userRoles && userRoles.includes(ORDERS_WRITE)
            ? [
                {
                  label: 'Create Order',
                  onClick: handleCreate,
                },
              ]
            : null
        }
      >
        <OrdersFiltersForm
          onFiltersChange={onFiltersChange}
          initialValues={initialFiltersValue}
        />
        <OrdersTable rows={formattedOrders || []} isLoading={loading} />
      </PageContentLayout>
    </>
  );
}

const mapStateToProps = (state) => ({
  apiEnv: generalSelectors.getAPIEnv(state),
  userRoles: userSelectors.getUserRoles(state),
});

export default compose(withRouterInfo)(connect(mapStateToProps)(OrdersListing));
