import React from 'react';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';

import appBarStyles from 'utils/ui/appBarStyles';
import useWindowSize from 'utils/hooks/useWindowSize';

import AppBarActions from 'components/AppBarActions';

import './styles.scss';

const useStyles = makeStyles((theme) => ({
  appbar: appBarStyles(theme),
}));

export default function TopBar(props) {
  const { isLoggedIn, userInfo, toggleMobileMenu } = props;
  const classes = useStyles();
  const size = useWindowSize();

  return (
    <AppBar position="sticky" className={classes.appbar}>
      <Toolbar>
        {size.isMobile && (
          <IconButton onClick={toggleMobileMenu} size="large">
            <MenuIcon />
          </IconButton>
        )}
        {isLoggedIn && (
          <div className="top-bar">
            <AppBarActions userInfo={userInfo} />
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
