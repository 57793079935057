const emailPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

const checkValidEmail = (value) => {
  if (!value) {
    return false;
  }
  return value.search(emailPattern) > -1;
};

export default checkValidEmail;
