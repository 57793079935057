import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Button, Paper, Typography } from '@mui/material';

import { SEVERITY } from 'config';
import { ORDER_ACTION_ERROR, ORDER_ACTION_SUCCESS } from 'config/messages';

import { REVIEW_ORDER } from 'store/orders/actions';

function CustomerSection({ order, setOrderStatus }) {
  const [isSent, setIsSent] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (type) => {
    dispatch({
      type: REVIEW_ORDER,
      payload: {
        data: {
          approve: type === 'approve',
          reject: type === 'reject',
          orderId: order.orderId,
        },
        callback: () => {
          enqueueSnackbar(ORDER_ACTION_SUCCESS, {
            variant: SEVERITY.SUCCESS,
          });
          setIsSent(true);
          setOrderStatus(type);
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${ORDER_ACTION_ERROR}: ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  return (
    <Paper className="order-section order-section-review mb-30">
      <Typography variant="h2" gutterBottom>
        Review
      </Typography>
      {isSent ? (
        <Typography paragraph={true}>Review sent!</Typography>
      ) : (
        <>
          <Button
            onClick={() => handleClick('approve')}
            variant="contained"
            disableElevation
            className="mr-20"
          >
            Approve
          </Button>
          <Button
            onClick={() => handleClick('reject')}
            variant="contained"
            disableElevation
          >
            Reject
          </Button>
        </>
      )}
    </Paper>
  );
}

export default CustomerSection;
