import React from 'react';
import { Paper, Typography } from '@mui/material';

import OrderProperty from '../../OrderProperty';
import OrderPropertiesWrapper from '../../OrderProperty/OrderPropertiesWrapper';

function OrderDetailsSection({ order }) {
  return (
    <Paper className="order-section order-section-order-details">
      <Typography variant="h2" gutterBottom>
        Order details
      </Typography>
      <OrderPropertiesWrapper>
        <OrderProperty label="Source" value={order.source} />
        <OrderProperty label="Created on" value={order.createdOn} />
        <OrderProperty label="Created by" value={order.createdBy} />
        <OrderProperty label="Updated on" value={order.updatedOn} />
        <OrderProperty label="Edited by" value={order.editedBy} />
        <OrderProperty label="Canceled on" value={order.canceledOn} />
        <OrderProperty label="Canceled by" value={order.canceledBy} />
        <OrderProperty label="Confirmation email on" value={order.referral} />
        <OrderProperty label="Reviewed on" value={order.referral} />
        <OrderProperty label="Refunded on" value={order.refundedOn} />
        {!!order.edu && (
          <>
            <OrderProperty label="EDU Website URL" value={order.edu?.website} />
            <OrderProperty
              label="EDU tax exempt"
              value={order.edu?.taxExempt}
            />
            <OrderProperty
              label="EDU school name"
              value={order.edu?.schoolName}
            />
          </>
        )}
        <OrderProperty label="UTM Tags" value={order.utmTags} />
        <OrderProperty label="Checkout" value={order.checkout} />
      </OrderPropertiesWrapper>
    </Paper>
  );
}

export default OrderDetailsSection;
