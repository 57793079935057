const normalizeProducts = (data) => {
  return data.map((product) => ({
    id: product.product_key,
    brand: product.brand,
    isBundle: product.is_bundle,
    isDigital: product.is_digital,
    status: product.is_ready === true ? 'active' : 'inactive',
    isReady: product.is_ready,
    name: product.name,
    shoppingName: product.shopping_name,
    hardwareName: product.hardware_name,
    productKey: product.product_key,
    workbookCount: product.workbook_count,
    requiredHardware: product.require_hardware,
    subscriptionsIncluded: product.subscriptions_included,
    marketedGames: product.games
      ?.filter((game) => game.is_marketed)
      .map((game) => game.game_id),
    gamesIncluded: product.games?.map((game) => game.game_id),
    skuKeys: product.sku_keys,
    warehouseCodes: product.warehouse_codes,
    websites: product.websites,
    ageRange: product.age_range,
    grades: product.grades,
    playosmoPath: product.url_paths?.playosmo,
    byjusPath: product.url_paths?.byjus_learning,
    redeemType: product.redeem_type,
    analyticsPage: product.analytics_page,
    analyticsCategory: product.analytics_category,
    taxCode: product.avalara_tax_code,
  }));
};

export default normalizeProducts;
