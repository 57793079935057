import * as React from 'react';
import { Tab, Paper, Box } from '@mui/material';
import { TabList, TabContext } from '@mui/lab';

export default function IssueListingTabs(props) {
  const value = props.value;
  const renderTab = props.renderTab;

  const handleChange = (event, newValue) => {
    renderTab(newValue);
  };

  return (
    <Paper sx={{ width: '100%', typography: 'body1' }} className="mb-10">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} centered={true}>
            <Tab label="Pending" value="Pending" />
            <Tab label="In Progress" value="In Progress" />
            <Tab label="Resolved" value="Resolved" />
            <Tab label="All" value="All" />
          </TabList>
        </Box>
      </TabContext>
    </Paper>
  );
}
