import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { errorHandler } from './config';

import ErrorPage from 'components/common/ErrorComponent/Component/ErrorPage';

const ErrorStatusContext = createContext();

export const ErrorHandler = ({ children }) => {
  const history = useHistory();
  const [errorStatusCode, setErrorStatusCode] = useState();
  const [message, setErrorMessage] = useState();

  useEffect(() => {
    const unlisten = history.listen(() => setErrorStatusCode(undefined));
    return unlisten;
  }, [history]);

  const renderContent = () => {
    if (errorStatusCode) {
      return (
        <ErrorPage data={errorHandler[errorStatusCode]} message={message} />
      );
    }

    return children;
  };

  const contextPayload = useMemo(() => {
    return { setErrorStatusCode, setErrorMessage };
  }, [setErrorStatusCode, setErrorMessage]);

  return (
    <ErrorStatusContext.Provider value={contextPayload}>
      {renderContent()}
    </ErrorStatusContext.Provider>
  );
};

export const useErrorStatus = () => useContext(ErrorStatusContext);
