import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const Media = ({
  name,
  content,
  mediaUrl = 'https://images.playosmo.com/shopping/product-renders-square/osmo_kindergarten_sk.jpg?auto=format&fm=jpg&ixlib=react-9.6.0&w=210&h=210&dpr=1&q=75',
  onDelete = () => {},
  onEdit = () => {},
}) => {
  return (
    <>
      <Card sx={{ display: 'flex' }} variant="outlined" className="mb-10">
        <Box sx={{ display: 'flex', minWidth: 151 }}>
          <CardMedia
            component="img"
            sx={{ width: 151 }}
            image={mediaUrl}
            alt=""
          />
        </Box>
        <CardContent
          sx={{
            flex: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minWidth: 0,
          }}
        >
          <Typography
            component="div"
            variant="h4"
            className="mb-10"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {name}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              {content}
            </Typography>
          </Box>
        </CardContent>
        <CardActions
          sx={{
            flex: 'auto',
          }}
        >
          <Button size="small" onClick={onEdit}>
            Edit
          </Button>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Card>
    </>
  );
};

export default Media;
