import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import paths from 'routes/paths';
import formatBannerFormData from '../../utils/formatBannerFormData';
import { TEXT_BANNER_SAVE_ERROR, TEXT_BANNER_SAVED } from 'config/messages';
import { SET_ENV } from 'store/general/actions';
import { SAVE_TEXT_BANNER } from 'store/content/actions';

import ModalComponent from 'components/common/ModalComponent';
import Confirmation from 'components/common/Confirmation';
import DuplicateBanner from './DuplicateBanner';

export const envConfirmConfig = {
  title: 'Please confirm to duplicate the banner',
  buttonGroup: [
    {
      text: 'Cancel',
      type: 'CANCEL',
    },
    {
      text: 'Confirm',
      type: 'CONFIRM',
    },
  ],
};

const DuplicateBannerModals = ({
  show: showDuplicateModal = false,
  setShow: setShowDuplicateModal,
  values,
}) => {
  const [showDuplicate, setShowDuplicate] = useState(showDuplicateModal);
  const [envSelected, setEnvSelected] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setShowDuplicate(showDuplicateModal);
  }, [showDuplicateModal]);

  const handleConfirmSubmit = () => {
    let data = formatBannerFormData(formDetails);
    dispatch({
      type: SAVE_TEXT_BANNER,
      payload: {
        envSelected: envSelected,
        data,
        callback: () => {
          enqueueSnackbar(TEXT_BANNER_SAVED, {
            variant: SEVERITY.SUCCESS,
          });
          setShowConfirm(false);
          // handleEnvCancel();
          localStorage.setItem('env', envSelected);
          dispatch({ type: SET_ENV, payload: { apiEnv: envSelected } });
          history.push(paths.content.textBanners.list);
          window.location.reload();
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${TEXT_BANNER_SAVE_ERROR} : ${error}`, {
            variant: SEVERITY.ERROR,
          });
          setShowConfirm(false);
        },
      },
    });
  };

  const handleConfirmCancel = () => {
    setShowConfirm(false);
  };

  const handleEnvSubmit = ({ environment, description }, data) => {
    setEnvSelected(environment);
    setShowDuplicate(false);
    setShowDuplicateModal(false);
    setShowConfirm(true);
    setFormDetails({
      ...data,
      description: description || data.description,
    });
    envConfirmConfig.title = `Campaign "${data.description}" will be published on ${environment}. Please confirm to publish`;
  };

  const handleEnvCancel = () => {
    setShowDuplicate(false);
    setShowDuplicateModal(false);
    setEnvSelected('');
    setFormDetails({});
  };

  const envConfirmButton = envConfirmConfig.buttonGroup.map((button, i) => {
    switch (button.type) {
      case 'CANCEL':
        button.handler = handleConfirmCancel;
        return button;
      case 'CONFIRM':
        button.handler = handleConfirmSubmit;
        return button;
      default:
        return button;
    }
  });
  envConfirmConfig.buttonGroup = envConfirmButton;

  return (
    <>
      <ModalComponent
        title="Duplicate"
        open={showDuplicate}
        handleClose={handleEnvCancel}
      >
        <DuplicateBanner
          handleEnvCancel={handleEnvCancel}
          handleEnvSubmit={handleEnvSubmit}
          formDetails={values}
        />
      </ModalComponent>
      <ModalComponent
        title="Confirm"
        open={showConfirm}
        handleClose={handleConfirmCancel}
      >
        <Confirmation envConfirmConfig={envConfirmConfig} />
      </ModalComponent>
    </>
  );
};

export default DuplicateBannerModals;
