import React from 'react';
import { Link, Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { ORDER_ACTION_ERROR, ORDER_ACTION_SUCCESS } from 'config/messages';

import { postAPIData } from 'api/playosmo';
import { API } from 'axiosClient/config';
import { SEVERITY } from 'config';

function DeveloperLinksSection({ order }) {
  const { enqueueSnackbar } = useSnackbar();

  const callEndpoint = async (e, endpoint) => {
    e.preventDefault();
    try {
      const response = await postAPIData(endpoint, {
        order_number: order.orderId,
      });
      if (!response || response.status > 208) {
        return;
      }
      enqueueSnackbar(ORDER_ACTION_SUCCESS, {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      enqueueSnackbar(`${ORDER_ACTION_ERROR} : ${e.message}`, {
        variant: SEVERITY.ERROR,
      });
      throw e;
    }
  };

  const exportOrderToSQL = async (e) => {
    callEndpoint(e, API.orders.exportToSQL);
  };

  const syncOrderStatus = async (e) => {
    callEndpoint(e, API.orders.syncOrderStatus);
  };

  const syncPaymentRecord = async (e) => {
    callEndpoint(e, API.orders.syncPaymentRecord);
  };

  return (
    <Paper className="order-section order-section-overview">
      <Typography variant="h2" gutterBottom>
        Developer links
      </Typography>
      <ul>
        <li>
          <Link onClick={exportOrderToSQL}>Export to SQL</Link>
        </li>
        <li>
          <Link onClick={syncOrderStatus}>Sync shipping status</Link>
        </li>
        <li>
          <Link onClick={syncPaymentRecord}>Sync payment record</Link>
        </li>
      </ul>
    </Paper>
  );
}

export default DeveloperLinksSection;
