export const normalizeCart = (cart, order) => {
  return cart.map((product) => ({
    productName: product.display,
    key: product.key,
    sku: product.sku,
    qty: product.qty,
    itemPrice: product.item_price,
    pricePaid: product.price_paid,
    discount: product.discount,
    redeemCodes: product.redeem_codes,
    orderCountry: order?.order_country?.toUpperCase(),
    orderCurrency: order?.order_currency?.toUpperCase(),
    giftCardDetails: product.EGiftCardDetails,
  }));
};

const normalizeOrdersResponse = (orders) => {
  return orders
    .map((order) => {
      // Guess the payment method based on the order data sent by the API
      const getPaymentMethod = () => {
        if (!!order.stripe_country?.length && !order.card_exp_year) {
          return 'apple pay';
        }
        if (!!order.card_exp_year) {
          return 'card';
        }
        if (!!order.paypal_order_id?.length) {
          return 'paypal';
        }
        if (!!order.affirm_checkout_id?.length) {
          return 'affirm';
        }

        return null;
      };

      const miscData = order.misc_data?.length
        ? JSON.parse(order.misc_data)
        : null;

      return {
        // General
        createdOn: order.created_on,
        createdBy: order.order_created_by,
        canceledOn: order.cancelled_on,
        canceledBy: order.canceled_by,
        description: order.order_description,
        updatedOn: order.updated_on,
        editedBy: order.edited_by,
        orderId: order.order_number,
        orderCountry: order.order_country.toUpperCase(),
        orderCurrency: order.order_currency.toUpperCase(),
        status: order.order_state || 'INVALID',
        shipped: order.shipped,
        parentOrderId: order.parent_order_id,
        childOrderIds: order.child_order_ids,
        needsReview: order.needs_review || order.order_state === 'NEEDS_REVIEW',
        isPreorder: order.is_preorder,
        pricePaid: (parseFloat(order.order_price_paid) * 100).toString(),
        taxPaid: (parseFloat(order.order_tax_paid) * 100).toString(),
        orderTotalTaxPaid: (
          parseFloat(order.order_total_tax_paid) * 100
        ).toString(),
        emailTrust: order.email_trust,
        addressId: order.address_unique_id,
        source: order.order_source,
        checkout: order.checkout,
        utmTags: miscData?.utm,
        // Parent subscription
        orderSubscriptionId: order.order_subscription_id,
        // Subscriptions linked to this order
        orderSubscriptionIds: order.subscriptions_order_ids,
        // Customer
        email: order.order_email,
        userId: order.myosmo_user_id,
        isRepeat: order.is_repeat,
        // Coupons
        referral: order.orders_referral,
        referralDiscount: order.referral_discount,
        refererAccountEmail: order.referrer_account_email,
        coupon: order.order_coupon,
        couponDiscounts: order.coupon_discounts,
        receiptUrl: order.receipt_url,
        // EDU
        edu: !!order.edu_school_name?.length
          ? {
              website: order.edu_website_url,
              schoolsName: order.edu_school_name,
              taxExempt: order.edu_tax_exempt,
            }
          : null,
        // Shipping/billing addresses
        shipping: {
          orderShippingCost: order.order_shipping_cost,
          method: order.shipping_method,
          provider: order.shipping_provider,
          status: order.shipping_status,
          id: order.shipping_id,
          trackingLink: order.tracking_url,
          // Address
          firstName: order.shipping_first_name?.length
            ? order.shipping_first_name
            : null,
          lastName: order.shipping_last_name?.length
            ? order.shipping_last_name
            : null,
          phone: order.shipping_phone?.length ? order.shipping_phone : null,
          address1: order.shipping_address_1?.length
            ? order.shipping_address_1
            : null,
          address2: order.shipping_address_2?.length
            ? order.shipping_address_2
            : null,
          city: order.shipping_city?.length ? order.shipping_city : null,
          state: order.shipping_state?.length ? order.shipping_state : null,
          country: order.shipping_country?.length
            ? order.shipping_country
            : null,
          zipCode: order.shipping_zip?.length ? order.shipping_zip : null,
        },
        billing: {
          firstName: order.billing_first_name.length
            ? order.billing_first_name
            : null,
          lastName: order.billing_last_name.length
            ? order.billing_last_name
            : null,
          phone: order.billing_phone.length ? order.billing_phone : null,
          address1: order.billing_address_1.length
            ? order.billing_address_1
            : null,
          address2: order.billing_address_2.length
            ? order.billing_address_2
            : null,
          city: order.billing_city.length ? order.billing_city : null,
          country: order.billing_country.length ? order.billing_country : null,
          state: order.billing_state.length ? order.billing_state : null,
          zipCode: order.billing_zip.length ? order.billing_zip : null,
          distanceBillingShipping: order?.distance_billing_to_shipping?.length
            ? order.distance_billing_to_shipping
            : null,
          // Credits
          creditsUsed: order.osmo_credits_used,
          // Payment
          provider: order.payment_provider,
          paymentFailureType: order.payment_failure_type,
          retryPaymentCount: order.retry_payment_count,
          autoRetryPaymentCount: order.auto_retry_payment_count,
          retryPaymentOn: order.retry_payment_on,
          autoRetryPaymentOn: order.auto_retry_payment_on,
          autoRetryPaymentScheduledOn: order.auto_retry_payment_scheduled_on,
          card: !!order.card_exp_year
            ? {
                expDate: `${order.card_exp_year}/${order.card_exp_month}`,
                last4: order.card_last4,
              }
            : null,
          stripe: !!order.stripe_country?.length
            ? {
                tokenId: order.stripe_token_id,
                chargeId: order.stripe_charge_id,
                errorCode: order.stripe_error_code,
              }
            : null,
          paypal: !!order.paypal_order_id?.length
            ? {
                orderId: order.paypal_order_id,
                authorizationId: order.paypal_authorization_id,
                authorizationStatus: order.paypal_authorization_status,
                captureStatus: order.paypal_capture_status,
                orderStatus: order.paypal_order_status,
                invoiceId: order.paypal_invoice_id,
              }
            : null,
          affirm: !!order.affirm_checkout_id?.length
            ? {
                checkoutId: order.affirm_checkout_id,
                authorizationId: order.affirm_authorization_id,
                chargeId: order.affirm_charge_id,
              }
            : null,
          paymentMethod: getPaymentMethod(),
        },
        // Carts
        digitalCart: normalizeCart(order.digital_games_cart || [], order),
        subscriptionsCart: normalizeCart(order.subscriptions_cart || [], order),
        captureCart: normalizeCart(order.capture_cart || [], order),
        orderedCart: normalizeCart(order.ordered_cart || [], order),
        shippedCart: normalizeCart(order.shipped_cart || [], order),
        returnedCart: normalizeCart(order.returned_cart || [], order),
        backorderedCart: normalizeCart(order.backordered_cart || [], order),
        // Notes
        notes: order.notes
          ? order.notes.map((note) => ({
              content: note.note,
              date: note.added_on,
              user: note.added_by,
            }))
          : [],
        // Others
        refundedAmount: order?.payment_refunded_amount?.toString(),
        refundsList: order.payment_refunds_list,
        refundedOn: order.refunded_on,
        website: order.website,
        // Additional fields generated from existing ones
        formattedShippingAddress: `${order.shipping_city}, ${order.shipping_state}, ${order.shipping_country}`,
      };
    })
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
};

export default normalizeOrdersResponse;
