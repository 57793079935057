import React, { useState } from 'react';
import { Link, Modal, Typography, Box } from '@mui/material';

const CartModal = (props) => {
  const giftCardDetails = props.giftCardDetails;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: '#fff',
    boxShadow: 24,
    p: 4,
    padding: '2em',
    borderRadius: '2em',
  };

  return (
    <>
      <Link onClick={handleOpen} underline="hover">
        See Details
      </Link>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <Typography variant="h3" gutterBottom>
            Recipient’s Name :{' '}
            {giftCardDetails && giftCardDetails.gift_recipient_name}
          </Typography>
          <Typography variant="h3" gutterBottom>
            Recipient’s Email :{' '}
            {giftCardDetails && giftCardDetails.gift_recipient_email}
          </Typography>
          <Typography variant="h3" gutterBottom>
            Sender’s Name :{' '}
            {giftCardDetails && giftCardDetails.gift_sender_name}
          </Typography>
          <Typography variant="h3" gutterBottom>
            Card Design : {giftCardDetails && giftCardDetails.card_design}
          </Typography>
          <Typography variant="h3" gutterBottom>
            Message :{' '}
            {giftCardDetails && giftCardDetails.gift_recipient_message}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default CartModal;
