import { Checkbox, FormControlLabel } from '@mui/material';

const CheckboxComponent = ({
  input: { name, required, onChange, value, checked, ...restInput },
  meta,
  defaultChecked,
  size,
  ...rest
}) => {
  const checkedValue = typeof checked !== 'undefined' ? checked : value;

  return (
    <FormControlLabel
      {...rest}
      name={name}
      checked={checkedValue}
      value={checkedValue}
      control={
        <Checkbox
          checked={checkedValue}
          onChange={onChange}
          defaultChecked={defaultChecked}
          color="primary"
          size={size}
        />
      }
    />
  );
};

export default CheckboxComponent;
