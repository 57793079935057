import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// import { DateTime } from 'luxon';
import qs from 'query-string';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import PageContentLayout from 'components/layout/PageContentLayout';
import SetPageTitle from 'components/common/SetPageTitle';

import AccountsTable from './components/AccountsTable';
import SearchSection, {
  initialValues as formInitialValues,
} from './components/SearchSection/SearchSection';
import { TableCellStates } from './components/AccountsTable/tableConfig';
import { getAccounts } from 'api/adminV2';
import { SEVERITY } from 'config';

const normalizeAccounts = (accounts) =>
  accounts.map((account) => ({
    id: account.user_id,
    [TableCellStates.MYOSMO_USER_ID]: account.user_id,
    [TableCellStates.EMAIL]: account.email,
    [TableCellStates.ACCOUNT_ID]: account.account_id,
    [TableCellStates.CREATED_ON]: account.created_on,
  }));

const fieldsNames = [
  {
    fieldName: 'accountID',
    filterName: 'accountID',
  },
  {
    fieldName: 'myOsmoUserID',
    filterName: 'myOsmoUserID',
  },
  {
    fieldName: 'email',
    filterName: 'email',
  },
  {
    fieldName: 'phoneNumber',
    filterName: 'phoneNumber',
  },
  {
    fieldName: 'schoolCode',
    filterName: 'schoolCode',
  },
  // {
  //   fieldName: 'dateRange.start',
  //   filterName: 'startDate',
  // },
  // {
  //   fieldName: 'dateRange.end',
  //   filterName: 'endDate',
  // },
];

const AccountsListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [accountsList, setAccountsList] = useState([]);
  const [initialFiltersValue, setInitialFiltersValue] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const getTitle = () => 'Accounts';

  const fetchAccounts = async (filters) => {
    if (!filters || !Object.values(filters).find((val) => !!val)) {
      return null;
    }

    setIsLoading(true);

    try {
      const payload = {
        user_id: parseInt(filters.myOsmoUserID),
        email: filters.email,
        phone_number: filters.phoneNumber,
        username: '',
        account_id: filters.accountID,
        token: '',
        count_only: false,
        time_field: '',
        // "start_date": filters.startDate,
        // "end_date": filters.endDate,
        cursor: '',
        limit: 20,
      };
      const response = await getAccounts(payload);

      if (!response?.data?.users || response.status > 208) {
        setAccountsList([]);
        setIsLoading(false);
        return;
      }

      const normalizedAccounts = normalizeAccounts(response.data.users);
      setAccountsList(normalizedAccounts);
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar(e.response.data, {
        variant: SEVERITY.ERROR,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Check if there is any parameters in the URL
    const urlParams = new URLSearchParams(location.search);

    const filtersFromUrl = fieldsNames.reduce(
      (acc, name) => ({
        ...acc,
        [name.filterName]: urlParams.get(name.filterName),
      }),
      {},
    );

    const initialValue = fieldsNames.reduce((acc, name) => {
      if (name.fieldName.includes('.')) {
        const keys = name.fieldName.split('.');
        return {
          ...acc,
          [keys[0]]: {
            [keys[1]]:
              filtersFromUrl[name.filterName] ||
              formInitialValues[keys[0]][keys[1]],
          },
        };
      }

      return {
        ...acc,
        [name.fieldName]:
          filtersFromUrl[name.filterName] || formInitialValues[name.fieldName],
      };
    }, {});

    // If we have an end date in the url, we use it, otherwise we add 1h to the start date in the url, else, we fallback to the default initial value
    // const initialStartDateValue = filtersFromUrl.startDate
    //   ? DateTime.fromSeconds(parseInt(filtersFromUrl.startDate)).toJSDate()
    //   : filtersFromUrl.endDate
    //   ? DateTime.fromSeconds(parseInt(filtersFromUrl.endDate))
    //       .minus({ hour: 1 })
    //       .toJSDate()
    //   : formInitialValues.dateRange.start;
    // initialValue.dateRange = {
    //   start: initialStartDateValue,
    //   end: filtersFromUrl.endDate
    //     ? DateTime.fromSeconds(parseInt(filtersFromUrl.endDate)).toJSDate()
    //     : formInitialValues.dateRange.end,
    // };

    setInitialFiltersValue(initialValue);

    // Get the orders based on the parameters
    fetchAccounts(filtersFromUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFiltersChange = (filters, isReset) => {
    // fetch a new batch of orders
    fetchAccounts(filters);
    // Update the URL params
    const queryParams = qs.parse(location.search);
    const newQueries = { ...queryParams, ...filters };
    history.push({ search: isReset ? null : qs.stringify(newQueries) });
  };

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <PageContentLayout title={getTitle()} isLoading={isLoading}>
        <Grid container className="mb-20">
          <Grid item xs={12}>
            <SearchSection
              onFiltersChange={onFiltersChange}
              initialValues={initialFiltersValue}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <AccountsTable rows={accountsList} />
          </Grid>
        </Grid>
      </PageContentLayout>
    </>
  );
};

export default AccountsListing;
