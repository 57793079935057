import { makeStyles } from '@mui/styles';

const useStylesAccordion = makeStyles({
  root: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
});

const useStylesAccordionSummary = makeStyles({
  root: {
    width: '30%',
    minHeight: '35px !important',
    height: '35px',
    marginLeft: '-0.8em',
    borderRadius: '8px',
    '&:hover': {
      background: '#ececec',
    },
  },
});

const useStylesAccordionDetails = makeStyles({
  root: {
    display: 'block',
    boxShadow: 'none',
    marginLeft: '-1em',
  },
});

export {
  useStylesAccordion,
  useStylesAccordionSummary,
  useStylesAccordionDetails,
};
