import { UPCOMING_CAMPAIGN_SUCCESS, PAST_CAMPAIGN_SUCCESS } from './actions';

const initialState = {
  upcomingCampaign: [],
  pastCampaign: [],
};

export const campaignListReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPCOMING_CAMPAIGN_SUCCESS: {
      const { campaign } = action.payload;
      return {
        ...state,
        upcomingCampaign: [...campaign.upcomingCampaign],
      };
    }

    case PAST_CAMPAIGN_SUCCESS: {
      const { campaign } = action.payload;
      return {
        ...state,
        pastCampaign: [...campaign.pastCampaign],
      };
    }

    default:
      return state;
  }
};
