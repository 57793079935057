import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button } from '@mui/material';

import { SUPPORTED_LANGUAGES } from 'config';
import {
  BACKGROUND_IMAGE_TOOLTIP,
  CONTENT_IMAGE_TOOLTIP,
} from 'config/messages';

import FileInput from 'components/common/FileInput';

import ColorPicker from 'components/common/colorPicker';
import ToolTip from 'components/common/Tooltip';
import {
  UPLOAD_FILES,
  DELETE_FILES,
} from 'components/containers/CampaignContainer/CreateCampaign/store/actions';
import Loader from 'components/common/Loader';
import ModalComponent from 'components/common/ModalComponent';
import Confirmation from 'components/common/Confirmation';
import { envConfirmConfig } from 'components/containers/CampaignContainer/CreateCampaign/config';
import SelectComponent from 'components/common/SelectDropdown/SelectComponent';

import './style.scss';

const BannerImageSection = ({
  value,
  uploadSectionData,
  isHeaderDefault,
  disabled,
  handleBannerSubmit,
  handleBannerCancel,
  handleBannerOption,
  handleCountryChange,
  handleLanguageChange,
}) => {
  const assets = useSelector((state) => state.assetsReducer.assets);
  const { shippingCountries } = useSelector((state) => state.general);
  const [showBackgroundLoader, setBackgroundLoader] = useState(false);
  const [showContentLoader, setContentLoader] = useState(false);
  const [showDeleteImagePopup, setShowDeleteImagePopup] = useState(false);
  const [deleteFileType, setDeleteFileType] = useState('');
  const dispatch = useDispatch();

  const deleteBackgroundImageDispatch = {
    type: DELETE_FILES,
    payload: {
      fileId: value && value.background_image,
      callback: () => {
        setBackgroundLoader(false);
        delete value.background_image;
        uploadSectionData({
          ...value,
        });
      },
      errorCallback: (e) => {
        setBackgroundLoader(false);
      },
    },
  };

  const deleteContentImageDispatch = {
    type: DELETE_FILES,
    payload: {
      fileId: value && value.content_image,
      callback: () => {
        setContentLoader(false);
        delete value.content_image;
        uploadSectionData({
          ...value,
        });
      },
      errorCallback: (e) => {
        showContentLoader(false);
      },
    },
  };

  const removeBackground = () => {
    setDeleteFileType('background');
    setShowDeleteImagePopup(true);
    envConfirmConfig.title = `Selected Background Image will be permanently deleted. Please confirm to continue`;
  };

  const removeContent = () => {
    setDeleteFileType('content');
    setShowDeleteImagePopup(true);
    envConfirmConfig.title = `Selected Content Image will be permanently deleted. Please confirm to continue`;
  };

  const handleColorChange = (color) => {
    uploadSectionData({
      background_color: color,
    });
  };
  const setBackgroundImage = (file) => {
    setBackgroundLoader(true);
    dispatch({
      type: UPLOAD_FILES,
      payload: {
        file: file[0],
        callback: (id) => {
          setBackgroundLoader(false);
          uploadSectionData({
            background_image: id,
          });
        },
        errorCallback: (e) => {
          setBackgroundLoader(false);
        },
      },
    });
  };
  const setContentImage = (file) => {
    setContentLoader(true);
    dispatch({
      type: UPLOAD_FILES,
      payload: {
        file: file[0],
        callback: (id) => {
          setContentLoader(false);
          uploadSectionData({
            content_image: id,
          });
        },
        errorCallback: (e) => {
          setContentLoader(false);
        },
      },
    });
  };

  const handleConfirmCancel = () => {
    setShowDeleteImagePopup(false);
  };

  const handleConfirmSubmit = () => {
    setShowDeleteImagePopup(false);
    switch (deleteFileType) {
      case 'background': {
        setBackgroundLoader(true);
        dispatch(deleteBackgroundImageDispatch);
        break;
      }
      case 'content': {
        setContentLoader(true);
        dispatch(deleteContentImageDispatch);
        return;
      }
      default:
        return;
    }
  };

  const envConfirmButton = envConfirmConfig.buttonGroup.map((button, i) => {
    switch (button.type) {
      case 'CANCEL':
        button.handler = handleConfirmCancel;
        return button;
      case 'CONFIRM':
        button.handler = handleConfirmSubmit;
        return button;
      default:
        return button;
    }
  });
  envConfirmConfig.buttonGroup = envConfirmButton;

  return (
    <div className="file-upload">
      <div className="file-upload-container mt-20">
        {isHeaderDefault && (
          <>
            {showBackgroundLoader ? (
              <div className="loader">
                <Loader />
              </div>
            ) : (
              <div>
                <div>
                  Background Image
                  <ToolTip title={BACKGROUND_IMAGE_TOOLTIP} />
                </div>
                <FileInput
                  onChange={setBackgroundImage}
                  value={
                    (value &&
                      value.background_image && {
                        id: value.background_image,
                        public_url: assets[value.background_image],
                      }) ||
                    null
                  }
                  className="mr-20"
                  remove={removeBackground}
                  disabled={disabled}
                />
              </div>
            )}
          </>
        )}
        {showContentLoader ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          <div>
            <div>
              Content Image
              <ToolTip title={CONTENT_IMAGE_TOOLTIP} />
            </div>
            <FileInput
              onChange={setContentImage}
              value={
                (value &&
                  value.content_image && {
                    id: value.content_image,
                    public_url: assets[value.content_image],
                  }) ||
                null
              }
              remove={removeContent}
              disabled={disabled}
            />
          </div>
        )}
      </div>
      {isHeaderDefault && (
        <div className="mt-20">
          <div>
            Color
            <ToolTip title="Color will be the background color for the banner image. E.g a image will blue background may have a blue color to the areas not covered by the image in case the image is short of banner space." />
          </div>
          <ColorPicker
            value={(value && value.background_color) || ''}
            onChange={handleColorChange}
            disabled={disabled}
          />
          {value && value.type !== 'Default' ? (
            <>
              <SelectComponent
                name="country"
                onChange={handleCountryChange}
                label="Select Country"
                className="discount-country"
                dataItems={shippingCountries}
                value={value && value.country}
              />
              <SelectComponent
                name="language"
                onChange={handleLanguageChange}
                label="Select Language"
                className="discount-country"
                dataItems={SUPPORTED_LANGUAGES}
                value={value && value.lang}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      )}
      <Grid item xs={12} className="button-container mt-20">
        <Button
          className="mr-20"
          size="small"
          variant="outlined"
          type="button"
          onClick={handleBannerCancel}
        >
          Cancel
        </Button>
        <Button
          className="button"
          size="small"
          variant="contained"
          type="button"
          onClick={handleBannerSubmit}
        >
          Submit
        </Button>
      </Grid>
      <ModalComponent
        title="Confirm"
        open={showDeleteImagePopup}
        handleClose={handleConfirmCancel}
      >
        <Confirmation envConfirmConfig={envConfirmConfig} />
      </ModalComponent>
    </div>
  );
};

export default React.memo(BannerImageSection);
