import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import AddRow from './AddRow';
import File from './File';

import './styles.scss';

const GameManuals = ({ inputBase }) => {
  const { mutators } = useForm();
  const disabled = inputBase?.disabled;

  const addRow = () => {
    mutators.push('manuals', {
      language: '',
      url: '',
    });
  };

  return (
    <div className="game-manuals">
      <FieldArray name="manuals">
        {({ fields }) =>
          fields.map((name, index) => {
            return (
              <File
                key={name}
                name={name}
                disabled={disabled}
                onDelete={() => fields.remove(index)}
              />
            );
          })
        }
      </FieldArray>
      <AddRow onClick={addRow} label="Add game manual" />
    </div>
  );
};

export default GameManuals;
