const getCreatorDataItems = (rows) => {
  // Creator array for filtering by creator
  let campaignCreatorValues = rows.map((item) => {
    return item.created_by;
  });
  campaignCreatorValues = [...new Set(campaignCreatorValues)];
  let campaignCreatorDataItems = campaignCreatorValues.map((item) => {
    return {
      id: item,
      value: item,
    };
  });
  campaignCreatorDataItems.push({
    id: 'ALL',
    value: 'ALL',
  });

  return campaignCreatorDataItems;
};

const getCountryDataItems = (rows) => {
  // Country array for filtering by country
  let countryValues = [];
  rows.map((item) => {
    const countryIterator = item.countries.values();
    for (const value of countryIterator) {
      countryValues.push(value);
    }
    return true;
  });
  countryValues = [...new Set(countryValues)];
  let countryDataItems = countryValues.map((item) => {
    return {
      id: item,
      value: item,
    };
  });
  countryDataItems.push({
    id: 'ALL',
    value: 'ALL',
  });
  return countryDataItems;
};

const getTypeDataItems = () => {
  return [
    {
      id: 'true',
      value: 'Global',
    },
    {
      id: 'false',
      value: 'URL',
    },
    {
      id: 'ALL',
      value: 'ALL',
    },
  ];
};

const getWebsiteDataItem = () => {
  return [
    {
      id: 'playosmo',
      value: 'Playosmo',
    },
    {
      id: 'byjuslearning',
      value: 'Byju’s Learning',
    },
    {
      id: 'ALL',
      value: 'ALL',
    },
  ];
};
export {
  getCreatorDataItems,
  getCountryDataItems,
  getTypeDataItems,
  getWebsiteDataItem,
};
