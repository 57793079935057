import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from './actions';

const initialState = {
  isLoggedIn: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const { isLoggedIn } = action.payload;
      return {
        ...state,
        isLoggedIn,
      };
    }
    case LOGOUT_SUCCESS: {
      const isLoggedIn = false;
      return {
        ...state,
        isLoggedIn,
      };
    }

    default:
      return state;
  }
};
