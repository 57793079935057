const normalizeCountriesResponse = (data) =>
  data.map((country) => ({
    id: country.country_code,
    countryCode: country.country_code,
    status: country.is_enabled ? 'active' : 'inactive',
    name: country.name,
    note: country.note,
    isDutyApplied: country.is_duty_applied,
    includedTax: country.included_tax / 100,
    warehouseCodes: country.warehouse_codes,
    isShippingAvailable: country.is_shipping_available,
    isZeroDecimalCurrency: country.is_zero_decimal_currency,
    currencyCode: country.currency_code,
    currencySymbolRight: country.symbol_right,
    currencySymbolLeft: country.symbol_left,
  }));

export default normalizeCountriesResponse;
