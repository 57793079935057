import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip, Link } from '@mui/material';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import paths from 'routes/paths';

import { SEVERITY } from 'config';

import { TableActionStates } from 'enums/tableStates';
import {
  getLocalDateFromUTC,
  formatDate,
  campaignPostDataTransform,
} from 'utils';
import { getCouponURL } from 'config/environments';
import { MOBILE_BREAK_POINT } from 'config/breakpoint';
import useWindowSize from '../useWindowSize';
import { getByjusCouponURL } from 'config/environments';

import TableComponent from 'components/common/TableComponent';
import TableActions from 'components/common/TableActions';
import Action from 'components/common/TableActions/Actions';
import TableToolbar from 'components/common/TableComponent/components/TableToolbar';
import ModalComponent from 'components/common/ModalComponent';
import Confirmation from 'components/common/Confirmation';
import StatusIndicator from 'components/common/StatusIndicator';
import MobileTableComponent from 'components/containers/MobileTableContainer/MobileTableComponent';
import { renderCampaignAction } from 'components/containers/MobileTableContainer/renderActions';
import {
  envConfirmConfig,
  envConfirmConfigForDuplicate,
} from 'components/containers/CampaignContainer/CampaignListing/config';
import SetPageTitle from 'components/common/SetPageTitle';
import CampaignFilters from '../CampaignFilters';

import {
  CAMPAIGN_DELETE_ERROR,
  CAMPAIGN_DELETE_SUCCESS,
  CAMPAIGN_SAVE_ERROR,
  CAMPAIGN_SAVED,
} from 'config/messages';

import {
  tableConfig,
  tableToolBarConfig,
  tableToolBarStates,
  TableCellStates,
} from './tableConfig';

import { DELETE_CAMPAIGN } from '../actions';
import DuplicateCampaign from '../../CreateCampaign/DuplicateCampaign/DuplicateCampaign';
import { SAVE_CAMPAIGN_DETAILS } from '../../CreateCampaign/store/actions';
import { SET_ENV } from '../../../../../store/general/actions';

import './styles.scss';
import 'components/containers/CampaignContainer/CampaignListing/index.scss';

function UpcomingCampaign(props) {
  const {
    upcomingCampaign,
    getUpcomingCampaign,
    reviewOnly,
    setWebsitePayload,
  } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [website, setWebsite] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmDuplicate, setShowConfirmDuplicate] = useState(false);
  const COUPON_URL = getCouponURL();
  const BYJUS_COUPON_URL = getByjusCouponURL();
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [envSelected, setEnvSelected] = useState('');
  const [formDetails, setFormDetails] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();

  const rows = upcomingCampaign;
  const windowSize = useWindowSize();
  const [filteredRows, setFilteredRows] = useState(rows);

  const handleEnvSubmit = ({ environment, campaign }, data) => {
    setEnvSelected(environment);
    setShowDuplicate(false);
    setShowConfirmDuplicate(true);
    setFormDetails({
      ...data,
      campaign: campaign || data.campaign,
    });
    envConfirmConfigForDuplicate.title = `Campaign ${data.campaign_name} (${
      campaign || data.campaign
    }) will be published on ${environment}. Please confirm to publish`;
  };

  const handleConfirmSubmit = () => {
    dispatch({
      type: SAVE_CAMPAIGN_DETAILS,
      payload: {
        envSelected: envSelected,
        campaignData: formDetails,
        callback: () => {
          enqueueSnackbar(CAMPAIGN_SAVED, {
            variant: SEVERITY.SUCCESS,
          });
          setShowConfirmDuplicate(false);
          handleEnvCancel();
          localStorage.setItem('env', envSelected);
          dispatch({ type: SET_ENV, payload: { apiEnv: envSelected } });
          history.push(paths.marketing.campaigns.list);
          window.location.reload();
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${CAMPAIGN_SAVE_ERROR} : ${error}`, {
            variant: SEVERITY.ERROR,
          });
          setShowConfirmDuplicate(false);
        },
      },
    });
  };

  const handleEnvCancel = () => {
    setShowDuplicate(false);
    setEnvSelected('');
    setFormDetails({});
  };

  const handleConfirmCancel = () => {
    setShowConfirmDuplicate(false);
  };

  const onRowSelectChange = (selectedRow) => {
    setSelectedRows(selectedRow);
  };

  useEffect(() => {
    // eslint-disable-next-line
    setWebsitePayload(website);
    setFilteredRows(rows);
  }, [rows, website, setWebsitePayload]);

  const envConfirmButton = envConfirmConfig.buttonGroup.map((button, i) => {
    switch (button.type) {
      case 'CANCEL':
        button.handler = () => setShowConfirm(false);
        return button;
      case 'CONFIRM':
        button.handler = () => deleteCampaign(selectedRows);
        return button;
      default:
        return button;
    }
  });
  envConfirmConfig.buttonGroup = envConfirmButton;

  const envConfirmButtonDuplicate =
    envConfirmConfigForDuplicate.buttonGroup.map((button, i) => {
      switch (button.type) {
        case 'CANCEL':
          button.handler = () => setShowConfirmDuplicate(false);
          return button;
        case 'CONFIRM':
          button.handler = () => handleConfirmSubmit();
          return button;
        default:
          return button;
      }
    });
  envConfirmConfigForDuplicate.buttonGroup = envConfirmButtonDuplicate;

  const deleteCampaign = (ids) => {
    ids.forEach((id) => {
      dispatch({
        type: DELETE_CAMPAIGN,
        payload: {
          id,
          callback: () => {
            getUpcomingCampaign();
            setSelectedRows([]);
            setShowConfirm(false);
            enqueueSnackbar(CAMPAIGN_DELETE_SUCCESS, {
              variant: SEVERITY.SUCCESS,
            });
          },
          errorCallback: (error) => {
            enqueueSnackbar(`${CAMPAIGN_DELETE_ERROR} (${id}) : ${error}`, {
              variant: SEVERITY.ERROR,
            });
          },
        },
      });
    });
  };

  const toolBarData = tableToolBarConfig.map((configDetail, index) => {
    switch (configDetail.type) {
      case tableToolBarStates.DeleteButton:
        configDetail.render = () => {
          return (
            <Tooltip title="Delete" key={index} disableInteractive>
              <IconButton
                aria-label="delete"
                onClick={() => setShowConfirm(true)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          );
        };
        return configDetail;
      default:
        return configDetail;
    }
  });

  const options = {
    selectedRows,
    onRowSelectChange: onRowSelectChange,
    isRowSelectable: true,
    rowsPerPage: 25,
    selectionKey: 'id',
    customToolbar: () => {
      return (
        <TableToolbar
          selectedRowsCount={selectedRows?.length || 0}
          title="Upcoming Campaigns"
          toolBarData={toolBarData}
        />
      );
    },
  };

  const renderActions = (cell, record) => {
    return cell.actions.map((action, index) => {
      switch (action.type) {
        case TableActionStates.EDIT:
          if (reviewOnly) return true;
          return (
            <Action
              key={index}
              text={action.text}
              link={`${paths.marketing.campaigns.edit}/${record.id}`}
            />
          );

        case TableActionStates.REVIEW:
          if (!reviewOnly) return true;
          return (
            <Action
              key={index}
              text={action.text}
              link={`${paths.marketing.campaigns.review}/${record.id}`}
            />
          );

        case TableActionStates.DELETE:
          if (reviewOnly) return true;
          return (
            <Action
              key={index}
              text={action.text}
              action={() => {
                deleteCampaign([record.id]);
              }}
            />
          );
        case TableActionStates.DUPLICATE:
          return (
            <Action
              key={index}
              text={action.text}
              action={() => {
                setShowDuplicate(true);
                setFormDetails(record);
              }}
            />
          );
        default:
          return cell;
      }
    });
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.STATUS_INDICATOR:
        cell.render = (record, index) => {
          return (
            <StatusIndicator
              key={i}
              status={record.is_live ? 'live' : 'inactive'}
            />
          );
        };
        return cell;
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return (
            <TableActions key={i}>{renderActions(cell, record)}</TableActions>
          );
        };
        return cell;
      case TableCellStates.COUNTRIES:
        cell.render = (record, index) => {
          return <span key={i}>{record.countries.join(', ')}</span>;
        };
        return cell;
      case TableCellStates.START_DATE:
        cell.render = (record, index) => {
          return (
            <span className="" key={i}>
              {formatDate(getLocalDateFromUTC(record.starts_on))}
            </span>
          );
        };
        return cell;
      case TableCellStates.END_DATE:
        cell.render = (record, index) => {
          return (
            <span className="" key={i}>
              {formatDate(getLocalDateFromUTC(record.expires_on))}
            </span>
          );
        };
        return cell;
      case TableCellStates.URL:
        cell.render = (record, index) => {
          return (
            <span key={i}>
              <Link
                href={
                  record.websites && record.websites.includes('byjuslearning')
                    ? `${BYJUS_COUPON_URL}${record.coupon_code}`
                    : `${COUPON_URL}${record.coupon_code}`
                }
                target="_blank"
                rel="noreferrer"
                className="campaign-test-link campaign-cell-text-short"
                underline="hover"
              >
                {record.websites && record.websites.includes('byjuslearning')
                  ? `${BYJUS_COUPON_URL}${record.coupon_code}`
                  : `${COUPON_URL}${record.coupon_code}`}
                <OpenInNewIcon />
              </Link>
            </span>
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  return (
    <>
      <SetPageTitle pageTitle="Current and Upcoming Campaigns" />
      <CampaignFilters
        rows={rows}
        filteredRows={filteredRows}
        setFilteredRows={setFilteredRows}
        website={website}
        setWebsite={setWebsite}
      />
      <ModalComponent
        title="Duplicate"
        open={showDuplicate}
        handleClose={handleEnvCancel}
      >
        <DuplicateCampaign
          handleEnvCancel={handleEnvCancel}
          campaignPostDataTransform={campaignPostDataTransform}
          handleEnvSubmit={handleEnvSubmit}
          formDetails={formDetails}
        />
      </ModalComponent>
      <ModalComponent
        title="Confirm"
        open={showConfirmDuplicate}
        handleClose={handleConfirmCancel}
      >
        <Confirmation envConfirmConfig={envConfirmConfigForDuplicate} />
      </ModalComponent>
      {windowSize.width >= MOBILE_BREAK_POINT ? (
        <div>
          <div>
            <TableComponent
              rows={filteredRows}
              headCells={headCells}
              options={options}
            />
          </div>
          <ModalComponent
            title="Confirm"
            open={showConfirm}
            handleClose={() => setShowConfirm(false)}
          >
            <Confirmation envConfirmConfig={envConfirmConfig} />
          </ModalComponent>
        </div>
      ) : (
        <div className="mobile-table">
          <MobileTableComponent
            reviewOnly={reviewOnly}
            rows={filteredRows}
            headCells={headCells}
            renderAction={renderCampaignAction}
            deleteCampaign={deleteCampaign}
          />
        </div>
      )}
    </>
  );
}

export default UpcomingCampaign;
