import { useState } from 'react';
import FileInputWrapper from './FileInputWrapper';

const FileInputForm = ({
  service,
  input: { name, required, onChange, value, ...restInput },
  meta,
  onLoad,
  onError,
  ...rest
}) => {
  const [error, setError] = useState(meta.error);

  const handleChange = (val) => {
    onChange(val);
  };

  const handleError = (e) => {
    setError(e || 'An error occurred');
    if (onError) {
      onError();
    }
  };

  const hasError =
    error ||
    (((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
      meta.touched);

  return (
    <FileInputWrapper
      service={service}
      name={name}
      required={required}
      onChange={handleChange}
      onError={handleError}
      onLoad={onLoad}
      value={value}
      error={hasError && (meta.error || error)}
      {...rest}
    />
  );
};

export default FileInputForm;
