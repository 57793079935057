import { Paper, Typography } from '@mui/material';
import GameManuals from './GameManuals';

const GameManualsSection = ({ inputBase }) => {
  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Game Manuals
      </Typography>
      <GameManuals name="manuals" inputBase={inputBase} />
    </Paper>
  );
};

export default GameManualsSection;
