import LiveTvIcon from '@mui/icons-material/LiveTv';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import GroupIcon from '@mui/icons-material/Group';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import StoreIcon from '@mui/icons-material/Store';
import Dashboard from '@mui/icons-material/Dashboard';
import More from '@mui/icons-material/More';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ForumIcon from '@mui/icons-material/Forum';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import UndoIcon from '@mui/icons-material/Undo';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import FaceIcon from '@mui/icons-material/Face';
import PeopleIcon from '@mui/icons-material/People';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import PublicIcon from '@mui/icons-material/Public';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LoopIcon from '@mui/icons-material/Loop';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import CategoryIcon from '@mui/icons-material/Category';
import { InsertLink, Subscriptions } from '@mui/icons-material';
import {
  MARKETING_READ,
  MARKETING_WRITE,
  USERS_WRITE,
  USERS_READ,
  ORDERS_WRITE,
  ORDERS_READ,
  SHORTLINK_WRITE,
  SHORTLINK_READ,
  COMMUNICATIONS_READ,
  COMMUNICATIONS_WRITE,
  USERS_ACCOUNTS_READ,
  USERS_ACCOUNTS_WRITE,
  PLAYOSMO_PRODUCTS_WRITE,
  IP_WHITELIST_READ,
  IP_WHITELIST_WRITE,
} from 'config/roles';
import IssueNotificationBadge from 'components/containers/IssueContainer/IssueNotificationBadge';

import paths from './paths';

export const navigationConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: <Dashboard />,
    exact: true,
    url: '/dashboard',
  },
  {
    id: 'ecommerce',
    title: 'E-commerce',
    type: 'category',
    roles: [
      ORDERS_WRITE,
      ORDERS_READ,
      MARKETING_READ,
      MARKETING_WRITE,
      SHORTLINK_WRITE,
      SHORTLINK_READ,
    ],
    children: [
      {
        id: 'orders',
        title: 'Orders',
        type: 'category',
        roles: [ORDERS_WRITE, ORDERS_READ],
        icon: <StoreIcon />,
        children: [
          {
            id: 'orders_listing',
            title: 'Orders Listing',
            type: 'item',
            url: paths.orders.list,
            roles: [ORDERS_WRITE, ORDERS_READ],
            icon: <LocalMallIcon />,
          },
          {
            id: 'create_order',
            title: 'Create an order',
            type: 'item',
            url: paths.orders.create,
            roles: [ORDERS_WRITE],
            icon: <AddShoppingCartIcon />,
          },
          {
            id: 'issues_listing',
            title: 'Issues Listing',
            type: 'item',
            url: paths.orders.issues.list,
            roles: [ORDERS_WRITE, ORDERS_READ],
            icon: <IssueNotificationBadge />,
          },
          {
            id: 'returns',
            title: 'Returns',
            type: 'item',
            url: paths.orders.returns.list,
            roles: [ORDERS_WRITE],
            icon: <UndoIcon />,
          },
          {
            id: 'subscription',
            title: 'Subscriptions',
            type: 'item',
            icon: <Subscriptions />,
            exact: true,
            url: '/subscriptions',
            roles: [ORDERS_WRITE, ORDERS_READ],
          },
        ],
      },
      {
        id: 'marketing',
        title: 'Marketing',
        type: 'category',
        icon: <LiveTvIcon />,
        roles: [
          MARKETING_READ,
          MARKETING_WRITE,
          SHORTLINK_WRITE,
          SHORTLINK_READ,
          ORDERS_WRITE,
          ORDERS_READ,
        ],
        children: [
          {
            id: 'campaigns',
            title: 'Campaigns',
            type: 'category',
            roles: [MARKETING_READ, MARKETING_WRITE],
            icon: <RecordVoiceOverIcon />,
            url: paths.marketing.campaigns.list,
          },
          {
            id: 'promocodes',
            title: 'Promo Codes',
            type: 'category',
            roles: [MARKETING_READ, MARKETING_WRITE],
            icon: <ConfirmationNumberIcon />,
            url: paths.marketing.promocodes.list,
          },
          {
            id: 'coupon',
            title: 'Coupons',
            type: 'item',
            icon: <More />,
            exact: true,
            url: '/coupons',
            roles: [MARKETING_READ, MARKETING_WRITE, ORDERS_WRITE, ORDERS_READ],
          },
          {
            id: 'shortlinks',
            title: 'Short Links',
            type: 'item',
            icon: <InsertLink />,
            exact: true,
            url: paths.marketing.shortlinks.list,
            roles: [SHORTLINK_WRITE, SHORTLINK_READ],
          },
        ],
      },
      {
        id: 'content',
        title: 'Content',
        type: 'category',
        icon: <LibraryBooksIcon />,
        roles: [MARKETING_READ, MARKETING_WRITE],
        children: [
          {
            id: 'text_banner',
            title: 'Text Banners',
            type: 'category',
            roles: [MARKETING_READ, MARKETING_WRITE],
            icon: <AnnouncementIcon />,
            url: paths.content.textBanners.list,
          },
        ],
      },
      {
        id: 'catalog',
        title: 'Catalog',
        type: 'category',
        roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
        icon: <ImportContactsIcon />,
        children: [
          {
            id: 'products_listing',
            title: 'Products',
            type: 'item',
            url: paths.catalog.products.list,
            roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
            icon: <CategoryIcon />,
          },
        ],
      },
      {
        id: 'ecommerce-config',
        title: 'Configuration',
        type: 'category',
        roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
        icon: <StorefrontIcon />,
        children: [
          {
            id: 'games_listing',
            title: 'Games',
            type: 'item',
            url: paths.eCommerceConfiguration.games.list,
            roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
            icon: <SportsEsportsIcon />,
          },
          {
            id: 'warehouses_listing',
            title: 'Warehouses',
            type: 'item',
            url: paths.eCommerceConfiguration.warehouses.list,
            roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
            icon: <WarehouseIcon />,
          },
          {
            id: 'countries_listing',
            title: 'Countries',
            type: 'item',
            url: paths.eCommerceConfiguration.countries.list,
            roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
            icon: <PublicIcon />,
          },
          {
            id: 'shipping_methods_listing',
            title: 'Shipping Methods',
            type: 'item',
            url: paths.eCommerceConfiguration.shippingMethods,
            roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
            icon: <LocalShippingIcon />,
          },
          {
            id: 'sku_substitutes_listing',
            title: 'SKU Substitutes',
            type: 'item',
            url: paths.eCommerceConfiguration.substitutes,
            roles: [PLAYOSMO_PRODUCTS_WRITE], // TODO: add READ permission when we have it
            icon: <LoopIcon />,
          },
        ],
      },
    ],
  },
  {
    id: 'users-cat',
    title: 'Users',
    type: 'category',
    roles: [
      COMMUNICATIONS_READ,
      COMMUNICATIONS_WRITE,
      USERS_ACCOUNTS_READ,
      USERS_ACCOUNTS_WRITE,
    ],
    icon: <MailOutlineIcon />,
    children: [
      {
        id: 'accounts',
        title: 'Accounts',
        type: 'item',
        icon: <PeopleIcon />,
        roles: [USERS_ACCOUNTS_READ, USERS_ACCOUNTS_WRITE],
        url: paths.users.accounts.list,
      },
      {
        id: 'profiles',
        title: 'Profiles',
        type: 'item',
        roles: [USERS_ACCOUNTS_READ, USERS_ACCOUNTS_WRITE],
        icon: <FaceIcon />,
        url: paths.users.profiles.list,
      },
      {
        id: 'communications',
        title: 'Communications',
        type: 'category',
        icon: <ForumIcon />,
        roles: [COMMUNICATIONS_READ, COMMUNICATIONS_WRITE],
        children: [
          {
            id: 'email-subscriptions',
            title: 'Email Subscriptions',
            type: 'item',
            roles: [COMMUNICATIONS_READ, COMMUNICATIONS_WRITE],
            icon: <MailOutlineIcon />,
            url: paths.users.emailSubscriptions.list,
          },
        ],
      },
      {
        id: 'credits',
        title: 'Credits',
        type: 'item',
        icon: <LocalAtmIcon />,
        roles: [USERS_ACCOUNTS_READ, USERS_ACCOUNTS_WRITE],
        url: paths.users.credits,
      },
    ],
  },
  {
    id: 'developer_tools',
    title: 'Developer tools',
    type: 'category',
    roles: [USERS_WRITE, USERS_READ, IP_WHITELIST_WRITE, IP_WHITELIST_READ],
    icon: <GroupIcon />,
    children: [
      {
        id: 'users',
        title: 'Admin permissions',
        type: 'item',
        exact: true,
        roles: [USERS_WRITE, USERS_READ],
        icon: <GroupIcon />,
        url: paths.tools.permissions.list,
      },
      {
        id: 'ip_whitelist',
        title: 'IP whitelist',
        type: 'item',
        exact: true,
        roles: [IP_WHITELIST_WRITE, IP_WHITELIST_READ],
        icon: <PlaylistAddCheckIcon />,
        url: paths.tools.ipWhitelist.list,
      },
    ],
  },
  {
    id: 'logout',
    title: 'Logout',
    type: 'item',
    icon: <ExitToAppIcon />,
    exact: true,
    url: paths.logout,
    dividerBefore: true,
  },
];
