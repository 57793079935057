import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { getProductManagement } from 'api/products';
import { API } from 'axiosClient/config';
import normalizeShippingMethodsResponse from 'utils/normalizers/api/normalizeShippingMethodsResponse';
import { FETCH_REFERENCES, FETCH_SKU_DETAILS } from 'store/general/actions';
import PageContentLayout from 'components/layout/PageContentLayout';
import SetPageTitle from 'components/common/SetPageTitle';
import SearchInput from 'components/common/SearchInput';

import ShippingMethodsTable from './ShippingMethodsTable';
import userSelectors from 'store/user/selectors';
import { PLAYOSMO_PRODUCTS_WRITE } from 'config/roles';
import SelectComponent from 'components/common/SelectDropdown/SelectComponent';
import generalSelectors from 'store/general/selectors';
import ShippingMethodCreationForm from './ShippingMethodCreationForm';
import ModalComponent from 'components/common/ModalComponent';

const ShippingMethodsListing = ({
  // From Redux
  userRoles,
}) => {
  const [loading, setLoading] = useState(false);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [filteredRows, setFilteredRows] = useState(null);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [filters, setFilters] = useState({
    type: '',
    country: '',
  });
  const dispatch = useDispatch();
  const shippingMethodsRefs = useSelector(
    generalSelectors.getShippingMethodReferences,
  );
  const canEdit = userRoles && userRoles.includes(PLAYOSMO_PRODUCTS_WRITE);
  const shippingMethodsData =
    shippingMethodsRefs?.map((method) => ({
      id: method.code,
      value: method.name,
    })) || [];

  shippingMethodsData.push({
    id: 'ALL',
    value: 'ALL',
  });

  const getTitle = () => 'Shipping methods';

  const fetchShippingMethods = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getProductManagement(
        API.products.shippingMethods.list,
      );
      const normalizedShippingMethods = normalizeShippingMethodsResponse(
        response.data.data,
      );
      setShippingMethods(normalizedShippingMethods);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }, []);

  useEffect(() => {
    fetchShippingMethods();
  }, [fetchShippingMethods]);

  useEffect(() => {
    dispatch({
      type: FETCH_REFERENCES,
      payload: {
        types: ['shipping_method'],
      },
    });
    dispatch({
      type: FETCH_SKU_DETAILS,
      payload: {
        callback: () => {},
        errorCallback: (error) => {},
      },
    });
  }, [dispatch]);

  const handleNewRow = () => {
    setShowCreationModal(true);
  };

  const filterShippingMethodsByIdOrName = (value, type) => {
    const newFilters = {
      ...filters,
      [type]: value,
    };

    const countryValue = newFilters.country.toLowerCase();
    const typeValue =
      newFilters.type === 'ALL' ? '' : newFilters.type.toLowerCase();

    const filteredShippingMethods = shippingMethods.filter(
      (shippingMethod) =>
        shippingMethod.shippingMethodCode.toLowerCase().includes(typeValue) &&
        shippingMethod.countryCode.toLowerCase().includes(countryValue),
    );

    setFilteredRows(filteredShippingMethods);
    setFilters(newFilters);
  };

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <PageContentLayout
        title={getTitle()}
        isLoading={loading}
        buttons={[
          {
            label: 'Add a new shipping method',
            onClick: handleNewRow,
          },
        ]}
      >
        <Grid container>
          <Grid item xs={9}>
            <SearchInput
              onSubmit={(val) =>
                filterShippingMethodsByIdOrName(val, 'country')
              }
              placeholder="Search by shipping method ID or name"
            />
          </Grid>
          <Grid item xs={3}>
            <SelectComponent
              name="type"
              variant="outlined"
              onChange={(e) =>
                filterShippingMethodsByIdOrName(e.target.value, 'type')
              }
              label="Filter by type"
              dataItems={shippingMethodsData}
              value={filters.type}
            />
          </Grid>
          <Grid item xs={12}>
            <ShippingMethodsTable
              canEdit={canEdit}
              rows={filteredRows || shippingMethods || []}
              isLoading={loading}
              disablePagination={true}
              rowCount={shippingMethods && shippingMethods.length}
              reloadData={fetchShippingMethods}
            />
          </Grid>
        </Grid>
      </PageContentLayout>
      <ModalComponent
        title="Add a shipping method"
        open={!!showCreationModal}
        handleClose={() => setShowCreationModal(false)}
      >
        <ShippingMethodCreationForm
          reloadData={fetchShippingMethods}
          closeModal={() => setShowCreationModal(false)}
        />
      </ModalComponent>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(ShippingMethodsListing);
