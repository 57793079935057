import httpClient from '../axiosClient';
import { API, getAdminV2Url } from '../axiosClient/config';

export const getAccounts = async (data) => {
  try {
    const resp = await httpClient.post(getAdminV2Url(API.accounts), data);
    return resp;
  } catch (e) {
    throw e;
  }
};

export const putAccounts = async (id, data) => {
  try {
    const resp = await httpClient.put(getAdminV2Url(API.accounts), {
      ...data,
      user_id: parseInt(id),
    });
    return resp;
  } catch (e) {
    throw e;
  }
};

export const deleteAccount = async (id) => {
  try {
    const resp = await httpClient.delete(
      getAdminV2Url(`${API.accounts}/${id}`),
    );
    return resp;
  } catch (e) {
    throw e;
  }
};

export const getProfiles = async (data) => {
  try {
    const resp = await httpClient.post(getAdminV2Url(API.profiles.get), data);
    return resp;
  } catch (e) {
    throw e;
  }
};

export const putProfiles = async (data) => {
  try {
    const resp = await httpClient.put(getAdminV2Url(API.profiles.get), {
      ...data,
    });
    return resp;
  } catch (e) {
    throw e;
  }
};

export const deleteProfile = async (data) => {
  try {
    const resp = await httpClient.put(getAdminV2Url(API.profiles.delete), data);
    return resp;
  } catch (e) {
    throw e;
  }
};
