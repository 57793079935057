import { useCallback, useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';

import { normalizeWarehousesResponse } from 'utils';
import { API } from 'axiosClient/config';
import { getProductManagement } from 'api/products';
import ExpandableSection from 'components/common/ExpandableSection';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';
import InventoryTable from './InventoryTable';

const InventorySection = ({ variant, inputBase }) => {
  const [warehouses, setWarehouses] = useState([]);

  const warehouseDataItems =
    warehouses?.map((warehouse) => ({
      id: warehouse.code,
      value: warehouse.name,
    })) || [];

  const fetchWarehouses = useCallback(async () => {
    try {
      const response = await getProductManagement(API.products.warehouses);
      const normalizedWarehouses = normalizeWarehousesResponse(
        response.data.data,
      );
      setWarehouses(normalizedWarehouses);
    } catch (e) {
      throw e;
    }
  }, []);

  useEffect(() => {
    fetchWarehouses();
  }, [fetchWarehouses]);

  const renderContent = () => (
    <Grid container>
      <Grid item xs={7}>
        <OrderPropertiesWrapper>
          <OrderPropertyInput
            label="Limit per order"
            input={{
              ...inputBase,
              name: 'order_limit',
              required: true,
            }}
          />
          <OrderPropertyInput
            label="Warehouses"
            type="autocomplete-select"
            input={{
              ...inputBase,
              name: 'warehouse_codes',
              required: true,
              options: warehouseDataItems,
              multiple: true,
              limitTags: 3,
            }}
          />
        </OrderPropertiesWrapper>
      </Grid>
      <Grid item xs={12}>
        <InventoryTable inputBase={inputBase} />
      </Grid>
      <Grid item xs={7}>
        <Typography variant="h3" gutterBottom className="mt-10">
          Buy from another reseller
        </Typography>
        <OrderPropertiesWrapper>
          <OrderPropertyInput
            label="Can be bought from (name of the store - used in the CTA)"
            input={{
              ...inputBase,
              name: 'external_provider_code',
            }}
          />
          <OrderPropertyInput
            label="Link to the store"
            input={{
              ...inputBase,
              name: 'external_provider_url',
            }}
          />
        </OrderPropertiesWrapper>
      </Grid>
    </Grid>
  );

  return (
    <ExpandableSection
      id={`inventory-${variant?.id || 'create'}`}
      heading="Inventory"
      secondaryHeading="Countries, warehouses, limit"
      content={renderContent()}
      openByDefault={false}
    />
  );
};

export default InventorySection;
