import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Grid, Skeleton } from '@mui/material';

import { SEVERITY } from 'config';
import { USERS_ACCOUNTS_WRITE } from 'config/roles';
import paths from 'routes/paths';
import { getAccounts, putAccounts } from 'api/adminV2';
import userSelectors from 'store/user/selectors';
import SetPageTitle from 'components/common/SetPageTitle';
import PageFormLayout from 'components/layout/PageFormLayout';
import QuickLinksSection from './components/QuickLinksSection';
import ActionsSection from './components/ActionsSection';
import AccountSection from './components/AccountSection';
import UserStatusSection from './components/UserStatusSection';
import ConsentsSection from './components/ConsentsSection';
import ProfilesSection from './components/ProfilesSection';
import AppsSection from './components/AppsSection';
import DebugSection from './components/DebugSection';
import DatesSection from './components/DatesSection';
import LocationSection from './components/LocationSection';

import './styles.scss';

const AccountView = ({ match, userRoles }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [accountData, setAccountData] = useState(null);
  const id = match.params.id;
  const canEdit = userRoles?.includes(USERS_ACCOUNTS_WRITE);

  const updateAccount = async (values) => {
    const payload = Object.entries(values).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: [value],
      };
    }, {});
    try {
      await putAccounts(id, { ...payload });
      enqueueSnackbar('Account updated', {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      enqueueSnackbar(e.response.data, {
        variant: SEVERITY.ERROR,
      });
      setIsLoading(false);
    }
  };

  const fetchAccountData = useCallback(
    async (id) => {
      setIsLoading(true);
      try {
        const payload = {
          user_id: parseInt(id),
        };
        const response = await getAccounts(payload);

        if (!response || response.status > 208) {
          setIsLoading(false);
          return;
        }

        setAccountData(response.data.users[0]);
        setIsLoading(false);
      } catch (e) {
        enqueueSnackbar(e.response.data, {
          variant: SEVERITY.ERROR,
        });
        setIsLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    // Get the orders based on the parameters
    if (id) {
      fetchAccountData(id);
    }
  }, [id, fetchAccountData]);

  const getTitle = () =>
    accountData?.user_id ? `Account #${accountData?.user_id}` : 'Loading...';

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <PageFormLayout
        title={getTitle()}
        chips={
          accountData?.user_id
            ? [
                {
                  label: !accountData.deleted ? 'Active' : 'Deleted',
                  color: !accountData.deleted ? 'success' : 'error',
                },
              ]
            : []
        }
        isLoading={isLoading}
        breadcrumbs={[
          {
            label: 'Accounts',
            link: paths.users.accounts.list,
          },
          {
            label: getTitle(),
            isCurrent: true,
          },
        ]}
        showSubmitButtons={false}
      >
        {isLoading ? (
          <Grid container>
            <Grid item xs={12}>
              <Skeleton height={800} variant="rect" />
            </Grid>
          </Grid>
        ) : !accountData ? null : (
          <Grid container spacing={3} alignItems="flex-start">
            <Grid container item xs={8}>
              <Grid item xs={12}>
                <AccountSection
                  account={accountData}
                  updateAccount={updateAccount}
                  canEdit={canEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <UserStatusSection
                  account={accountData}
                  updateAccount={updateAccount}
                  canEdit={canEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <ConsentsSection
                  account={accountData}
                  updateAccount={updateAccount}
                  canEdit={canEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <ProfilesSection account={accountData} canEdit={canEdit} />
              </Grid>
              <Grid item xs={12}>
                <AppsSection
                  account={accountData}
                  updateAccount={updateAccount}
                  canEdit={canEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <DebugSection
                  account={accountData}
                  updateAccount={updateAccount}
                  canEdit={canEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <DatesSection account={accountData} />
              </Grid>
              <Grid item xs={12}>
                <LocationSection
                  account={accountData}
                  updateAccount={updateAccount}
                  canEdit={canEdit}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4}>
              <Grid item xs={12}>
                <QuickLinksSection account={accountData} />
              </Grid>
              <Grid item xs={12}>
                <ActionsSection
                  account={accountData}
                  setAccountData={setAccountData}
                  canEdit={canEdit}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </PageFormLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(AccountView);
