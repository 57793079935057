import { useState } from 'react';
import { ProductRender } from '@tangibleplay/ecommerce-ui';

const ProductRenderWrapper = (props) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <ProductRender {...props} isHovering={isHovering} />
    </div>
  );
};

export default ProductRenderWrapper;
