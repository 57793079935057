import { utcToZonedTime } from 'date-fns-tz';

function getLocalDateFromUTC(
  date,
  tz = Intl.DateTimeFormat().resolvedOptions().timeZone,
) {
  return utcToZonedTime(date, tz);
}

export default getLocalDateFromUTC;
