import { Typography, Link, Divider } from '@mui/material';

import paths from 'routes/paths';

function OrderRMAs({ RMAs }) {
  if (!RMAs) return null;

  return (
    <>
      <Divider className="order-section-divider mb-20 mt-20" />
      <Typography variant="h3" gutterBottom>
        RMAs
      </Typography>
      {RMAs.map((rma) => (
        <div key={rma.id}>
          RMA #{rma.id}:{' '}
          <Link
            href={`${paths.orders.returns.view}/${rma.id}`}
            underline="hover"
          >
            {rma.status}
          </Link>
        </div>
      ))}
    </>
  );
}

export default OrderRMAs;
