import React from 'react';
import { Form, Field } from 'react-final-form';

import TextInputStandalone from 'components/common/TextInputStandalone';
import SelectInputStandalone from 'components/common/SelectInputStandalone';

import './styles.scss';

const EditableProperty = ({
  id,
  label,
  value,
  disabled,
  onSubmit,
  options,
}) => {
  const formattedValue =
    typeof value === 'boolean' ? value.toString() : value || '-';

  const onResetClick = (form) => {
    form.reset();
  };

  return (
    <>
      <div className="editable-property-label">{label}: </div>
      <div className="editable-property-value">
        <Form
          initialValues={{
            [id]: formattedValue,
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Field
                name={id}
                placeholder={formattedValue}
                component={
                  !!options?.length
                    ? SelectInputStandalone
                    : TextInputStandalone
                }
                options={options}
                className="editable-property-input"
                buttonDisabled={formattedValue === values[id]}
                onResetClick={() => onResetClick(form)}
                disabled={disabled}
              />
            </form>
          )}
        />
      </div>
    </>
  );
};

export default EditableProperty;
