function modifyBannerData(sectionObj) {
  Object.keys(sectionObj).forEach((item) => {
    sectionObj[item].forEach((banner) => {
      if (banner.type === 'Default') {
        banner.default = true;
      }
      delete banner.id;
      delete banner.type;
    });
  });
  return sectionObj;
}

const bannerTransform = (value, header, feature) => {
  const banners = {};
  banners.header = {
    images: modifyBannerData(JSON.parse(JSON.stringify(header))),
    show_on_pages: value.show_on_pages ? value.show_on_pages.split(',') : [],
    hide_on_pages: value.hide_on_pages ? value.hide_on_pages.split(',') : [],
    hide_on_shopping_tabs: value.hide_on_shopping_tabs
      ? value.hide_on_shopping_tabs.split(',')
      : [],
    link_to: value.link_to || '',
  };
  banners.featured = modifyBannerData(JSON.parse(JSON.stringify(feature)));
  return banners;
};

export default bannerTransform;
