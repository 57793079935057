export const tableConfig = [
  {
    title: 'ID',
    id: 'id',
  },
  {
    title: 'Subscription Name',
    id: 'name',
  },
  {
    title: 'Email Address',
    id: 'customer.email',
  },
  {
    title: 'Created On',
    id: 'created_on',
  },
  {
    title: 'Sub-orders',
    id: 'orders',
  },
  {
    title: 'Status',
    id: 'status',
  },
  {
    title: 'Actions',
    id: 'actions',
    sorting: false,
    actions: [
      {
        text: 'View',
        type: 'View',
      },
    ],
  },
];

export const TableCellStates = {
  STATUS: 'status',
  SUBSCRIPTION_ID: 'id',
  ACTIONS: 'actions',
  SUBSCRIPTION_NAME: 'name',
  ORDERS: 'orders',
  CREATED_ON: 'created_on',
  EMAIL: 'customer.email',
};
