import { takeLatest, put, call } from 'redux-saga/effects';

import getTextBannerData from 'utils/content/getTextBannerData';

import { SET_LOADER } from 'store/general/actions';

import {
  UPDATE_TEXT_BANNER,
  SAVE_TEXT_BANNER,
  GET_TEXT_BANNERS,
  BANNERS_SUCCESS,
  DELETE_TEXT_BANNER_SAGA,
  DELETE_TEXT_BANNER,
} from './actions';

import {
  getBanners,
  postBanner,
  postBannerWithEnv,
  putBanner,
  deleteBanner,
} from 'api/banners';

// TEXT BANNERS

function* getTextBanners(action) {
  const { callback, errorCallback, limit, cursor } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const bannersData = yield call(getBanners, limit, cursor);
    if (!bannersData || bannersData.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    const { data, pagination } = bannersData.data;
    const formattedBanners = getTextBannerData(data);

    const bannersObj = formattedBanners.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val,
      };
    }, {});

    yield put({
      type: BANNERS_SUCCESS,
      payload: {
        banners: bannersObj,
        pagination,
      },
    });

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    if (errorCallback) {
      yield put({
        type: SET_LOADER,
        payload: {
          isLoading: false,
        },
      });
      errorCallback(e?.response?.data?.error?.message);
    }
  }
}

function* saveTextBannerDetails(action) {
  const { callback, errorCallback, data, envSelected } = action.payload;

  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    yield call(envSelected ? postBannerWithEnv : postBanner, data, envSelected);
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    console.log({ e });
    if (errorCallback) {
      yield put({
        type: SET_LOADER,
        payload: {
          isLoading: false,
        },
      });
      errorCallback(e?.response?.data?.error?.message);
    }
  }
}

function* updateTextBannerDetails(action) {
  const { callback, errorCallback, data, id } = action.payload;

  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    yield call(putBanner, id, data);
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    if (errorCallback) {
      yield put({
        type: SET_LOADER,
        payload: {
          isLoading: false,
        },
      });
      errorCallback(e?.response?.data?.error?.message);
    }
  }
}

function* deleteTextBanner(action) {
  const { callback, errorCallback, id } = action.payload;

  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });

  try {
    yield call(deleteBanner, id);
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    yield put({
      type: DELETE_TEXT_BANNER,
      payload: {
        id,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    if (errorCallback) {
      yield put({
        type: SET_LOADER,
        payload: {
          isLoading: false,
        },
      });
      errorCallback(e?.response?.data?.error?.message);
    }
  }
}

export function* contentSaga() {
  yield takeLatest(UPDATE_TEXT_BANNER, updateTextBannerDetails);
  yield takeLatest(SAVE_TEXT_BANNER, saveTextBannerDetails);
  yield takeLatest(GET_TEXT_BANNERS, getTextBanners);
  yield takeLatest(DELETE_TEXT_BANNER_SAGA, deleteTextBanner);
}
