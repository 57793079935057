import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import { postProductManagement } from 'api/products';
import { API } from 'axiosClient/config';
import generalSelectors from 'store/general/selectors';
import CheckboxComponent from 'components/common/CheckboxComponent';
import SelectDropdown from 'components/common/SelectDropdown';
import AutoCompleteWrapper from 'components/common/AutoCompleteWrapper';
import { FETCH_REFERENCES } from 'store/general/actions';

const initialValues = {
  src_sku_key: '',
  dst_sku_key: '',
  warehouse_code: '',
  condition_type: '',
  is_active: false,
};

const SkuSubstituteCreationForm = ({
  reloadData,
  closeModal,
  warehouseDataItems,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const purchasableSkus = useSelector(generalSelectors.getPurchasableProducts);
  const substituteConditions = useSelector(
    generalSelectors.getSubstituteConditionReferences,
  );
  const substituteConditionsData = substituteConditions?.map((condition) => ({
    id: condition.code,
    value: condition.code,
  }));

  useEffect(() => {
    dispatch({
      type: FETCH_REFERENCES,
      payload: {
        types: ['substitute_condition'],
      },
    });
  }, [dispatch]);

  const handleSave = async (values) => {
    const payload = {
      is_active: values.is_active,
      src_sku_key: values.src_sku_key,
      dst_sku_key: values.dst_sku_key,
      warehouse_code: values.warehouse_code,
      condition_type: values.condition_type,
    };

    try {
      await postProductManagement(API.products.substitutes.create, payload);
      enqueueSnackbar('Shipping method successfully added', {
        variant: SEVERITY.SUCCESS,
      });
      reloadData();
      closeModal();
    } catch (e) {
      const errorMessage =
        e.response.data && typeof e.response.data === 'string'
          ? e.response.data
          : e.response.data?.error?.message;
      if (errorMessage) {
        enqueueSnackbar(errorMessage, {
          variant: SEVERITY.ERROR,
        });
      }
      throw e;
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSave}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container>
            <Grid item xs={6}>
              <Field
                component={AutoCompleteWrapper}
                name="src_sku_key"
                label="Select source SKU"
                options={purchasableSkus}
                getOptionLabel={(option) => {
                  return option.id;
                }}
                renderDropdownOption={(option) =>
                  option.name + ` (${option['id']})`
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={AutoCompleteWrapper}
                name="dst_sku_key"
                label="Select destination SKU"
                options={purchasableSkus}
                getOptionLabel={(option) => {
                  return option.id;
                }}
                renderDropdownOption={(option) =>
                  option.name + ` (${option['id']})`
                }
              />
            </Grid>
            <Grid item xs={6}>
              {substituteConditionsData && (
                <Field
                  variant="standard"
                  component={SelectDropdown}
                  fullWidth
                  dataItems={substituteConditionsData}
                  name="condition_type"
                  label="Condition"
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Field
                variant="standard"
                component={SelectDropdown}
                fullWidth
                dataItems={warehouseDataItems}
                name="warehouse_code"
                label="Warehouse"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                label="Is active"
                type="checkbox"
                name="is_active"
                component={CheckboxComponent}
                color="primary"
              />
            </Grid>
          </Grid>
          <div className="mt-20">
            <Button
              className="select-input-standalone-button"
              variant="contained"
              type="submit"
              color="primary"
            >
              Add
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default SkuSubstituteCreationForm;
