export const tableConfig = [
  {
    title: 'Status',
    id: 'status',
  },
  {
    title: 'Code',
    id: 'code',
  },
  {
    title: 'Promo code ID',
    id: 'promo_code_id',
  },
  {
    title: 'Coupon ID',
    id: 'coupon_id',
  },
  {
    title: 'Creation Date',
    id: 'created_on',
  },
  {
    title: 'Website',
    id: 'website',
  },
  {
    title: 'Actions',
    id: 'actions',
    sorting: false,
    actions: [
      {
        text: 'Edit',
        permissions: ['EDIT'],
        type: 'Edit',
      },
      {
        text: 'Review',
        type: 'Review',
      },
    ],
  },
];

export const TableCellStates = {
  ACTIONS: 'actions',
  PROMO_CODE_ID: 'promo_code_id',
  COUPON_ID: 'coupon_id',
  CREATION_DATE: 'created_on',
  CODE: 'code',
  STATUS: 'status',
  WEBSITE: 'website',
};
