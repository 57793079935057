import { useState, useEffect } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useSnackbar } from 'notistack';
import {
  Button,
  Grid,
  Paper,
  Typography,
  Skeleton,
  IconButton,
} from '@mui/material';

import { SEVERITY } from 'config';
import { deleteProfile, getProfiles } from 'api/adminV2';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';
import NoData from 'components/common/NoData';
import paths from 'routes/paths';
import { useCallback } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import './index.scss';

const ProfilesForm = ({
  title,
  profiles,
  onSubmit: onSubmitProp,
  canEdit,
  action,
}) => {
  const initialValues = profiles.reduce(
    (acc, profile) => ({
      ...acc,
      [profile.name]: false,
    }),
    {},
  );

  const inputBase = {
    disabled: !canEdit,
  };

  const onResetClick = (form) => {
    form.reset();
  };

  const onSubmit = (values, form) => {
    onSubmitProp(values, action);
    form.reset(values);
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit} noValidate className="mt-20">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="h3" gutterBottom>
                {title}
              </Typography>
              <OrderPropertiesWrapper>
                {profiles.map((profile) => (
                  <div className="profile-section-cta-flex">
                    <OrderPropertyInput
                      key={profile.name}
                      label={profile.name}
                      type="checkbox"
                      input={{
                        ...inputBase,
                        name: profile.id,
                        label: '',
                      }}
                    />
                    <IconButton
                      target="_blank"
                      href={`${paths.users.profiles.edit}/${profile.id}`}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                ))}
              </OrderPropertiesWrapper>
            </Grid>
            {canEdit && (
              <FormSpy subscription={{ pristine: true }}>
                {(props) => (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h3" gutterBottom>
                      Actions
                    </Typography>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={props.pristine}
                    >
                      {action} selected profiles
                    </Button>
                    <Button
                      onClick={() => onResetClick(form)}
                      variant="text"
                      className="ml-10"
                      disabled={props.pristine}
                    >
                      Reset
                    </Button>
                  </Grid>
                )}
              </FormSpy>
            )}
          </Grid>
        </form>
      )}
    />
  );
};

const ProfilesSection = ({ account, canEdit }) => {
  const [profiles, setProfiles] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const fetchProfiles = useCallback(async () => {
    try {
      const payload = {
        user_id: account.user_id,
      };
      const response = await getProfiles(payload);

      if (!response?.data?.profiles || response.status > 208) {
        setIsLoading(false);
        return;
      }

      setProfiles(response.data.profiles);
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar(e?.response?.data, {
        variant: SEVERITY.ERROR,
      });
      setIsLoading(false);
    }
  }, [account.user_id, enqueueSnackbar]);

  useEffect(() => {
    if (account?.user_id) {
      fetchProfiles();
    }
  }, [account.user_id, fetchProfiles]);

  const activeProfiles = profiles?.filter((profile) => profile.active);
  const deletedProfiles = profiles?.filter((profile) => !profile.active);

  const onSubmit = async (values, action) => {
    const idsToDelete = Object.entries(values).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }
      return [...acc, key];
    }, []);

    if (!idsToDelete?.length) {
      return;
    }

    try {
      const payload = {
        action,
        profiles: idsToDelete,
      };
      await deleteProfile(payload);
      const newProfilesData = profiles.map((profile) => {
        return {
          ...profile,
          active: idsToDelete.includes(profile.id)
            ? !profile.active
            : profile.active,
        };
      });
      setProfiles(newProfilesData);
    } catch (error) {
      throw error;
    }
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Profiles ({profiles?.length})
      </Typography>
      {!profiles?.length && <NoData />}
      {!!activeProfiles?.length && (
        <ProfilesForm
          profiles={activeProfiles}
          onSubmit={onSubmit}
          title="Active profiles"
          canEdit={canEdit}
          action="delete"
        />
      )}
      {!!deletedProfiles?.length && (
        <ProfilesForm
          profiles={deletedProfiles}
          onSubmit={onSubmit}
          title="Deleted profiles"
          canEdit={canEdit}
          action="undelete"
        />
      )}
    </Paper>
  );
};

export default ProfilesSection;
