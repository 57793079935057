import React from 'react';
import { Link, Paper, Typography } from '@mui/material';

import paths from 'routes/paths';

function QuickLinksSection({ account }) {
  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Quick links
      </Typography>
      <ul>
        <li>
          <Link
            href={`https://my.playosmo.com/api/v4/users/${account.user_id}/storage/`}
            target="_blank"
          >
            Account Storage
          </Link>
        </li>
        {account.user_id && (
          <li>
            <Link
              href={`${paths.users.credits}?accountId=${account.account_id}`}
              target="_blank"
            >
              Manage Credits
            </Link>
          </li>
        )}
        {account.user_id && (
          <li>
            <Link
              href={`${paths.orders.list}?email=${account.email}`}
              target="_blank"
            >
              See orders associated
            </Link>
          </li>
        )}
      </ul>
    </Paper>
  );
}

export default QuickLinksSection;
