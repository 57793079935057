import { takeLatest, put, call, select } from 'redux-saga/effects';

import { API } from 'axiosClient/config';
import { handleException } from 'store/general/sagas';

import {
  getAPIData,
  postAPIData,
  putAPIData,
  postAPIDataWithEnv,
} from 'api/playosmo';
import {
  fileUpload,
  deleteFile,
  bulkDeleteFiles,
  hardDeleteFiles,
  fileUploadClone,
} from 'api/fileUpload';

import { SET_LOADER } from 'store/general/actions';
import {
  CAMPAIGN_DETAILS_SUCCESS,
  FETCH_CAMPAIGN_DETAILS,
  SAVE_CAMPAIGN_DETAILS,
  RESET_CAMPAIGN_DETAILS,
  LOAD_CAMPAIGN_ASSETS,
  SAVE_CAMPAIGN_ASSETS,
  DELETE_CAMPAIGN_ASSETS,
  EDIT_CAMPAIGN_DETAILS,
  UPLOAD_FILES,
  DELETE_FILES,
  BULK_DELETE_FILES,
  BULK_DELETE_CAMPAIGN_ASSETS,
  CLEANUP_ASSETS,
  CLEANUP_ASSET,
  CLEAN_ADDED_ASSETS,
  CLEAN_DELETED_ASSETS,
  PUBLISH_BULK_FILE,
} from './actions';

import { assetsSelector } from './selectors';
import generalSelector from 'store/general/selectors';

function* getCampaignDetails(action) {
  const { callback, errorCallback, id } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const response = yield call(getAPIData, `${API.coupons}/${id}`);
    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    const campaignDetails = response.data;
    const skuDetails = yield select(generalSelector.getSkuDetails);
    yield put({
      type: CAMPAIGN_DETAILS_SUCCESS,
      payload: { campaignDetails, skuDetails },
    });
    yield put({
      type: LOAD_CAMPAIGN_ASSETS,
      payload: { ...campaignDetails.assets },
    });
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* saveCampaignDetails(action) {
  const {
    callback,
    errorCallback,
    campaignData,
    envSelected,
    resetCampaignDetails = true,
  } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const assets = yield select(assetsSelector.getAssets);
    if (assets) {
      campaignData.assets = assets;
    }
    const response = yield call(
      envSelected ? postAPIDataWithEnv : postAPIData,
      `${API.coupons}`,
      campaignData,
      envSelected,
    );
    if (resetCampaignDetails) {
      yield put({
        type: RESET_CAMPAIGN_DETAILS,
      });
    }
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback(response.data);
    }
  } catch (e) {
    if (errorCallback) {
      yield put({
        type: SET_LOADER,
        payload: {
          isLoading: false,
        },
      });
      errorCallback(e);
    }
  }
}

function* updateCampaignDetails(action) {
  const { callback, errorCallback, campaignData } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const assets = yield select(assetsSelector.getAssets);
    if (assets) {
      campaignData.assets = assets;
    }
    yield call(putAPIData, `${API.coupons}/${campaignData.id}`, campaignData);
    yield put({
      type: RESET_CAMPAIGN_DETAILS,
    });
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    if (errorCallback) {
      yield put({
        type: SET_LOADER,
        payload: {
          isLoading: false,
        },
      });
      errorCallback();
    }
  }
}

function* fileUploads(action) {
  const { callback, errorCallback, file } = action.payload;

  try {
    const fileResp = yield call(fileUpload, 'campaigns', file);

    if (fileResp.data.data) {
      const { id, public_url } = fileResp.data.data;
      yield put({
        type: SAVE_CAMPAIGN_ASSETS,
        payload: { [`${id}`]: public_url },
      });
      callback(id);
    } else {
      errorCallback();
    }
  } catch (e) {
    errorCallback();
  }
}

function* deleteUploadedFile(action) {
  const { callback, errorCallback, fileId } = action.payload;

  try {
    yield call(deleteFile, 'campaigns', fileId);
    yield put({
      type: DELETE_CAMPAIGN_ASSETS,
      payload: { fileId },
    });
    callback();
  } catch (e) {
    errorCallback();
  }
}

function* bulkDeleteUploadedFiles(action) {
  const { callback, errorCallback, fileIds } = action.payload;

  try {
    yield call(bulkDeleteFiles, 'campaigns', fileIds);
    yield put({
      type: BULK_DELETE_CAMPAIGN_ASSETS,
      payload: { fileIds },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    if (errorCallback) {
      errorCallback();
    }
  }
}

function* assetCleanup(action) {
  const { fileId } = action.payload;
  const addedAssets = yield select(assetsSelector.getAddedAssets);
  const deletedAssets = yield select(assetsSelector.getDeletedAssets);
  if (addedAssets[fileId]) {
    yield call(hardDeleteFiles, 'campaigns', addedAssets[fileId], true);
    yield put({
      type: CLEAN_ADDED_ASSETS,
      payload: { fileId: addedAssets[fileId] },
    });
  } else {
    yield call(hardDeleteFiles, 'campaigns', deletedAssets[fileId], false);
    yield put({
      type: CLEAN_DELETED_ASSETS,
      payload: { fileId: deletedAssets[fileId] },
    });
  }
}

function* assetsCleanup() {
  const addedAssets = yield select(assetsSelector.getAddedAssets);
  const deletedAssets = yield select(assetsSelector.getDeletedAssets);

  for (let asset in addedAssets) {
    yield call(hardDeleteFiles, 'campaigns', addedAssets[asset], true);
    yield put({
      type: CLEAN_ADDED_ASSETS,
      payload: { fileId: addedAssets[asset] },
    });
  }
  for (let asset in deletedAssets) {
    yield call(hardDeleteFiles, 'campaigns', deletedAssets[asset], false);
    yield put({
      type: CLEAN_DELETED_ASSETS,
      payload: { fileId: deletedAssets[asset] },
    });
  }
}
function* publishBulkFile(action) {
  const { callback, errorCallback, assets, envSelected } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const newAssets = yield call(
      fileUploadClone,
      'campaigns',
      assets,
      envSelected,
    );
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback(newAssets.data);
    }
  } catch (e) {
    if (errorCallback) {
      yield put({
        type: SET_LOADER,
        payload: {
          isLoading: false,
        },
      });
      errorCallback();
    }
  }
}

export function* createCampaignSaga() {
  yield takeLatest(FETCH_CAMPAIGN_DETAILS, getCampaignDetails);
  yield takeLatest(SAVE_CAMPAIGN_DETAILS, saveCampaignDetails);
  yield takeLatest(EDIT_CAMPAIGN_DETAILS, updateCampaignDetails);
  yield takeLatest(UPLOAD_FILES, fileUploads);
  yield takeLatest(DELETE_FILES, deleteUploadedFile);
  yield takeLatest(BULK_DELETE_FILES, bulkDeleteUploadedFiles);
  yield takeLatest(CLEANUP_ASSETS, assetsCleanup);
  yield takeLatest(CLEANUP_ASSET, assetCleanup);
  yield takeLatest(PUBLISH_BULK_FILE, publishBulkFile);
}
