import React, { useCallback, useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { API } from 'axiosClient/config';
import { getAccount } from 'api/auth';
import { SEVERITY } from 'config';
import TableComponent from 'components/common/TableComponent';
import { normalizeCreditTransactions } from 'utils/normalizers/api/normalizeCreditBalances';

const keys = {
  ID: 'id',
  TYPE: 'type',
  AMOUNT: 'amount',
  COMMENT: 'comment',
  META: 'meta',
  STATUS: 'status',
  UPDATED_ON: 'updatedOn',
  CREATED_ON: 'createdOn',
  CANCELLED_ON: 'cancelledOn',
  CONFIRMED_ON: 'confirmedOn',
  REFUNDED_ON: 'refundedOn',
};

const keysArray = Object.values(keys);

const TransactionsTable = ({ balance }) => {
  const [transactions, setTransactions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const options = {
    pagination: false,
  };
  const { enqueueSnackbar } = useSnackbar();

  const fetchTransactions = useCallback(
    async (id) => {
      try {
        const response = await getAccount(
          API.microservices.account.credits.transactions(id),
        );

        if (!response || response.status > 208) {
          setIsLoading(false);
          return;
        }

        return normalizeCreditTransactions(response.data.data);
      } catch (e) {
        console.error('An error occurred when fetching the transactions', e);

        enqueueSnackbar(
          `An error occurred when fetching the transactions: ${e.response.data.error.message}`,
          {
            variant: SEVERITY.ERROR,
          },
        );
        setIsLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  const fetchData = useCallback(
    async (id) => {
      const data = await fetchTransactions(id);
      setTransactions(data);
      setIsLoading(false);
    },
    [fetchTransactions],
  );

  useEffect(() => {
    if (balance.id) {
      fetchData(balance.id);
    }
  }, [balance, fetchData]);

  const createData = (item) =>
    keysArray.reduce((acc, key) => {
      const getFormat = () => {
        if (key === keys.META && item[key]) {
          return (
            <div>
              {item[key].created_by && (
                <div>
                  <b>Created by:</b> {item[key].created_by}
                </div>
              )}
              {item[key].jira_ticket && (
                <div>
                  <b>Jira Ticket:</b> {item[key].jira_ticket}
                </div>
              )}
            </div>
          );
        }

        return item[key]?.toString();
      };
      return {
        ...acc,
        [key]: getFormat(),
      };
    }, {});

  const rows = transactions?.map((item) => createData(item)) || [];
  const headCells = keysArray.map((key) => ({
    title: key,
    id: key,
    render: (record) => record[key],
  }));

  if (isLoading) {
    return <Skeleton />;
  }

  return <TableComponent rows={rows} headCells={headCells} options={options} />;
};

export default TransactionsTable;
