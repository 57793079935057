const normalizeBalanceDetails = (details, total) => {
  if (!details) {
    return null;
  }

  return {
    current: details.current,
    giftCard: details.gift_card,
    promo: details.promo,
    total,
  };
};

export const normalizeCreditTransactions = (data) => {
  return data.map((balance) => ({
    id: balance.id,
    balanceId: balance.balance_id,
    amount: balance.amount,
    type: balance.type,
    status: balance.status,
    comment: balance.comment,
    orderId: balance.order_id,
    createdOn: balance.created_on,
    updatedOn: balance.updated_on,
    confirmedOn: balance.confirmed_on,
    cancelledOn: balance.cancelled_on,
    refundedOn: balance.refunded_on,
    meta: balance.meta,
  }));
};

const normalizeCreditBalances = (data) => {
  return data.map((balance) => ({
    id: balance.id,
    accountId: balance.account_id,
    currency: balance.currency,
    createdOn: balance.created_on,
    updatedOn: balance.updated_on,
    expireOn: balance.expire_on,
    current: normalizeBalanceDetails(balance.current_details, balance.current),
    pending: normalizeBalanceDetails(balance.pending_details, balance.pending),
    available: balance.available,
  }));
};

export default normalizeCreditBalances;
