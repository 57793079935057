import { useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { Button, Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { putProfiles } from 'api/adminV2';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';
import { SEVERITY } from 'config';

const EditSection = ({ profile, canEdit, updateProfile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const userId = profile?.user_ids[0];

  const inputBase = {
    disabled: !canEdit,
  };

  const initialValues = {
    user_id: userId,
    data: profile?.data,
    state_data: profile?.state_data,
  };

  const handleSubmit = async (values) => {
    try {
      const payload = {
        active_local_id: profile.id,
        update_user_id: userId !== values.user_id ? [values.user_id] : null,
        data: [values.data],
        state_data: [values.state_data],
      };
      const response = await putProfiles(payload);

      if (!response?.data || response.status > 208) {
        setIsLoading(false);
        return;
      }

      updateProfile(response.data);
      setIsLoading(false);
      enqueueSnackbar('Profile updated successfully', {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      console.log({ e });
      enqueueSnackbar(e?.response?.data, {
        variant: SEVERITY.ERROR,
      });
      setIsLoading(false);
    }
  };

  const onResetClick = (form) => {
    form.reset();
  };

  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Edit
      </Typography>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({ form, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <OrderPropertiesWrapper>
              <OrderPropertyInput
                input={{
                  ...inputBase,
                  name: 'user_id',
                }}
                label="myOsmo user ID"
              />
              <OrderPropertyInput
                input={{
                  ...inputBase,
                  name: 'data',
                }}
                label="Profile data"
                type="textarea"
              />
              <OrderPropertyInput
                input={{
                  ...inputBase,
                  name: 'state_data',
                }}
                label="State data"
                type="textarea"
              />
            </OrderPropertiesWrapper>
            {canEdit && (
              <FormSpy subscription={{ pristine: true }}>
                {(props) => (
                  <div className="mt-20">
                    <Button
                      onClick={() => onResetClick(form)}
                      variant="text"
                      disabled={props.pristine}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleSubmit}
                      className="ml-10"
                      disabled={isLoading || props.pristine}
                    >
                      Update
                    </Button>
                  </div>
                )}
              </FormSpy>
            )}
          </form>
        )}
      />
    </Paper>
  );
};

export default EditSection;
