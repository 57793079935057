import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import Chip from '@mui/material/Chip';

import './style.scss';

const FileInput = ({
  onChange,
  label,
  value,
  remove,
  disabled,
  className,
  type = 'zone',
  dropZoneProps = {},
}) => {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const files = value ? [value] : [];

  const { getRootProps, getInputProps } = useDropzone({
    accept: dropZoneProps.accept || 'image/*',
    multiple: dropZoneProps.multiple || false,
    noDrag: dropZoneProps.noDrag || false,
    onDrop: (acceptedFiles) => {
      let files = [...filesToUpload];
      files = files.concat([...acceptedFiles]);
      setFilesToUpload([...files]);
    },
  });

  const removeFile = (file) => () => {
    const newFiles = [...filesToUpload];
    newFiles.splice(newFiles.indexOf(file), 1);
    remove(file);
  };

  const previewFile = (file) => () => {
    window.open(file.public_url);
  };

  const thumbs = files.map((file) => (
    <div key={file.public_url}>
      <div className="file-preview">
        <img src={file.public_url} alt="file upload preview" />
      </div>
      <Chip
        label={file.public_url.substring(0, 25) + '...'}
        clickable
        className="chip"
        onClick={previewFile(file)}
        onDelete={removeFile(file)}
      />
    </div>
  ));

  const fileNames = files.map((file) => (
    <div key={file.public_url}>
      <Chip
        label={file.public_url.substring(0, 55) + '...'}
        clickable
        className="chip"
        onClick={previewFile(file)}
        onDelete={removeFile(file)}
      />
    </div>
  ));

  useEffect(() => {
    if (filesToUpload.length) {
      onChange(filesToUpload);
    }
  }, [filesToUpload, onChange]);

  return (
    <div className={clsx(className, 'file-input')}>
      <label className="subHeader">{label}</label>
      {type === 'zone' ? (
        <>
          {!files.length && (
            <div {...getRootProps()} className="file-drop-zone mt-10">
              <input {...getInputProps()} disabled={disabled} />
              <span>
                Drag 'n' drop some files here, or click to select files
              </span>
            </div>
          )}
          <div>{thumbs}</div>
        </>
      ) : type === 'input' ? (
        <>
          <div {...getRootProps()} className="file-input-input">
            <input {...getInputProps()} disabled={disabled} />
            <div className="file-input-input__filenames">{fileNames}</div>
            <span className="file-input-input__label">Upload a file</span>
          </div>
        </>
      ) : (
        'Wrong prop "type" provided to FileInput'
      )}
    </div>
  );
};

export default FileInput;
