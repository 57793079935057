import React from 'react';
import { Grid, Button } from '@mui/material';

import './style.scss';

export default function Confirmation(props) {
  const { envConfirmConfig } = props;
  return (
    <Grid container>
      <Grid item xs={12} className="row">
        {envConfirmConfig.title}
      </Grid>
      <Grid item xs={12} className="button-container">
        {envConfirmConfig.buttonGroup.map((button, i) => (
          <Button
            className={`mr-20 ${button.type === 'CANCEL' ? '' : 'button'}`}
            size="small"
            variant={button.type === 'CANCEL' ? 'outlined' : 'contained'}
            type="button"
            onClick={button.handler}
            key={i}
          >
            {button.text}
          </Button>
        ))}
      </Grid>
    </Grid>
  );
}
