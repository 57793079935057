import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Skeleton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { formatDate, getLocalDateFromUTC } from 'utils';
import { SEVERITY } from 'config';
import {
  SUBSCRIPTIONS_SAVE_ERROR,
  SUBSCRIPTIONS_SAVE_SUCCESS,
} from 'config/messages';
import { API } from 'axiosClient/config';
import { postAPIDataDifferentOrigin } from 'api/microservices';
import TableComponent from 'components/common/TableComponent';

const keys = {
  ID: 'id',
  NAME: 'name',
  SUBSCRIBED: 'subscribed',
  SUBSCRIBED_ON: 'subscribed_on',
  UNSUBSCRIBED_ON: 'unsubscribed_on',
  SUBSCRIPTION_SOURCE: 'subscription_source',
};

const SubscriptionsTable = ({
  token,
  mailingLists,
  subscriptions,
  updateSubscriptionsData,
  canEdit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  if (!subscriptions) {
    return null;
  }

  const options = {
    pagination: false,
  };

  const unsubscribe = async (subscription) => {
    setIsLoading(true);
    const payload = {
      data: {
        token,
      },
    };

    try {
      const url = `${API.microservices.communications.emailSubscriptions.unsubscribe(
        subscription.id,
      )}`;
      const response = await postAPIDataDifferentOrigin(url, payload);

      if (!response || response.status > 208) {
        enqueueSnackbar(response.message || SUBSCRIPTIONS_SAVE_ERROR, {
          variant: SEVERITY.ERROR,
        });
        setIsLoading(false);
      } else {
        enqueueSnackbar(SUBSCRIPTIONS_SAVE_SUCCESS, {
          variant: SEVERITY.SUCCESS,
        });
        updateSubscriptionsData(response.data.data);
        setIsLoading(false);
      }
    } catch (e) {
      enqueueSnackbar(e.message || SUBSCRIPTIONS_SAVE_ERROR, {
        variant: SEVERITY.ERROR,
      });
      setIsLoading(false);
    }
  };

  const subscribe = async (subscription) => {
    setIsLoading(true);
    const payload = {
      data: {
        referrer: 'osmo-admin-tools',
        subscription_source: 'purchase',
        token,
      },
    };

    try {
      const url = `${API.microservices.communications.emailSubscriptions.subscribe(
        subscription.id,
      )}`;
      const response = await postAPIDataDifferentOrigin(url, payload);

      if (!response || response.status > 208) {
        enqueueSnackbar(response.message || SUBSCRIPTIONS_SAVE_ERROR, {
          variant: SEVERITY.ERROR,
        });
        setIsLoading(false);
      } else {
        enqueueSnackbar(SUBSCRIPTIONS_SAVE_SUCCESS, {
          variant: SEVERITY.SUCCESS,
        });
        updateSubscriptionsData(response.data.data);
        setIsLoading(false);
      }
    } catch (e) {
      enqueueSnackbar(e.message || SUBSCRIPTIONS_SAVE_ERROR, {
        variant: SEVERITY.ERROR,
      });
      setIsLoading(false);
    }
  };

  const createData = (item) => ({
    [keys.ID]: item.list_id,
    [keys.NAME]: mailingLists?.find((list) => list.id === item.list_id).name,
    [keys.SUBSCRIBED]:
      !item.unsubscribed_on || item.unsubscribed_on === '0001-01-01T00:00:00Z',
    [keys.SUBSCRIBED_ON]: item.subscribed_on,
    [keys.UNSUBSCRIBED_ON]: item.unsubscribed_on,
    [keys.SUBSCRIPTION_SOURCE]: item.subscription_source,
  });

  const rows = subscriptions.map((item) => createData(item));
  const headCells = [
    {
      title: 'List ID',
      id: keys.ID,
      render: (record) => record[keys.ID],
    },
    {
      title: 'List name',
      id: keys.NAME,
      render: (record) => record[keys.NAME],
    },
    {
      title: 'Subscribed',
      id: keys.SUBSCRIBED,
      render: (record) => record[keys.SUBSCRIBED].toString(),
    },
    {
      title: 'Subscribed on',
      id: keys.SUBSCRIBED_ON,
      render: (record) =>
        formatDate(getLocalDateFromUTC(record[keys.SUBSCRIBED_ON])),
    },
    {
      title: 'Unsubscribed on',
      id: keys.UNSUBSCRIBED_ON,
      render: (record) =>
        formatDate(getLocalDateFromUTC(record[keys.UNSUBSCRIBED_ON])),
    },
    {
      title: 'Source',
      id: keys.SUBSCRIPTION_SOURCE,
      render: (record) => record[keys.SUBSCRIPTION_SOURCE],
    },
    {
      title: 'Action',
      id: keys.ACTION,
      render: (record) => (
        <Button
          onClick={() =>
            record[keys.SUBSCRIBED] ? unsubscribe(record) : subscribe(record)
          }
          color="primary"
          variant="text"
          startIcon={record[keys.SUBSCRIBED] ? <ClearIcon /> : <CheckIcon />}
          disabled={!canEdit}
        >
          {record[keys.SUBSCRIBED] ? 'Unsubscribe' : 'Subscribe'}
        </Button>
      ),
    },
  ];

  if (isLoading) {
    return <Skeleton height={300} animation="wave" variant="rectangular" />;
  }

  return <TableComponent rows={rows} headCells={headCells} options={options} />;
};

export default SubscriptionsTable;
