export const tableConfig = [
  {
    title: 'Type',
    id: 'type',
  },
  {
    title: 'Country',
    id: 'country',
  },
  {
    title: 'Language',
    id: 'language',
  },
  {
    title: 'Badge',
    id: 'badges',
  },
  {
    title: 'Actions',
    id: 'actions',
    actions: [
      {
        text: 'Edit',
        permissions: ['EDIT'],
        type: 'Edit',
      },

      {
        text: 'Delete',
        type: 'Delete',
      },
    ],
  },
];

export const tableToolBarConfig = [
  {
    type: 'DeleteButton',
    label: 'Delete',
    onSelect: true,
  },
];

export const TableCellStates = {
  ACTIONS: 'actions',
  BADGES: 'badges',
  TYPE: 'type',
};

export const tableToolBarStates = {
  DeleteButton: 'DeleteButton',
  Select: 'Select',
};
