import React from 'react';
import { Field } from 'react-final-form';
import { Grid } from '@mui/material';
import TextInput from 'components/common/TextInput';

const BannerView = () => {
  return (
    <Grid container className="mt-20 mb-20" spacing={4}>
      <Grid item xs={12}>
        <Field
          fullWidth
          required
          name="show_on_pages"
          component={TextInput}
          type="text"
          label="Show on Pages"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          required
          name="hide_on_pages"
          component={TextInput}
          type="text"
          label="Hide on Pages"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          required
          name="hide_on_shopping_tabs"
          component={TextInput}
          type="text"
          label="Hide on Shopping Tabs"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          required
          name="link_to"
          component={TextInput}
          type="text"
          label="Link to URL"
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(BannerView);
