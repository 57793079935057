import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Grid, Button } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import { ROLES_SAVE_ERROR } from 'config/messages';

import { useErrorStatus } from 'components/hoc/ErrorHandler';
import TextInput from 'components/common/TextInput';
import AutoCompleteWrapper from 'components/common/AutoCompleteWrapper';

import policiesSelectors from 'store/policies/selectors';

import {
  FETCH_ROLES,
  UPDATE_USER_ROLES,
  FETCH_USERS_WITH_ROLES,
} from 'store/policies/actions';

function CreateUserRole({ handleCancel, roles, users, dispatch }) {
  const { enqueueSnackbar } = useSnackbar();
  const { setErrorStatusCode } = useErrorStatus();
  const initialValues = {
    account_email: '',
    roles: [],
  };

  useEffect(() => {
    dispatch({
      type: FETCH_ROLES,
      payload: {
        callback: () => {},
        errorCallback: (error) => {
          setErrorStatusCode(error);
        },
      },
    });
  }, [dispatch, setErrorStatusCode]);

  const handleSubmit = (values) => {
    dispatch({
      type: UPDATE_USER_ROLES,
      payload: {
        email: values.account_email,
        newRoles: values.roles,
        userRoles: [],
        callback: () => {
          handleCancel();
          dispatch({
            type: FETCH_USERS_WITH_ROLES,
            payload: {
              callback: () => {},
              errorCallback: (error) => {
                setErrorStatusCode(error);
              },
            },
          });
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${ROLES_SAVE_ERROR}: ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.roles || !values.roles.length) {
      errors.roles = 'Required';
    }
    if (!values.account_email || !values.account_email.length) {
      errors.account_email = 'Required';
    }
    const userHasRolesAlready =
      users && users.find((user) => user.email === values.account_email);
    if (!!userHasRolesAlready) {
      errors.account_email =
        'This user already has some permissions, please use the edition form instead';
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className="add-sku-form" noValidate>
          <Grid container>
            <Grid item xs={12} className="row">
              <Field
                fullWidth
                required
                name="account_email"
                component={TextInput}
                type="text"
                label="Osmo email address"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} className="row">
              <Field
                multiple={true}
                limitTags={3}
                name="roles"
                label="Select roles"
                placeholder="Search for a role"
                options={roles}
                selectField="id"
                component={AutoCompleteWrapper}
                variant="outlined"
                noSelectAll={true}
              />
            </Grid>
            <Grid item xs={12} className="button-container">
              <Button
                className="mr-20"
                size="small"
                variant="outlined"
                type="button"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="mr-20 button"
                size="small"
                variant="contained"
                type="submit"
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
}

const mapStateToProps = (state) => ({
  users: policiesSelectors.getUsersWithRolesArray(state),
  roles: policiesSelectors.getRoles(state),
});

export default connect(mapStateToProps)(CreateUserRole);
