import React, { useEffect, useState } from 'react';

import { Link } from '@mui/material';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

import useWindowSize from '../useWindowSize';

import paths from 'routes/paths';

import { TableCellStates, tableConfig } from './tableConfig';
import { TableActionStates } from 'enums/tableStates';
import { formatDate, getLocalDateFromUTC } from 'utils';
import { getByjusCouponURL, getCouponURL } from 'config/environments';
import { MOBILE_BREAK_POINT } from 'config/breakpoint';

import TableComponent from '../../../../common/TableComponent';
import TableActions from 'components/common/TableActions';
import Action from 'components/common/TableActions/Actions';
import TableToolbar from 'components/common/TableComponent/components/TableToolbar';
import CampaignFilters from '../CampaignFilters';
import MobileTableComponent from 'components/containers/MobileTableContainer/MobileTableComponent';
import SetPageTitle from 'components/common/SetPageTitle';
import { renderCampaignAction } from 'components/containers/MobileTableContainer/renderActions';

import 'components/containers/MobileTableContainer/index.css';

function PastCampaign(props) {
  const { pastCampaign, setWebsitePayload } = props;
  const COUPON_URL = getCouponURL();
  const BYJUS_COUPON_URL = getByjusCouponURL();
  const window = useWindowSize();

  const rows = pastCampaign;
  const [filteredRows, setFilteredRows] = useState(rows);
  const [website, setWebsite] = useState('');

  useEffect(() => {
    setWebsitePayload(website);
    setFilteredRows(rows);
  }, [rows, setWebsitePayload, website]);

  const options = {
    isRowSelectable: false,
    rowsPerPage: 25,
    customToolbar: () => {
      return (
        <TableToolbar
          selectedRowsCount={0}
          title="Past Campaigns"
          toolBarData={[]}
        />
      );
    },
  };

  const renderActions = (cell, record) => {
    return cell.actions.map((action, index) => {
      switch (action.type) {
        case TableActionStates.REVIEW:
          return (
            <Action
              key={index}
              text={action.text}
              link={`${paths.marketing.campaigns.review}/${record.id}`}
            />
          );
        default:
          return cell;
      }
    });
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return (
            <TableActions key={i}>{renderActions(cell, record)}</TableActions>
          );
        };
        return cell;
      case TableCellStates.COUNTRIES:
        cell.render = (record, index) => {
          return <span key={i}>{record.countries.join(', ')}</span>;
        };
        return cell;
      case TableCellStates.START_DATE:
        cell.render = (record, index) => {
          return (
            <span className="" key={i}>
              {formatDate(getLocalDateFromUTC(record.starts_on))}
            </span>
          );
        };
        return cell;
      case TableCellStates.END_DATE:
        cell.render = (record, index) => {
          return (
            <span className="" key={i}>
              {formatDate(getLocalDateFromUTC(record.expires_on))}
            </span>
          );
        };
        return cell;
      case TableCellStates.URL:
        cell.render = (record, index) => {
          return (
            <span key={i}>
              <Link
                href={
                  record.websites && record.websites.includes('byjuslearning')
                    ? `${BYJUS_COUPON_URL}${record.coupon_code}`
                    : `${COUPON_URL}${record.coupon_code}`
                }
                target="_blank"
                rel="noreferrer"
                className="campaign-test-link campaign-cell-text-short"
                underline="hover"
              >
                {record.websites && record.websites.includes('byjuslearning')
                  ? `${BYJUS_COUPON_URL}${record.coupon_code}`
                  : `${COUPON_URL}${record.coupon_code}`}
                <OpenInNewIcon />
              </Link>
            </span>
          );
        };
        return cell;
      default:
        return cell;
    }
  });

  return (
    <>
      <SetPageTitle pageTitle="Past Campaigns" />
      <CampaignFilters
        rows={rows}
        filteredRows={filteredRows}
        setFilteredRows={setFilteredRows}
        website={website}
        setWebsite={setWebsite}
      />
      {window.width >= MOBILE_BREAK_POINT ? (
        <TableComponent
          rows={filteredRows}
          headCells={headCells}
          options={options}
        />
      ) : (
        <div className="mobile-table">
          <MobileTableComponent
            rows={filteredRows}
            headCells={headCells}
            renderAction={renderCampaignAction}
          />
        </div>
      )}
    </>
  );
}

export default PastCampaign;
