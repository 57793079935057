import {
  contentFeatureTransform,
  contentSubsectionTransform,
  bannerTransform,
  removeEmptyProps,
} from 'utils';

const contentData = (values, contentSubsectionsData, contentFeature) => {
  if (!contentSubsectionsData || !contentFeature) {
    return null;
  }

  const content = {
    featured_sections: {},
  };
  content.featured_sections.heading = contentFeatureTransform([
    ...contentFeature,
  ]);
  content.featured_sections.hide_existing_featured_sections =
    values.hideFeature;
  content.featured_sections.subsections = contentSubsectionTransform([
    ...contentSubsectionsData,
  ]);
  content.featured_sections.skus = values.featureSku;
  return content;
};

const campaignPostDataTransform = (
  values,
  campaignId,
  { bannerHeader, bannerFeature, contentSubsectionsData, contentFeature } = {},
) => {
  let discounted_skus = {};
  Array.isArray(values.discounted_skus) &&
    values.discounted_skus.forEach((item) => {
      discounted_skus[item.id] = {
        enabled: true,
        percent_off: item.percent_off,
        badges: item.badges,
      };
    });
  const content = contentData(values, contentSubsectionsData, contentFeature);
  const postData = {
    id: campaignId && parseInt(campaignId),
    campaign_name: values.campaign_name,
    campaign: values.campaign,
    banners:
      bannerHeader &&
      bannerFeature &&
      bannerTransform(values, bannerHeader, bannerFeature),
    discounted_skus: discounted_skus,
    type: 'discount',
    countries: values.countries,
    starts_on: values.dateRange?.start,
    expires_on: values.dateRange?.end,
    is_default: values.is_default === 'global',
    bonus_skus: null,
    trigger_skus: null,
    discount_original_price: true,
    content: content,
    orders_per_customer: parseInt(values.orders_per_customer),
    new_customer_only: values.new_customer_only,
    limitless: !!values.limitless,
    units: 1000,
    websites: values.website ? [values.website] : [],
  };
  removeEmptyProps(postData, ['banners', 'websites']);
  return postData;
};

export default campaignPostDataTransform;
