import { useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Field, Form } from 'react-final-form';
import { Button, Grid, Paper, Typography } from '@mui/material';

import TextInput from 'components/common/TextInput';
import SelectDropdown from 'components/common/SelectDropdown';
import FormattedNumericInput from 'components/common/FormattedNumericInput';

import userSelectors from 'store/user/selectors';

import { currenciesForInput } from 'data/currencies';
import { postAccount } from 'api/auth';

import { SEVERITY } from 'config';
import { API } from 'axiosClient/config';

const publicTransactionTypes = [
  {
    id: 'gift_claim',
    value: 'gift_claim',
  },
  {
    id: 'qr_code_welcome',
    value: 'qr_code_welcome',
  },
];

const allTransactionTypes = [
  ...publicTransactionTypes,
  {
    id: 'playosmo_order_refund',
    value: 'playosmo_order_refund',
  },
  {
    id: 'expiration',
    value: 'expiration',
  },
  {
    id: 'playosmo_order',
    value: 'playosmo_order',
  },
  {
    id: 'trial_upgrade',
    value: 'trial_upgrade',
  },
];

const initialValues = {
  amount: 0,
  type: '',
  order_id: '',
  currency: '',
  comment: '',
};

const CreateTransactionSection = ({
  balances,
  reloadData,

  // From Redux
  userIsDeveloper,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleReset = (form) => {
    setIsLoading(false);
    form.reset();
  };

  const handleFormSubmit = async (values, form) => {
    setIsLoading(true);
    try {
      const balanceId = balances.find(
        (balance) => balance.currency === values.currency.value,
      ).id;

      const payload = {
        balance_id: balanceId,
        amount: parseFloat(values.amount) * 100,
        type: values.type,
        order_id: values.order_id?.length ? parseInt(values.order_id) : null,
        comment: values.comment,
        status: 'confirmed',
      };

      const response = await postAccount(
        API.microservices.account.credits.createTransaction,
        payload,
      );

      if (!response || response.status > 208) {
        setIsLoading(false);
        form.reset();
        return;
      }

      reloadData();

      enqueueSnackbar(`Transaction created`, {
        variant: SEVERITY.SUCCESS,
      });
      setIsLoading(false);
      form.reset();
    } catch (e) {
      console.error('An error occurred while creating a transaction', e);

      enqueueSnackbar(
        `An error occurred while creating a transaction: ${e.response.data.error.message}`,
        {
          variant: SEVERITY.ERROR,
        },
      );
      setIsLoading(false);
      form.reset();
    }
  };

  return (
    <Paper className="p20" elevation={0}>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        render={({ form, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid item container>
              <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>
                  Create a new transaction
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="amount"
                  component={FormattedNumericInput}
                  type="number"
                  label="Amount"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="currency"
                  component={SelectDropdown}
                  label="Currency"
                  dataItems={currenciesForInput}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="type"
                  component={SelectDropdown}
                  label="Type"
                  dataItems={
                    userIsDeveloper
                      ? allTransactionTypes
                      : publicTransactionTypes
                  }
                  variant="outlined"
                  required
                />
              </Grid>
              {(values.type === 'playosmo_order' ||
                values.type === 'playosmo_order_refund') && (
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="order_id"
                    component={TextInput}
                    type="text"
                    label="Order ID"
                    variant="outlined"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="comment"
                  component={TextInput}
                  type="text"
                  label="Comment"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} className="mt-20">
                <Button
                  className="mr-20"
                  size="small"
                  variant="outlined"
                  type="button"
                  onClick={() => handleReset(form)}
                >
                  Reset
                </Button>
                <Button
                  className="button"
                  size="small"
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  userIsDeveloper: userSelectors.getUserIsDeveloper(state),
});

export default connect(mapStateToProps)(CreateTransactionSection);
