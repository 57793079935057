const getTextBannerData = (data) => {
  return data.map((textBanner) => ({
    ...textBanner,
    id: textBanner.id,
    is_live:
      new Date(textBanner.starts_on) < new Date() &&
      new Date() < new Date(textBanner.ends_on),
    is_expired: new Date() > new Date(textBanner.ends_on),
  }));
};

export default getTextBannerData;
