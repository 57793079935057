import React from 'react';
import { Field } from 'react-final-form';
import clsx from 'clsx';

import TextInput from 'components/common/TextInput';
import CartItem from 'components/common/CartItem';

import './RMAItem.scss';

function RMAItem({ product, selectedQty, price, currency, className }) {
  return (
    <div className={clsx('rma-item', className)}>
      <div className="rma-item__qty">
        <CartItem
          sku={product.sku}
          inputName={product.sku}
          skuName={product.productName}
          qties={product.qty}
        />
      </div>
      {!!selectedQty &&
        [...Array(selectedQty).keys()].map((qty) => (
          <div key={qty} className="rma-item__price">
            <div className="rma-item__price__label">
              Amount to refund for product #{qty + 1}
            </div>
            <div className="rma-item__price__input">
              <Field
                fullWidth
                required
                name={`price.${product.sku}.${qty}`}
                component={TextInput}
                initialValue={price / 100}
                placeholder={price / 100}
                type="number"
                label={`Amount to refund (${currency})`}
                variant="outlined"
              />
            </div>
          </div>
        ))}
    </div>
  );
}

export default RMAItem;
