const normalizeMedias = (medias) => {
  return Object.entries(medias).reduce((acc, [key, media]) => {
    let newMedia;
    if (key === 'carousel') {
      newMedia = Object.values(media).map((submedia) => ({
        metadata: submedia.metadata,
        mimeType: submedia.metadata?.type,
        alt: submedia.metadata?.alt,
        url: submedia.url,
      }));
    } else {
      newMedia = Object.entries(media).reduce((acc, [subKey, submedia]) => {
        return {
          ...acc,
          [subKey]: {
            metadata: submedia.metadata,
            url: submedia.url,
          },
        };
      }, {});
    }

    return {
      ...acc,
      [key]: newMedia,
    };
  }, {});
};

const normalizeSkus = (data) => {
  return data.map((product) => ({
    id: product.sku_key,
    sku: product.sku_id,
    skuKey: product.sku_key,
    productKey: product.product_key,
    render: product.render,
    name: product.name,
    note: product.note,
    type: product.type,
    status: product.is_frontend_active
      ? 'is_frontend_active'
      : product.is_ready
      ? 'is_ready'
      : 'inactive',
    isProflectorIncluded: product.is_proflector_included,
    isReady: product.is_ready,
    isInvisible: product.is_invisible,
    isDeprecated: product.is_deprecated,
    isFrontendActive: product.is_frontend_active,
    basesIncluded: product.bases_included,
    medias: product.medias ? normalizeMedias(product.medias) : null,
    storageIncluded: product.storages_included,
    orderLimit: product.order_limit,
    websites: product.websites,
    physicalSkuKeys: product.physical_sku_keys,
    isSubscription: !!product.subscription_type,
    subscriptionType: product.subscription_type,
    subscriptionFrequency: product.subscription_frequency,
    externalProviderCode: product.external_provider_code,
    externalProviderUrl: product.external_provider_url,
    warehouses: product.warehouse_codes,
    complianceDoc: product.medias?.compliance?.doc?.url,
    complianceRender: product.medias?.compliance?.image?.url,
  }));
};

export default normalizeSkus;
