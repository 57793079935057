import { DEVELOPER } from 'config/roles';

const getUserRoles = (state) => state.user.userRoles;
const getUserInfo = (state) => state.user.userInfo;
const getUserIsDeveloper = (state) =>
  state.user.userInfo?.roles?.includes(DEVELOPER);

const userSelectors = {
  getUserRoles,
  getUserInfo,
  getUserIsDeveloper,
};

export default userSelectors;
