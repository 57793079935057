import React from 'react';
import Error from '@mui/icons-material/Error';
import './style.scss';
const ErrorDisplay = ({ message }) => {
  return (
    <div className="error">
      <Error />
      {message}
    </div>
  );
};

export default ErrorDisplay;
