import React from 'react';
import MediaForm from './MediaForm';
import ModalComponent from 'components/common/ModalComponent';

const MediaModal = ({
  open,
  setOpen = () => {},
  onSave = () => {},
  // For the form:
  withVideoOption,
  type,
  skuKey,
  sku,
  action = 'add', // or 'edit'
  value,
}) => {
  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      title={`${action === 'add' ? 'Add' : 'Edit'} media`}
      open={open}
      handleClose={handleClose}
    >
      <MediaForm
        withVideoOption={withVideoOption}
        action={action}
        type={type}
        skuKey={skuKey}
        sku={sku}
        value={value}
        onSave={onSave}
        onClose={handleClose}
      />
    </ModalComponent>
  );
};

export default MediaModal;
