import { makeStyles } from '@mui/styles';
import { Chip, Typography } from '@mui/material';
import ExpandableSection from 'components/common/ExpandableSection';
import OrderProperty from 'components/containers/OrdersContainer/OrderView/components/OrderProperty';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import BalanceTable from './BalanceTable';
import TransactionsTable from './TransactionsTable';
import { DisplayPrice } from '@tangibleplay/ecommerce-ui';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  transactionTableWrapper: {
    maxWidth: '100%',
    padding: 5,
  },
}));

const Balance = ({ balance, balancesCount }) => {
  const classes = useStyles();

  const renderContent = () => (
    <div className={classes.root}>
      <OrderPropertiesWrapper>
        <OrderProperty label="Account ID" value={balance.accountId} />
        <OrderProperty label="Currency" value={balance.currency} />
        <OrderProperty label="Created on" value={balance.createdOn} />
        <OrderProperty label="Updated on" value={balance.updatedOn} />
        <OrderProperty label="Expire on" value={balance.expireOn} />
      </OrderPropertiesWrapper>
      <div className="mt-20 mb-40">
        <BalanceTable balance={balance} />
      </div>
      <Typography variant="h4">Transactions history</Typography>
      <div className={`${classes.transactionTableWrapper} mt-20 mb-40 p-20`}>
        <TransactionsTable balance={balance} />
      </div>
    </div>
  );

  return (
    <ExpandableSection
      id={`balance-${balance.accountId}-${balance.currency}`}
      heading={`${balance.currency} balance`}
      headerContentRight={
        <Chip
          label={
            <DisplayPrice
              price={balance.available}
              currency={balance.currency}
              country={balance.currency}
            />
          }
          variant="outlined"
          size="small"
        />
      }
      content={renderContent()}
      openByDefault={balancesCount === 1}
    />
  );
};

export default Balance;
