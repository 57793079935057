import httpClient from '../axiosClient';
import { fileApiUrl, API, getFileBaseURL } from '../axiosClient/config';

export const fileUpload = async (service, file, tags) => {
  try {
    const fileData = new FormData();
    fileData.append('file', file);
    tags &&
      Object.entries(tags).forEach(([key, value]) => {
        fileData.append(`tags[${key}]`, value);
      });
    const resp = await httpClient.post(
      fileApiUrl(API.fileUpload(service), service),
      fileData,
    );

    return resp;
  } catch (e) {}
};

export const fileUploadClone = async (service, data, env = '') => {
  try {
    const resp = await httpClient.post(
      getFileBaseURL(env, service) + API.fileUploadClone(service),
      { data },
    );
    return resp;
  } catch (e) {
    throw e;
  }
};

export const deleteFile = async (service, id) => {
  try {
    const data = {
      data: [id],
    };
    const resp = await httpClient.delete(
      fileApiUrl(API.fileUpload(service), service),
      {
        data,
      },
    );

    return resp;
  } catch (e) {}
};

export const bulkDeleteFiles = async (service, ids) => {
  try {
    const data = {
      data: ids,
    };
    const resp = await httpClient.delete(
      fileApiUrl(API.fileUpload(service), service),
      {
        data,
      },
    );
    return resp;
  } catch (e) {}
};

export const hardDeleteFiles = async (service, id, hardDelete) => {
  try {
    const resp = await httpClient.put(
      fileApiUrl(`${API.fileUpload(service)}/${id}`, service),
      {
        deleted: hardDelete,
      },
    );
    return resp;
  } catch (e) {}
};
