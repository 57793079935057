const validateCampaignInformation = (values) => {
  let errors = {};

  if (!values.campaign_name) {
    errors.campaign_name = 'Campaign Name is required';
  }
  if (!values.campaign) {
    errors.campaign = 'Campaign Key is required';
  }
  if (!values.website) {
    errors.website = 'Website Value is required';
  }
  if (!values.countries || !values.countries.length) {
    errors.countries = 'Select at least one country or region';
  }
  if (values.dateRange && values.dateRange.start > values.dateRange.end) {
    errors.dateRange = 'Campaign start date should be before than end date';
  }
  if (values.dateRange && new Date() > values.dateRange.end) {
    errors.dateRange = "The end date can't be in the past";
  }

  if (values.orders_per_customer < 0) {
    errors.orders_per_customer = 'Invalid orders per customers';
  }

  return errors;
};

export default validateCampaignInformation;
