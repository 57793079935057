import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { compose } from 'recompose';

import { MARKETING_WRITE } from 'config/roles';
import paths from 'routes/paths';
import getCampaignStatus from 'utils/campaigns/getCampaignStatus';

import generalSelectors from 'store/general/selectors';
import contentSelectors from 'store/content/selectors';
import userSelectors from 'store/user/selectors';
import { GET_TEXT_BANNERS } from 'store/content/actions';

import PageContentLayout from 'components/layout/PageContentLayout';
import SetPageTitle from 'components/common/SetPageTitle';
import withRouterInfo from 'components/hoc/withRouterInfo';
import { useErrorStatus } from 'components/hoc/ErrorHandler';

import TextBannersTable from './TextBannersTable';

function TextBannerListing({
  // From Redux
  dispatch,
  textBanners = [],
  userRoles,
}) {
  const [loading, setLoading] = useState(false);
  const { setErrorStatusCode } = useErrorStatus();

  const history = useHistory();

  const fetchBanners = useCallback(() => {
    setLoading(true);
    dispatch({
      type: GET_TEXT_BANNERS,
      payload: {
        limit: 100,
        callback: (e) => {
          setLoading(false);
        },
        errorCallback: (error) => {
          setErrorStatusCode(error);
          setLoading(false);
        },
      },
    });
  }, [dispatch, setErrorStatusCode]);

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners]);

  const formattedTextBanners = textBanners
    ?.map((textBanner) => {
      return {
        ...textBanner,
        id: textBanner.id,
        status: textBanner && getCampaignStatus(textBanner),
      };
    })
    .sort((a, b) => new Date(b.updated_on) - new Date(a.updated_on));

  const handleCreate = () => {
    history.push(paths.content.textBanners.create);
  };

  const canEdit = userRoles && userRoles.includes(MARKETING_WRITE);

  return (
    <>
      <SetPageTitle pageTitle="Text banners" />
      <PageContentLayout
        title="Text Banners"
        isLoading={loading}
        buttons={
          canEdit && [
            {
              label: 'Create a banner',
              onClick: handleCreate,
            },
          ]
        }
        showProductionWarning={canEdit}
      >
        <TextBannersTable rows={formattedTextBanners || []} />
      </PageContentLayout>
    </>
  );
}

const mapStateToProps = (state) => ({
  apiEnv: generalSelectors.getAPIEnv(state),
  textBanners: contentSelectors.getBannersArray(state),
  userRoles: userSelectors.getUserRoles(state),
});

export default compose(withRouterInfo)(
  connect(mapStateToProps)(TextBannerListing),
);
