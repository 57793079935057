import React from 'react';
import TableComponent from 'components/common/TableComponent';
import { DisplayPrice } from '@tangibleplay/ecommerce-ui';

const keys = {
  ID: 'id',
  NAME: 'name',
  CURRENT: 'current',
  PROMO: 'promo',
  GIFT_CARD: 'giftCard',
  TOTAL: 'total',
};

const BalanceTable = ({ balance }) => {
  if (!balance) {
    return null;
  }

  const balanceDetails = [
    {
      name: 'Pending',
      id: 'pending',
      ...balance.pending,
    },
    {
      name: 'Current',
      id: 'current',
      ...balance.current,
    },
  ];

  const options = {
    pagination: false,
  };

  const createData = (item) => ({
    [keys.ID]: item.id,
    [keys.NAME]: item.name,
    [keys.CURRENT]: (
      <DisplayPrice
        price={item.current}
        currency={balance.currency}
        includeMicroData={false}
      />
    ),
    [keys.PROMO]: (
      <DisplayPrice
        price={item.promo}
        currency={balance.currency}
        includeMicroData={false}
      />
    ),
    [keys.GIFT_CARD]: (
      <DisplayPrice
        price={item.giftCard}
        currency={balance.currency}
        includeMicroData={false}
      />
    ),
    [keys.TOTAL]: (
      <DisplayPrice
        price={item.total}
        currency={balance.currency}
        includeMicroData={false}
      />
    ),
  });

  const rows = balanceDetails.map((item) => createData(item));
  const headCells = [
    {
      title: '',
      id: keys.NAME,
      render: (record) => record[keys.NAME],
    },
    {
      title: 'Current',
      id: keys.CURRENT,
      render: (record) => record[keys.CURRENT],
    },
    {
      title: 'Promo',
      id: keys.PROMO,
      render: (record) => record[keys.PROMO],
    },
    {
      title: 'Gift Card',
      id: keys.GIFT_CARD,
      render: (record) => record[keys.GIFT_CARD],
    },
    {
      title: 'Total',
      id: keys.TOTAL,
      render: (record) => record[keys.TOTAL],
    },
  ];

  return <TableComponent rows={rows} headCells={headCells} options={options} />;
};

export default BalanceTable;
