import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';

import { SEVERITY } from 'config';
import { ORDER_ACTION_ERROR, ORDER_ACTION_SUCCESS } from 'config/messages';
import { UPDATE_ORDER_ADDRESS } from 'store/orders/actions';
import TextInput from 'components/common/TextInput';
import SelectDropdown from 'components/common/SelectDropdown';
import generalSelectors from 'store/general/selectors';
import stateData from 'data/states';

const labels = {
  first_name: 'First Name',
  last_name: 'Last Name',
  phone: 'Phone',
  address_1: 'Address 1',
  address_2: 'Address 2',
  city: 'City',
  state: 'State',
  country: 'Country',
  zip: 'Zip',
};

const getLabel = (key) => labels[key];

export const getInitialValues = (order) => ({
  first_name: order?.shipping?.firstName,
  last_name: order?.shipping?.lastName,
  phone: order?.shipping?.phone,
  address_1: order?.shipping?.address1,
  address_2: order?.shipping?.address2,
  city: order?.shipping?.city,
  state: order?.shipping?.state,
  country: order?.shipping?.country,
  zip: order?.shipping?.zipCode,
});

function UpdateShippingAddressFrom({ order, canEdit, shippingCountries }) {
  const [initialValues, setInitialValues] = useState(getInitialValues(order));
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onResetClick = (form) => {
    form.reset();
  };

  useEffect(() => {
    setInitialValues(getInitialValues(order));
  }, [order]);

  const handleFormSubmit = (values) => {
    const data = {
      order_number: order.orderId,
      address_request_id: order.addressId,
      ...values,
    };

    dispatch({
      type: UPDATE_ORDER_ADDRESS,
      payload: {
        data,
        callback: () => {
          enqueueSnackbar(ORDER_ACTION_SUCCESS, {
            variant: SEVERITY.SUCCESS,
          });
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${ORDER_ACTION_ERROR} : ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const getSelectInput = (key, dataItems) => {
    return (
      <Field
        key={key}
        id={key}
        name={key}
        label={getLabel(key)}
        component={SelectDropdown}
        dataItems={dataItems}
        variant="outlined"
        className="mb-10"
        disabled={!canEdit}
      />
    );
  };

  const getTextInput = (key) => {
    return (
      <Field
        fullWidth
        key={key}
        id={key}
        name={key}
        label={getLabel(key)}
        component={TextInput}
        variant="outlined"
        disabled={!canEdit}
        className="mb-10"
      />
    );
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit} noValidate>
          {Object.keys(initialValues).map((key) => {
            if (key === 'country') {
              return getSelectInput(key, shippingCountries);
            } else if (key === 'state') {
              if (
                values.country === 'US' ||
                values.country === 'CA' ||
                values.country === 'AU'
              ) {
                return getSelectInput(key, stateData[values.country]);
              } else {
                return getTextInput(key);
              }
            } else {
              return getTextInput(key);
            }
          })}
          <div className="mt-20">
            <Button
              onClick={() => onResetClick(form)}
              variant="outlined"
              className="mr-10"
            >
              Reset
            </Button>
            <Button type="submit" variant="contained" onClick={handleSubmit}>
              Update
            </Button>
          </div>
        </form>
      )}
    />
  );
}

const mapStateToProps = (state) => ({
  shippingCountries: generalSelectors.getShippingCountries(state),
});

export default connect(mapStateToProps)(UpdateShippingAddressFrom);
