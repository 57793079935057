import paths from './paths';

export const redirectsConfig = [
  {
    from: '/',
    exact: true,
    to: {
      pathname: paths.home.dashboard,
    },
  },
];
