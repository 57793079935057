export const TableCellStates = {
  ORDER_ID: 'orderId',
  EMAIL: 'email',
  CREATED_ON: 'createdOn',
  CREATED_BY: 'createdBy',
  SHIPPING_ADDRESS: 'formattedShippingAddress',
  STATUS: 'status',
  ACTIONS: 'actions',
  WEBSITE: 'website',
};

export const tableConfig = [
  {
    title: 'Order ID',
    id: TableCellStates.ORDER_ID,
  },
  {
    title: 'Email address',
    id: TableCellStates.EMAIL,
  },
  {
    title: 'Created on',
    id: TableCellStates.CREATED_ON,
  },
  {
    title: 'Type',
    id: TableCellStates.CREATED_BY,
  },
  {
    title: 'Shipping address',
    id: TableCellStates.SHIPPING_ADDRESS,
  },
  {
    title: 'Status',
    id: TableCellStates.STATUS,
  },
  {
    title: 'Website',
    id: TableCellStates.WEBSITE,
  },
  {
    title: 'Actions',
    id: 'actions',
  },
];
