import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import { postProductManagement } from 'api/products';
import { API } from 'axiosClient/config';
import generalSelectors from 'store/general/selectors';
import TextInput from 'components/common/TextInput';
import CheckboxComponent from 'components/common/CheckboxComponent';
import SelectDropdown from 'components/common/SelectDropdown';
import { Button, Grid } from '@mui/material';

const initialValues = {
  shipping_method_code: '',
  delay_days: '',
  base_price: '',
  increment_price: '',
  free_shipping_threshold: '',
  display_as_new_threshold: false,
  country_code: '',
};

const ShippingMethodCreationForm = ({ reloadData, closeModal }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { shippingCountries } = useSelector((state) => state.general);
  const shippingMethods = useSelector(
    generalSelectors.getShippingMethodReferences,
  );
  const shippingMethodsData =
    shippingMethods?.map((method) => ({
      id: method.code,
      value: method.name,
    })) || [];

  const handleSave = async (values) => {
    const payload = {
      shipping_method_code: values.shipping_method_code,
      delay_days: values.delay_days
        ?.replace(' ', '')
        .split('-')
        .map((numb) => parseInt(numb)),
      base_price: parseInt(values.base_price),
      increment_price: parseInt(values.increment_price),
      free_shipping_threshold: parseInt(values.free_shipping_threshold),
      display_as_new_threshold: values.display_as_new_threshold,
    };

    try {
      await postProductManagement(
        API.products.shippingMethods.create(values.country_code),
        payload,
      );
      enqueueSnackbar('Shipping method successfully added', {
        variant: SEVERITY.SUCCESS,
      });
      reloadData();
      closeModal();
    } catch (e) {
      const errorMessage =
        e.response.data && typeof e.response.data === 'string'
          ? e.response.data
          : e.response.data?.error?.message;
      if (errorMessage) {
        enqueueSnackbar(errorMessage, {
          variant: SEVERITY.ERROR,
        });
      }
      throw e;
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSave}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container>
            <Grid item xs={6}>
              <Field
                component={SelectDropdown}
                name="country_code"
                label="Select Country"
                dataItems={shippingCountries}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={SelectDropdown}
                name="shipping_method_code"
                label="Select method"
                dataItems={shippingMethodsData}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                variant="standard"
                component={TextInput}
                fullWidth
                name="base_price"
                label="Base price"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                variant="standard"
                component={TextInput}
                fullWidth
                name="increment_price"
                label="Increment price"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                variant="standard"
                component={TextInput}
                fullWidth
                name="free_shipping_threshold"
                label="Free shipping threshold"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                variant="standard"
                component={TextInput}
                fullWidth
                name="delay_days"
                label="Days delayed"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                disableTypography
                label="Display as new threshold"
                type="checkbox"
                name="display_as_new_threshold"
                component={CheckboxComponent}
                color="primary"
              />
            </Grid>
          </Grid>
          <div className="mt-20">
            <Button
              className="select-input-standalone-button"
              variant="contained"
              type="submit"
              color="primary"
            >
              Add
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default ShippingMethodCreationForm;
