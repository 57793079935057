export const TableCellStates = {
  ID: 'id',
  COUNTRY: 'countryCode',
  METHOD: 'shippingMethodCode',
  DELAY: 'delayDays',
  PRICE: 'basePrice',
  INCREMENT: 'incrementPrice',
  THRESHOLD: 'freeShippingThreshold',
  NEW_THRESHOLD: 'displayAsNewThreshold',
  ACTIONS: 'actions',
};

export const tableConfig = [
  {
    title: 'Country',
    id: TableCellStates.COUNTRY,
  },
  {
    title: 'Method',
    id: TableCellStates.METHOD,
  },
  {
    title: 'Days (delay)',
    id: TableCellStates.DELAY,
  },
  {
    title: 'Base price',
    id: TableCellStates.PRICE,
  },
  {
    title: 'Increment',
    id: TableCellStates.INCREMENT,
  },
  {
    title: 'Free Shipping Threshold',
    id: TableCellStates.THRESHOLD,
  },
  {
    title: 'Display as new threshold',
    id: TableCellStates.NEW_THRESHOLD,
  },
  {
    title: 'Actions',
    id: TableCellStates.ACTIONS,
    style: { minWidth: '200px' },
  },
];
