import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@mui/material';

import { formatDate, getLocalDateFromUTC } from 'utils';
import paths from 'routes/paths';

import TableComponent from 'components/common/TableComponent';

import { tableConfig, TableCellStates } from './tableConfig';

const AccountsTable = ({ rows }) => {
  const history = useHistory();

  const options = {
    rowsPerPage: 10,
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return (
            <Button
              key={i}
              color="primary"
              variant="text"
              onClick={() => {
                history.push(
                  `${paths.users.accounts.edit}/${
                    record[TableCellStates.MYOSMO_USER_ID]
                  }`,
                );
              }}
            >
              View
            </Button>
          );
        };
        return cell;
      case TableCellStates.MYOSMO_USER_ID:
        cell.render = (record, index) => {
          return <span key={i}>{record[TableCellStates.MYOSMO_USER_ID]}</span>;
        };
        return cell;
      case TableCellStates.EMAIL:
        cell.render = (record, index) => {
          return <span key={i}>{record[TableCellStates.EMAIL]}</span>;
        };
        return cell;
      case TableCellStates.ACCOUNT_ID:
        cell.render = (record, index) => {
          return <span key={i}>{record[TableCellStates.ACCOUNT_ID]}</span>;
        };
        return cell;
      case TableCellStates.CREATED_ON:
        cell.render = (record, index) => {
          return (
            <span key={i}>
              {formatDate(
                getLocalDateFromUTC(record[TableCellStates.CREATED_ON]),
              )}
            </span>
          );
        };
        return cell;
      default:
        return cell;
    }
  });

  return <TableComponent rows={rows} headCells={headCells} options={options} />;
};

export default AccountsTable;
