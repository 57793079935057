import React from 'react';
import { Modal, Backdrop, Fade, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';

export default function ModalComponent(props) {
  const { open, handleClose, title } = props;
  return (
    <Modal // `disableBackdropClick` is removed by codemod.
      // You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)

      container={document.querySelector('#modal-container')}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} className="modal-container">
        <div>
          <div className="modal-container-header">
            <Typography variant="h3" gutterBottom>
              {title}
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
          {props.children}
        </div>
      </Fade>
    </Modal>
  );
}
