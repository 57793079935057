import { takeLatest, put, call } from 'redux-saga/effects';

import { UPDATE_SUBSCRIPTION } from './actions';

import { SET_LOADER } from 'store/general/actions';
import { handleException } from 'store/general/sagas';

import { API } from 'axiosClient/config';
import { putAPIData } from 'api/playosmo';

function* updateSubscription(action) {
  const { data, callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const payload = {
      status: data.status,
    };

    const response = yield call(
      putAPIData,
      `${API.subscriptions.update}${data.subscriptionId}`,
      payload,
    );

    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

export function* subscriptionsSaga() {
  yield takeLatest(UPDATE_SUBSCRIPTION, updateSubscription);
}
