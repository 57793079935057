import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, FormSpy, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Grid, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  modifyCampaignAssets,
  campaignPostDataTransform,
  validateCampaignInformation,
} from 'utils';

import { IS_DEVELOP, IS_RELC, IS_BETA, PRODUCTION } from 'config/environments';
import { SEVERITY, ASSET_MODIFICATION_MAPPING } from 'config';
import { MARKETING_WRITE } from 'config/roles';
import { CAMPAIGN_SAVED, CAMPAIGN_SAVE_ERROR } from 'config/messages';
import { campaignStateMapping, envConfirmConfig } from './config';
import paths from 'routes/paths';

import { FETCH_SKU_DETAILS } from 'store/general/actions';
import { SET_ENV } from 'store/general/actions';
import generalSelectors from 'store/general/selectors';
import userSelectors from 'store/user/selectors';

import { useErrorStatus } from 'components/hoc/ErrorHandler';
import ModalComponent from 'components/common/ModalComponent';
import Confirmation from 'components/common/Confirmation';
import SetPageTitle from 'components/common/SetPageTitle';
import PageFormLayout from 'components/layout/PageFormLayout';
import InformationSection from 'components/containers/CampaignContainer/CreateCampaign/InformationSection';
import AssetsSection from 'components/containers/CampaignContainer/CreateCampaign/Assets';
import DiscountSection from 'components/containers/CampaignContainer/CreateCampaign/Discounts';

import {
  FETCH_CAMPAIGN_DETAILS,
  RESET_CAMPAIGN_DETAILS,
  SAVE_CAMPAIGN_DETAILS,
  EDIT_CAMPAIGN_DETAILS,
  CLEANUP_ASSETS,
  PUBLISH_BULK_FILE,
} from 'components/containers/CampaignContainer/CreateCampaign/store/actions';

import DuplicateCampaign from './DuplicateCampaign/DuplicateCampaign';
import ContentSection from './ContentSection';

import './style.scss';

export const getCampaignKey = (formData, canEdit, prefix) => {
  if (!formData.values.campaign_name) {
    return null;
  }
  if (
    (!formData.visited.campaign || !formData.values.campaign) &&
    formData.values.campaign_name &&
    !canEdit
  ) {
    let year = new Date().getFullYear();
    let campaignName = `${
      !!prefix ? `${prefix}_` : ''
    }${year}_${formData.values.campaign_name
      .split(' ')
      .join('_')
      .toLowerCase()}`;
    return campaignName;
  }
  return formData.values.campaign;
};

export const formatFormValues = (campaignData, discountedProducts) => {
  let formData = Object.assign({}, campaignData);
  formData.dateRange = {
    start: campaignData.starts_on,
    end: campaignData.expires_on,
  };
  formData.discounted_skus = [...discountedProducts];
  return formData;
};

const CreateCampaign = ({
  match,
  type,
  // From Redux
  apiEnv,
  userRoles,
}) => {
  const [campaignId, setCampaignId] = useState();
  const [initialValues, setInitialValues] = useState({});
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [envSelected, setEnvSelected] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [formDetails, setFormDetails] = useState({});

  const { setErrorStatusCode } = useErrorStatus();

  const { campaignData } = useSelector((state) => state.createCampaignReducer);
  const { assets } = useSelector((state) => state.assetsReducer);
  const {
    discountedProducts,
    bannerHeader,
    bannerFeature,
    contentFeature,
    contentSubsectionsData,
  } = useSelector((state) => state.createCampaignReducer);

  const canEdit =
    userRoles.includes(MARKETING_WRITE) && type === campaignStateMapping.EDIT;
  const canReview = type === campaignStateMapping.REVIEW;
  const canCreate =
    userRoles.includes(MARKETING_WRITE) && type === campaignStateMapping.CREATE;
  const canDuplicate =
    canEdit && apiEnv !== PRODUCTION && type !== campaignStateMapping.CREATE;

  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const fetchSkuDetails = useCallback(
    (cb) => {
      dispatch({
        type: FETCH_SKU_DETAILS,
        payload: {
          callback: () => {
            cb();
          },
          errorCallback: (error) => {
            setErrorStatusCode(error);
          },
        },
      });
    },
    [dispatch, setErrorStatusCode],
  );

  useEffect(() => {
    const callback = () => {
      const id = match.params.id;
      if (id && id !== campaignId) {
        dispatch({
          type: FETCH_CAMPAIGN_DETAILS,
          payload: {
            id: id,
            callback: () => {},
            errorCallback: (error) => {
              setErrorStatusCode(error);
            },
          },
        });
        setCampaignId(id);
      } else if (!id) {
        dispatch({
          type: RESET_CAMPAIGN_DETAILS,
        });
        const formValues = {
          dateRange: {
            start: new Date(),
            end: new Date(),
          },
          is_default: 'global',
          hide_on_shopping_tabs: 'featured',
          show_on_pages: '*',
          link_to: '/--/shopping/featured',
          limitless: false,
          new_customer_only: false,
          website: '',
        };
        setInitialValues(formValues);
      }
    };

    fetchSkuDetails(callback);
  }, [
    match.params.id,
    campaignId,
    fetchSkuDetails,
    dispatch,
    setErrorStatusCode,
  ]);

  const getFormValues = useCallback(
    (campaignData) => formatFormValues(campaignData, discountedProducts),
    [discountedProducts],
  );

  useEffect(() => {
    let formValues = {};
    if (campaignId) {
      formValues = getFormValues(campaignData);
      setInitialValues(formValues);
    } else {
    }
  }, [campaignId, campaignData, getFormValues]);

  const submitCampaign = (values) => {
    let data = campaignPostDataTransform(values, campaignId, {
      bannerHeader,
      bannerFeature,
      contentFeature,
      contentSubsectionsData,
    });
    if (campaignId) {
      dispatch({
        type: EDIT_CAMPAIGN_DETAILS,
        payload: {
          campaignData: { ...data },
          callback: () => {
            enqueueSnackbar(CAMPAIGN_SAVED, {
              variant: SEVERITY.SUCCESS,
            });

            history.push(paths.marketing.campaigns.list);
          },
          errorCallback: (error) => {
            enqueueSnackbar(`${CAMPAIGN_SAVE_ERROR}: ${error}`, {
              variant: SEVERITY.ERROR,
            });
          },
        },
      });
    } else {
      dispatch({
        type: SAVE_CAMPAIGN_DETAILS,
        payload: {
          campaignData: data,
          callback: () => {
            enqueueSnackbar(CAMPAIGN_SAVED, {
              variant: SEVERITY.SUCCESS,
            });

            history.push(paths.marketing.campaigns.list);
          },
          errorCallback: (error) => {
            enqueueSnackbar(`${CAMPAIGN_SAVE_ERROR} : ${error}`, {
              variant: SEVERITY.ERROR,
            });
          },
        },
      });
    }
  };

  const getTitle = () => {
    return type.charAt(0).toUpperCase() + type.slice(1);
  };

  const handleCancel = () => {
    dispatch({
      type: CLEANUP_ASSETS,
    });
    history.push(paths.marketing.campaigns.list);
  };

  // TODO: persistence code
  //const saveCampaign = (formData) => {
  //   if (!isObjectEmpty(formData.values) && type !== "edit") {
  //     storageService.storeItem("Campaign", formData.values);
  //   }
  // };

  const setCampaignKey = (formData) => {
    formData.values.campaign = getCampaignKey(formData, canEdit);
  };

  const handleEnvSubmit = ({ environment, campaign }, data) => {
    setEnvSelected(environment);
    setShowDuplicate(false);
    setShowConfirm(true);
    setFormDetails({
      ...data,
      campaign: campaign || data.campaign,
    });
    envConfirmConfig.title = `Campaign ${data.campaign_name} (${
      campaign || data.campaign
    }) will be published on ${environment}. Please confirm to publish`;
  };

  const handleEnvCancel = () => {
    setShowDuplicate(false);
    setEnvSelected('');
    setFormDetails({});
  };

  const publishBulkFile = (assets, cb) => {
    dispatch({
      type: PUBLISH_BULK_FILE,
      payload: {
        assets,
        envSelected,
        callback: (assetsMapping) => {
          cb(assetsMapping);
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${CAMPAIGN_SAVE_ERROR}: ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const handleConfirmSubmit = () => {
    publishBulkFile(assets, (assetsMapping) => {
      let transformData = campaignPostDataTransform(formDetails, campaignId, {
        bannerHeader,
        bannerFeature,
        contentFeature,
        contentSubsectionsData,
      });
      delete transformData.id;
      ASSET_MODIFICATION_MAPPING.forEach((item) => {
        modifyCampaignAssets(assetsMapping, transformData, item);
      });
      dispatch({
        type: SAVE_CAMPAIGN_DETAILS,
        payload: {
          envSelected: envSelected,
          campaignData: transformData,
          callback: () => {
            enqueueSnackbar(CAMPAIGN_SAVED, {
              variant: SEVERITY.SUCCESS,
            });
            setShowConfirm(false);
            handleEnvCancel();
            localStorage.setItem('env', envSelected);
            dispatch({ type: SET_ENV, payload: { apiEnv: envSelected } });
            history.push(paths.marketing.campaigns.list);
            window.location.reload();
          },
          errorCallback: (error) => {
            enqueueSnackbar(`${CAMPAIGN_SAVE_ERROR} : ${error}`, {
              variant: SEVERITY.ERROR,
            });
            setShowConfirm(false);
          },
        },
      });
    });
  };

  const handleConfirmCancel = () => {
    setShowConfirm(false);
  };

  const envConfirmButton = envConfirmConfig.buttonGroup.map((button, i) => {
    switch (button.type) {
      case 'CANCEL':
        button.handler = handleConfirmCancel;
        return button;
      case 'CONFIRM':
        button.handler = handleConfirmSubmit;
        return button;
      default:
        return button;
    }
  });

  envConfirmConfig.buttonGroup = envConfirmButton;

  const WhenFieldChanges = ({ field, becomes, set, to }) => {
    return (
      <Field name={set} subscription={{}}>
        {({ input: { onChange } }) => (
          <FormSpy subscription={{}}>
            {({ form }) => (
              <OnChange name={field}>
                {(value) => {
                  if (value === becomes) {
                    onChange(to);
                  }
                }}
              </OnChange>
            )}
          </FormSpy>
        )}
      </Field>
    );
  };

  return (
    <div>
      {(canEdit || canReview) && (
        <SetPageTitle
          pageTitle={
            campaignData && campaignData.campaign_name
              ? campaignData.campaign_name
              : 'Create Campaigns'
          }
        />
      )}
      <Form
        onSubmit={submitCampaign}
        validate={validateCampaignInformation}
        initialValues={initialValues}
        mutators={{
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        render={({ form, handleSubmit, values, submitting, invalid }) => (
          <form onSubmit={handleSubmit} noValidate>
            <>
              <WhenFieldChanges
                field="is_default"
                becomes="global"
                set="limitless"
                to={true}
              />
            </>

            <PageFormLayout
              title={`${getTitle()} Campaign`}
              isLoading={false}
              breadcrumbs={[
                {
                  label: 'Campaign listing',
                  link: paths.marketing.campaigns.list,
                },
                {
                  label: `${type} campaign`,
                  isCurrent: true,
                },
              ]}
              buttons={
                canDuplicate && (IS_RELC || IS_BETA || IS_DEVELOP)
                  ? [
                      {
                        label: 'Duplicate',
                        onClick: () => setShowDuplicate(true),
                        disabled: invalid,
                      },
                    ]
                  : null
              }
              showSubmitButtons={canEdit || canCreate}
              handleCancel={handleCancel}
              showProductionWarning={canEdit || canCreate}
            >
              {
                <FormSpy
                  onChange={(formData) => {
                    // saveCampaign(formData);
                    setCampaignKey(formData);
                  }}
                />
              }

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className="p20">
                    <InformationSection mode={type} values={values} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className="p20">
                    <AssetsSection mode={type} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className="p20">
                    <DiscountSection mode={type} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className="p20">
                    <ContentSection
                      mode={type}
                      values={values}
                      setValue={form.mutators.setValue}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </PageFormLayout>

            <ModalComponent
              title="Duplicate"
              open={showDuplicate}
              handleClose={handleEnvCancel}
            >
              <DuplicateCampaign
                handleEnvCancel={handleEnvCancel}
                campaignPostDataTransform={campaignPostDataTransform}
                handleEnvSubmit={handleEnvSubmit}
                formDetails={values}
              />
            </ModalComponent>
            <ModalComponent
              title="Confirm"
              open={showConfirm}
              handleClose={handleConfirmCancel}
            >
              <Confirmation envConfirmConfig={envConfirmConfig} />
            </ModalComponent>
          </form>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  apiEnv: generalSelectors.getAPIEnv(state),
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(CreateCampaign);
