import React from 'react';
import Paper from '@mui/material/Paper';

import { Button, Typography } from '@mui/material';

import './style.scss';

const ErrorPage = (props) => {
  const { data: { title = '', imageSrc, description } = {}, message } = props;

  const handleClick = () => window.location.reload();

  return (
    <Paper className="error-page">
      <img src={imageSrc} alt="" className="error-page__img" />
      <Typography variant="h1">{title}</Typography>
      <p className="error-page__text-content">{message || description}</p>
      <Button
        color="primary"
        className="mt-20 button"
        size="small"
        variant="contained"
        type="button"
        onClick={handleClick}
      >
        Try Again
      </Button>
    </Paper>
  );
};

export default ErrorPage;
