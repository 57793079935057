import httpClient from '../axiosClient';

export const getAPIDataDifferentOrigin = async (url) => {
  try {
    const resp = await httpClient.get(url);
    return resp;
  } catch (e) {
    throw e;
  }
};

export const postAPIDataDifferentOrigin = async (url, data) => {
  try {
    const resp = await httpClient.post(url, data);
    return resp;
  } catch (e) {
    throw e;
  }
};

export const putAPIDataDifferentOrigin = async (url, data) => {
  try {
    const resp = await httpClient.put(url, data);
    return resp;
  } catch (e) {
    throw e;
  }
};

export const deleteAPIDataDifferentOrigin = async (url) => {
  try {
    const resp = await httpClient.delete(url);
    return resp;
  } catch (e) {
    throw e;
  }
};
