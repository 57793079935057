export const TableCellStates = {
  ID: 'id',
  CODE: 'countryCode',
  NAME: 'name',
  STATUS: 'status',
  EDIT: 'edit',
  CURRENCY: 'currencyCode',
  WAREHOUSES: 'warehouseCodes',
  IS_SHIPPING_AVAILABLE: 'isShippingAvailable',
};

export const tableConfig = [
  {
    title: 'Code',
    id: TableCellStates.CODE,
  },
  {
    title: 'Country name',
    id: TableCellStates.NAME,
  },
  {
    title: 'Status',
    id: TableCellStates.STATUS,
  },
  {
    title: 'Currency',
    id: TableCellStates.CURRENCY,
  },
  {
    title: 'Shipping Available',
    id: TableCellStates.IS_SHIPPING_AVAILABLE,
  },
  {
    title: 'Warehouses',
    id: TableCellStates.WAREHOUSES,
  },
  {
    title: '',
    id: TableCellStates.EDIT,
  },
];
