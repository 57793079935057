import {
  SKU_DETAILS_SUCCESS,
  COUNTRY_SKU_DETAILS_SUCCESS,
  ADD_MAILING_LISTS,
  SET_ENV,
  SET_LOADER,
  ADD_REFERENCE_LIST,
} from './actions';

const initialState = {
  apiEnv: 'DEVELOP',
  skuDetails: [],
  purchasableProducts: [],
  shippingCountries: [],
  productsPerCountries: {},
  mailingLists: null,
  references: null,
  isLoading: false,
};

export const getProductsWithoutGroups = (products) => {
  return products.filter((sku) => !sku.id.includes('_group'));
};

export const getPurchasableProducts = (products) => {
  return products.filter((sku) => !!sku.price && !sku.not_purchasable);
};

export const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SKU_DETAILS_SUCCESS: {
      const {
        skuDetails: { products, shipping_countries },
      } = action.payload;

      return {
        ...state,
        skuDetails: products,
        purchasableProducts: getPurchasableProducts(products),
        shippingCountries: shipping_countries,
      };
    }
    case COUNTRY_SKU_DETAILS_SUCCESS: {
      const {
        skuDetails: { products, country },
      } = action.payload;

      return {
        ...state,
        productsPerCountries: {
          [country]: getProductsWithoutGroups(products),
        },
      };
    }
    case ADD_MAILING_LISTS: {
      const { lists } = action.payload;

      return {
        ...state,
        mailingLists: lists,
      };
    }

    case ADD_REFERENCE_LIST: {
      const { type, data } = action.payload;

      return {
        ...state,
        references: {
          ...(state.references || {}),
          [type]: data,
        },
      };
    }

    case SET_ENV: {
      const { apiEnv } = action.payload;
      return {
        ...state,
        apiEnv,
      };
    }

    case SET_LOADER: {
      const { isLoading } = action.payload;
      return {
        ...state,
        isLoading,
      };
    }

    default:
      return state;
  }
};
