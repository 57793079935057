import { Form, FormSpy } from 'react-final-form';
import { Button, Paper, Typography } from '@mui/material';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';

const DebugSection = ({ account, updateAccount, canEdit }) => {
  const inputBase = {
    disabled: false,
  };

  const initialValues = {
    capture_automatic: account.debug_capture_automatic || false,
    capture_button: account.debug_capture_button || false,
    capture_cutoff_count: account.debug_capture_cutoff_count || '',
    capture_history_size: account.debug_capture_history_size || '',
    capture_upload_delay: account.debug_capture_upload_delay || '',
    capture_upload_size: account.debug_capture_upload_size || '',
    debug_console: account.debug_console || false,
    debug_crash: account.debug_crash || '',
    debug_cheats: account.debug_cheats || false,
    admin_login: account.admin_login || false,
  };

  const onSubmit = (values, form) => {
    const payload = {
      debug_console: values.debug_console,
      debug_crash: values.debug_crash,
      debug_cheats: values.debug_cheats,
      admin_login: values.admin_login,
      debug_capture_cutoff_count: parseInt(values.capture_cutoff_count),
      debug_capture_history_size: values.capture_history_size?.length
        ? parseInt(values.capture_history_size)
        : null,
      debug_capture_upload_delay: values.capture_upload_delay?.length
        ? parseInt(values.capture_upload_delay)
        : null,
      debug_capture_upload_size: values.capture_upload_size?.length
        ? parseInt(values.capture_upload_size)
        : null,
    };
    updateAccount(payload);
    form.reset(values);
  };

  const onResetClick = (form) => {
    form.reset();
  };

  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Debug
      </Typography>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <OrderPropertiesWrapper>
              <OrderPropertyInput
                label="Debug capture automatic"
                type="checkbox"
                input={{
                  ...inputBase,
                  name: 'capture_automatic',
                  label: '',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="Debug capture button"
                type="checkbox"
                input={{
                  ...inputBase,
                  name: 'capture_button',
                  label: '',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="Debug capture cutoff count"
                input={{
                  ...inputBase,
                  name: 'capture_cutoff_count',
                  label: '',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="Debug capture history size"
                input={{
                  ...inputBase,
                  name: 'capture_history_size',
                  label: '',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="Debug capture upload delay"
                input={{
                  ...inputBase,
                  name: 'capture_upload_delay',
                  label: '',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="Debug capture upload size"
                input={{
                  ...inputBase,
                  name: 'capture_upload_size',
                  label: '',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="Debug console"
                type="checkbox"
                input={{
                  ...inputBase,
                  name: 'debug_console',
                  label: '',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="Debug crash"
                input={{
                  ...inputBase,
                  name: 'debug_crash',
                  label: '',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="Debug cheats"
                type="checkbox"
                input={{
                  ...inputBase,
                  name: 'debug_cheats',
                  label: '',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="Admin login"
                type="checkbox"
                input={{
                  ...inputBase,
                  name: 'admin_login',
                  label: '',
                  disabled: !canEdit,
                }}
              />
            </OrderPropertiesWrapper>
            {canEdit && (
              <FormSpy subscription={{ pristine: true }}>
                {(props) => (
                  <div className="mt-20">
                    <Button
                      onClick={() => onResetClick(form)}
                      variant="text"
                      disabled={props.pristine}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleSubmit}
                      className="ml-10"
                      disabled={props.pristine}
                    >
                      Update
                    </Button>
                  </div>
                )}
              </FormSpy>
            )}
          </form>
        )}
      />
    </Paper>
  );
};

export default DebugSection;
