export const OTP_EXPIRY_TIME_SECONDS = 20;
export const TIMEZONE = 'America/Los_Angeles';
export const DATE_FORMAT_CALENDAR = 'MM-dd-yyyy hh:mm aa';
export const DATE_FORMAT_CALENDAR_CAMPAIGN = 'MM-dd-yyyy';
export const MAX_SNACK = 4;
export const MAX_NUM_INPUTS = 6;
export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50];
export const SENTRY_DSN =
  'https://b74cb35d3a464c6ab35ccbc62897a9d9@o90419.ingest.sentry.io/4505113619595264';
export const COUPON_URL = 'https://www.playosmo.com/--/shopping/?c=';
export const DATE_INFO = '(Start and End Date follow PST timezone)';
export const SUPPORTED_LANGUAGES = [
  'de-DE',
  'en-CA',
  'en-GB',
  'en-US',
  'fr-FR',
  'fr-CA',
  'ja-JP',
  'ko-KR',
  'zh-Hans-CN',
  'zh-Hant-HK',
];

export const SEVERITY = {
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
};

export const ASSET_MODIFICATION_MAPPING = [
  'banners',
  'discounted_skus',
  'assets',
];
