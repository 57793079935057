function currencyFormat(price, currency) {
  try {
    return price.toLocaleString('en-US', {
      style: 'currency',
      currency: currency,
    });
  } catch (err) {
    return price;
  }
}

export default currencyFormat;
