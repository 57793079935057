import { Container, Button, Paper, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import { checkValidEmail } from 'utils';
import { INVALID_EMAIL } from 'config/messages';

import authSelectors from 'store/auth/selectors';
import userSelectors from 'store/user/selectors';
import { REQUEST_OTP } from 'store/auth/actions';

import TextInput from 'components/common/TextInput';
import VerificationForm from './VerificationForm';

import './styles.scss';

const Login = (props) => {
  const { isLoggedIn, userRoles } = props;
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [isOtpFormVisible, setIsOtpFormVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && userRoles) {
      history.push('/');
    }
  }, [isLoggedIn, userRoles, history]);

  const requestOtp = () => {
    dispatch({
      type: REQUEST_OTP,
      payload: {
        data: {
          email: email,
        },
        callback: () => {
          setIsOtpFormVisible(true);
          setIsLoading(false);
        },
        errorCallback: (error) => {
          setIsOtpFormVisible(false);
          setIsLoading(false);
        },
      },
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!checkValidEmail(values.email)) {
      errors.email = INVALID_EMAIL;
    }
    if (!Object.entries(errors).length) {
      setEmail(values.email);
    }
    return errors;
  };

  return (
    <Paper className="login-container">
      <Container maxWidth="sm">
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1">Sign In</Typography>
        </div>
        {isOtpFormVisible ? (
          <VerificationForm
            email={email}
            requestOtp={requestOtp}
            setIsOtpFormVisible={setIsOtpFormVisible}
          />
        ) : (
          <Form
            onSubmit={requestOtp}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <p className="primary-label text-center mb-30 mt-30">
                  Enter your Osmo email address to receive a verification code
                  to sign in
                </p>
                <Field
                  fullWidth
                  required
                  name="email"
                  component={TextInput}
                  type="text"
                  label="Email"
                  variant="outlined"
                />
                <div className="mt-20">
                  <Button
                    className="button"
                    fullWidth
                    size="large"
                    variant="contained"
                    type="submit"
                    disabled={isLoading}
                  >
                    Login
                  </Button>
                </div>
              </form>
            )}
          />
        )}
      </Container>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: authSelectors.getIsLoggedIn(state),
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(Login);
