// recursively traversing asset object and replacing it with asset mapping
const modifyCampaignAssets = (assetsMapping, formItems, item) => {
  function recurse(obj) {
    if (item !== 'assets') {
      for (const key in obj) {
        let value = obj[key];
        if (value !== undefined) {
          if (value && typeof value === 'object' && !Array.isArray(value)) {
            recurse(value, key);
          } else {
            if (
              key === 'default' ||
              key === 'mobile' ||
              key === 'tablet' ||
              key === 'badges'
            ) {
              value.forEach((objItem, i) => {
                if (objItem) {
                  if (objItem.background_image) {
                    objItem.background_image = assetsMapping[
                      objItem.background_image
                    ]
                      ? assetsMapping[objItem.background_image].id
                      : objItem.background_image;
                  }

                  if (objItem.content_image) {
                    objItem.content_image = assetsMapping[objItem.content_image]
                      ? assetsMapping[objItem.content_image].id
                      : objItem.content_image;
                  }

                  if (key === 'badges') {
                    if (objItem.key) {
                      objItem.key = assetsMapping[objItem.key]
                        ? assetsMapping[objItem.key].id
                        : objItem.key;
                    }
                  }
                }
              });
            }
          }
        }
      }
    } else {
      let assetsObj = {};
      for (const key in assetsMapping) {
        assetsObj[assetsMapping[key].id] = assetsMapping[key].public_url;
      }
      formItems.assets = assetsObj;
    }
  }
  recurse(formItems[item]);
};

export default modifyCampaignAssets;
