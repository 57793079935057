import { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { Box, Button, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileInputForm from 'components/common/FileInput/FileInputForm';
import TextInput from 'components/common/TextInput';
import TabsComponent from 'components/common/TabsComponent';
import CheckboxComponent from 'components/common/CheckboxComponent';
import PreviewModal from '../InventorySection/PreviewModal';

export const tabsConfig = [
  {
    title: 'Image',
    id: 'image',
  },
  {
    title: 'Video',
    id: 'video',
  },
];

const MediaForm = ({
  value,
  withVideoOption = true,
  type,
  skuKey,
  sku,
  action,
  onSave,
  onClose,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const mimeType = value?.mimeType;

  const initialValues = {
    image: '',
    alt: value?.alt,
    existing_url: mimeType?.includes('image') ? value.url : '',
    existing_alt: value?.existing_alt,
    // Commented for now since it doesnt generate a cloudflare id yet
    // video: mimeType?.includes('video')
    //   ? {
    //       public_url: '',
    //       id: '',
    //     }
    //   : null,
    poster: mimeType?.includes('video')
      ? {
          public_url: value.url,
          id: value.url,
        }
      : null,
    youtube_id: value?.metadata?.youtube_id,
    cloudflare_id: value?.metadata?.cloudflare_id,
    open_in_popup: value?.metadata?.open_in_popup,
  };

  useEffect(() => {
    if (mimeType?.includes('video')) {
      setSelectedTab(1);
    }
  }, [mimeType]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCancel = () => {
    onClose();
  };

  const onSubmit = (values) => {
    const type = values?.image || values?.existing_url ? 'image' : 'video';

    onSave({
      action: action === 'edit' ? 'update' : 'create',
      url: values?.existing_url || values?.image?.public_url,
      mimeType: values?.image?.type || type || mimeType,
      alt: values?.alt,
      existing_alt: values?.existing_alt,
    });
    onClose();
  };

  const getVideoSection = (values) => {
    return (
      <>
        {/* <Box sx={{ padding: 1 }}>
          <Typography variant="h4" gutterBottom>
            Upload a Video
          </Typography>
          <Field
            service="products-management"
            name="video"
            component={FileInputForm}
            deleteFromServer={false}
            tags={{
              type,
              sku,
              skuKey,
            }}
          />
        </Box> */}
        <Box sx={{ padding: 1 }}>
          <Typography variant="h4" gutterBottom>
            Add a video
          </Typography>
          <Box sx={{ position: 'relative', marginBottom: 1 }}>
            <Field
              fullWidth
              name="youtube_id"
              component={TextInput}
              type="text"
              label="YouTube ID"
              variant="outlined"
            />
            {!!values?.youtube_id && (
              <Button
                sx={{ position: 'absolute', right: 10, top: 10 }}
                onClick={() => setPreviewOpen(true)}
                color="primary"
                variant="text"
                endIcon={<VisibilityIcon />}
              >
                Preview
              </Button>
            )}
          </Box>

          <Box sx={{ position: 'relative', marginBottom: 1 }}>
            <Field
              fullWidth
              name="cloudflare_id"
              component={TextInput}
              type="text"
              label="Cloudflare ID"
              variant="outlined"
            />
            {!!values?.cloudflare_id && (
              <Button
                sx={{ position: 'absolute', right: 10, top: 10 }}
                onClick={() => setPreviewOpen(true)}
                color="primary"
                variant="text"
                endIcon={<VisibilityIcon />}
              >
                Preview
              </Button>
            )}
          </Box>
        </Box>
        <Box sx={{ padding: 1 }}>
          <Typography variant="h4" gutterBottom>
            Upload a poster image
          </Typography>
          <Field
            service="products-management"
            name="poster"
            component={FileInputForm}
            deleteFromServer={false}
            tags={{
              type,
              sku,
              skuKey,
            }}
          />
        </Box>
        <Box sx={{ padding: 1 }}>
          <Box sx={{ marginBottom: 1 }}>
            <Field
              name="open_in_popup"
              component={CheckboxComponent}
              type="checkbox"
              label="Should open in popup"
              variant="outlined"
            />
          </Box>
        </Box>
      </>
    );
  };

  const getImageSection = () => {
    return (
      <>
        <Box sx={{ padding: 1 }}>
          <Typography variant="h4" gutterBottom>
            Upload an image
          </Typography>
          <Box sx={{ marginBottom: 1 }}>
            <Field
              service="products-management"
              name="image"
              component={FileInputForm}
              deleteFromServer={false}
              tags={{
                type,
                sku,
                skuKey,
              }}
            />
          </Box>
          <Box sx={{ marginBottom: 1 }}>
            <Field
              fullWidth
              name="alt"
              component={TextInput}
              type="text"
              label="Alternative text"
              variant="outlined"
            />
          </Box>
        </Box>
        <Box sx={{ padding: 1, marginBottom: 1 }}>
          <Typography variant="h4" gutterBottom>
            Add an existing image
          </Typography>
          <Box sx={{ marginBottom: 1 }}>
            <Field
              fullWidth
              name="existing_url"
              component={TextInput}
              type="text"
              label="Image URL"
              variant="outlined"
            />
          </Box>
          <Box sx={{ marginBottom: 1 }}>
            <Field
              fullWidth
              name="existing_alt"
              component={TextInput}
              type="text"
              label="Alternative text"
              variant="outlined"
            />
          </Box>
        </Box>
      </>
    );
  };

  const getTabsDetails = (values) =>
    tabsConfig.map((tab) => {
      if (tab.id === 'image') {
        tab.render = () => {
          return getImageSection();
        };
      }
      if (tab.id === 'video') {
        tab.render = () => {
          return getVideoSection(values);
        };
      }

      return tab;
    });

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit} noValidate>
          {withVideoOption ? (
            <TabsComponent
              selectedTab={selectedTab}
              tabsDetails={getTabsDetails(values)}
              handleChangeTab={handleChangeTab}
            />
          ) : (
            getImageSection()
          )}
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="primary" type="submit" className="ml-10">
            Save
          </Button>
          {!!(values?.cloudflare_id || values?.youtube_id) && (
            <PreviewModal
              open={previewOpen}
              setOpen={setPreviewOpen}
              cloudflareId={values.cloudflare_id}
              youtubeId={values.youtube_id}
            />
          )}
        </form>
      )}
    />
  );
};

export default MediaForm;
