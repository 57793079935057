const getEditingProductID = (state) => state.products.currentProduct?.id;
const getEditingProductVariants = (state) =>
  state.products.currentProduct?.variants;
const getEditingProductValues = (state) =>
  state.products.currentProduct?.values;

const productsSelectors = {
  getEditingProductID,
  getEditingProductVariants,
  getEditingProductValues,
};

export default productsSelectors;
