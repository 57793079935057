const prefix = 'PRODUCTS_';

export const SET_EDITING_PRODUCT = `${prefix}SET_EDITING_PRODUCT`;
export const RESET_EDITING_PRODUCT = `${prefix}RESET_EDITING_PRODUCT`;
export const UPDATE_EDITING_PRODUCT_VALUES = `${prefix}UPDATE_EDITING_PRODUCT_VALUES`;
export const SET_EDITING_PRODUCT_VARIANTS = `${prefix}SET_EDITING_PRODUCT_VARIANTS`;
export const UPDATE_EDITING_PRODUCT_VARIANT = `${prefix}UPDATE_EDITING_PRODUCT_VARIANT`;
export const ADD_EDITING_PRODUCT_VARIANT = `${prefix}ADD_EDITING_PRODUCT_VARIANT`;

// Sagas:
export const UPDATE_EDITING_PRODUCT = `${prefix}UPDATE_EDITING_PRODUCT`;
export const CREATE_NEW_PRODUCT = `${prefix}CREATE_NEW_PRODUCT`;
