export const TableCellStates = {
  NAME: 'name',
  PLATFORMS: 'platforms',
  EDIT: 'edit',
  ICON: 'id',
  ID: 'id',
};

export const tableConfig = [
  {
    title: '',
    id: TableCellStates.ICON,
  },
  {
    title: 'Game name',
    id: TableCellStates.NAME,
  },
  {
    title: 'ID',
    id: TableCellStates.ID,
  },
  {
    title: 'Platforms',
    id: TableCellStates.PLATFORMS,
  },
  {
    title: '',
    id: TableCellStates.EDIT,
  },
];
