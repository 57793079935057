import { useState, useCallback } from 'react';
import { Facebook, Twitter } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';

import ExpandableSection from 'components/common/ExpandableSection';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';
import SectionFilters from '../SectionFilters';

const SEOSection = ({ product, inputBase }) => {
  const [website, setWebsite] = useState();
  const [language, setLanguage] = useState();

  const onFilterChange = useCallback(
    (type, value) => {
      if (type === 'website') {
        setWebsite(value);
      }
      if (type === 'language') {
        setLanguage(value);
      }
    },
    [setWebsite, setLanguage],
  );

  const renderContent = () => (
    <div>
      <SectionFilters
        filters={['website', 'language']}
        onChange={onFilterChange}
      />

      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h3" gutterBottom>
            SEO
          </Typography>
          <OrderPropertiesWrapper>
            <OrderPropertyInput
              label="Page title"
              input={{
                ...inputBase,
                name: `${language}.${website}.seo_title`,
              }}
            />
            <OrderPropertyInput
              label="Page description"
              type="textarea"
              input={{
                ...inputBase,
                name: `${language}.${website}.seo_description`,
              }}
            />
          </OrderPropertiesWrapper>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              Social content
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Twitter />
              <Typography variant="h4" gutterBottom className="mt-10">
                Twitter
              </Typography>
            </Stack>
            <OrderPropertiesWrapper>
              <OrderPropertyInput
                label="Title"
                input={{
                  ...inputBase,
                  name: `${language}.${website}.twitter_title`,
                }}
              />
              <OrderPropertyInput
                label="Image preview URL"
                input={{
                  ...inputBase,
                  name: `${language}.${website}.twitter_preview_url`,
                }}
              />
              <OrderPropertyInput
                label="Description (xx chars max)"
                type="textarea"
                input={{
                  ...inputBase,
                  name: `${language}.${website}.twitter_description`,
                }}
              />
            </OrderPropertiesWrapper>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Facebook />
              <Typography variant="h4" gutterBottom className="mt-10">
                Facebook
              </Typography>
            </Stack>
            <OrderPropertiesWrapper>
              <OrderPropertyInput
                label="Title"
                input={{
                  ...inputBase,
                  name: `${language}.${website}.fb_title`,
                }}
              />
              <OrderPropertyInput
                label="Image preview URL"
                input={{
                  ...inputBase,
                  name: `${language}.${website}.fb_preview_url`,
                }}
              />
              <OrderPropertyInput
                label="Description (xx chars max)"
                type="textarea"
                input={{
                  ...inputBase,
                  name: `${language}.${website}.fb_description`,
                }}
              />
            </OrderPropertiesWrapper>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h3" gutterBottom>
            Google Merchant
          </Typography>
          <OrderPropertiesWrapper>
            <OrderPropertyInput
              label="Feed Description"
              input={{
                ...inputBase,
                name: `${language}.${website}.google_merchant_feed_desc`,
              }}
            />
          </OrderPropertiesWrapper>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <ExpandableSection
      id={`seo-${product?.id || 'creation'}`}
      heading={'SEO & Social Data'}
      secondaryHeading={'SEO, Social media preview, Google Merchant'}
      content={renderContent()}
      openByDefault={false}
    />
  );
};

export default SEOSection;
