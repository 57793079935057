import React from 'react';
import { Field } from 'react-final-form';
import { Grid, Link, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import TextInput from 'components/common/TextInput';
import { promoCodeStateMapping } from '../config';
import CheckboxComponent from 'components/common/CheckboxComponent';
import StatusIndicator from 'components/common/StatusIndicator';
import SelectDropdown from 'components/common/SelectDropdown';
import paths from 'routes/paths';
import getCampaignStatus from 'utils/campaigns/getCampaignStatus';
import { getWebsiteDataItem } from '../../../CampaignContainer/CampaignListing/CampaignFilters/filters';

const PromoCodeForm = (props) => {
  const {
    mode,
    values: { code, coupon_id, showCouponCreation },
  } = props;

  const { upcomingCampaign, pastCampaign } = useSelector(
    (state) => state.campaignListReducer,
  );

  const websiteDataItems = getWebsiteDataItem();
  const review = mode === promoCodeStateMapping.REVIEW;
  const canEdit = mode === promoCodeStateMapping.EDIT;

  const campaigns =
    !!code && review
      ? [...upcomingCampaign, ...pastCampaign]
      : upcomingCampaign;
  const selectedCoupon = campaigns.find(
    (campaign) => campaign.id.toString() === coupon_id,
  );

  // Some promo codes are linked to coupons that are not created through the admin platform
  if (coupon_id && !selectedCoupon) {
    campaigns.push({
      id: coupon_id.toString(),
      name: 'Unknown coupon',
      value: coupon_id.toString(),
    });
  }

  const coupons = campaigns.map((coupon) => ({
    id: coupon.id.toString(),
    name: coupon.campaign_name,
    value: coupon.id.toString(),
  }));

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          Promo code
        </Typography>
        {!!selectedCoupon && (
          <StatusIndicator
            status={getCampaignStatus(selectedCoupon)}
            withLabel={true}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          required
          name="code"
          component={TextInput}
          type="text"
          label="Code"
          variant="outlined"
          disabled={review}
        />
      </Grid>
      <Grid item xs={6}>
        {!showCouponCreation ? (
          <Field
            name="website"
            label="Website"
            dataItems={websiteDataItems}
            component={SelectDropdown}
            variant="outlined"
          />
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={6}>
        <Field
          id="coupon_id"
          name="coupon_id"
          label="Select a Coupon"
          dataItems={coupons}
          renderValue={(option) => {
            const coupon = campaigns.find(
              (campaign) => campaign.id.toString() === option,
            );
            return coupon ? `${coupon.id} - ${coupon.campaign}` : option;
          }}
          renderItems={(option) => `${option.id} - ${option.name}`}
          component={SelectDropdown}
          variant="outlined"
          disabled={
            review || showCouponCreation || !campaigns || !campaigns.length
          }
        />
      </Grid>
      <Grid item xs={6}>
        {!canEdit && !review ? (
          <Field
            name="showCouponCreation"
            component={CheckboxComponent}
            label="I need to create a coupon"
          />
        ) : (
          <Link
            href={`${
              canEdit
                ? paths.marketing.campaigns.edit
                : paths.marketing.campaigns.review
            }/${coupon_id}`}
            underline="hover"
          >
            {canEdit ? 'Edit the coupon' : 'See the coupon'}
          </Link>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(PromoCodeForm);
