import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Chip, Grid, Typography, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Container from 'components/common/Container';
import ProductionWarning from 'components/common/ProductionWarning';
import Breadcrumbs, {
  propTypes as breadcrumbsPropTypes,
} from 'components/common/Breadcrumbs';

import './styles.scss';

const useStyles = makeStyles((theme) => ({
  chipSuccess: {
    backgroundColor: 'green',
    color: '#fff',
  },
  chipError: {
    backgroundColor: 'red',
    color: '#fff',
  },
}));

function PageContentLayout({
  className = '',
  title,
  buttons,
  isLoading,
  breadcrumbs,
  children,
  showProductionWarning = false,
  RenderStatusValue,
  record,
  chips,
}) {
  const classes = useStyles();
  const hasButtons = !!(buttons && buttons.length);

  return (
    <div className={`page-content-layout ${className}`}>
      <Container>
        {showProductionWarning && (
          <Grid container alignItems="start">
            <Grid item xs={12} className="page-content-layout-header">
              <ProductionWarning />
            </Grid>
          </Grid>
        )}
        {(title || hasButtons) && (
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className="mb-20"
          >
            <Grid item className="page-content-layout-header">
              <div className="page-content-layout-header-flex">
                <Typography
                  variant="h1"
                  className="page-content-layout-header-title"
                >
                  {title}
                </Typography>
                {RenderStatusValue && record && (
                  <RenderStatusValue record={record} />
                )}
                {chips?.map(
                  (chip) =>
                    chip &&
                    chip.label && (
                      <Chip
                        key={chip.label}
                        classes={{
                          root:
                            chip.color === 'success'
                              ? classes.chipSuccess
                              : chip.color === 'error'
                              ? classes.chipError
                              : null,
                        }}
                        label={chip.label}
                      />
                    ),
                )}
              </div>
            </Grid>
            {hasButtons && (
              <Grid item className="page-content-layout-header-buttons">
                {buttons.map((button) => (
                  <Button
                    key={button.label.replace(' ', '')}
                    type="button"
                    className="button ml-10"
                    variant="contained"
                    size="small"
                    onClick={button.onClick}
                    disabled={button.disabled}
                    color={button.color || 'primary'}
                  >
                    {button.label}
                  </Button>
                ))}
              </Grid>
            )}
          </Grid>
        )}

        {breadcrumbs && (
          <Grid container alignItems="start" className="mb-40">
            <Grid item xs={12}>
              <Breadcrumbs data={breadcrumbs} />
            </Grid>
            <br />
          </Grid>
        )}

        {!isLoading ? (
          <Grid container alignItems="start">
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        ) : (
          <Skeleton height={300} animation="wave" variant="rectangular" />
        )}
      </Container>
    </div>
  );
}

export const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  isLoading: PropTypes.bool,
  breadcrumbs: breadcrumbsPropTypes.data,
};

PageContentLayout.propTypes = propTypes;

export default memo(PageContentLayout);
