import { useEffect } from 'react';
import { useHistory, Route } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Login from 'components/containers/Login';

import { GET_USER_INFO } from 'store/user/actions';
import authSelectors from 'store/auth/selectors';
import userSelectors from 'store/user/selectors';
import paths from 'routes/paths';

const IsAuth = (props) => {
  const { children, isLoggedIn, userRoles } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn || !userRoles) {
      dispatch({
        type: GET_USER_INFO,
        payload: {
          callback: () => {
            if (history.location.pathname === paths.login) {
              history.push('/');
            }
          },
          errorCallback: (error) => {
            history.push(paths.login);
          },
        },
      });
    }
  }, [isLoggedIn, userRoles, dispatch, history]);

  return (
    <>
      {isLoggedIn && userRoles ? (
        children
      ) : (
        <Route
          path={paths.login}
          exact
          render={(props) => <Login {...props} />}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: authSelectors.getIsLoggedIn(state),
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(IsAuth);
