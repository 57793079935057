export const TableCellStates = {
  MYOSMO_USER_ID: 'myOsmoUserID',
  EMAIL: 'email',
  ACCOUNT_ID: 'accountID',
  CREATED_ON: 'createdOn',
  ACTIONS: 'actions',
};

export const tableConfig = [
  {
    title: 'myOsmo user ID',
    id: TableCellStates.MYOSMO_USER_ID,
  },
  {
    title: 'Email',
    id: TableCellStates.EMAIL,
  },
  {
    title: 'Account ID',
    id: TableCellStates.ACCOUNT_ID,
  },
  {
    title: 'Created on',
    id: TableCellStates.CREATED_ON,
  },
  {
    title: '',
    id: TableCellStates.ACTIONS,
  },
];
