import React from 'react';
import { Grid, Paper } from '@mui/material';

const EmptyStateMessage = ({ message }) => (
  <Grid item xs={12} className="row">
    <Paper variant="outlined" elevation={0}>
      <p className="text-center">{message}</p>
    </Paper>
  </Grid>
);

export default EmptyStateMessage;
