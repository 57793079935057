import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { FETCH_SKU_DETAILS } from 'store/general/actions';
import TextInput from 'components/common/TextInput';
import DateRangePicker from 'components/common/DateRangePicker/index';
import SelectDropdown from 'components/common/SelectDropdown';
import { campaignType, campaignStateMapping } from './config';
import AutoCompleteWrapper from 'components/common/AutoCompleteWrapper';
import { DATE_INFO, TIMEZONE } from 'config';
import CheckboxComponent from 'components/common/CheckboxComponent';
import { websiteData } from 'websiteData/websites';

const InformationSection = (props) => {
  const { mode, values, source } = props;
  const dispatch = useDispatch();
  const review = mode === campaignStateMapping.REVIEW;
  const { shippingCountries } = useSelector((state) => state.general);

  useEffect(() => {
    dispatch({
      type: FETCH_SKU_DETAILS,
      payload: {
        callback: () => {},
        errorCallback: (error) => {},
      },
    });
  }, [dispatch]);

  const edit = mode === campaignStateMapping.EDIT;
  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          Information
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Field
          fullWidth
          required
          name="campaign_name"
          component={TextInput}
          type="text"
          label="Campaign Name"
          variant="outlined"
          disabled={review}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Field
          fullWidth
          required
          name="campaign"
          component={TextInput}
          type="text"
          label="Campaign Key"
          variant="outlined"
          disabled={review || edit}
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          multiple={true}
          limitTags={2}
          name="countries"
          label="Select Countries"
          placeholder="Search Countries"
          options={shippingCountries}
          selectField="id"
          component={AutoCompleteWrapper}
          variant="outlined"
          getOptionLabel={(option) => option.value}
          renderDropdownOption={(option) => option.value}
          disabled={review}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          required
          name="website"
          component={SelectDropdown}
          label="Website"
          dataItems={websiteData}
          disabled={review}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          required
          name="dateRange"
          component={DateRangePicker}
          disabled={review}
          timezone={TIMEZONE}
        />
        <Typography variant="caption">{DATE_INFO}</Typography>
      </Grid>
      {source !== 'promocode' && (
        <Grid item sm={3} xs={6}>
          <Field
            name="is_default"
            component={SelectDropdown}
            label="Type (global/url)"
            dataItems={campaignType}
            disabled={review}
          />
        </Grid>
      )}
      <Grid item sm={3} xs={6}>
        <Field
          name="limitless"
          component={CheckboxComponent}
          label="Limitless"
          disabled={(values && values.is_default === 'global') || review}
        />
      </Grid>
      <Grid item sm={3} xs={6}>
        <Field
          name="orders_per_customer"
          component={TextInput}
          type="number"
          label="Orders per customer"
          disabled={review}
        />
      </Grid>
      <Grid item sm={3} xs={6}>
        <Field
          name="new_customer_only"
          component={CheckboxComponent}
          label="New Customer Only"
          disabled={review}
        />
      </Grid>
    </Grid>
  );
};
export default React.memo(InformationSection);
