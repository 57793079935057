import { all } from 'redux-saga/effects';
import { userSaga } from './user/sagas';
import { authSaga } from './auth/sagas';
import { generalSaga } from './general/sagas';
import { policiesSaga } from './policies/sagas';
import { contentSaga } from './content/sagas';
import { campaignListSaga } from 'components/containers/CampaignContainer/CampaignListing/sagas';
import { createCampaignSaga } from 'components/containers/CampaignContainer/CreateCampaign/store/sagas';
import { promoCodeListSaga } from 'components/containers/PromoCodeContainer/PromoCodeListing/store/sagas';
import { createPromoCodeSaga } from 'components/containers/PromoCodeContainer/CreatePromoCode/store/sagas';
import { ordersSaga } from './orders/sagas';
import { subscriptionsSaga } from './subscriptions/sagas';

export default function* rootSaga() {
  yield all([
    generalSaga(),
    policiesSaga(),
    authSaga(),
    userSaga(),
    contentSaga(),
    createPromoCodeSaga(),
    createCampaignSaga(),
    campaignListSaga(),
    promoCodeListSaga(),
    ordersSaga(),
    subscriptionsSaga(),
  ]);
}
