import React, { Fragment } from 'react';
import { DateTime } from 'luxon';
import { Link } from '@mui/material';
import { DisplayPrice } from '@tangibleplay/ecommerce-ui';
import { CountryWithFlag } from '@tangibleplay/core-ui';

import { formatDate, getLocalDateFromUTC } from 'utils';
import paths from 'routes/paths';
import { isLabel } from 'utils/label/isLabel';
import './styles.scss';

const OrderProperty = ({
  label,
  value,
  href,
  currency,
  country,
  isPrice,
  component,
}) => {
  let formattedValue;

  if (component) {
    formattedValue = component;
  } else if (value && typeof value === 'object' && value?.length) {
    formattedValue = (
      <ul>
        {value.map((val) => (
          <li key={val}>{val}</li>
        ))}
      </ul>
    );
  } else if (value && typeof value === 'object' && Object.keys(value)?.length) {
    formattedValue = Object.keys(value).map((key) => (
      <Fragment key={key}>
        <span>
          <strong>{key}</strong>: {value[key]}
        </span>
        <br />
      </Fragment>
    ));
  } else if (typeof value === 'boolean') {
    formattedValue = value.toString();
  } else if (typeof value === 'number') {
    formattedValue = value;
  } else if (typeof value === 'string' && value.length) {
    if (isPrice) {
      formattedValue = (
        <DisplayPrice
          price={value}
          currency={currency}
          country={country}
          alwaysBold={true}
          includeMicroData={false}
        />
      );
    } else if (
      DateTime.fromISO(value).isValid &&
      value.length > 4 &&
      !isPrice &&
      isNaN(value)
    ) {
      //isNan used to bypass any other value that comes in and is not a date string, in a certain case we were getting shipping order id in this block and that was crashing it ticket : BYJUSLEARN-508
      if (DateTime.fromISO(value).toUnixInteger() > 946684861) {
        formattedValue = formatDate(getLocalDateFromUTC(value));
      }
    } else if (label === 'Country') {
      formattedValue = (
        <CountryWithFlag country={{ code: value, name: value }} />
      );
    } else if (isLabel(label, 'coupon')) {
      // to get coupon link
      formattedValue = (
        <Link
          target="_blank"
          href={`${paths.marketing.campaigns.review}/${value}`}
          underline="hover"
        >
          {value}
        </Link>
      );
    } else if (href || value.includes('http')) {
      formattedValue = (
        <Link href={href || value} underline="hover">
          {value}
        </Link>
      );
    } else {
      formattedValue = value;
    }
  }

  if (!formattedValue) {
    return null;
  }

  return (
    <>
      <div className="order-property-label">
        {typeof label === 'function'
          ? label()
          : !!label?.length
          ? `${label}:`
          : ''}
      </div>
      <div className="order-property-value">{formattedValue || '-'}</div>
    </>
  );
};

export default OrderProperty;
