import { useState, useCallback, useEffect } from 'react';
import { Form } from 'react-final-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button, Grid } from '@mui/material';

import { SEVERITY } from 'config';
import { normalizeGamesResponse } from 'utils';
import { PLAYOSMO_PRODUCTS_WRITE } from 'config/roles';
import paths from 'routes/paths';
import {
  getProductManagement,
  postProductManagement,
  putProductManagement,
} from 'api/products';
import { API } from 'axiosClient/config';
import normalizeProducts from 'utils/normalizers/api/normalizeProducts';
import normalizeSkus from 'utils/normalizers/api/normalizeSkus';
import userSelectors from 'store/user/selectors';
import productsSelectors from 'store/products/selectors';
import { FETCH_REFERENCES } from 'store/general/actions';
import {
  RESET_EDITING_PRODUCT,
  SET_EDITING_PRODUCT,
  SET_EDITING_PRODUCT_VARIANTS,
} from 'store/products/actions';

import SetPageTitle from 'components/common/SetPageTitle';
import OrderPageLayout from 'components/layout/OrderPageLayout';

import GeneralSection from './components/GeneralSection';
import SEOSection from './components/SEOSection';
import ProductDetailsSection from './components/ProductDetailsSection';
import VariantsSection from './components/VariantsSection';
import formatProductForAPI from './utils/formatProductForAPI';

const ProductView = ({
  match,
  type = 'view',
  // From Redux
  userRoles,
  currentProductKey,
  editingProductValues,
  skus,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [games, setGames] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const productKey = match.params.key;
  const canEdit = userRoles && userRoles.includes(PLAYOSMO_PRODUCTS_WRITE);
  const isCreationMode = type === 'create';
  const initialValues = editingProductValues || {
    name: product?.name || '',
    shopping_name: product?.shoppingName || '',
    product_key: product?.productKey || '',
    playosmo_path: product?.playosmoPath || '',
    byjus_path: product?.byjusPath || '',
    brand: product?.brand || '',
    grades: product?.grades || null,
    is_digital: product?.isDigital || false,
    is_ready: product?.isReady || false,
    is_bundle: product?.isBundle || false,
    marketed_games: product?.marketedGames,
    hardware_name: product?.hardwareName,
    workbook_count: product?.workbookCount,
    games_included: product?.gamesIncluded,
    subscriptions_included:
      (product?.subscriptionsIncluded && product?.subscriptionsIncluded[0]) ||
      null,
    websites: product?.websites || null,
    age_range: product?.ageRange?.join('-') || null,
    hardware_required: product?.requiredHardware || [],
    redeem_type: product?.redeemType,
    analytics_page: product?.analyticsPage,
    analytics_category: product?.analyticsCategory,
    tax_code: product?.taxCode,
    ...(product?.translations?.reduce(
      (acc, translationData) => ({
        ...acc,
        [translationData.language]: {
          ...(acc[translationData.language] || {}),
          [translationData.website]: {
            ...((acc[translationData.language] || {})[
              translationData.website
            ] || {}),
            google_merchant_feed_desc:
              translationData.translations.google_merchant?.content?.value,
            twitter_title: translationData.translations.twitter?.title?.value,
            twitter_preview_url:
              translationData.translations.twitter?.image?.value,
            twitter_description:
              translationData.translations.twitter?.content?.value,
            fb_title: translationData.translations.fb?.title?.value,
            fb_preview_url: translationData.translations.fb?.image?.value,
            fb_description: translationData.translations.fb?.content?.value,
            seo_title: translationData.translations.seo?.title?.value,
            seo_description: translationData.translations.seo?.content?.value,
          },
        },
      }),
      {},
    ) || {}),
  };

  const inputBase = {
    disabled: !canEdit,
  };

  const fetchProduct = useCallback(
    async (id) => {
      setIsLoading(true);
      let productResponse, translationsResponse;
      try {
        productResponse = await getProductManagement(
          API.products.products.get(id),
        );
        translationsResponse = await getProductManagement(
          API.products.products.getTranslations(id),
        );
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
      const normalizedProduct = {
        ...normalizeProducts([productResponse.data.data])[0],
        translations: translationsResponse?.data?.data,
      };
      dispatch({
        type: SET_EDITING_PRODUCT,
        payload: {
          productID: normalizedProduct.id,
        },
      });
      setProduct(normalizedProduct);
      setIsLoading(false);
    },
    [dispatch],
  );

  const fetchSkus = useCallback(
    async (id) => {
      setIsLoading(true);
      try {
        const skusResponse = await getProductManagement(
          API.products.skus.list(id),
        );
        const normalizedSkus = normalizeSkus(skusResponse.data.data);
        dispatch({
          type: SET_EDITING_PRODUCT_VARIANTS,
          payload: {
            variants: normalizedSkus,
          },
        });
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [dispatch],
  );

  // TODO: Create a saga to have this call in one place and not call the API again if the data is already in the store
  const fetchGames = useCallback(async () => {
    try {
      const response = await getProductManagement(API.products.games);
      const normalizedGames = normalizeGamesResponse(response.data.data);
      setGames(normalizedGames);
    } catch (e) {
      throw e;
    }
  }, []);

  useEffect(() => {
    if (
      !!currentProductKey &&
      !!productKey &&
      productKey !== currentProductKey
    ) {
      dispatch({
        type: RESET_EDITING_PRODUCT,
      });
    }
  }, [dispatch, productKey, currentProductKey]);

  useEffect(() => {
    fetchGames();
  }, [fetchGames]);

  useEffect(() => {
    if (!isCreationMode && productKey) {
      fetchProduct(productKey);
    }
    if (!!productKey && productKey !== currentProductKey) {
      fetchSkus(productKey);
    }
  }, [productKey, fetchProduct, fetchSkus, isCreationMode, currentProductKey]);

  useEffect(() => {
    // The values fetched by this saga are used in child components
    dispatch({
      type: FETCH_REFERENCES,
      payload: {
        types: ['brand', 'grade', 'hardware', 'redeem_type'],
      },
    });
  }, [dispatch]);

  const getTitle = () =>
    isCreationMode
      ? 'Create a new product'
      : isLoading || !product
      ? 'Loading...'
      : canEdit
      ? `Editing: ${product?.name}`
      : `Viewing: ${product?.name}`;

  const getActionButtons = useCallback(() => {
    if (isCreationMode) return null;
    const buttons = [];
    // TODO: https://playosmo.atlassian.net/browse/ADMIN-366
    // const buttons = [
    //   {
    //     label: 'Duplicate to...',
    //     onClick: () => setShowDuplicateModal(true),
    //   },
    // ];

    if (product?.byjusPath || product?.playosmoPath) {
      const options = [];

      if (product?.playosmoPath) {
        options.push({
          key: 'playosmo',
          label: 'PlayOsmo',
          onClick: () => {}, // TODO
        });
      }

      if (product?.byjusPath) {
        options.push({
          key: 'byjus-learning',
          label: "BYJU's Learning",
          onClick: () => {}, // TODO
        });
      }

      buttons.push({
        key: 'view',
        label: 'View on...',
        options,
      });
    }

    buttons.push({
      key: 'save',
      label: 'Save',
      type: 'submit',
      color: 'primary',
    });

    return buttons;
  }, [isCreationMode, product?.byjusPath, product?.playosmoPath]);

  const onSubmit = async (values) => {
    const payload = formatProductForAPI(values, skus, isCreationMode);

    if (isCreationMode) {
      try {
        await postProductManagement(API.products.products.create, payload);
        enqueueSnackbar('Product successfully updated', {
          variant: SEVERITY.SUCCESS,
        });
      } catch (e) {
        const errorMessage =
          e.response?.data && typeof e.response?.data === 'string'
            ? e.response?.data
            : e.response?.data?.error?.message;
        if (errorMessage) {
          enqueueSnackbar(errorMessage, {
            variant: SEVERITY.ERROR,
          });
        }
        throw e;
      }
    } else {
      try {
        await putProductManagement(
          API.products.products.update(productKey),
          payload,
        );
        enqueueSnackbar('Product successfully updated', {
          variant: SEVERITY.SUCCESS,
        });
      } catch (e) {
        const errorMessage =
          e.response?.data && typeof e.response?.data === 'string'
            ? e.response?.data
            : e.response?.data?.error?.message;
        if (errorMessage) {
          enqueueSnackbar(errorMessage, {
            variant: SEVERITY.ERROR,
          });
        }
        throw e;
      }
    }
  };

  const onCancel = () => {
    history.push(paths.catalog.products.list);
  };

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        showProductionWarning={canEdit}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <OrderPageLayout
              title={getTitle()}
              status={product?.status}
              isLoading={isLoading}
              handleClickBack={onCancel}
              buttons={canEdit ? getActionButtons() : null}
            >
              <Grid container spacing={3} alignItems="flex-start">
                <Grid item xs={12}>
                  <GeneralSection
                    product={product}
                    inputBase={inputBase}
                    isCreationMode={isCreationMode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SEOSection product={product} inputBase={inputBase} />
                </Grid>
                <Grid item xs={12}>
                  <ProductDetailsSection
                    product={product}
                    inputBase={inputBase}
                    games={games}
                    values={values}
                  />
                </Grid>
                <Grid item xs={12}>
                  <VariantsSection
                    product={product}
                    inputBase={inputBase}
                    skus={skus}
                    values={values}
                    isCreationMode={isCreationMode}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                  <Button type="submit" color="primary">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </OrderPageLayout>
          </form>
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
  currentProductKey: productsSelectors.getEditingProductID(state),
  editingProductValues: productsSelectors.getEditingProductValues(state),
  skus: productsSelectors.getEditingProductVariants(state),
});

export default connect(mapStateToProps)(ProductView);
