import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  useStylesAccordion,
  useStylesAccordionSummary,
  useStylesAccordionDetails,
} from './CollapsibleAccordionStyles';

const CollapsibleAccordion = (props) => {
  const accordionClass = useStylesAccordion();
  const accordionSummaryClass = useStylesAccordionSummary();
  const accordionDetailsClass = useStylesAccordionDetails();

  const { title } = props;

  return (
    <Accordion className={accordionClass.root} defaultExpanded={false}>
      <AccordionSummary
        className={accordionSummaryClass.root}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="ordered-content"
        id="ordered-header"
      >
        <Typography variant="h4">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={accordionDetailsClass.root}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CollapsibleAccordion;
