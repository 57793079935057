import React, { useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';
import { IconButton, Paper, InputBase, Tooltip } from '@mui/material';
import {
  Search as SearchIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const SearchInput = (props) => {
  const {
    id,
    disabled,
    defaultValue = '',
    placeholder = 'Search',
    onSubmit = () => {},
    className,
    variant = 'elevation',
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);

  const handleReset = () => {
    setValue('');
    onSubmit('');
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(value);
  };

  return (
    <Paper
      variant={variant}
      component="form"
      elevation={variant === 'elevation' ? 1 : 0}
      className={clsx(classes.root, className)}
    >
      <InputBase
        id={id}
        className={classes.input}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        value={value}
      />
      {value && !!value.length && (
        <Tooltip title="Reset" disableInteractive>
          <IconButton onClick={handleReset} size="large">
            <CancelIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Search" disableInteractive>
        <IconButton
          type="submit"
          onClick={handleSubmit}
          className={classes.iconButton}
          size="large"
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>
    </Paper>
  );
};

export default SearchInput;
