export const websites = [
  {
    id: 'playosmo',
    value: 'Playosmo',
  },
  {
    id: 'byjuslearning',
    value: "Byju's Learning",
  },
];
export const websiteData = websites;
