export const TableCellStates = {
  ID: 'id',
  ACTIONS: 'actions',
  NAME: 'name',
  VARIANTS: 'skuKeys',
  RENDER: 'render',
  WEBSITES: 'websites',
  STATUS: 'status',
};

export const tableConfig = [
  {
    title: 'Render',
    id: TableCellStates.RENDER,
  },
  {
    title: 'Name',
    id: TableCellStates.NAME,
  },
  {
    title: 'Websites',
    id: TableCellStates.WEBSITES,
  },
  {
    title: 'Variants count',
    id: TableCellStates.VARIANTS,
  },
  {
    title: 'Status',
    id: TableCellStates.STATUS,
  },
  {
    title: 'Actions',
    id: TableCellStates.ACTIONS,
  },
];
