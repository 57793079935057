import { formatDistanceToNow } from 'date-fns';
import { Typography, Paper } from '@mui/material';
import OrderProperty from 'components/containers/OrdersContainer/OrderView/components/OrderProperty';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import { formatDate, getLocalDateFromUTC } from 'utils';

const DatesSection = ({ account }) => {
  const formatAccountDate = (date) => {
    if (!date?.length) {
      return '-';
    }

    return `${formatDate(
      getLocalDateFromUTC(date),
      'yyyy MMM d, HH:mm',
    )} (${formatDistanceToNow(getLocalDateFromUTC(date), {
      addSuffix: true,
    })})`;
  };

  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Dates
      </Typography>
      <OrderPropertiesWrapper>
        <OrderProperty
          label="Created on"
          value={formatAccountDate(account.created_on)}
        />
        <OrderProperty
          label="Confirm emailed on"
          value={formatAccountDate(account.confirm_emailed_on)}
        />
        <OrderProperty
          label="Confirmed on"
          value={formatAccountDate(account.confirmed_on)}
        />
        <OrderProperty
          label="Confirmed email on"
          value={formatAccountDate(account.confirmed_email_on)}
        />
        <OrderProperty
          label="Confirmed phone on"
          value={formatAccountDate(account.confirmed_phone_on)}
        />
        <OrderProperty
          label="Updated on"
          value={formatAccountDate(account.updated_on)}
        />
        <OrderProperty
          label="Deleted on"
          value={formatAccountDate(account.deleted_on)}
        />
        <OrderProperty label="Deleted by" value={account.deleted_by} />
      </OrderPropertiesWrapper>
    </Paper>
  );
};

export default DatesSection;
