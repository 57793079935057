export const TableCellStates = {
  PROJECT: 'project',
  ID: 'id',
  REASON: 'reason',
  IP_ADDRESS: 'ipAddress',
  ACTIONS: 'actions',
};

export const tableConfig = [
  {
    title: 'IP address',
    id: TableCellStates.IP_ADDRESS,
  },
  {
    title: 'Reason',
    id: TableCellStates.REASON,
  },
  {
    title: 'Project',
    id: TableCellStates.PROJECT,
  },
  {
    title: 'ID',
    id: TableCellStates.ID,
  },
  {
    title: 'Actions',
    id: TableCellStates.ACTIONS,
  },
];
