import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import paths from 'routes/paths';
import { SEVERITY } from 'config';
import userSelectors from 'store/user/selectors';
import SetPageTitle from 'components/common/SetPageTitle';
import PageFormLayout from 'components/layout/PageFormLayout';
import ProfileSection from './components/ProfileSection';
import EditSection from './components/EditSection';
import StoragesSection from './components/StoragesSection';
import { getAccounts, getProfiles } from 'api/adminV2';
import { USERS_ACCOUNTS_WRITE } from 'config/roles';

const ProfileView = ({ match, userRoles }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const id = match.params.id;
  const canEdit = userRoles?.includes(USERS_ACCOUNTS_WRITE);
  const getTitle = () =>
    profile?.name ? `${profile?.name}'s profile` : 'Loading...';

  const updateProfile = (data) => {
    setProfile(data);
  };

  const fetchAccountData = useCallback(async () => {
    try {
      const payload = {
        user_id: profile.user_ids[0],
      };
      const response = await getAccounts(payload);

      if (!response?.data?.users || response.status > 208) {
        setIsLoading(false);
        return;
      }

      setUser(response.data.users[0]);
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar(e?.response?.data, {
        variant: SEVERITY.ERROR,
      });
      setIsLoading(false);
    }
  }, [profile, enqueueSnackbar]);

  useEffect(() => {
    if (profile?.id) {
      fetchAccountData();
    }
  }, [profile, fetchAccountData]);

  const fetchProfileData = useCallback(async () => {
    try {
      const payload = {
        profile_id: id,
      };
      const response = await getProfiles(payload);

      if (!response?.data?.profiles || response.status > 208) {
        setIsLoading(false);
        return;
      }

      setProfile(response.data.profiles[0]);
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar(e?.response?.data, {
        variant: SEVERITY.ERROR,
      });
      setIsLoading(false);
    }
  }, [enqueueSnackbar, id]);

  useEffect(() => {
    // Get the orders based on the parameters
    if (id) {
      setIsLoading(true);

      fetchProfileData();
    }
  }, [id, fetchProfileData]);

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <PageFormLayout
        title={getTitle()}
        isLoading={isLoading}
        breadcrumbs={[
          {
            label: 'Profiles',
            link: paths.users.profiles.list,
          },
          {
            label: getTitle(),
            isCurrent: true,
          },
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProfileSection
              profile={profile}
              user={user}
              canEdit={canEdit}
              updateProfile={updateProfile}
            />
          </Grid>
          <Grid item xs={8}>
            <EditSection
              profile={profile}
              canEdit={canEdit}
              updateProfile={updateProfile}
            />
          </Grid>
          <Grid item xs={4}>
            <StoragesSection profile={profile} />
          </Grid>
        </Grid>
      </PageFormLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(ProfileView);
