import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Drawer, Link, Toolbar } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import useWindowSize from 'utils/hooks/useWindowSize';

import authSelectors from 'store/auth/selectors';
import userSelectors from 'store/user/selectors';

import PermissionDenied from '../ErrorComponent/Component/PermissionDenied';

import TopBar from '../TopBar';
import NavBar from '../NavBar';
import { ADMIN_ACCESS } from '../../../config/roles';

import './styles.scss';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#1b1c1d',
  },
}));

function AdminLayout(props) {
  const {
    navigation,
    routes,
    // For Redux
    isLoggedIn,
    userRoles,
    userInfo,
  } = props;
  const classes = useStyles();
  const location = useLocation();
  const size = useWindowSize();
  const [open, setOpen] = useState(false);
  const canAccessAdminTool =
    userInfo && userInfo.id && userRoles && userRoles.includes(ADMIN_ACCESS);

  const currentRoute = routes.find(
    (route) => !!matchPath(location.pathname, route.path),
  );

  const toggleMobileMenu = () => setOpen(!open);

  if (!isLoggedIn) {
    return (
      <div className="admin-layout">
        <CssBaseline />
        <div className="admin-layout-container">
          <div className="admin-layout-content">
            <Toolbar />
            {props.children}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {canAccessAdminTool ? (
        <div className="admin-layout">
          <CssBaseline />
          <div className="admin-layout-container">
            <Drawer
              className={clsx(classes.drawer, 'admin-layout-drawer')}
              variant={!size.isMobile ? 'permanent' : null}
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="left"
              open={open}
              onClose={() => setOpen(false)}
            >
              <div className="admin-layout-drawer-logo">
                <Link href="/" underline="hover">
                  <img
                    src="https://www.playosmo.com/kids-learning/wp-content/themes/playosmo_content/assets/images/osmo-white.png"
                    alt="Osmo"
                  />
                </Link>
              </div>
              <NavBar navigation={navigation} roles={userRoles} />
            </Drawer>
            <div className="admin-layout-content-container">
              <TopBar
                isLoggedIn={isLoggedIn}
                userInfo={userInfo}
                toggleMobileMenu={toggleMobileMenu}
              />
              <main
                className={clsx('admin-layout-content', {
                  'admin-layout-content-without-padding':
                    currentRoute?.removeLayoutPadding,
                })}
              >
                {props.children}
              </main>
            </div>
          </div>
        </div>
      ) : (
        <PermissionDenied />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: authSelectors.getIsLoggedIn(state),
  userRoles: userSelectors.getUserRoles(state),
  userInfo: userSelectors.getUserInfo(state),
});

export default connect(mapStateToProps)(AdminLayout);
