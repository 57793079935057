import React from 'react';
import { Paper, Typography, Divider } from '@mui/material';
import { DisplayPrice } from '@tangibleplay/ecommerce-ui';

import { formatDate, getLocalDateFromUTC } from 'utils';

import CollapsibleAccordion from '../../CollapsibleAccordion';
import OrderProperty from '../../OrderProperty';
import OrderPropertiesWrapper from '../../OrderProperty/OrderPropertiesWrapper';

function PaymentSection({ order }) {
  return (
    <Paper className="order-section order-section-payment">
      <Typography variant="h2" gutterBottom>
        Payment
      </Typography>
      <CollapsibleAccordion title="Payment Info">
        <OrderPropertiesWrapper>
          <OrderProperty label="Provider" value={order.billing?.provider} />
          <OrderProperty label="Method" value={order.billing?.paymentMethod} />
          <OrderProperty
            label="Price paid"
            value={order.pricePaid}
            isPrice={true}
            currency={order.orderCurrency}
            country={order.orderCountry}
          />
          <OrderProperty
            label="Tax paid"
            value={order.taxPaid}
            isPrice={true}
            currency={order.orderCurrency}
            country={order.orderCountry}
          />
          <OrderProperty
            label="Refunded amount"
            value={order.refundedAmount}
            isPrice={true}
            currency={order.orderCurrency}
            country={order.orderCountry}
          />
          {order.billing?.paymentFailureType.length ? (
            <>
              <OrderProperty
                label="Payment Failure Type"
                value={order.billing.paymentFailureType}
              />
              <OrderProperty
                label="Retry Payment Count"
                value={order.billing?.retryPaymentCount}
              />
              <OrderProperty
                label="Retry Payment On"
                value={order.billing.retryPaymentOn}
              />
              {order.billing?.autoRetryPaymentCount ? (
                <>
                  <OrderProperty
                    label="Auto Retry Payment Count"
                    value={order.billing?.autoRetryPaymentCount}
                  />
                  <OrderProperty
                    label="Auto Retry Payment On"
                    value={order.billing?.autoRetryPaymentOn}
                  />
                  <OrderProperty
                    label="Auto Retry Payment Scheduled On"
                    value={order.billing?.autoRetryPaymentScheduledOn}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {!!order.refundsList && (
            <OrderProperty
              label="Refunds"
              component={
                <ul>
                  {order.refundsList.map((refund) => (
                    <li key={refund.amount}>
                      <DisplayPrice
                        price={refund.amount}
                        currency={refund.currency}
                        country={order.country}
                      />{' '}
                      - {formatDate(getLocalDateFromUTC(refund.date))}
                    </li>
                  ))}
                </ul>
              }
            />
          )}
          <OrderProperty label="Refunded on" value={order.refundedOn} />
          {order.billing?.card && (
            <>
              <OrderProperty
                label="Car exp date"
                value={order.billing?.card.expDate}
              />
              <OrderProperty
                label="Card last 4"
                value={order.billing?.card.last4}
              />
            </>
          )}
          {order.billing?.stripe && (
            <>
              <OrderProperty
                label="Stripe token ID"
                value={order.billing?.stripe.tokenId}
              />
              <OrderProperty
                label="Stripe error code"
                value={order.billing?.stripe.errorCode}
              />
            </>
          )}
          {order.billing?.paypal && (
            <>
              <OrderProperty
                label="Paypal order ID"
                value={order.billing?.paypal.orderId}
              />
              <OrderProperty
                label="Paypal authorization ID"
                value={order.billing?.paypal.authorizationId}
              />
              <OrderProperty
                label="Paypal authorization status"
                value={order.billing?.paypal.authorizationStatus}
              />
              <OrderProperty
                label="Paypal capture status"
                value={order.billing?.paypal.captureStatus}
              />
              <OrderProperty
                label="Paypal order status"
                value={order.billing?.paypal.orderStatus}
              />
            </>
          )}
          {order.billing?.affirm && (
            <>
              <OrderProperty
                label="Affirm checkout ID"
                value={order.billing?.affirm.checkoutId}
              />
              <OrderProperty
                label="Affirm authorization ID"
                value={order.billing?.affirm.authorizationId}
              />
              <OrderProperty
                label="Affirm charge ID"
                value={order.billing?.affirm.chargeId}
              />
            </>
          )}
        </OrderPropertiesWrapper>
      </CollapsibleAccordion>
      <Divider className="order-section-divider mb-20 mt-20" />

      <Typography variant="h3" gutterBottom>
        Credits
      </Typography>
      <OrderPropertiesWrapper>
        <OrderProperty
          label="Credits used"
          value={order.billing?.creditsUsed}
        />
      </OrderPropertiesWrapper>
      <Divider className="order-section-divider mb-20 mt-20" />
      <CollapsibleAccordion title="Billing Address">
        <OrderPropertiesWrapper>
          <OrderProperty
            label="Distance billing to shipping"
            value={order.billing?.distanceBillingShipping}
          />
          <OrderProperty label="First name" value={order.billing?.firstName} />
          <OrderProperty label="Last name" value={order.billing?.lastName} />
          <OrderProperty label="Address 1" value={order.billing?.address1} />
          <OrderProperty label="Address 2" value={order.billing?.address2} />
          <OrderProperty label="Zip code" value={order.billing?.zipCode} />
          <OrderProperty label="City" value={order.billing?.city} />
          <OrderProperty label="State" value={order.billing?.state} />
          <OrderProperty label="Country" value={order.billing?.country} />
        </OrderPropertiesWrapper>
      </CollapsibleAccordion>
    </Paper>
  );
}

export default PaymentSection;
