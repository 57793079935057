import { Form, FormSpy } from 'react-final-form';
import { Button, Paper, Typography } from '@mui/material';
import OrderProperty from 'components/containers/OrdersContainer/OrderView/components/OrderProperty';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';

const UserStatusSection = ({ account, updateAccount, canEdit }) => {
  const inputBase = {
    disabled: false,
  };

  const initialValues = {
    origin_status: account.origin_status || '',
    type_parent: account.user_types?.includes('parent') || false,
    type_teacher: account.user_types?.includes('teacher') || false,
  };

  const onSubmit = (values, form) => {
    const userTypes = [];
    if (values.type_parent) {
      userTypes.push('parent');
    }
    if (values.type_teacher) {
      userTypes.push('teacher');
    }

    const payload = {
      origin_status: values.origin_status,
      user_types: userTypes,
    };
    updateAccount(payload);
    form.reset(values);
  };

  const onResetClick = (form) => {
    form.reset();
  };

  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        User status
      </Typography>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <OrderPropertiesWrapper>
              <OrderProperty
                label="Onboarding version"
                value={account.onboarding_version}
              />
              <OrderProperty label="Status" value={account.status} />
              <OrderPropertyInput
                label="Origin status"
                input={{
                  ...inputBase,
                  name: 'origin_status',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label="User types"
                type="checkbox"
                input={{
                  ...inputBase,
                  name: 'type_parent',
                  label: 'Parent',
                  disabled: !canEdit,
                }}
              />
              <OrderPropertyInput
                label=""
                type="checkbox"
                input={{
                  ...inputBase,
                  name: 'type_teacher',
                  label: 'Teacher',
                  disabled: !canEdit,
                }}
              />
            </OrderPropertiesWrapper>
            {canEdit && (
              <FormSpy subscription={{ pristine: true }}>
                {(props) => (
                  <div className="mt-20">
                    <Button
                      onClick={() => onResetClick(form)}
                      variant="text"
                      disabled={props.pristine}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleSubmit}
                      className="ml-10"
                      disabled={props.pristine}
                    >
                      Update
                    </Button>
                  </div>
                )}
              </FormSpy>
            )}
          </form>
        )}
      />
    </Paper>
  );
};

export default UserStatusSection;
