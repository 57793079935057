import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { Button, Paper, Link, Typography } from '@mui/material';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';
import paths from 'routes/paths';
import userSelectors from 'store/user/selectors';

const emailConsents = [
  'promotions',
  'news',
  'activity',
  'onboarding',
  'osmo_forums',
  'insights',
];

const ConsentsSection = ({
  account,
  updateAccount,
  userIsDeveloper,
  canEdit,
}) => {
  const inputBase = {
    disabled: false,
  };

  const initialEmailValues = emailConsents.reduce((acc, key) => {
    return {
      ...acc,
      [`email_${key}`]: account.subscribed_emails?.includes(key) || false,
    };
  });
  const initialValues = {
    ...initialEmailValues,
    marketing_data_usage_consent: account.marketing_data_usage_consent || false,
  };

  const onSubmit = (values, form) => {
    const subscribedEmails = [];
    Object.entries(values).forEach(([key, value]) => {
      if (key.includes('email_') && value) {
        subscribedEmails.push(key.replace('email_', ''));
      }
    }, {});
    updateAccount({
      subscribed_emails: subscribedEmails,
      marketing_data_usage_consent: values.marketing_data_usage_consent,
    });
    form.reset(values);
  };

  const handleUnsubscribe = (form) => {
    form.mutators.unsubscribeAll();

    updateAccount({
      subscribed_emails: [],
      marketing_data_usage_consent: false,
    });
  };

  const onResetClick = (form) => {
    form.reset();
  };

  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Consents
      </Typography>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        mutators={{
          unsubscribeAll: (args, state, utils) => {
            emailConsents.forEach((field) => {
              utils.changeValue(state, `email_${field}`, () => false);
            });
            utils.changeValue(
              state,
              'marketing_data_usage_consent',
              () => false,
            );
          },
        }}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <OrderPropertiesWrapper>
              {userIsDeveloper && (
                <>
                  <OrderPropertyInput
                    label="Subscribed emails (myOsmo)"
                    type="checkbox"
                    input={{
                      ...inputBase,
                      name: 'email_promotions',
                      label: 'Promotions',
                      disabled: !canEdit,
                    }}
                  />
                  <OrderPropertyInput
                    label=""
                    type="checkbox"
                    input={{
                      ...inputBase,
                      name: 'email_news',
                      label: 'News',
                      disabled: !canEdit,
                    }}
                  />
                  <OrderPropertyInput
                    label=""
                    type="checkbox"
                    input={{
                      ...inputBase,
                      name: 'email_activity',
                      label: 'Activity',
                      disabled: !canEdit,
                    }}
                  />
                  <OrderPropertyInput
                    label=""
                    type="checkbox"
                    input={{
                      ...inputBase,
                      name: 'email_onboarding',
                      label: 'Onboarding',
                      disabled: !canEdit,
                    }}
                  />
                  <OrderPropertyInput
                    label=""
                    type="checkbox"
                    input={{
                      ...inputBase,
                      name: 'email_osmo_forums',
                      label: 'Osmo forums',
                      disabled: !canEdit,
                    }}
                  />
                  <OrderPropertyInput
                    label=""
                    type="checkbox"
                    input={{
                      ...inputBase,
                      name: 'email_insights',
                      label: 'Insights',
                      disabled: !canEdit,
                    }}
                  />
                </>
              )}
              <OrderPropertyInput
                label="Marketing data usage (FB & Google ads)"
                type="checkbox"
                input={{
                  ...inputBase,
                  name: 'marketing_data_usage_consent',
                  label: '',
                  disabled: !canEdit,
                }}
              />
            </OrderPropertiesWrapper>
            <FormSpy subscription={{ pristine: true }}>
              {(props) => (
                <div className="mt-20">
                  {(userIsDeveloper || canEdit) && (
                    <>
                      <Button
                        onClick={() => onResetClick(form)}
                        variant="text"
                        disabled={props.pristine}
                      >
                        Reset
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit}
                        className="ml-10"
                        disabled={props.pristine}
                      >
                        Update
                      </Button>
                    </>
                  )}
                  {userIsDeveloper && (
                    <Button
                      variant="outlined"
                      onClick={() => handleUnsubscribe(form)}
                      className="ml-10"
                    >
                      Unsubscribe from all
                    </Button>
                  )}
                  <div className="mt-10">
                    <Link
                      href={`${paths.users.emailSubscriptions.edit}/${account.email}`}
                      target="_blank"
                    >
                      Manage communication settings
                    </Link>
                  </div>
                </div>
              )}
            </FormSpy>
          </form>
        )}
      />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  userIsDeveloper: userSelectors.getUserIsDeveloper(state),
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(ConsentsSection);
