import {
  getCurrentEnvironment,
  LOCAL,
  DEVELOP,
  HOTFIX,
  RELC,
  BETA,
  PRODUCTION,
  LATHA,
  MARION,
  BINIT,
  CLEMENT,
  ERWAN,
  ERWAN_LE,
  ABHIJIT,
  IS_DEVELOP,
  IS_LOCAL,
  IS_HOTFIX,
  IS_RELC,
  IS_BETA,
} from 'config/environments';

// Base URL (PlayOsmo)
export const getBaseURL = (currentEnv) => {
  let env = currentEnv || getCurrentEnvironment();

  switch (env) {
    case PRODUCTION:
      return 'https://www.playosmo.com';
    case BETA:
      return 'https://beta.dev.playosmo.com';
    case HOTFIX:
      return 'https://hotfix.dev.playosmo.com';
    case RELC:
      return 'https://relc.dev.playosmo.com';
    case LOCAL:
      return 'https://local.dev.playosmo.com:8081';
    case DEVELOP:
      return 'https://develop.dev.playosmo.com';
    case LATHA:
      return 'https://latha.dev.playosmo.com';
    case MARION:
      return 'https://marion.dev.playosmo.com';
    case BINIT:
      return 'https://binit.dev.playosmo.com';
    case ERWAN:
      return 'https://erwan.dev.playosmo.com';
    case ERWAN_LE:
      return 'https://erwanle.dev.playosmo.com';
    case CLEMENT:
      return 'https://clement.dev.playosmo.com';
    case ABHIJIT:
      return 'https://abhijit.dev.playosmo.com';
    default:
      return null;
  }
};

export const apiUrl = (path) => {
  return getBaseURL() + path;
};

// File service URL
export const getFileBaseURL = (currentEnv, service) => {
  let env = currentEnv || getCurrentEnvironment();
  switch (env) {
    case PRODUCTION:
    case RELC:
    case HOTFIX:
    case BETA:
      return 'https://files-storage-v2.api.playosmo.com';
    default:
      return 'https://develop.api.playosmo.com/utilities/files-storage/v2';
  }
};

export const fileApiUrl = (path, service) => {
  return getFileBaseURL(null, service) + path;
};

// Identity URL
const AUTHORIZATION_URL =
  IS_LOCAL || IS_DEVELOP
    ? 'https://identity.develop.api.playosmo.com/authorization/v1'
    : 'https://identity.api.playosmo.com/authorization/v1';

export const getAuthorizationUrl = (path) => {
  return AUTHORIZATION_URL + path;
};

const LOGIN_URL =
  IS_LOCAL || IS_DEVELOP
    ? 'https://identity.develop.api.playosmo.com'
    : 'https://identity.api.playosmo.com';

export const getLoginUrl = (path) => {
  return LOGIN_URL + path;
};

// Banners URL
const BANNERS_URL =
  IS_LOCAL || IS_DEVELOP
    ? 'https://banners.develop.api.playosmo.com'
    : IS_RELC || IS_BETA || IS_HOTFIX
    ? 'https://banners.relc.api.playosmo.com'
    : 'https://banners.api.playosmo.com';

export const getBannersUrl = (path) => {
  return BANNERS_URL + path;
};

// Admin v2 URL
const ADMIN_V2_URL =
  IS_LOCAL || IS_DEVELOP || IS_RELC || IS_BETA || IS_HOTFIX
    ? 'https://develop.dev.my.playosmo.com'
    : 'https://my.playosmo.com';

export const getAdminV2Url = (path) => {
  return ADMIN_V2_URL + path;
};

// Products management URL
const PRODUCTS_URL =
  IS_LOCAL || IS_DEVELOP
    ? 'https://develop.api.playosmo.com/ecommerce/products-management'
    : 'https://develop.api.playosmo.com/ecommerce/products-management'; // TODO: Add the production URL when available

export const getProductsUrl = (path) => {
  return PRODUCTS_URL + path;
};

// IP whitelist URL
const IP_WHITELIST_URL =
  IS_LOCAL || IS_DEVELOP || IS_RELC || IS_BETA || IS_HOTFIX
    ? 'https://develop.api.playosmo.com/security/shield/v1'
    : 'https://api.playosmo.com/security/shield/v1/admin/ip/whitelists';

export const getIPProtectionUrl = (path) => {
  return IP_WHITELIST_URL + path;
};

export const getBannersURL = (currentEnv) => {
  let env = currentEnv || getCurrentEnvironment();
  switch (env) {
    case PRODUCTION:
      return 'https://banners.api.playosmo.com';
    case BETA:
    case HOTFIX:
    case RELC:
      return 'https://banners.relc.api.playosmo.com';
    case LOCAL:
    case DEVELOP:
      return 'https://banners.develop.api.playosmo.com';
    default:
      return null;
  }
};

const microservicePrefix = () => {
  if (getCurrentEnvironment() === 'PRODUCTION') {
    return '';
  } else {
    return '.develop';
  }
};

// List of paths
export const API = {
  auth: '/authentication/v1/login/email',
  getUser: '/account/v1/accounts/me',
  logout: '/authentication/v1/logout',
  productCatalog: '/api/v3/catalog',
  coupons: '/api/v3/coupons/admin/coupons',
  fileUpload: (service) => `/services/${service}/files`,
  fileUploadClone: (service) => `/services/${service}/files/clone`,
  createPromoCode: '/api/v2/promo_codes/admin/promo_codes',
  getPromoCodes: '/api/v2/promo_codes/admin/promo_codes',
  getUsersWithRole: '/policies/users-with-role',
  assignRoleToUser: '/policies/user-roles',
  getUserRoles: '/user-roles',
  textBanners: '/text-banners',
  subscriptionDetails: '/api/v3/orders/admin/subscriptions?subscription_id=',
  orders: {
    query: '/api/v3/orders/admin/query',
    addNote: '/api/v3/orders/admin/notes',
    inspect: '/api/v3/orders/admin/inspect',
    partialRefund: '/api/v3/orders/admin/payments/partial_refund',
    cancel: '/api/v3/orders/admin/cancel',
    review: '/api/v3/orders/admin/review',
    update: '/api/v3/orders/admin/update',
    addressUpdate: '/api/v3/orders/admin/address/update',
    create: '/api/v3/orders/admin/manual_create',
    sendEmailConfirmation: '/api/v3/orders/admin/emails',
    issues: '/api/v3/orders/admin/issues',
    exportToSQL: '/api/v3/orders/admin/export_to_sql',
    syncOrderStatus: '/api/orders/status',
    syncPaymentRecord: '/api/v3/orders/admin/sync_payment_record',
  },
  subscriptions: {
    query: '/api/v3/orders/admin/subscriptions',
    update: '/api/v3/orders/admin/subscriptions/',
  },
  products: {
    references: '/references',
    games: '/games',
    warehouses: '/warehouses',
    countries: '/countries',
    products: {
      list: '/products',
      get: (key) => `/products/${key}`,
      getTranslations: (key) => `/products/translations?product_key=${key}`,
      create: '/products',
      update: (key) => `/products/${key}`,
      delete: (key) => `/products/${key}`,
    },
    skus: {
      list: (key) => `/skus?product_key=${key}`,
      get: (key) => `/skus/${key}`,
      create: '/skus',
      getPricing: (key) => `/skus/${key}/pricing`,
      getInventory: (key) => `/skus/inventory?sku_key=${key}`,
      getTranslations: (key) => `/skus/translations?sku_key=${key}`,
    },
    shippingMethods: {
      list: '/countries/shippings',
      get: (country, id) => `/countries/${country}/shippings/${id}`,
      create: (country) => `/countries/${country}/shippings`,
      put: (country, id) => `/countries/${country}/shippings/${id}`,
      delete: (country, id) => `/countries/${country}/shippings/${id}`,
    },
    substitutes: {
      list: '/skus/substitutes',
      create: '/skus/substitutes',
      put: (skuFrom, warehouse, condition) =>
        `/skus/${skuFrom}/warehouses/${warehouse}/substitutes/${condition}`,
      delete: (skuFrom, warehouse, condition) =>
        `/skus/${skuFrom}/warehouses/${warehouse}/substitutes/${condition}`,
    },
  },
  accounts: '/api/v4/users/admin/',
  profiles: {
    get: '/api/v4/users/admin/profiles',
    delete: '/api/v4/users/admin/profiles/handle-delete',
  },
  returns: '/api/v1/shipping/returns',
  ipWhitelists: {
    list: '/admin/ip/whitelists',
    add: '/admin/ip/whitelists',
    delete: '/admin/ip/whitelists',
  },
  microservices: {
    shortLinks: {
      update: `https://url-shortener${microservicePrefix()}.api.playosmo.com/urls`,
      get: `https://url-shortener${microservicePrefix()}.api.playosmo.com/urls?limit=&cursor=`,
    },
    communications: {
      emailSubscriptions: {
        getLists: `https://communications${microservicePrefix()}.api.playosmo.com//mailing/lists`,
        get: `https://communications${microservicePrefix()}.api.playosmo.com/subscriptions/search`,
        view: `https://communications${microservicePrefix()}.api.playosmo.com/emails/settings`,
        update: `https://communications${microservicePrefix()}.api.playosmo.com/emails/settings`,
        subscribe: (listId) =>
          `https://communications${microservicePrefix()}.api.playosmo.com/settings/mailing/${listId}/subscribe`,
        unsubscribe: (listId) =>
          `https://communications${microservicePrefix()}.api.playosmo.com/settings/mailing/${listId}/unsubscribe`,
      },
    },
    account: {
      credits: {
        balances: '/credit/v1/admin/balances',
        transactions: (userId) =>
          `/credit/v1/admin/balance/${userId}/transactions`,
        createTransaction: '/credit/v1/admin/transactions',
      },
    },
  },
};
