import React from 'react';
import { Link, ListItem } from '@mui/material';

const Action = (props) => {
  const { text, action, link } = props;

  return (
    <>
      {!link ? (
        <ListItem key={text} onClick={action}>
          {text}
        </ListItem>
      ) : (
        <ListItem key={text}>
          <Link underline="none" color="textPrimary" href={link}>
            {text}
          </Link>
        </ListItem>
      )}
    </>
  );
};
export default Action;
