import React from 'react';

import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

export default function TableHeadComponent(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
    onRequestSort,
    headCells,
    isRowSelectable,
    selectedRowsCount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {isRowSelectable && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={
                selectedRowsCount > 0 && selectedRowsCount < rowCount
              }
              checked={rowCount > 0 && selectedRowsCount === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
              color="primary"
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={headCell.style}
          >
            {headCell.sorting ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.title}
              </TableSortLabel>
            ) : (
              <> {headCell.title} </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
