import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';
import { SENTRY_DSN } from './config';
import {
  IS_RELC_SERVER,
  IS_PROD_SERVER,
  getCurrentEnvironment,
} from './config/environments';
import './index.css';

const sendErrorDataToSentry = () => {
  Sentry.init({
    environment: getCurrentEnvironment(),
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

if (IS_PROD_SERVER || IS_RELC_SERVER) {
  sendErrorDataToSentry();
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
