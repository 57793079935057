import React, { useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';

import ModalComponent from 'components/common/ModalComponent';
import { envConfirmConfig } from 'components/containers/CampaignContainer/CreateCampaign/config';
import Confirmation from 'components/common/Confirmation';
import { deleteAccount } from 'api/adminV2';

const getConfirmConfig = (deleted) => ({
  title: deleted
    ? 'Are you sure you want to undelete this user?'
    : 'Are you sure you want to delete this user?',
  buttonGroup: [
    {
      text: 'Cancel',
      type: 'CANCEL',
    },
    {
      text: 'Confirm',
      type: 'CONFIRM',
    },
  ],
});

function ActionsSection({ account, setAccountData, canEdit }) {
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [disableDeleteAction, setDisableDeleteAction] = useState(false);
  const confirmConfig = getConfirmConfig(account.deleted);

  const onDeleteClick = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleDeleteConfirmationModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const deleteUser = async (e) => {
    e.preventDefault();

    setDisableDeleteAction(true);
    try {
      const response = await deleteAccount(account.user_id);

      setAccountData(response.data);

      enqueueSnackbar(
        response.data.deleted
          ? 'Account deleted successfully'
          : 'Account restored successfully',
        {
          variant: SEVERITY.SUCCESS,
        },
      );

      setDisableDeleteAction(false);
      setIsDeleteConfirmationModalOpen(false);
    } catch (e) {
      enqueueSnackbar(`An error occurred: ${e.message}`, {
        variant: SEVERITY.ERROR,
      });
      throw e;
    }
  };

  const confirmButton = envConfirmConfig.buttonGroup.map((button, i) => {
    switch (button.type) {
      case 'CANCEL':
        button.handler = handleDeleteConfirmationModalClose;
        return button;
      case 'CONFIRM':
        button.handler = deleteUser;
        return button;
      default:
        return button;
    }
  });
  confirmConfig.buttonGroup = confirmButton;

  return (
    <>
      <Paper className="p20">
        <Typography variant="h2" gutterBottom>
          Actions
        </Typography>

        <Button
          variant="contained"
          disabled={!canEdit || disableDeleteAction}
          onClick={onDeleteClick}
        >
          {account.deleted ? 'Undelete user' : 'Delete user'}
        </Button>
      </Paper>

      <ModalComponent
        title={account.deleted ? 'Undelete the account' : 'Delete the account'}
        open={isDeleteConfirmationModalOpen}
        handleClose={handleDeleteConfirmationModalClose}
      >
        <Confirmation envConfirmConfig={confirmConfig} />
      </ModalComponent>
    </>
  );
}

export default ActionsSection;
