const getAssets = (state) => state.assetsReducer.assets;
const getAddedAssets = (state) => state.assetsReducer.addedAssets;
const getDeletedAssets = (state) => state.assetsReducer.deletedAssets;

const assetObj = {
  getAssets,
  getAddedAssets,
  getDeletedAssets,
};

export default assetObj;
