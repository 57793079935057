import { useState } from 'react';
import { useForm, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import {
  Box,
  Button,
  Drawer,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';

import ProductRender from 'components/common/ProductRender';
import AddRow from 'components/containers/GamesContainer/GameView/components/GameManualsSection/GameManuals/AddRow';
import MediaWrapper from './MediaWrapper';
import MediaModal from './MediaModal';

import './MediaSection.scss';

const MediaSection = ({ variant, values }) => {
  const { mutators } = useForm();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [newMediaType, setNewMediaType] = useState(null);
  const carouselMedias = values?.medias?.carousel || variant?.medias?.carousel;
  const carouselAssets = carouselMedias ? carouselMedias?.slice(0, 3) : null;
  const badge =
    values?.medias?.render?.badge?.url || variant?.medias?.render?.badge?.url;
  const renderUrl =
    values?.medias?.render?.render?.url || variant?.medias?.render?.render?.url;
  const hoverUrl =
    values?.medias?.render?.hover?.url || variant?.medias?.render?.hover?.url;

  const editMedia = () => {
    setDetailsOpen(true);
  };

  const handleAddCarouselItem = () => {
    setNewMediaType('carousel');
  };

  const handleCancel = () => {
    setDetailsOpen(false);
  };

  const addCarouselAsset = (media) => {
    mutators.push('medias.carousel', {
      action: media.action,
      mimeType: media.mimeType,
      url: media.url,
      alt: media.alt,
      existing_alt: media.existing_alt,
    });
  };

  const handleCarouselSave = (media) => {
    addCarouselAsset(media);
  };

  const handleSave = () => {
    setDetailsOpen(false);
  };

  const getSkeleton = () => (
    <Skeleton
      animation={false}
      variant="rectangular"
      width="100%"
      height="100%"
    />
  );

  return (
    <>
      <Paper className="media-section-container" elevation={1}>
        <div className="media-section">
          <div className="media-section__render">
            {renderUrl ? (
              <ProductRender
                image={{
                  main: renderUrl,
                  hover: hoverUrl,
                }}
                badge={
                  badge
                    ? {
                        url: badge,
                      }
                    : null
                }
              />
            ) : (
              getSkeleton()
            )}
          </div>
          <div className="media-section__carousel-thumbnails">
            {carouselAssets ? (
              carouselAssets.map((asset, i) =>
                asset.url ? (
                  <div
                    key={asset.url}
                    className="media-section__carousel-thumbnail"
                  >
                    <img
                      src={asset.url}
                      alt={asset.alt}
                      style={{ width: '100%' }}
                    />
                  </div>
                ) : (
                  <div
                    key={`empty-${i}`}
                    className="media-section__carousel-thumbnail"
                  >
                    {getSkeleton()}
                  </div>
                ),
              )
            ) : (
              <>
                <div className="media-section__carousel-thumbnail">
                  {getSkeleton()}
                </div>
                <div className="media-section__carousel-thumbnail">
                  {getSkeleton()}
                </div>
                <div className="media-section__carousel-thumbnail">
                  {getSkeleton()}
                </div>
              </>
            )}
          </div>
          <div className="media-section__actions">
            <AddRow onClick={editMedia} label="Edit media" />
          </div>
        </div>
      </Paper>
      <Drawer
        anchor="right"
        open={detailsOpen}
        onClose={handleCancel}
        PaperProps={{
          sx: {
            maxWidth: '50%',
          },
        }}
      >
        <Box
          sx={{
            padding: 3,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 1,
                }}
              >
                <Typography variant="h2" gutterBottom>
                  Medias
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: 1,
                }}
              >
                <Typography variant="h3" gutterBottom>
                  Product Render
                </Typography>
                <Typography variant="h4" gutterBottom>
                  Main
                </Typography>
                <Field
                  component={MediaWrapper}
                  name="medias.render.render"
                  withVideoOption={false}
                />
                <Typography variant="h4" gutterBottom>
                  Hover
                </Typography>
                <Field
                  component={MediaWrapper}
                  name="medias.render.hover"
                  withVideoOption={false}
                />
              </Box>
              <Box
                sx={{
                  padding: 1,
                }}
              >
                <Typography variant="h3" gutterBottom>
                  Badge
                </Typography>
                <Field
                  component={MediaWrapper}
                  name="medias.render.badge"
                  withVideoOption={false}
                />
              </Box>
              <Box
                sx={{
                  padding: 1,
                }}
              >
                <Typography variant="h3" gutterBottom>
                  Carousel
                </Typography>
                <FieldArray name="medias.carousel">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      return (
                        <Field
                          component={MediaWrapper}
                          key={name}
                          name={name}
                          onDelete={() => fields.remove(index)}
                        />
                      );
                    })
                  }
                </FieldArray>
                <AddRow
                  onClick={handleAddCarouselItem}
                  label="Add a media to the carousel"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
              <Button color="primary" onClick={handleSave} className="ml-10">
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
        <MediaModal
          open={!!newMediaType}
          setOpen={() => setNewMediaType(null)}
          withVideoOption={newMediaType === 'carousel'}
          action="add"
          onSave={handleCarouselSave}
        />
      </Drawer>
    </>
  );
};

export default MediaSection;
