import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { TableActionStates } from 'enums/tableStates';
import { getLocalDateFromUTC, formatDate } from 'utils';
import { SEVERITY } from 'config';
import { MARKETING_WRITE, MARKETING_READ } from 'config/roles';
import { BANNERS_DELETE_SUCCESS, BANNERS_DELETE_ERROR } from 'config/messages';
import paths from 'routes/paths';

import { DELETE_TEXT_BANNER_SAGA } from 'store/content/actions';
import userSelectors from 'store/user/selectors';

import TableComponent from 'components/common/TableComponent';
import TableActions from 'components/common/TableActions';
import Action from 'components/common/TableActions/Actions';
import StatusIndicator from 'components/common/StatusIndicator';

import { tableConfig, TableCellStates } from './tableConfig';

const TextBannersTable = (props) => {
  const {
    rows,
    // From Redux
    userRoles,
    dispatch,
  } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const rowsWithKeys = rows.map((row) => ({ ...row, id: row.id }));
  const reviewOnly =
    userRoles.includes(MARKETING_READ) && !userRoles.includes(MARKETING_WRITE);

  const handleDelete = (record) => {
    dispatch({
      type: DELETE_TEXT_BANNER_SAGA,
      payload: {
        id: record.id,
        callback: () => {
          enqueueSnackbar(BANNERS_DELETE_SUCCESS, {
            variant: SEVERITY.SUCCESS,
          });
        },
        errorCallback: (error) => {
          enqueueSnackbar(`${BANNERS_DELETE_ERROR} : ${error}`, {
            variant: SEVERITY.ERROR,
          });
        },
      },
    });
  };

  const options = {
    isRowSelectable: false,
    selectionKey: 'id',
  };

  const renderActions = (cell, record) => {
    const canEdit = !reviewOnly;
    const canReview = reviewOnly;

    return cell.actions.map((action, index) => {
      switch (action.type) {
        case TableActionStates.DELETE:
          if (!canEdit) return null;
          return (
            <Action
              key={index}
              text={action.text}
              action={() => handleDelete(record)}
            />
          );
        case TableActionStates.EDIT:
          if (!canEdit) return null;
          return (
            <Action
              key={index}
              text={action.text}
              action={() => {
                history.push(`${paths.content.textBanners.edit}/${record.id}`);
              }}
            />
          );
        case TableActionStates.REVIEW:
          if (!canReview) return canReview;
          return (
            <Action
              key={index}
              text={action.text}
              action={() => {
                history.push(
                  `${paths.content.textBanners.review}/${record.id}`,
                );
              }}
            />
          );

        default:
          return cell;
      }
    });
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return (
            <TableActions key={`${i}-${index}`}>
              {renderActions(cell, record)}
            </TableActions>
          );
        };
        return cell;
      case TableCellStates.BANNER_NAME:
        cell.render = (record, index) => {
          return <span key={`${i}-${index}`}>{record.description}</span>;
        };
        return cell;
      case TableCellStates.BANNER_ID:
        cell.render = (record, index) => {
          return <span key={`${i}-${index}`}>{record.id}</span>;
        };
        return cell;
      case TableCellStates.STATUS:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              <StatusIndicator status={record.status} withLabel={true} />
            </span>
          );
        };
        return cell;
      case TableCellStates.START_DATE:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {formatDate(getLocalDateFromUTC(record.starts_on))}
            </span>
          );
        };
        return cell;
      case TableCellStates.END_DATE:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {formatDate(getLocalDateFromUTC(record.ends_on))}
            </span>
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  return (
    <TableComponent
      rows={rowsWithKeys}
      headCells={headCells}
      options={options}
    />
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(TextBannersTable);
