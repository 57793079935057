import React from 'react';
import { Button, Grid } from '@mui/material';
import { Field, Form } from 'react-final-form';
import TextInput from 'components/common/TextInput';
import { putAPIDataDifferentOrigin } from 'api/microservices';
import { useSnackbar } from 'notistack';
import { API } from 'axiosClient/config';
import { SEVERITY } from 'config';
import { SHORT_LINK_UPDATED_SUCCESS } from 'config/messages';

const ShortLinkUpdateForm = (props) => {
  const setUpdatedData = props.setUpdatedData;
  const shortLinkId = props.id;
  const shortLinkUpdateObj = props.shortLinkUpdateObj;
  const setShowConfirm = props.setShowOpen;
  const { enqueueSnackbar } = useSnackbar();
  const updateShortUrl = async (values, shortLinkId) => {
    try {
      let valueObject = values;
      valueObject.title = `[${values.tag}] ${values.title}`;
      const payload = {
        data: valueObject,
      };

      const response = await putAPIDataDifferentOrigin(
        API.microservices.shortLinks.update + `/${shortLinkId}`,
        payload,
      );
      setShowConfirm(false);

      if (!response || response.status > 208) {
        return (
          enqueueSnackbar(`Some error occurred`),
          {
            variant: SEVERITY.ERROR,
          }
        );
      }

      setUpdatedData(response.data.data);
      return enqueueSnackbar(SHORT_LINK_UPDATED_SUCCESS, {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      return (
        enqueueSnackbar(`Error occurred : ${e.message}`),
        {
          variant: SEVERITY.ERROR,
        }
      );
    }
  };

  const handleSubmit = (values, form) => {
    updateShortUrl(values, shortLinkId).then(() => {
      form.reset();
      form.resetFieldState('title');
      form.resetFieldState('tag');
      form.resetFieldState('alias');
    });
  };
  return (
    <div className="orders-filters-form">
      <Form
        initialValues={shortLinkUpdateObj}
        onSubmit={handleSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="title"
                  component={TextInput}
                  type="text"
                  label="Description"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="tag"
                  component={TextInput}
                  type="text"
                  label="JIRA Ticket"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  fullWidth
                  name="alias"
                  component={TextInput}
                  type="text"
                  label="URL alias"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  className="button"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Update Short Link
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </div>
  );
};

export default ShortLinkUpdateForm;
