import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { compose } from 'recompose';

import { ORDERS_READ, ORDERS_WRITE } from 'config/roles';
import generalSelectors from 'store/general/selectors';
import userSelectors from 'store/user/selectors';
import { GET_RETURNS } from 'store/orders/actions';

import PageContentLayout from 'components/layout/PageContentLayout';
import SetPageTitle from 'components/common/SetPageTitle';
import withRouterInfo from 'components/hoc/withRouterInfo';
import { useErrorStatus } from 'components/hoc/ErrorHandler';

import ReturnsTable from './ReturnsTable';
import { Button, CircularProgress } from '@mui/material';

function ReturnsListing({
  // From Redux
  dispatch,
  userRoles,
}) {
  const [cursor, setCursor] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [returns, setReturns] = useState([]);
  const { setErrorStatusCode } = useErrorStatus();
  const canView =
    userRoles.includes(ORDERS_READ) || userRoles.includes(ORDERS_WRITE);

  const fetchReturns = useCallback(
    (loadMore = false) => {
      if (loadMore) {
        setDisabled(true);
        dispatch({
          type: GET_RETURNS,
          payload: {
            callback: (returnsData) => {
              setReturns(returns.concat(returnsData.data));
              setCursor(returnsData.cursor ? returnsData.cursor : 'current');
              setDisabled(false);
            },

            cursor,
          },
        });
      } else {
        setLoading(true);
        dispatch({
          type: GET_RETURNS,
          payload: {
            callback: (returns) => {
              setLoading(false);
              setReturns(returns.data);
              setCursor(returns.cursor ? returns.cursor : 'current');
            },
            errorCallback: (error) => {
              setErrorStatusCode(error);
              setLoading(false);
            },
          },
        });
      }
    },
    [dispatch, cursor],
  );

  useEffect(() => {
    // Get the returns
    fetchReturns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMoreData = () => {
    fetchReturns(true);
  };
  return (
    <>
      <SetPageTitle pageTitle="Returns" />
      <PageContentLayout title="Returns" isLoading={loading}>
        <ReturnsTable
          rows={returns || []}
          isLoading={loading}
          canView={canView}
          disablePagination={true}
          rowCount={returns && returns.length}
        />
        <div className="load-more-btn">
          <div className="btn-flex">
            {cursor !== 'current' ? (
              <Button
                disabled={disabled}
                type="button"
                className="button"
                variant="contained"
                size="small"
                onClick={handleLoadMoreData}
                color="primary"
              >
                Load More
              </Button>
            ) : (
              <></>
            )}
            {disabled ? <CircularProgress size={30} /> : <></>}
          </div>
        </div>
      </PageContentLayout>
    </>
  );
}

const mapStateToProps = (state) => ({
  apiEnv: generalSelectors.getAPIEnv(state),
  userRoles: userSelectors.getUserRoles(state),
});

export default compose(withRouterInfo)(
  connect(mapStateToProps)(ReturnsListing),
);
