import React from 'react';
import { useSelector } from 'react-redux';
import './style.scss';

const ImagePreview = (props) => {
  const { assets } = useSelector((state) => state.assetsReducer);
  return (
    <div className="badges-preview">
      {assets[props.id] && <img src={assets[props.id]} alt={props.id} />}
    </div>
  );
};

export default ImagePreview;
