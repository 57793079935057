import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';

import TableComponent from 'components/common/TableComponent';

import { tableConfig } from './tableConfig';

import { percentToFraction, currencyFormat } from 'utils';

import './style.scss';

export default function PriceDetails(props) {
  const [priceDetails, setPriceDetails] = useState([]);
  const { handlePriceCancel, skuPriceDetails, shippingCountries } = props;
  const options = {
    pagination: false,
  };
  useEffect(() => {
    let details = [];
    shippingCountries.forEach((country) => {
      const countryKey = country.value;
      if (skuPriceDetails.prices[countryKey]) {
        const obj = { ...skuPriceDetails.prices[countryKey] };
        obj.percent_off = percentToFraction(skuPriceDetails.percent_off, false);
        obj.discountedPrice = currencyFormat(
          (obj.price / 100) * (1 - obj.percent_off / 100),
          obj.currency,
        );
        obj.price = currencyFormat(obj.price / 100, obj.currency);
        obj.country = countryKey;
        details.push(obj);
      }
    });
    setPriceDetails(details);
  }, [shippingCountries, skuPriceDetails]);

  return (
    <div className="price-details">
      {priceDetails.length > 0 && (
        <TableComponent
          rows={priceDetails}
          headCells={tableConfig}
          options={options}
        />
      )}
      <Button
        size="small"
        variant="outlined"
        type="submit"
        onClick={handlePriceCancel}
      >
        Cancel
      </Button>
    </div>
  );
}
