import { useState, useCallback } from 'react';
import { useForm } from 'react-final-form';
import { Grid, Typography } from '@mui/material';

import { getProductManagement } from 'api/products';
import { API } from 'axiosClient/config';
import normalizeTranslations from 'utils/normalizers/api/skus/normalizeTranslations';
import ExpandableSection from 'components/common/ExpandableSection';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';
import SectionFilters from 'components/containers/ProductsContainer/ProductView/components/SectionFilters';
import TabsContent from './TabsContent';

const PageContentSection = ({
  variant,
  inputBase: inputBaseProp,
  importFromSkusData,
}) => {
  const [isLoading, setIsLoading] = useState();
  const [website, setWebsite] = useState();
  const [language, setLanguage] = useState();
  const { mutators } = useForm();
  const inputBase = {
    ...inputBaseProp,
    disabled: isLoading,
  };

  const importContent = useCallback(
    async (id) => {
      setIsLoading(true);
      try {
        const translationsResponse = await getProductManagement(
          API.products.skus.getTranslations(id),
        );
        const normalizedContent = normalizeTranslations(
          translationsResponse.data.data,
        );
        mutators.importPageContent(normalizedContent);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [mutators],
  );

  const onFilterChange = useCallback(
    (type, value) => {
      if (type === 'website') {
        setWebsite(value);
      }
      if (type === 'language') {
        setLanguage(value);
      }
      if (type === 'import') {
        importContent(value);
      }
    },
    [setWebsite, setLanguage, importContent],
  );

  const renderContent = () => (
    <div>
      <SectionFilters
        filters={['import', 'website', 'language']}
        onChange={onFilterChange}
        importData={importFromSkusData}
      />
      <Grid container>
        <Grid item xs={7}>
          <Typography variant="h3" gutterBottom>
            General
          </Typography>
          <OrderPropertiesWrapper>
            <OrderPropertyInput
              label="One liner"
              input={{
                ...inputBase,
                name: `${language}.${website}.one_liner`,
              }}
            />
            <OrderPropertyInput
              label="Description"
              type="textarea"
              input={{
                ...inputBase,
                name: `${language}.${website}.top_blurb`,
              }}
            />
          </OrderPropertiesWrapper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            Tabs content
          </Typography>
          <TabsContent
            baseName={`${language}.${website}`}
            inputBase={inputBase}
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <ExpandableSection
      id={`content-${variant?.id || 'create'}`}
      heading={'Page content'}
      secondaryHeading="One liner, description, tabs"
      content={renderContent()}
      openByDefault={false}
    />
  );
};

export default PageContentSection;
