import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useSnackbar } from 'notistack';
import { Grid } from '@mui/material';

import { SEVERITY } from 'config';
import { getProfiles } from 'api/adminV2';
import SetPageTitle from 'components/common/SetPageTitle';
import PageContentLayout from 'components/layout/PageContentLayout';
import ProfilesSearchForm, {
  initialValues as formInitialValues,
} from './components/ProfilesSearchForm/ProfilesSearchForm';
import ProfilesTable from './components/ProfilesTable';
import { TableCellStates } from './components/ProfilesTable/tableConfig';

const normalizeProfiles = (profiles) =>
  profiles.map((profile) => ({
    ...profile,
    [TableCellStates.STATUS]: profile.active ? 'active' : 'deleted',
  }));

const fieldsNames = [
  {
    fieldName: 'profileID',
    filterName: 'profileID',
  },
  {
    fieldName: 'myOsmoUserID',
    filterName: 'myOsmoUserID',
  },
];

const ProfilesListing = () => {
  const title = 'Profiles';
  const [isLoading, setIsLoading] = useState(false);
  const [initialFiltersValue, setInitialFiltersValue] = useState({});
  const [rows, setRows] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const fetchProfiles = useCallback(
    async (filters) => {
      if (!filters || !Object.values(filters).find((val) => !!val)) {
        return null;
      }

      setIsLoading(true);

      try {
        const payload = {
          user_id: parseInt(filters.myOsmoUserID),
          profile_id: filters.profileID,
          limit: 20,
        };
        const response = await getProfiles(payload);

        if (!response?.data?.profiles || response.status > 208) {
          setRows([]);
          setIsLoading(false);
          return;
        }

        setRows(normalizeProfiles(response?.data?.profiles));
        setIsLoading(false);
      } catch (e) {
        enqueueSnackbar(e.response.data, {
          variant: SEVERITY.SUCCESS,
        });
        setIsLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    // Check if there is any parameters in the URL
    const urlParams = new URLSearchParams(location.search);

    const filtersFromUrl = fieldsNames.reduce(
      (acc, name) => ({
        ...acc,
        [name.filterName]: urlParams.get(name.filterName),
      }),
      {},
    );

    const initialValue = fieldsNames.reduce((acc, name) => {
      return {
        ...acc,
        [name.fieldName]:
          filtersFromUrl[name.filterName] || formInitialValues[name.fieldName],
      };
    }, {});

    setInitialFiltersValue(initialValue);

    // Get the orders based on the parameters
    if (urlParams.toString().length) {
      fetchProfiles(filtersFromUrl);
    }
  }, [fetchProfiles, location.search]);

  const onFiltersChange = (filters, isReset) => {
    if (!isReset) {
      // fetch a new batch of orders
      fetchProfiles(filters);
    } else {
      setRows([]);
    }
    // Update the URL params
    const queryParams = qs.parse(location.search);
    const newQueries = { ...queryParams, ...filters };
    history.push({ search: isReset ? null : qs.stringify(newQueries) });
  };

  return (
    <>
      <SetPageTitle pageTitle={title} />
      <PageContentLayout title={title} isLoading={isLoading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProfilesSearchForm
              onFiltersChange={onFiltersChange}
              initialValues={initialFiltersValue}
            />
          </Grid>
          {!!rows?.length && (
            <Grid item xs={12}>
              <ProfilesTable rows={rows} />
            </Grid>
          )}
        </Grid>
      </PageContentLayout>
    </>
  );
};

export default ProfilesListing;
