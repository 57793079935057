import React from 'react';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { USERS_WRITE, USERS_READ } from 'config/roles';
import { TableActionStates } from 'enums/tableStates';
import userSelectors from 'store/user/selectors';

import paths from 'routes/paths';

import TableComponent from 'components/common/TableComponent';
import TableActions from 'components/common/TableActions';
import Action from 'components/common/TableActions/Actions';

import { tableConfig, TableCellStates } from './tableConfig';

function UsersTable(props) {
  const { rows, userRoles } = props;
  const history = useHistory();
  const reviewOnly =
    userRoles.includes(USERS_READ) && !userRoles.includes(USERS_WRITE);

  const options = {
    rowsPerPage: 10,
  };

  const renderActions = (cell, record) => {
    return cell.actions.map((action, index) => {
      switch (action.type) {
        case TableActionStates.EDIT:
          return (
            !reviewOnly && (
              <Action
                key={index}
                text={action.text}
                action={() => {
                  history.push(`${paths.tools.permissions.edit}/${record.id}`);
                }}
              />
            )
          );
        case TableActionStates.REVIEW:
          return (
            reviewOnly && (
              <Action
                key={index}
                text={action.text}
                action={() => {
                  history.push(
                    `${paths.tools.permissions.review}/${record.id}`,
                  );
                }}
              />
            )
          );

        default:
          return cell;
      }
    });
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return (
            <TableActions key={i}>{renderActions(cell, record)}</TableActions>
          );
        };
        return cell;
      case TableCellStates.ID:
        cell.render = (record, index) => {
          return <span key={i}>{record.id}</span>;
        };
        return cell;
      case TableCellStates.FIRST_NAME:
        cell.render = (record, index) => {
          return <span key={i}>{record.first_name}</span>;
        };
        return cell;
      case TableCellStates.LAST_NAME:
        cell.render = (record, index) => {
          return <span key={i}>{record.last_name}</span>;
        };
        return cell;
      case TableCellStates.EMAIL:
        cell.render = (record, index) => {
          return <span key={i}>{record.email}</span>;
        };
        return cell;
      default:
        return cell;
    }
  });

  return <TableComponent rows={rows} headCells={headCells} options={options} />;
}

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(UsersTable);
