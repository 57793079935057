export const countries = {
  'All Countries': [],
  'North America': [
    { label: 'United States', value: 'us' },
    { label: 'Canada', value: 'cn' },
  ],
  Europe: [
    { label: 'France', value: 'fr' },
    { label: 'United Kingdom', value: 'uk' },
  ],
};

export const campaignType = ['global', 'url'];

export const campaignStateMapping = {
  EDIT: 'edit',
  CREATE: 'create',
  REVIEW: 'review',
};

export const envConfirmConfig = {
  title: 'Are you sure?',
  buttonGroup: [
    {
      text: 'Cancel',
      type: 'CANCEL',
    },
    {
      text: 'Confirm',
      type: 'CONFIRM',
    },
  ],
};
