const prefix = 'CREATE_CAMPAIGN_ACTIONS_';
export const FETCH_CAMPAIGN_DETAILS = `${prefix}FETCH_CAMPAIGN_DETAILS`;
export const CAMPAIGN_DETAILS_SUCCESS = `${prefix}CAMPAIGN_DETAILS_SUCCESS`;
export const EDIT_PRODUCT_DISCOUNT = `${prefix}EDIT_PRODUCT_DISCOUNT`;
export const SET_PRODUCT_DISCOUNT = `${prefix}SET_PRODUCT_DISCOUNT`;
export const DELETE_PRODUCT_DISCOUNT = `${prefix}DELETE_PRODUCT_DISCOUNT`;
export const SAVE_CAMPAIGN_DETAILS = `${prefix}SAVE_CAMPAIGN_DETAILS`;
export const RESET_CAMPAIGN_DETAILS = `${prefix}RESET_CAMPAIGN_DETAILS`;
export const SAVE_CAMPAIGN = `${prefix}SAVE_CAMPAIGN`;
export const LOAD_CAMPAIGN_ASSETS = `${prefix}LOAD_CAMPAIGN_ASSETS`;
export const SAVE_CAMPAIGN_ASSETS = `${prefix}SAVE_CAMPAIGN_ASSETS`;
export const SET_BANNERS_HEADERS = `${prefix}SET_BANNERS_HEADERS`;
export const EDIT_CAMPAIGN_DETAILS = `${prefix}EDIT_CAMPAIGN_DETAILS`;
export const UPLOAD_FILES = `${prefix}UPLOAD_FILES`;
export const DELETE_FILES = `${prefix}DELETE_FILES`;
export const DELETE_CAMPAIGN_ASSETS = `${prefix}DELETE_CAMPAIGN_ASSETS`;
export const BULK_DELETE_FILES = `${prefix}BULK_DELETE_FILES`;
export const BULK_DELETE_CAMPAIGN_ASSETS = `${prefix}BULK_DELETE_CAMPAIGN_ASSETS`;
export const CLEANUP_ASSETS = `${prefix}CLEANUP_ASSETS`;
export const CLEANUP_ASSET = `${prefix}CLEANUP_ASSET`;
export const CLEAN_DELETED_ASSETS = `${prefix}CLEAN_DELETED_ASSETS`;
export const CLEAN_ADDED_ASSETS = `${prefix}CLEAN_ADDED_ASSETS`;
export const PUBLISH_BULK_FILE = `${prefix}PUBLISH_BULK_FILE`;
export const SET_CONTENT_HEADING_DATA = `${prefix}SET_CONTENT_HEADING_DATA`;
export const EDIT_CONTENT_HEADING_DATA = `${prefix}EDIT_CONTENT_HEADING_DATA`;
export const DELETE_CONTENT_HEADING_DATA = `${prefix}DELETE_CONTENT_HEADING_DATA`;
export const CANCEL_CONTENT_SECTION_DATA = `${prefix}CANCEL_CONTENT_SECTION_DATA`;
export const SUBMIT_CONTENT_SECTION_DATA = `${prefix}SUBMIT_CONTENT_SECTION_DATA`;
export const DELETE_SECTION_HEADING_DATA = `${prefix}DELETE_SECTION_HEADING_DATA`;
export const SET_BANNER_DATA = `${prefix}SET_BANNER_DATA`;
export const EDIT_BANNER_DATA = `${prefix}EDIT_BANNER_DATA`;
