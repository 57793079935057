import { Form } from 'react-final-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState, useEffect, useCallback, useMemo } from 'react';
import formArrayMutators from 'final-form-arrays';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SEVERITY } from 'config';
import { PLAYOSMO_PRODUCTS_WRITE } from 'config/roles';
import { normalizeGamesResponse } from 'utils';
import {
  deleteProductManagement,
  getProductManagement,
  postProductManagement,
  putProductManagement,
} from 'api/products';
import paths from 'routes/paths';
import { API } from 'axiosClient/config';
import userSelectors from 'store/user/selectors';
import { FETCH_REFERENCES } from 'store/general/actions';

import SetPageTitle from 'components/common/SetPageTitle';
import Confirmation from 'components/common/Confirmation';
import PageFormLayout from 'components/layout/PageFormLayout';
import GeneralSection from './components/GeneralSection';
import AppSection from './components/AppSection';
import DemoSection from './components/DemoSection';
import GameManualsSection from './components/GameManualsSection';
import ModalComponent from 'components/common/ModalComponent';
import DuplicateObjectForm from 'components/common/DuplicateObjectForm';
import { envConfirmConfig } from 'components/containers/CampaignContainer/CreateCampaign/config';

const GameView = ({
  match,
  type = 'view',
  // From Redux
  userRoles,
  userIsDeveloper,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showConfirmDuplicateModal, setShowConfirmDuplicateModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [game, setGame] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const canEdit = userRoles && userRoles.includes(PLAYOSMO_PRODUCTS_WRITE);
  const isCreationMode = type === 'create';
  const getTitle = () =>
    isCreationMode
      ? 'Create a new game'
      : isLoading || !game
      ? 'Loading...'
      : canEdit
      ? `Editing: ${game.name}`
      : `Viewing: ${game.name}`;

  const gameId = match.params.id;

  const inputBase = {
    disabled: !canEdit,
  };

  const initialValues = {
    id: game?.id || '',
    name: game?.name || '',
    skills: game?.skills,
    platforms: game?.platforms,
    manuals: game?.manuals.reduce((acc, manual) => {
      const newArr = [
        ...acc,
        {
          url: manual.url,
          language: manual.language,
        },
      ];
      return newArr;
    }, []),
    demo_cloudflare_id: game?.demoCloudflareId || '',
    demo_still_file: game?.demoStillUrl
      ? {
          public_url: game?.demoStillUrl,
        }
      : null,
    is_fire_proflector_compatible: game?.isFireProflectorCompatible || false,
    is_fire8_compatible: game?.isFire8Compatible || false,
    ios_app_link: game?.iosAppLink,
    fire_app_link: game?.fireAppLink,
    app_icon: game?.appIcon
      ? {
          public_url: game?.appIcon,
        }
      : null,
  };

  const fetchGame = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const response = await getProductManagement(
        `${API.products.games}/${id}`,
      );
      const normalizedGames = normalizeGamesResponse([response.data.data])[0];
      setGame(normalizedGames);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: FETCH_REFERENCES,
      payload: {
        types: ['game_skill', 'platform'],
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (gameId) {
      fetchGame(gameId);
    }
  }, [gameId, fetchGame]);

  const onSubmit = async (values) => {
    const medias = [];
    if (values.app_icon?.public_url) {
      medias.push({
        action: game?.appIcon ? 'update' : 'create',
        media_type: 'app_icon',
        mime_type: 'image',
        url: values.app_icon?.public_url,
      });
    }
    if (values.fire_app_link) {
      medias.push({
        action: game?.fireAppLink ? 'update' : 'create',
        media_type: 'store_url',
        key: 'fire',
        mime_type: 'link',
        url: values.fire_app_link,
      });
    }
    if (values.ios_app_link) {
      medias.push({
        action: game?.iosAppLink ? 'update' : 'create',
        media_type: 'store_url',
        key: 'ios',
        mime_type: 'link',
        url: values.ios_app_link,
      });
    }

    const data = {
      game_id: values.id.toLowerCase(),
      name: values.name,
      is_ready: true,
      skills: values.skills,
      platforms_availability: values.platforms,
      is_fire8_compatible: values.is_fire8_compatible,
      is_fire_proflector_incompatible: !values.is_fire_proflector_compatible,
      demo_cloudflare_id: values.demo_cloudflare_id,
      demo_still_url: values.demo_still_file?.public_url,
      manuals: values.manuals,
      medias,
    };

    if (isCreationMode) {
      try {
        await postProductManagement(API.products.games, data);
        history.push(paths.eCommerceConfiguration.games.list);
        enqueueSnackbar('Game created successfully', {
          variant: SEVERITY.SUCCESS,
        });
      } catch (e) {
        enqueueSnackbar(
          e?.response?.data?.error?.message || 'An error occurred',
          {
            variant: SEVERITY.ERROR,
          },
        );
        throw e;
      }
    } else {
      try {
        await putProductManagement(`${API.products.games}/${gameId}`, data);
        history.push(paths.eCommerceConfiguration.games.list);
        enqueueSnackbar('Game updated successfully', {
          variant: SEVERITY.SUCCESS,
        });
      } catch (e) {
        enqueueSnackbar(
          e?.response?.data?.error?.message || 'An error occurred',
          {
            variant: SEVERITY.ERROR,
          },
        );
        throw e;
      }
    }
  };

  const onCancel = () => {
    history.push(paths.eCommerceConfiguration.games.list);
  };

  const handleEnvCancel = () => {
    setShowDuplicateModal(false);
  };

  const handleConfirmCancel = () => {
    setShowConfirmDuplicateModal(false);
  };

  const handleEnvSubmit = () => {
    setShowDuplicateModal(false);
    setShowConfirmDuplicateModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteProductManagement(`${API.products.games}/${gameId}`);
      history.push(paths.eCommerceConfiguration.games.list);
      enqueueSnackbar('Game deleted successfully', {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      enqueueSnackbar(
        e?.response?.data?.error?.message || 'An error occurred',
        {
          variant: SEVERITY.ERROR,
        },
      );
      throw e;
    }
    setShowDeleteModal(false);
  };

  const getActionButtons = useCallback(() => {
    if (isCreationMode) return null;
    const buttons = [];
    // TODO: https://playosmo.atlassian.net/browse/ADMIN-366
    // const buttons = [
    //   {
    //     label: 'Duplicate to...',
    //     onClick: () => setShowDuplicateModal(true),
    //   },
    // ];

    if (userIsDeveloper) {
      buttons.push({
        label: 'Delete',
        onClick: () => setShowDeleteModal(true),
      });
    }

    return buttons;
  }, [userIsDeveloper, isCreationMode]);

  const deleteConfirmConfig = {
    title: 'Are you sure you want to delete this game?',
    buttonGroup: [
      {
        text: 'Cancel',
        type: 'CANCEL',
        handler: () => setShowDeleteModal(false),
      },
      {
        text: 'Confirm',
        type: 'CONFIRM',
        handler: handleDelete,
      },
    ],
  };

  const mutators = useMemo(
    () => ({
      ...formArrayMutators,
    }),
    [],
  );

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        mutators={mutators}
        showProductionWarning={canEdit}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <PageFormLayout
              title={getTitle()}
              isLoading={isLoading}
              breadcrumbs={[
                {
                  label: 'Games',
                  link: paths.eCommerceConfiguration.games.list,
                },
                {
                  label: getTitle(),
                  isCurrent: true,
                },
              ]}
              handleCancel={onCancel}
              showSubmitButtons={true}
              buttons={canEdit ? getActionButtons() : null}
            >
              <Grid container spacing={3} alignItems="flex-start">
                <Grid container item xs={12} sm={6}>
                  <Grid item xs={12}>
                    <GeneralSection game={game} inputBase={inputBase} />
                  </Grid>
                  <Grid item xs={12}>
                    <AppSection game={game} inputBase={inputBase} />
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={6}>
                  <Grid item xs={12}>
                    <DemoSection
                      game={game}
                      canEdit={canEdit}
                      inputBase={inputBase}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <GameManualsSection game={game} inputBase={inputBase} />
                  </Grid>
                </Grid>
              </Grid>
            </PageFormLayout>
          </form>
        )}
      />
      <ModalComponent
        title="Duplicate"
        open={showDuplicateModal}
        handleClose={handleEnvCancel}
      >
        <DuplicateObjectForm
          handleEnvCancel={handleEnvCancel}
          handleEnvSubmit={handleEnvSubmit}
        />
      </ModalComponent>
      <ModalComponent
        title="Confirm"
        open={showConfirmDuplicateModal}
        handleClose={handleConfirmCancel}
      >
        <Confirmation envConfirmConfig={envConfirmConfig} />
      </ModalComponent>
      <ModalComponent
        title="Confirm deletion"
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
      >
        <Confirmation envConfirmConfig={deleteConfirmConfig} />
      </ModalComponent>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
  userIsDeveloper: userSelectors.getUserIsDeveloper(state),
});

export default connect(mapStateToProps)(GameView);
