import { useEffect, useState, useCallback, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Form } from 'react-final-form';
import formArrayMutators from 'final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { Button, Grid } from '@mui/material';

import { getProductManagement } from 'api/products';
import { API } from 'axiosClient/config';
import { PLAYOSMO_PRODUCTS_WRITE } from 'config/roles';
import paths from 'routes/paths';
import normalizeSkus from 'utils/normalizers/api/normalizeSkus';
import normalizeInventory from 'utils/normalizers/api/skus/normalizeInventory';
import normalizePrices from 'utils/normalizers/api/skus/normalizePrices';
import normalizeTranslations from 'utils/normalizers/api/skus/normalizeTranslations';
import { FETCH_REFERENCES, FETCH_SKU_DETAILS } from 'store/general/actions';
import userSelectors from 'store/user/selectors';
import productsSelectors from 'store/products/selectors';
import {
  ADD_EDITING_PRODUCT_VARIANT,
  UPDATE_EDITING_PRODUCT_VARIANT,
} from 'store/products/actions';
import SetPageTitle from 'components/common/SetPageTitle';
import OrderPageLayout from 'components/layout/OrderPageLayout';
import GeneralSection from './components/GeneralSection';
import DetailsSection from './components/DetailsSection';
import PricingSection from './components/PricingSection';
import InventorySection from './components/InventorySection';
import PageContentSection from './components/PageContentSection';
import AdditionalContentSection from './components/AdditionalContentSection';
import MediaSection from './components/MediaSection';

const VariantView = ({
  match,
  type = 'view',
  // From Redux
  userRoles,
  editingProductVariants,
}) => {
  const skuKey = match.params.key;
  const productKey = match.params.productKey;
  const initialVariantFromStore = editingProductVariants?.find(
    (variant) => !!variant.action && variant.id === skuKey,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [variant, setVariant] = useState(initialVariantFromStore);
  const [productSkus, setProductSkus] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const isCreationMode = type === 'create';
  const isProductCreationMode = queryParams?.isProductNew === 'true';
  const canEdit = userRoles?.includes(PLAYOSMO_PRODUCTS_WRITE);
  const inputBase = {
    disabled: !canEdit,
  };
  const importFromSkusData = (
    (!!productSkus?.length && productSkus) ||
    editingProductVariants
  )?.map((sku) => ({
    id: sku.id,
    value: sku.id,
  }));

  const initialValues = {
    sku: variant?.sku,
    sku_key: variant?.skuKey,
    name: variant?.name,
    note: variant?.note,
    is_invisible: variant?.isInvisible || false,
    is_deprecated: variant?.isDeprecated || false,
    is_ready: variant?.isReady || false,
    type: variant?.type,
    is_subscription: variant?.isSubscription || false,
    is_frontend_active: variant?.isFrontendActive || false,
    subscription_type: variant?.subscriptionType,
    subscription_frequency: variant?.subscriptionFrequency,
    is_proflector_included: variant?.isProflectorIncluded || false,
    physical_sku_keys: variant?.physicalSkuKeys,
    bases_included: variant?.basesIncluded,
    storages_included: variant?.storageIncluded,
    compliance_doc: variant?.complianceDoc
      ? {
          public_url: variant?.complianceDoc,
        }
      : null,
    compliance_render: variant?.complianceRender
      ? {
          public_url: variant?.complianceRender,
        }
      : null,
    external_provider_code: variant?.externalProviderCode,
    external_provider_url: variant?.externalProviderUrl,
    order_limit: variant?.orderLimit,
    warehouse_codes: variant?.warehouses,
    inventory: variant?.inventory,
    pricing: variant?.pricing,
    medias: variant?.medias,
    ...(initialVariantFromStore
      ? variant?.translations
      : normalizeTranslations(variant?.translations) || {}),
  };

  const fetchVariant = useCallback(async (id) => {
    setIsLoading(true);
    let variantResponse,
      translationsResponse,
      inventoryResponse,
      pricesResponse;
    try {
      if (!variant?.action) {
        variantResponse = await getProductManagement(API.products.skus.get(id));
      }
      if (!variant?.translations) {
        translationsResponse = await getProductManagement(
          API.products.skus.getTranslations(id),
        );
      }
      if (!variant?.inventory) {
        inventoryResponse = await getProductManagement(
          API.products.skus.getInventory(id),
        );
      }
      if (!variant?.pricing) {
        pricesResponse = await getProductManagement(
          API.products.skus.getPricing(id),
        );
      }
      const normalizedSku = {
        ...(variant ||
          (variantResponse && normalizeSkus([variantResponse.data.data])[0]) ||
          {}),
        translations:
          variant?.translations ||
          (translationsResponse && translationsResponse.data.data),
        inventory:
          variant?.inventory ||
          (inventoryResponse &&
            normalizeInventory(inventoryResponse.data.data)),
        pricing:
          variant?.pricing ||
          (pricesResponse && normalizePrices(pricesResponse.data.data)),
      };
      setVariant(normalizedSku);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
    // eslint-disable-next-line
  }, []);

  const fetchProductSkus = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const variantsResponse = await getProductManagement(
        API.products.skus.list(id),
      );
      const normalizedSkus = normalizeSkus(variantsResponse.data.data);
      setProductSkus(normalizedSkus);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }, []);

  useEffect(() => {
    if (skuKey) {
      fetchVariant(skuKey);
    }
  }, [skuKey, fetchVariant]);

  useEffect(() => {
    if (productKey && !editingProductVariants) {
      fetchProductSkus(productKey);
    }
  }, [productKey, fetchProductSkus, editingProductVariants]);

  useEffect(() => {
    // The values fetched by this saga are used in child components
    dispatch({
      type: FETCH_REFERENCES,
      payload: {
        types: [
          'subscription_type',
          'subscription_frequency',
          'platform',
          'currency',
          'storage',
          'base',
        ],
      },
    });
    dispatch({
      type: FETCH_SKU_DETAILS,
      payload: {
        callback: () => {},
        errorCallback: (error) => {},
      },
    });
  }, [dispatch]);

  const getTitle = () =>
    isCreationMode
      ? 'Create a new variant'
      : isLoading || !variant
      ? 'Loading...'
      : canEdit
      ? `Editing: ${variant?.name}`
      : `Viewing: ${variant?.name}`;

  const getActionButtons = () => {
    if (isCreationMode) return null;
    const buttons = [];

    buttons.push({
      label: 'Save',
      type: 'submit',
      color: 'primary',
    });

    return buttons;
  };

  const onSubmit = (values) => {
    const translations = Object.entries(values).reduce((acc, [key, value]) => {
      // At the moment, only the language keys have a - in them
      // TODO: look through a list of predefined language codes (we don't have that list yet)
      if (key.includes('-')) {
        return {
          ...acc,
          [key]: value,
        };
      }
      return acc;
    }, {});
    const websites = values.inventory?.reduce((acc, country) => {
      const websites = [];
      Object.keys(country.values).forEach((website) => {
        if (!acc.includes(country.website)) {
          websites.push(website);
        }
      });
      return [...acc, ...websites];
    }, []);
    const normalizedSku = {
      ...normalizeSkus([values])[0],
      sku: values.sku,
      translations,
      complianceDoc: values.compliance_doc?.public_url,
      complianceRender: values.compliance_render?.public_url,
      inventory: values.inventory,
      pricing: values.pricing,
      medias: values.medias || variant?.medias,
      render: values?.medias?.render?.render?.url,
      websites: [...new Set(websites)],
    };
    const payload = {
      action: isCreationMode ? 'create' : 'update', //TODO: delete?
      ...normalizedSku,
    };
    dispatch({
      type: isCreationMode
        ? ADD_EDITING_PRODUCT_VARIANT
        : UPDATE_EDITING_PRODUCT_VARIANT,
      payload: {
        variant: payload,
      },
    });
    if (isProductCreationMode) {
      history.push(`${paths.catalog.products.create}`);
    } else {
      history.push(`${paths.catalog.products.view}/${productKey}`);
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  const resetPricingWhenSubscriptionValueChanges = (
    [name],
    state,
    { changeValue },
  ) => {
    const subscriptionTypeValue = state.formState.values.subscription_type;
    const pricingValue = state.formState.values.pricing;
    const newPricing = pricingValue.map((price) => {
      if (subscriptionTypeValue === 'recurring') {
        return {
          ...price,
          msrp_value: 0,
          selling_price: 0,
        };
      }

      return {
        ...price,
        recurring_price: 0,
      };
    });

    changeValue(state, 'pricing', () => newPricing);
  };

  const importPricing = ([pricing], state, { changeValue }) => {
    changeValue(state, 'pricing', () => pricing);
  };

  const importPageContent = ([content], state, { changeValue }) => {
    Object.entries(content).forEach(([key, value]) => {
      changeValue(state, key, () => value);
    });
  };

  const mutators = useMemo(
    () => ({
      ...formArrayMutators,
      resetPricingWhenSubscriptionValueChanges,
      importPricing,
      importPageContent,
    }),
    [],
  );

  return (
    <>
      <SetPageTitle pageTitle={getTitle()} />
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        mutators={mutators}
        showProductionWarning={canEdit}
        render={({ form, handleSubmit, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <OrderPageLayout
              title={getTitle()}
              status={variant?.status}
              isLoading={!isCreationMode && isLoading}
              handleClickBack={onCancel}
              buttons={canEdit ? getActionButtons() : null}
            >
              <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={7}>
                  <GeneralSection inputBase={inputBase} />
                </Grid>
                <Grid item xs={5}>
                  <MediaSection
                    variant={variant}
                    inputBase={inputBase}
                    values={values}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DetailsSection
                    variant={variant}
                    inputBase={inputBase}
                    values={values}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PricingSection
                    variant={variant}
                    inputBase={inputBase}
                    values={values}
                    importFromSkusData={importFromSkusData}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InventorySection variant={variant} inputBase={inputBase} />
                </Grid>
                <Grid item xs={12}>
                  <PageContentSection
                    variant={variant}
                    inputBase={inputBase}
                    importFromSkusData={importFromSkusData}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AdditionalContentSection
                    variant={variant}
                    inputBase={inputBase}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                  <Button type="submit" color="primary">
                    {isCreationMode ? 'Add variant' : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            </OrderPageLayout>

            <OnChange name="subscription_type">
              {(value, previous) => {
                form.mutators.resetPricingWhenSubscriptionValueChanges();
              }}
            </OnChange>
          </form>
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
  editingProductVariants: productsSelectors.getEditingProductVariants(state),
});

export default connect(mapStateToProps)(VariantView);
