import { useState } from 'react';
import { Button, Grid, Link, Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import paths from 'routes/paths';
import { deleteProfile } from 'api/adminV2';
import { SEVERITY } from 'config';
import ModalComponent from 'components/common/ModalComponent';
import OrderProperty from 'components/containers/OrdersContainer/OrderView/components/OrderProperty';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import { envConfirmConfig } from 'components/containers/CampaignContainer/CreateCampaign/config';
import Confirmation from 'components/common/Confirmation';

const ProfileSection = ({ profile, user, canEdit, updateProfile }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteProfile = async () => {
    try {
      const payload = {
        action: profile?.active ? 'delete' : 'undelete',
        profiles: [profile.id],
      };
      await deleteProfile(payload);
      updateProfile({ ...profile, active: !profile.active });
      enqueueSnackbar('Profile updated', {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      enqueueSnackbar(e?.response?.data, {
        variant: SEVERITY.ERROR,
      });
      throw e;
    }
    setShowDeleteConfirmation(false);
  };

  const confirmConfig = {
    ...envConfirmConfig,
    title: `Are you sure you want to ${
      profile?.active ? '' : 'un'
    }delete this profile?`,
  };
  const confirmButton = envConfirmConfig.buttonGroup.map((button, i) => {
    switch (button.type) {
      case 'CANCEL':
        button.handler = () => setShowDeleteConfirmation(false);
        return button;
      case 'CONFIRM':
        button.handler = handleDeleteProfile;
        return button;
      default:
        return button;
    }
  });
  confirmConfig.buttonGroup = confirmButton;

  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        Profile
      </Typography>
      <Grid container>
        <Grid item xs={10} sm={9}>
          <OrderPropertiesWrapper>
            <OrderProperty label="Name" value={profile?.name} />
            <OrderProperty
              label="User"
              value={user?.username}
              component={
                <Link href={`${paths.users.accounts.edit}/${user?.user_id}`}>
                  {user?.username}
                </Link>
              }
            />
            <OrderProperty label="User email" value={user?.email} />
            <OrderProperty label="Created on" value={profile?.created_on} />
            <OrderProperty label="Updated on" value={profile?.updated_on} />
            <OrderProperty label="Deleted on" value={profile?.deleted_on} />
          </OrderPropertiesWrapper>
        </Grid>
        <Grid item xs={2} sm={3}>
          <div>
            {profile?.id && (
              <img
                src={`https://my.playosmo.com/api/v4/profiles/${profile?.id}/avatar`}
                width={175}
                height={175}
                alt=""
              />
            )}
          </div>
          {canEdit && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => setShowDeleteConfirmation(true)}
            >
              {profile?.active
                ? 'Delete this profile'
                : 'Undelete this profile'}
            </Button>
          )}
        </Grid>
      </Grid>
      <ModalComponent
        title="Confirm"
        open={showDeleteConfirmation}
        handleClose={() => setShowDeleteConfirmation(false)}
      >
        <Confirmation envConfirmConfig={confirmConfig} />
      </ModalComponent>
    </Paper>
  );
};

export default ProfileSection;
