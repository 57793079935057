import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button } from '@mui/material';

import { TableActionStates } from 'enums/tableStates';

import { tableConfig, TableCellStates } from './tableConfig';

import { DELETE_CONTENT_HEADING_DATA } from '../../store/actions';

import EmptyStateMessage from 'components/common/EmptyStateMessage';
import Action from 'components/common/TableActions/Actions';
import TableComponent from 'components/common/TableComponent';
import TableActions from 'components/common/TableActions';

import './style.scss';

const ListContentHeading = (props) => {
  const {
    handleEditList,
    handleAddMore,
    handleAddDefault,
    listData,
    sectionId,
  } = props;
  const [selectedRows, setSelectedRows] = useState([]);

  const dispatch = useDispatch();

  const onRowSelectChange = (selectedRow) => {
    setSelectedRows(selectedRow);
  };

  const handleEdit = (record) => {
    handleEditList(record);
  };

  const handleDelete = (record) => {
    const filteredRecord = listData.filter((item) => item.id !== record.id);
    dispatch({
      type: DELETE_CONTENT_HEADING_DATA,
      payload: {
        contentData: filteredRecord,
        sectionId,
      },
    });
  };

  const options = {
    selectedRows,
    onRowSelectChange: onRowSelectChange,
    selectionKey: 'id',
    rowsPerPage: 5,
  };

  const renderActions = (cell, record) => {
    return cell.actions.map((action) => {
      switch (action.type) {
        case TableActionStates.EDIT:
          return (
            <Action text={action.text} action={() => handleEdit(record)} />
          );

        case TableActionStates.DELETE:
          return (
            <Action text={action.text} action={() => handleDelete(record)} />
          );
        default:
          return null;
      }
    });
  };

  const headCells = tableConfig.map((cell) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return <TableActions>{renderActions(cell, record)}</TableActions>;
        };
        return cell;
      default:
        return cell;
    }
  });

  return (
    <>
      <Grid item xs={12}>
        {!listData.length && (
          <>
            <EmptyStateMessage message="No featured page heading for now" />
            <div className="discount-add">
              <Button
                className="button"
                variant="contained"
                onClick={handleAddDefault}
              >
                Add a heading
              </Button>
            </div>
          </>
        )}
        {listData.length > 0 && (
          <>
            <Grid item xs={12}>
              <TableComponent
                rows={listData}
                options={options}
                headCells={headCells}
              />
            </Grid>
            <div className="discount-add">
              <Button
                className="button"
                variant="contained"
                onClick={handleAddMore}
              >
                Add More
              </Button>
            </div>
          </>
        )}
      </Grid>
    </>
  );
};

export default React.memo(ListContentHeading);
