import { Field } from 'react-final-form';
import { Grid, Typography } from '@mui/material';
import ExpandableSection from 'components/common/ExpandableSection';
import FileInputForm from 'components/common/FileInput/FileInputForm';

const AdditionalContentSection = ({ variant, inputBase }) => {
  const handleIconLoad = () => {};
  const handleError = () => {};

  const renderContent = () => (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h3" gutterBottom className="mt-10">
          Compliance doc
        </Typography>
        <Field
          label="Compliance document"
          service="products-management"
          name="compliance_doc"
          component={FileInputForm}
          inputType="input"
          deleteFromServer={false}
          onError={handleError}
          onLoad={handleIconLoad}
          dropZoneProps={{
            accept: 'application/pdf',
          }}
          tags={{
            type: 'compliance-doc',
            sku: variant?.sku,
            sku_key: variant?.skuKey,
          }}
        />
        <Field
          label="Compliance render"
          service="products-management"
          name="compliance_render"
          component={FileInputForm}
          inputType="input"
          deleteFromServer={false}
          onError={handleError}
          onLoad={handleIconLoad}
          tags={{
            type: 'compliance-render',
            sku: variant?.sku,
            sku_key: variant?.skuKey,
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <ExpandableSection
      id={`add-content-${variant?.id || 'create'}`}
      heading={'Additional content'}
      secondaryHeading="Compliance"
      content={renderContent()}
      openByDefault={false}
    />
  );
};

export default AdditionalContentSection;
