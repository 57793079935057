import { connect } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Grid } from '@mui/material';

import { SEVERITY } from 'config';
import { IP_WHITELIST_WRITE } from 'config/roles';
import userSelectors from 'store/user/selectors';
import { getIPWhitelists, postIPWhitelist } from 'api/ipProtection';
import PageContentLayout from 'components/layout/PageContentLayout';
import ModalComponent from 'components/common/ModalComponent';
import SearchForm from './components/SearchForm';
import IPAddressForm from './components/IPAddressForm';
import IPAddressesTable from './components/IPAddressesTable';

const normalizeIPAddresses = (addresses) =>
  addresses.map((address) => ({
    ...address,
    ipAddress: address.ip,
  }));

const IPWhitelistContainer = ({ userRoles }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [searchValue, setSearchValue] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const canEdit = userRoles?.includes(IP_WHITELIST_WRITE);

  const fetchIPs = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await getIPWhitelists();

      if (!response?.data?.data || response.status > 208) {
        setIsLoading(false);
        return;
      }

      const data = normalizeIPAddresses(response?.data?.data);
      setAddresses(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, []);

  const onSearchChange = (values) => {
    setSearchValue(values.ipWhitelist);
  };
  const onAddIPButtonClick = () => {
    setShowModal(true);
  };

  const onSubmitAddIPAddress = async (values) => {
    try {
      const response = await postIPWhitelist({
        ip: values.ip_address,
        project: values.project,
        reason: values.reason,
      });

      if (response.status > 208) {
        setIsLoading(false);
        return;
      }

      fetchIPs();
      setIsLoading(false);
      setShowModal(false);
      enqueueSnackbar('IP address successfully added', {
        variant: SEVERITY.SUCCESS,
      });
    } catch (e) {
      console.warn({ e });
      enqueueSnackbar(e.response.data?.error?.message, {
        variant: SEVERITY.ERROR,
      });
      setIsLoading(false);
    }
  };

  const onCancelAddIPAddress = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchIPs();
  }, [fetchIPs]);

  const displayedAddresses = addresses.filter(
    (address) => !searchValue || address.ipAddress.includes(searchValue),
  );

  return (
    <PageContentLayout
      title="IP whitelist"
      isLoading={isLoading}
      buttons={
        canEdit
          ? [
              {
                label: 'Add an IP address',
                onClick: onAddIPButtonClick,
              },
            ]
          : null
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <SearchForm onFiltersChange={onSearchChange} />
        </Grid>
        {!!displayedAddresses?.length && (
          <Grid item xs={12} className="mt-20">
            <IPAddressesTable
              addresses={displayedAddresses}
              refreshData={fetchIPs}
              canEdit={canEdit}
            />
          </Grid>
        )}
      </Grid>
      <ModalComponent
        title="Whitelist an IP address"
        open={showModal}
        handleClose={() => setShowModal(false)}
      >
        <IPAddressForm
          onSubmit={onSubmitAddIPAddress}
          onCancel={onCancelAddIPAddress}
        />
      </ModalComponent>
    </PageContentLayout>
  );
};

const mapStateToProps = (state) => ({
  userRoles: userSelectors.getUserRoles(state),
});

export default connect(mapStateToProps)(IPWhitelistContainer);
