import React from 'react';

import { Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';

const ToolTip = (props) => {
  return (
    <Tooltip disableInteractive {...props} arrow>
      <Info className="info-ico" fontSize="small" color="secondary" />
    </Tooltip>
  );
};

export default ToolTip;
