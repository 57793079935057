export const TableCellStates = {
  ORDER_ID: 'orderId',
  LABEL_URL: 'returnLabelUrl',
  CREATED_ON: 'createdOn',
  RECEIVED_ON: 'receivedOn',
  STATUS: 'status',
  VIEW: 'view',
  RMA_ID: 'id',
};

export const tableConfig = [
  {
    title: 'ID',
    id: TableCellStates.RMA_ID,
  },
  {
    title: 'Order ID',
    id: TableCellStates.ORDER_ID,
  },
  {
    title: 'Status',
    id: TableCellStates.STATUS,
  },
  {
    title: 'Created on',
    id: TableCellStates.CREATED_ON,
  },
  {
    title: 'Received on',
    id: TableCellStates.RECEIVED_ON,
  },
  {
    title: '',
    id: TableCellStates.VIEW,
  },
];
