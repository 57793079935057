import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Button, Grid } from '@mui/material';
import Countdown from 'react-countdown';
import TextInput from 'components/common/TextInput';
import { VERIFY_OTP } from 'store/auth/actions';
import { INVALID_OTP } from 'config/messages';

import './styles.scss';
import { OTP_EXPIRY_TIME_SECONDS, MAX_NUM_INPUTS } from 'config';
import OtpInput from 'components/common/OtpInput';
import DisplayError from 'components/common/DisplayError';

const VerificationForm = ({ email, setIsOtpFormVisible, requestOtp }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  const [otpError, setOtpError] = useState('');

  const dispatch = useDispatch();

  const [otpTimer, setOtpTimer] = useState(
    Date.now() + OTP_EXPIRY_TIME_SECONDS * 1000,
  );

  const verifyOTP = (values) => {
    setIsSubmitting(true);
    if (isValid(values)) {
      setOtpError('');

      dispatch({
        type: VERIFY_OTP,
        payload: {
          data: {
            email: email,
            code: values.otp,
          },
          callback: () => {
            setIsSubmitting(false);
            history.push('/');
          },
          errorCallback: (error) => {
            setIsSubmitting(false);
            setOtpError(error);
          },
        },
      });
    }
  };

  const cancelOTP = () => {
    setIsOtpFormVisible(false);
  };

  const resetOtpTimer = () =>
    setOtpTimer(Date.now() + OTP_EXPIRY_TIME_SECONDS * 1000);

  const renderResendOtp = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Button
          variant="contained"
          color="primary"
          className="link"
          onClick={() => {
            requestOtp();
            resetOtpTimer();
          }}
        >
          Resend Code
        </Button>
      );
    } else {
      return (
        <span className="primary-label">
          {minutes}:{seconds}
        </span>
      );
    }
  };
  const isValid = (values) => {
    if (
      !values.otp ||
      values.otp.length !== MAX_NUM_INPUTS ||
      isNaN(values.otp)
    ) {
      setOtpError(INVALID_OTP);
      return false;
    }
    return true;
  };

  return (
    <Form
      onSubmit={verifyOTP}
      initialValues={{ email }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div>
            <Field
              fullWidth
              required
              name="email"
              component={TextInput}
              type="text"
              label="Email"
              variant="outlined"
              disabled
            />
          </div>
          <p className="primary-label text-center mb-30 mt-30">
            You’re almost there! Please enter the verification code sent to your
            email address
          </p>
          <div>
            <Field
              fullWidth
              required
              name="otp"
              component={OtpInput}
              otpInputClass="otpInput"
              otpParentClass="otpInputContainer"
              numInputBoxes={MAX_NUM_INPUTS}
            />
          </div>
          {otpError && !isSubmitting && (
            <div className="error-block">
              <DisplayError message={otpError} />
            </div>
          )}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="mt-30"
          >
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <Button
                    onClick={cancelOTP}
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className="button"
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    Verify OTP
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" className="mt-30">
            <Grid item>
              <Countdown
                key={otpTimer}
                date={otpTimer}
                renderer={renderResendOtp}
              />
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default VerificationForm;
