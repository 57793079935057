import React from 'react';
import { Paper, Typography, Divider } from '@mui/material';

import OrderProperty from '../../OrderProperty';
import CollapsibleAccordion from '../../CollapsibleAccordion';
import UpdateShippingAddressForm from './components/UpdateShippingAddressForm';
import OrderRMAs from './components/OrderRMAs';
import OrderPropertiesWrapper from '../../OrderProperty/OrderPropertiesWrapper';

function ShipmentSection({ order, canEdit, latestRMACreated, RMAs }) {
  return (
    <Paper className="order-section order-section-shipment">
      <Typography variant="h2" gutterBottom>
        Shipment
      </Typography>
      <CollapsibleAccordion title="Fulfillment">
        <OrderPropertiesWrapper>
          <OrderProperty label="Shipping ID" value={order.shipping?.id} />
          <OrderProperty label="Provider" value={order.shipping?.provider} />
          <OrderProperty label="Method" value={order.shipping?.method} />
          <OrderProperty label="Status" value={order.shipping?.status} />
          <OrderProperty
            label="Tracking link"
            value={order.shipping?.trackingLink}
          />
          <OrderProperty
            label="Shipping cost"
            value={order.shipping?.orderShippingCost}
            isPrice={true}
            currency={order.orderCurrency}
            country={order.orderCountry}
          />
        </OrderPropertiesWrapper>
      </CollapsibleAccordion>
      <OrderRMAs RMAs={RMAs} />
      <Divider className="order-section-divider mb-20 mt-20" />
      <Typography variant="h3" gutterBottom className="mb-20">
        Shipping Address
      </Typography>
      <UpdateShippingAddressForm order={order} canEdit={canEdit} />
    </Paper>
  );
}

export default ShipmentSection;
