import { takeLatest, takeEvery, put, call, select } from 'redux-saga/effects';
import {
  ADD_MAILING_LISTS,
  FETCH_SKU_DETAILS,
  FETCH_MAILING_LISTS,
  SKU_DETAILS_SUCCESS,
  COUNTRY_SKU_DETAILS_SUCCESS,
  FETCH_REFERENCE,
  ADD_REFERENCE_LIST,
  FETCH_REFERENCES,
} from './actions';

import { API } from 'axiosClient/config';
import { SET_LOADER } from 'store/general/actions';
import generalSelectors from 'store/general/selectors';

import { postAPIData } from 'api/playosmo';
import { getAPIDataDifferentOrigin } from 'api/microservices';
import { getReferences } from 'api/products';

export function* handleException(err, errorCallback) {
  let errorCode = 500;
  let error;
  if (err.response) {
    errorCode = err.response.status;
    error = err.response.data?.error;
  }
  if (errorCallback) {
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    errorCallback(errorCode, error);
  }
}

function* fetchSkuDetails(action) {
  const { country, includeAdminSkus, callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const catalogPayload = {
      country: !!country?.length ? country : 'all',
      include_admin_skus: !!includeAdminSkus,
    };
    const skuData = yield call(postAPIData, API.productCatalog, catalogPayload);

    if (!skuData || skuData.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    const getFilteredSkusFromProducts = (products) => {
      let filteredSkus = [];
      const productsValueArray = Object.values(products);
      productsValueArray.map((product) => {
        //Getting skus as array from object
        const skusValueArray = Object.values(product.skus);

        //filtering skus
        const filteredSkuValues = skusValueArray.filter(
          (sku) => sku.prices || sku.price,
        );

        //Pushing SKUS to the filteredSKU
          filteredSkus.push(...filteredSkuValues);
      });
      return filteredSkus;
    };

    const { products, shipping_countries } = skuData.data;
    const filteredSku = getFilteredSkusFromProducts(products);

    let skuDetails = null;

    if (country) {
      skuDetails = {
        products: [...filteredSku],
        country,
      };

      yield put({
        type: COUNTRY_SKU_DETAILS_SUCCESS,
        payload: {
          skuDetails,
        },
      });
    } else {
      const mappedCountries = shipping_countries
        .map((shipping_country) => {
          return {
            id: shipping_country.toUpperCase(),
            value: shipping_country.toUpperCase(),
          };
        })
        .sort((a, b) => (a.id > b.id ? 1 : -1));

      skuDetails = {
        products: [...filteredSku],
        shipping_countries: mappedCountries,
      };

      yield put({
        type: SKU_DETAILS_SUCCESS,
        payload: {
          skuDetails,
        },
      });
    }
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback(skuDetails);
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* fetchMailingLists(action) {
  const { callback, errorCallback } = action.payload;

  // If the lists are already loaded, we don't need to fetch them again
  const mailingListState = yield select(generalSelectors.getMailingLists);
  if (mailingListState) {
    return;
  }

  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const mailingListsData = yield call(
      getAPIDataDifferentOrigin,
      API.microservices.communications.emailSubscriptions.getLists,
    );

    if (!mailingListsData || mailingListsData.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: ADD_MAILING_LISTS,
      payload: {
        lists: mailingListsData.data.data,
      },
    });
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback(mailingListsData);
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* fetchReference(action) {
  const { type, callback, errorCallback } = action.payload;

  // If the lists are already loaded, we don't need to fetch them again
  const references = yield select(generalSelectors.getReferences);
  if (references && references[type]) {
    return;
  }

  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const references = yield call(getReferences, type);

    if (!references || references.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: ADD_REFERENCE_LIST,
      payload: {
        type,
        data: references.data.data,
      },
    });
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback(references);
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* fetchReferences(action) {
  const { types } = action.payload;

  if (!types?.length) {
    return;
  }

  for (let index = 0; index < types.length; index++) {
    const type = types[index];

    yield put({
      type: FETCH_REFERENCE,
      payload: {
        type,
      },
    });
  }
}

export function* generalSaga() {
  yield takeLatest(FETCH_SKU_DETAILS, fetchSkuDetails);
  yield takeLatest(FETCH_MAILING_LISTS, fetchMailingLists);
  yield takeEvery(FETCH_REFERENCE, fetchReference);
  yield takeLatest(FETCH_REFERENCES, fetchReferences);
}
