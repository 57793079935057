const getContent = (state) => state.content;
const getBanners = (state) => state.content?.banners?.data;
const getBannerById = (state, id) => {
  const data = state.content?.banners?.data || {};
  return data[id];
};
const getBannersArray = (state) => {
  const data = state.content?.banners?.data || {};
  return Object.values(data);
};
const getBannersPagination = (state) => state.content?.banners?.pagination;

const contentSelectors = {
  getContent,
  getBanners,
  getBannersArray,
  getBannersPagination,
  getBannerById,
};

export default contentSelectors;
