export const tableConfig = [
  {
    title: 'Campaign Name',
    id: 'campaign_name',
  },
  {
    title: 'Country',
    id: 'countries',
  },
  {
    title: 'URL',
    id: 'url',
  },
];

export const TableCellStates = {
  CAMPAIGN_NAME: 'campaign_name',
  COUNTRIES: 'countries',
  URL: 'url',
};
