const normalizeInventory = (data) =>
  data?.reduce((acc, item) => {
    const statusValue = item.is_available
      ? 'is_available'
      : item.notify_when_available
      ? 'notify_when_available'
      : 'not_available';
    const currentCountryValue =
      acc.find((a) => a.country === item.country_code) || {};
    return [
      ...acc,
      {
        ...currentCountryValue,
        country: item.country_code,
        values: {
          ...(currentCountryValue?.values || {}),
          [item.website]: {
            status: statusValue,
            isNew: item.show_is_new_badge,
          },
        },
      },
    ];
  }, []);

export default normalizeInventory;
