const normalizeShippingMethodsResponse = (data) => {
  return data.map((method) => ({
    id: method.shipping_method_code + method.country_code,
    basePrice: method.base_price,
    countryCode: method.country_code,
    delayDays: method.delay_days.join('-'),
    displayAsNewThreshold: method.display_as_new_threshold,
    freeShippingThreshold: method.free_shipping_threshold,
    incrementPrice: method.increment_price,
    shippingMethodCode: method.shipping_method_code,
  }));
};

export default normalizeShippingMethodsResponse;
