const normalizeImportedOrder = (order, purchasableProducts) => {
  const purchasableProductsKeys = Object.values(purchasableProducts).map(
    (p) => p.id,
  );
  const skus = Object.keys(order.cart).filter((skuKey) =>
    purchasableProductsKeys.includes(skuKey),
  );
  const skusQties = Object.keys(order.cart).reduce((acc, skuKey) => {
    if (!purchasableProductsKeys.includes(skuKey)) {
      return acc;
    }

    return {
      ...acc,
      [`${skuKey}-qty`]: order.cart[skuKey].quantity,
    };
  }, {});

  return {
    email: order.order_email,
    coupon: order.order_coupon,
    country: order.shipping_country?.length ? order.shipping_country : null,
    zip: order.shipping_zip?.length ? order.shipping_zip : null,
    price_paid: order.order_price_paid,
    shipping_cost: order.order_shipping_cost,
    description: order.order_description,
    skus,
    ...skusQties,
    shipping: {
      shipping_first_name: order.shipping_first_name?.length
        ? order.shipping_first_name
        : null,
      shipping_last_name: order.shipping_last_name?.length
        ? order.shipping_last_name
        : null,
      shipping_phone: order.shipping_phone?.length
        ? order.shipping_phone
        : null,
      shipping_address_1: order.shipping_address_1?.length
        ? order.shipping_address_1
        : null,
      shipping_address_2: order.shipping_address_2?.length
        ? order.shipping_address_2
        : null,
      shipping_city: order.shipping_city?.length ? order.shipping_city : null,
      shipping_state: order.shipping_state?.length
        ? order.shipping_state
        : null,
    },
    billing: {
      billing_first_name: order.billing_first_name.length
        ? order.billing_first_name
        : null,
      billing_last_name: order.billing_last_name.length
        ? order.billing_last_name
        : null,
      billing_phone: order.billing_phone.length ? order.billing_phone : null,
      billing_address_1: order.billing_address_1.length
        ? order.billing_address_1
        : null,
      billing_address_2: order.billing_address_2.length
        ? order.billing_address_2
        : null,
      billing_city: order.billing_city.length ? order.billing_city : null,
      billing_country: order.billing_country.length
        ? order.billing_country
        : null,
      billing_state: order.billing_state.length ? order.billing_state : null,
      billing_zip: order.billing_zip.length ? order.billing_zip : null,
    },
  };
};

export default normalizeImportedOrder;
