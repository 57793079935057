import React, { useState, useEffect } from 'react';
import { Paper, Typography } from '@mui/material';

import { postAPIData } from 'api/playosmo';
import { API } from 'axiosClient/config';

import OrderProperty from '../../OrderProperty';
import OrderPropertiesWrapper from '../../OrderProperty/OrderPropertiesWrapper';

function OverviewSection({ order }) {
  const [totalOrders, setTotalOrders] = useState(0);
  useEffect(() => {
    const getTotalNumberOfOrders = async () => {
      try {
        const payload = {
          email: order && order.email,
        };
        if (payload.email) {
          const response = await postAPIData(API.orders.query, payload);
          return response && response.data && response.data.total;
        }
      } catch (e) {}
    };
    getTotalNumberOfOrders().then((response) => {
      setTotalOrders(response);
    });
  }, [order]);

  return (
    <Paper className="order-section order-section-overview">
      <Typography variant="h2" gutterBottom>
        Overview
      </Typography>
      <OrderPropertiesWrapper>
        {order.description ? (
          <OrderProperty label="Description" value={order.description} />
        ) : (
          ''
        )}
        <OrderProperty label="Status" value={order.status} />
        {!!order.refundedAmount && (
          <OrderProperty
            label="Refunded amount"
            isPrice={true}
            currency={order.orderCurrency}
            country={order.orderCountry}
            value={order.refundedAmount}
          />
        )}
        {!!order.orderTotalTaxPaid && (
          <OrderProperty
            label="Tax Paid"
            isPrice={true}
            currency={order.orderCurrency}
            country={order.orderCountry}
            value={order.orderTotalTaxPaid}
          />
        )}
        <OrderProperty label="Shipping status" value={order.shipping?.status} />
        <OrderProperty label="Country" value={order.shipping?.country} />
        <OrderProperty
          label="Price paid"
          value={order.pricePaid}
          isPrice={true}
          currency={order.orderCurrency}
          country={order.orderCountry}
        />
        <OrderProperty
          label="Referer Account"
          value={order.refererAccountEmail}
        />
        <OrderProperty
          label="Referral Discount"
          value={order.referralDiscount}
        />
        {order && order.coupon ? (
          <OrderProperty
            label="Coupon"
            value={order && order.coupon && order.coupon.toString()}
          />
        ) : (
          <></>
        )}

        <OrderProperty
          label="Payment Provider"
          value={order.billing?.provider}
        />
        <OrderProperty
          label="Payment Method"
          value={order.billing?.paymentMethod}
        />
        <OrderProperty label="Email trust" value={order.emailTrust} />
        <OrderProperty label="Orders for this Customer" value={totalOrders} />
      </OrderPropertiesWrapper>
    </Paper>
  );
}

export default OverviewSection;
