export const tableConfig = [
  {
    title: 'Status',
    id: 'status_indicator',
  },
  {
    title: 'Campaign Name',
    id: 'campaign_name',
  },
  {
    title: 'Country',
    id: 'countries',
  },
  {
    title: 'Coupon ID',
    id: 'id',
  },
  {
    title: 'URL',
    id: 'url',
  },
  {
    title: 'Start Date',
    id: 'starts_on',
  },
  {
    title: 'End Date',
    id: 'expires_on',
  },
  {
    title: 'Actions',
    id: 'actions',
    sorting: false,
    actions: [
      {
        text: 'Edit',
        permissions: ['EDIT'],
        type: 'Edit',
      },
      {
        text: 'Review',
        type: 'Review',
      },
      {
        text: 'Delete',
        type: 'Delete',
      },
      {
        text: 'Duplicate',
        type: 'Duplicate',
      },
    ],
  },
];

export const tableToolBarConfig = [
  {
    type: 'DeleteButton',
    label: 'Delete',
    onSelect: true,
  },
];

export const tableToolBarStates = {
  DeleteButton: 'DeleteButton',
};

export const TableCellStates = {
  STATUS_INDICATOR: 'status_indicator',
  ACTIONS: 'actions',
  COUNTRIES: 'countries',
  START_DATE: 'starts_on',
  END_DATE: 'expires_on',
  URL: 'url',
};
