import { Field } from 'react-final-form';
import { Paper, Typography } from '@mui/material';
import FileInputForm from 'components/common/FileInput/FileInputForm';
import OrderPropertiesWrapper from 'components/containers/OrdersContainer/OrderView/components/OrderProperty/OrderPropertiesWrapper';
import OrderPropertyInput from 'components/containers/OrdersContainer/OrderView/components/OrderPropertyInput';

const AppSection = ({ game, inputBase }) => {
  const handleIconLoad = () => {};
  const handleError = () => {};
  return (
    <Paper className="p20">
      <Typography variant="h2" gutterBottom>
        App
      </Typography>
      <div className="mb-10">
        <strong>Icon</strong>
        <Field
          service="games-management"
          name="app_icon"
          component={FileInputForm}
          deleteFromServer={false}
          onError={handleError}
          onLoad={handleIconLoad}
          tags={{
            type: 'app-icon',
            app: game?.name,
          }}
        />
      </div>
      <OrderPropertiesWrapper>
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'ios_app_link',
            required: true,
          }}
          label="App store link"
        />
        <OrderPropertyInput
          input={{
            ...inputBase,
            name: 'fire_app_link',
            required: true,
          }}
          label="Amazon Fire store link"
        />
      </OrderPropertiesWrapper>
    </Paper>
  );
};

export default AppSection;
