import { createTheme, alpha } from '@mui/material/styles';

import osmoColors from 'utils/ui/colors';

const baseTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: osmoColors.grey[70],
    },
    primary: {
      main: osmoColors.blue[50],
      dark: osmoColors.blue[40],
      light: osmoColors.blue[60],
      contrastText: osmoColors.white,
    },
    secondary: {
      main: osmoColors.yellow[50],
      dark: osmoColors.yellow[40],
      light: osmoColors.yellow[60],
      contrastText: osmoColors.grey[10],
    },
    error: {
      main: osmoColors.red[50],
      dark: osmoColors.red[40],
      light: osmoColors.red[60],
      contrastText: osmoColors.white,
    },
    warning: {
      main: osmoColors.orange[50],
      dark: osmoColors.orange[50],
      light: osmoColors.orange[50],
      contrastText: osmoColors.grey[10],
    },
    info: {
      main: osmoColors.blue[60],
      dark: osmoColors.blue[40],
      light: osmoColors.blue[70],
      contrastText: osmoColors.blue[10],
    },
    grey: {
      main: osmoColors.grey[60],
      dark: osmoColors.grey[40],
    },
  },
});

export const baseProperties = {
  shape: {
    borderRadius: 12,
  },
  typography: {
    fontSize: 14,
    h1: {
      fontSize: '2.4rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.9rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.3rem',
    },
    h5: {
      fontSize: '1.1rem',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: osmoColors.grey[20],
          backgroundColor: osmoColors.grey[80],
        },
        sticky: {
          backgroundColor: osmoColors.grey[80],
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: osmoColors.blue[50],
        },
      },
    },
    MuiDivider: {
      defaultProps: {
        light: true,
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 1,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
        color: 'grey',
      },
      variants: [
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: baseTheme.palette.getContrastText(
              baseTheme.palette.grey[300],
            ),
          },
        },
        {
          props: { variant: 'outlined', color: 'grey' },
          style: {
            color: baseTheme.palette.text.primary,
            borderColor:
              baseTheme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.23)'
                : 'rgba(255, 255, 255, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${baseTheme.palette.action.disabledBackground}`,
            },
            '&:hover': {
              borderColor:
                baseTheme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(
                baseTheme.palette.text.primary,
                baseTheme.palette.action.hoverOpacity,
              ),
            },
          },
        },
        {
          props: { color: 'grey', variant: 'text' },
          style: {
            color: baseTheme.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha(
                baseTheme.palette.text.primary,
                baseTheme.palette.action.hoverOpacity,
              ),
            },
          },
        },
      ],
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 220,
          fontSize: 'normal',
        },
      },
    },
  },
};

const theme = createTheme(baseTheme, baseProperties);

export default theme;
