import React from 'react';
import { Button, Skeleton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { getLocalDateFromUTC, formatDate } from 'utils';
import paths from 'routes/paths';

import TableComponent from 'components/common/TableComponent';

import { tableConfig, TableCellStates } from './tableConfig';

import './styles.scss';

function normalizeCreatedByColumn(order) {
  if (order.createdBy === 'splitorder') {
    return 'Split order';
  }
  if (order.source && order.source.includes('subscription_')) {
    return 'Subscription sub-order';
  }
  if (order.source && order.source === 'admin') {
    return 'Manual order';
  }
  return 'Order';
}

const OrdersTable = (props) => {
  const { rows, isLoading } = props;
  const rowsWithKeys = rows.map((row) => ({ ...row, id: row.orderId }));

  const options = {
    isRowSelectable: false,
    selectionKey: 'id',
  };

  const headCells = tableConfig.map((cell, i) => {
    switch (cell.id) {
      case TableCellStates.ACTIONS:
        cell.render = (record, index) => {
          return (
            <Button
              key={`${i}-${index}`}
              href={`${paths.orders.view}/${record[TableCellStates.ORDER_ID]}`}
              color="primary"
              variant="text"
            >
              View <SearchIcon />
            </Button>
          );
        };
        return cell;
      case TableCellStates.ORDER_ID:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {record[TableCellStates.ORDER_ID]}
            </span>
          );
        };
        return cell;
      case TableCellStates.WEBSITE:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {record[TableCellStates.WEBSITE]
                ? record[TableCellStates.WEBSITE]
                : '-'}
            </span>
          );
        };
        return cell;
      case TableCellStates.EMAIL:
        cell.render = (record, index) => {
          return (
            <span className="cell-text-short" key={`${i}-${index}`}>
              {record[TableCellStates.EMAIL]}
            </span>
          );
        };
        return cell;
      case TableCellStates.CREATED_BY:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {normalizeCreatedByColumn(record)}
            </span>
          );
        };
        return cell;
      case TableCellStates.CREATED_ON:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>
              {formatDate(
                getLocalDateFromUTC(record[TableCellStates.CREATED_ON]),
              )}
            </span>
          );
        };
        return cell;
      case TableCellStates.SHIPPING_ADDRESS:
        cell.render = (record, index) => {
          return (
            <span className="cell-text-short" key={`${i}-${index}`}>
              {record[TableCellStates.SHIPPING_ADDRESS]}
            </span>
          );
        };
        return cell;
      case TableCellStates.STATUS:
        cell.render = (record, index) => {
          return (
            <span key={`${i}-${index}`}>{record[TableCellStates.STATUS]}</span>
          );
        };
        return cell;

      default:
        return cell;
    }
  });

  if (isLoading) {
    return <Skeleton height={300} animation="wave" variant="rectangular" />;
  }

  return (
    <TableComponent
      rows={rowsWithKeys}
      headCells={headCells}
      options={options}
      className="orders-table"
    />
  );
};

export default OrdersTable;
